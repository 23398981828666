import React, { useState } from "react";
import MTypography from "../MTypography";

interface Props {
  content: string;
  maxLength?: number;
  textVariant: any;
  textCustomClass: string;
}

const ShowMoreLess: React.FC<Props> = (props) => {
  const { content, maxLength = 15, textVariant, textCustomClass } = props;
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={"d-flex justify-content-between"}>
      <div className={"d-flex w-100"}>
        <MTypography variant={textVariant} customClass={textCustomClass}>
          {content.length > 0 ? (
            <>
              {expanded ? content : `${content.slice(0, maxLength)}`}
              {content.length > maxLength && (
                <span onClick={handleToggle} style={{ fontSize: "10px", color: "#E6B540" }}>
                  {expanded ? " ...See Less" : " ...See More"}
                </span>
              )}
            </>
          ) : (
            ""
          )}
        </MTypography>
      </div>
    </div>
  );
};

export default ShowMoreLess;
