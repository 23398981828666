import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { initialValue, inputName, ClientProfileObject } from "./config";
import FormTextField from "../../../Molecules/FormTextField";
import FormPhoneInput from "../../../Molecules/FormPhoneInput/FormPhoneInput";
import HeadingUnderline from "../../../Molecules/HeadingUnderline";
// import FormSwitch from "../../../Molecules/FormSwitch/FormSwitch";
import classes from "./index.module.css";
import MButton from "../../../Atoms/MButton";
import MainLoader from "../../../Atoms/MainLoader";
import { UserApis } from "../../../../apis/user";
import { toast } from "react-toastify";
import { SUCC_COMPANY_PROFILE_UPDATED, SUCC_USER_PROFILE_UPDATED } from "../../../../utils/constants/messages/success";
import { CompanyApis } from "../../../../apis/companies";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { errorHandler } from "../../../../utils/helpers/apis";
import ToastError from "../../../Atoms/ToastError";
import { useDeviceType } from "../../../../hooks/useDeviceType";

interface BasicDetailsProps {
  userData: any;
  isLoading: boolean;
  isCompany: boolean;
  type: "company" | "individual";
}

interface LimitsType {
  asinLimit: number;
  keywordRefreshes: number;
  contentRegeneration: number;
  countAsinLimit: number;
  countKeywordRefreshes: number;
  countContentRegeneration: number;
}
const BasicDetails: React.FC<BasicDetailsProps> = (props) => {
  const { userData, isLoading, isCompany } = props;
  const [initialVals, setInitialVals] = useState(initialValue);

  const [limits, setLimits] = useState<LimitsType>();
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const { isDesktop } = useDeviceType();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const keywordTracking = isCompany ? userData?.company?.keywordTracking : userData?.keywordTracking;
    const asinLimit = isCompany ? userData?.company?.contentGenerationLimit : userData?.contentGenerationLimit;
    const keywordRefreshes = isCompany ? userData?.company?.helium10CallsLimit : userData?.helium10CallsLimit;
    const contentRegeneration = isCompany
      ? userData?.company?.contentRegenerationLimit
      : userData?.contentRegenerationLimit;
    // counts
    const countAsinLimit = isCompany ? userData?.company?.countContentGeneration : userData?.countContentGeneration;
    const countKeywordRefreshes = isCompany ? userData?.company?.countHelium10Calls : userData?.countHelium10Calls;
    const countContentRegeneration = isCompany
      ? userData?.company?.countContentRegeneration
      : userData?.countContentRegeneration;

    setLimits({
      asinLimit,
      keywordRefreshes,
      contentRegeneration,
      countAsinLimit,
      countKeywordRefreshes,
      countContentRegeneration,
    });
    setInitialVals({
      ...initialVals,
      name: capitalizeEachLetter((isCompany ? userData?.company?.name : userData?.name) || ""),
      email: userData?.email || "",
      phoneNo: userData?.phoneNumber || "",
      keywordTracking: keywordTracking || true,
      asinLimit: asinLimit || 0,
      keywordRefreshes: keywordRefreshes || 0,
      contentRegeneration: contentRegeneration || 0,
    });
  }, [userData]);

  const submitForm = async (values: ClientProfileObject) => {
    try {
      setIsFormLoading(true);
      if (!isCompany) {
        const res = await UserApis.updateUserSettingsWithError({
          userID: userData?._id,
          name: values.name,
          helium10CallsLimit: parseInt(values.keywordRefreshes as any) || 0,
          contentGenerationLimit: parseInt(values.asinLimit as any) || 0,
          contentRegenerationLimit: parseInt(values.contentRegeneration as any) || 0,
        });
        // @ts-ignore
        if (res) {
          setIsFormLoading(false);
          toast.success(SUCC_USER_PROFILE_UPDATED);
        }
      } else {
        const res = await CompanyApis.updateCompanyProfile({
          name: values.name,
          helium10CallsLimit: parseInt(values.keywordRefreshes as any) || 0,
          contentGenerationLimit: parseInt(values.asinLimit as any) || 0,
          contentRegenerationLimit: parseInt(values.contentRegeneration as any) || 0,
          companyID: userData?.company?._id,
          phoneNumber: values.phoneNo,
          // keywordTracking: values.keywordTracking,
        });
        // @ts-ignore
        if (res) {
          setIsFormLoading(false);
          toast.success(SUCC_COMPANY_PROFILE_UPDATED);
        }
      }
    } catch (e) {
      console.log("error Submit Form", errorHandler(e));
      toast.warning(<ToastError error={e} />);
      setIsFormLoading(false);
    }
  };

  return (
    <div className={`${isDesktop ? "mb-4" : classes.MobileContainer}`}>
      {isLoading ? (
        <MainLoader />
      ) : (
        <Formik enableReinitialize initialValues={initialVals} onSubmit={submitForm}>
          {({ handleSubmit, dirty }) => (
            <Form autoComplete="off">
              <div>
                <div>
                  <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                      <FormTextField
                        fullWidth
                        disabled
                        name={inputName.name}
                        label={isCompany ? "Company Name" : "Full Name"}
                      />
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                      <FormTextField fullWidth name={inputName.email} label={"Work Email"} disabled />
                    </div>
                    {/* <div className={"col-md-6 col-sm-12"}>
                    <FormTextField fullWidth name={inputName.userName} label={"User Name*"} />
                  </div> */}
                    {isCompany && (
                      <div className={"col-md-6 col-sm-12 mt-3"}>
                        <FormPhoneInput label={"Phone Number (Optional)"} name={inputName.phoneNo} disabled />
                      </div>
                    )}
                  </div>

                  {/*<HeadingUnderline content={"Keyword Tracking"} />*/}
                  {/*<FormSwitch*/}
                  {/*  label={"By enabling this feature, users gain access to tracking keywords on a weekly basis."}*/}
                  {/*  name={inputName.keywordTracking}*/}
                  {/*  isLabelRight*/}
                  {/*/>*/}

                  <>
                    <HeadingUnderline content={"Set Limits"} />
                    <div className={"row"}>
                      <div className={"col-md-6 col-sm-12"}>
                        <div className={"d-flex justify-content-end "}>
                          <p className={`m-0 ${classes.LimitText}`}>
                            {limits?.countAsinLimit || 0}/{limits?.asinLimit || 0}
                          </p>
                        </div>
                        <FormTextField type={"number"} fullWidth name={inputName.asinLimit} label={"ASIN Limit"} />
                      </div>

                      <div className={"col-md-6 col-sm-12"}>
                        <div className={"d-flex justify-content-end "}>
                          <p className={`m-0 ${classes.LimitText}`}>
                            {limits?.countKeywordRefreshes || 0}/{limits?.keywordRefreshes || 0}
                          </p>
                        </div>
                        <FormTextField
                          type={"number"}
                          fullWidth
                          name={inputName.keywordRefreshes}
                          label={"Keyword Refreshes"}
                        />
                      </div>

                      <div className={"col-md-6 col-sm-12"}>
                        <div className={"d-flex justify-content-end "}>
                          <p className={`m-0 ${classes.LimitText}`}>
                            {limits?.countContentRegeneration || 0}/{limits?.contentRegeneration || 0}
                          </p>
                        </div>
                        <FormTextField
                          type={"number"}
                          fullWidth
                          name={inputName.contentRegeneration}
                          label={"Content Regeneration"}
                        />
                      </div>
                    </div>
                  </>
                </div>
                <div className={"d-flex justify-content-end pb-4 pt-2"}>
                  <MButton
                    isLoading={isFormLoading}
                    size={"small"}
                    variant={"contained"}
                    onClick={handleSubmit}
                    disabled={!dirty}
                  >
                    Update
                  </MButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default BasicDetails;
