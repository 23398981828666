import React from "react";

import MTypography from "../../../Atoms/MTypography";
import Space from "../../../Atoms/Space";

import CheckIcon from "../../../../assets/svgs/checked.svg";
import classes from "./index.module.css";
import { formatNumberWithCommas } from "../../../../utils/helpers/numbers";

interface Props {
  row: any;
  index: number;
  onClick: (e: number) => void;
  isActive: boolean;
}
const KeywordCardM: React.FC<Props> = (props) => {
  const { row, index, onClick, isActive = false } = props;

  const { currentAsin } = row;

  const getRelvencyClass = () =>
    row.relevancy >= 90
      ? classes.Green
      : row.relevancy >= 80 && row.relevancy <= 89
      ? classes.LightGreen
      : classes.Grey;
  return (
    <div
      className={`d-flex flex-row gap-2 w-100 ${isActive ? classes.ActiveContainer : classes.Container}`}
      onClick={() => onClick(index)}
    >
      <div>
        {isActive ? <img src={CheckIcon} width={20} height={20} alt="" /> : <div className={classes.EmptyIcon} />}
      </div>
      <div className={"d-flex flex-grow-1 flex-column"}>
        <MTypography variant={"subtitle2"} customClass={classes.HeadingText}>
          {row.phrase}
        </MTypography>
        <Space top={"16px"} />
        <div className={"d-flex flex-row"}>
          <div className={"d-flex w-50 flex-column "}>
            <MTypography variant={"subtitle2"} customClass={classes.HeadingText}>
              {formatNumberWithCommas(row.searchVolume)}
            </MTypography>
            <MTypography variant={"caption"} customClass={classes.CaptionText}>
              Search Volume
            </MTypography>
          </div>
          <div className={"d-flex w-50 flex-column "}>
            <MTypography variant={"subtitle2"} customClass={classes.HeadingText}>
              {row[currentAsin]}
            </MTypography>
            <MTypography variant={"caption"} customClass={classes.CaptionText}>
              Your Rank
            </MTypography>
          </div>
        </div>
        <Space top={"14px"} />
        <div className={"d-flex flex-row"}>
          {row.relevancy && (
            <div className={"d-flex w-50 flex-column "}>
              <MTypography variant={"subtitle2"} customClass={`${classes.HeadingText} ${getRelvencyClass()}`}>
                {row.relevancy || "--"}
              </MTypography>
              <MTypography variant={"caption"} customClass={classes.CaptionText}>
                Relevancy
              </MTypography>
            </div>
          )}

          {row?.titleRarity && (
            <div className={"d-flex w-50 flex-column"}>
              <MTypography variant={"subtitle2"} customClass={classes.HeadingText}>
                {row.titleRarity || "--"}
              </MTypography>
              <MTypography variant={"caption"} customClass={classes.CaptionText}>
                Title Rarity
              </MTypography>
            </div>
          )}
        </div>
        <Space top={"14px"} />

        <div className={"d-flex flex-row"}>
          {row?.average && (
            <div className={"d-flex w-50 flex-column "}>
              <MTypography variant={"subtitle2"} customClass={`${classes.HeadingText} ${getRelvencyClass()}`}>
                {row.average || "--"}
              </MTypography>
              <MTypography variant={"caption"} customClass={classes.CaptionText}>
                Average
              </MTypography>
            </div>
          )}
          {row?.competitiveness && (
            <div className={"d-flex w-50 flex-column"}>
              <MTypography variant={"subtitle2"} customClass={classes.HeadingText}>
                {row.competitiveness || "--"}
              </MTypography>
              <MTypography variant={"caption"} customClass={classes.CaptionText}>
                Rank Ease
              </MTypography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordCardM;
