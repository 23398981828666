import axios, { AxiosError, AxiosInstance } from "axios";
// import { KEY_USER_ACCESS_TOKEN } from "./utils/constants/store";
import { getAuth } from "firebase/auth";
import app from "./firebase/firebase";
import { signOutHandler } from "./firebase/auth";

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 0,
});

// @ts-ignore

// const refreshAccessToken = async () => {
//   try {
//     const user = getAuth(app).currentUser;
//     if (user) {
//       const token = await user.getIdToken(/* forceRefresh */ true);
//       localStorage.setItem(KEY_USER_ACCESS_TOKEN, token);
//       return token;
//     }
//     return null;
//   } catch (error) {
//     console.error("Failed to refresh access token:", error);
//     return null;
//   }
// };

instance.interceptors.request.use(
  async (config) => {
    const user: any = getAuth(app).currentUser;

    // let token = localStorage.getItem(KEY_USER_ACCESS_TOKEN);
    // if (token && user) {
    //   // Check if token is expired
    //   const tokenExpiration = user.stsTokenManager.expirationTime;
    //   const currentTime = Math.floor(Date.now());
    //
    //   if (tokenExpiration && tokenExpiration < currentTime) {
    //     // Token is expired, refresh it
    //     token = await refreshAccessToken();
    //   }
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403 && "data" in error.response) {
      if (error.response.data.error === "4030") {
        signOutHandler(false);
      }
    }
    if (error.hasOwnProperty("name") && error?.name === "AxiosError" && !error.hasOwnProperty("response")) {
      return Promise.reject("Network Error");
    }
    return Promise.reject(error);
  }
);

export default instance;
