import React, { useMemo, useContext } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Backdrop, BottomNavigation, BottomNavigationAction, Divider, Grid, Paper } from "@mui/material";

import {
  URL_CONGRATS,
  URL_LANDING_PAGE,
  URL_PRODUCTS,
  URL_PRODUCT_CATALOG,
  URL_TEAM_MEMBERS,
  URL_UNSUBSCRIBE,
} from "../../routes/routes-path";

import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { CatalogEnum, ParamsEnum, RolesEnum, TabsEnum } from "../../utils/constants/enums";

import AppBar from "../Molecules/AppBar";
import SignOutPopper from "../Organisms/SignOutPopper";
import MTypography from "../Atoms/MTypography";
import MuiTooltip from "../Atoms/MuiTooltip";
import UpgradeCard from "../Molecules/UpgradeCard";

import { navigatePage } from "../../utils/helpers/common";

import {
  ScreenTabs,
  getLayoutHeightForUpgradeBanner,
  getTabTitle,
  isEnableNav,
  isEnableNavOnMobile,
  isSignInOrSignUpMobile,
  setTabOnReload,
  showUpgradeBannerForCompany,
} from "./config";

import {
  showAccountManagementTab,
  showAuthorizedBarOnLayout,
  showAuthorizedBarOnLayoutCompany,
} from "../../utils/helpers/priviligesChecks";

import PricingContext from "../../context/PricingContext";
import { DbUserSelector } from "../../store/user/user.selector";
import { CompanySelector } from "../../store/company/company.selector";
import { AppState } from "../../store/reducers";
import { useSidebarToggle } from "../../hooks/useSidebarToggle";
import { useDeviceType } from "../../hooks/useDeviceType";
import * as previliges from "../../utils/helpers/priviligesChecks";

import "./index.style.scss";
import classes from "./index.module.css";
import { useTopBarHeight } from "../../hooks/useTopBarHeight";

type LandingPageState = {
  anchorEl: HTMLImageElement | null;
  openPopper: boolean;
  loading: boolean;
  openTab: string;
};

interface Props {
  children: JSX.Element;
}

const Layout: React.FC<Props> = (props) => {
  const { onOpen } = useContext(PricingContext);
  const userRole = useSelector((state: AppState) => state.user.role);
  const currentUser = useSelector(DbUserSelector);
  const { setTopBarHeight } = useTopBarHeight();


  const params = useParams();
  const location = useLocation();
  const currentUrl = location.pathname;

  const [state, setState] = useState<LandingPageState>({
    anchorEl: null,
    openPopper: false,
    loading: false,
    openTab: params[ParamsEnum.TAB] || TabsEnum.SAVED,
  });

  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      currentUrl === URL_UNSUBSCRIBE ||
      currentUrl === URL_CONGRATS ||
      currentUrl === URL_TEAM_MEMBERS ||
      currentUrl === URL_PRODUCTS
    ) {
      document.title = getTabTitle(currentUrl);
    }
  }, [currentUrl]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState({ ...state, openTab: params[ParamsEnum.TAB] || TabsEnum.SAVED });
  }, [params[ParamsEnum.TAB]]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState({ ...state, openTab: setTabOnReload(window.location.pathname) });
  }, []);

  const isNav = useMemo(() => {
    if (currentUrl) {
      const activeUrl = currentUrl.split("/")[1];
      setState({ ...state, openTab: activeUrl });
    }
    return isEnableNav(currentUrl);
  }, [currentUrl]);

  const isNavOnDetailsPage = useMemo(() => {
    return currentUrl.includes(`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}`);
  }, [currentUrl]);

  const hideAuthorizeBar = useMemo(() => {
    return currentUrl === "/amazon";
  }, [currentUrl]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (state.openTab === TabsEnum.MORE && isDesktop) {
      navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate);
    }
  }, [isDesktop, state.openTab]);

  const { showDrawer, setShowDrawer } = useSidebarToggle();

  const currentCompany = useSelector(CompanySelector);

  const [appBarHeight, setAppBarHeight] = useState("5rem");
  const [appBarHeightM, setAppBarHeightM] = useState("54px");
  const [showUpgradeBanner, setShowUpgradeBanner] = useState<boolean>(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentUser?.companyID && currentCompany) {
      const barHeight = getLayoutHeightForUpgradeBanner(
        true,
        currentCompany?.countContentGeneration,
        currentCompany?.contentGenerationLimit,
        userRole,
        currentCompany?.role
      );

      let height = `${barHeight.appBarHeight}px`;
      let heightM = `${barHeight.appBarHeightM}px`;
      if (
        showAuthorizedBarOnLayoutCompany(currentUser?.role, currentCompany?.role) &&
        !currentUser?.sellerPartnerAuthorized &&
        !hideAuthorizeBar
        // && process.env.REACT_APP_ENV === "development"
      ) {
        height = `${barHeight.appBarHeight + 60}px`;
        heightM = `${barHeight.appBarHeightM + 52}px`;
      }

      setAppBarHeight(height);
      setAppBarHeightM(heightM);

      // hook to setting top bar height
      setTopBarHeight({mobile: heightM, desktop: height})
    } else {
      const barHeight = getLayoutHeightForUpgradeBanner(
        false,
        currentUser?.countContentGeneration,
        currentUser?.contentGenerationLimit,
        userRole
      );

      let height = `${barHeight.appBarHeight}px`;
      let heightM = `${barHeight.appBarHeightM}px`;

      if (
        showAuthorizedBarOnLayout(currentUser?.role, currentUser?.email) &&
        !currentUser?.sellerPartnerAuthorized &&
        !hideAuthorizeBar 
        // && process.env.REACT_APP_ENV === "development"
      ) {
        height = `${barHeight.appBarHeight + 60}px`;
        heightM = `${barHeight.appBarHeightM + 52}px`;
      }
      setAppBarHeight(height);
      setAppBarHeightM(heightM);
      setTopBarHeight({mobile: heightM, desktop: height})
    }
  }, [currentUser, currentCompany, hideAuthorizeBar]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (userRole) {
      if (currentUser?.companyID) {
        const show = showUpgradeBannerForCompany(userRole, currentCompany, "sidebar_banner");
        setShowUpgradeBanner(show);
      } else {
        setShowUpgradeBanner(userRole === RolesEnum.USER);
      }
    }
  }, [userRole, currentCompany]);

  if (isDesktop) {
    return (
      <>
        {isNav ? (
          <>
            <Grid container sx={{ background: "#f8f9fd" }}>
              <Grid item xs={12} minHeight={`${appBarHeight}`} sx={{ position: "fixed", zIndex: 4, width: "100%" }}>
                <AppBar rightElement={<SignOutPopper />} showMenuButton={isNav} handleToggleSideBar={setShowDrawer} />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: `${appBarHeight}`, overflowY: "auto", maxHeight: `calc(100vh - ${appBarHeight})` }}
              >
                <Box component={"div"} className="landing-page">
                  <Box component={"div"}>
                    <Drawer
                      variant={"permanent"}
                      className="lp-drawer"
                      sx={
                        isNavOnDetailsPage
                          ? {
                              width: `${showDrawer ? "16rem" : "0px"} !important`,
                              position: `absolute !important`,
                              zIndex: 2, // it is effecting backdrop
                            }
                          : { width: `${showDrawer ? "16rem" : "100px"} !important` }
                      }
                    >
                      <Box className={"d-flex flex-column h-100 justify-content-between"}>
                        <List>
                          {ScreenTabs.getScreenTabs(isDesktop).map((item, index) => {
                            if (index === 3 && !showAccountManagementTab(userRole)) {
                              return null;
                            }
                            if (
                              item.name === TabsEnum.CONTACT_US &&
                              previliges.userRole.isAdminOrEditorOrInternalOrOwner(userRole)
                            ) {
                              return null;
                            }

                            return (
                              <div key={index}>
                                {index === 5 && <Divider className={"my-2"} />}
                                <ListItem
                                  key={item.name}
                                  disablePadding
                                  className="lp-drawer-list-item"
                                  sx={index === 0 ? { marginTop: `${appBarHeight}` } : {}}
                                >
                                  <ListItemButton
                                    onClick={() => {
                                      document.title = capitalizeEachLetter(
                                        item.name === TabsEnum.SAVED
                                          ? TabsEnum.PRODUCTS.replace("-", " ")
                                          : item.name.replace("-", " ")
                                      );
                                      setState({ ...state, openTab: item.name });
                                      navigatePage(`${URL_LANDING_PAGE}/${item.name}`, navigate);
                                    }}
                                    sx={{ padding: 0, height: "100%" }}
                                  >
                                    {!showDrawer && (
                                      <ListItemIcon>
                                        <MuiTooltip
                                          arrow={false}
                                          content={
                                            item.name === TabsEnum.SAVED
                                              ? TabsEnum.PRODUCTS
                                              : capitalizeEachLetter(item.name.replace("-", " "))
                                          }
                                          placement="right"
                                        >
                                          <img
                                            className={state.openTab === item.name ? "icon-active" : "icon"}
                                            src={state.openTab === item.name ? item.logo.active : item.logo.disabled}
                                            alt={item.name}
                                          />
                                        </MuiTooltip>
                                      </ListItemIcon>
                                    )}
                                    {showDrawer && (
                                      <>
                                        <ListItemIcon>
                                          <img
                                            className={state.openTab === item.name ? "icon-active" : "icon"}
                                            src={state.openTab === item.name ? item.logo.active : item.logo.disabled}
                                            alt={item.name}
                                          />
                                        </ListItemIcon>
                                        <ListItemText>
                                          <MTypography
                                            variant={"subtitle1"}
                                            customClass={
                                              state.openTab === item.name ? classes.ActiveTab : classes.DisableTab
                                            }
                                          >
                                            {item.name === TabsEnum.SAVED
                                              ? TabsEnum.PRODUCTS
                                              : capitalizeEachLetter(item.name.replace("-", " "))}
                                          </MTypography>
                                        </ListItemText>
                                      </>
                                    )}
                                  </ListItemButton>
                                </ListItem>
                              </div>
                            );
                          })}
                        </List>

                        {showUpgradeBanner && <UpgradeCard onClick={onOpen} showDrawer={showDrawer} />}
                      </Box>
                    </Drawer>
                  </Box>
                  <Box component={"main"} flexGrow={1} sx={{ minHeight: `calc(100vh - ${appBarHeight})` }}>
                    {props.children}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {isNavOnDetailsPage && showDrawer && (
              // matching backdrop index with app bar grid item above
              <Backdrop open={showDrawer} sx={{ zIndex: 4, marginLeft: "16rem" }} onClick={setShowDrawer} />
            )}
          </>
        ) : (
          <>{props.children}</>
        )}
      </>
    );
  } else {
    return (
      <Grid>
        <Box component="main" className={classes.LandingPageContentMB}>
          <Box className="landing-page">
            <Grid container direction={"row"}>
              {!isSignInOrSignUpMobile(window.location.href) && (
                <Grid item xs={12} minHeight={`${appBarHeightM}`} sx={{ position: "fixed", zIndex: 4, width: "100%" }}>
                  <AppBar hideAuthorizeBar={hideAuthorizeBar} />
                </Grid>
              )}
              <Grid item xs={12} mt={isSignInOrSignUpMobile(window.location.href) ? "0" : appBarHeightM}>
                {props.children}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {isEnableNavOnMobile(window.location.href) && (
          <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: +1 }} elevation={3}>
            <BottomNavigation showLabels value={state.openTab} sx={{ height: "75px" }}>
              {ScreenTabs.getScreenTabs(isDesktop).map((item, index) => {
                if (index === 3 && !showAccountManagementTab(userRole)) {
                  return null;
                }
                if (
                  item.name === TabsEnum.CONTACT_US &&
                  previliges.userRole.isAdminOrEditorOrInternalOrOwner(userRole)
                ) {
                  return null;
                }
                return (
                  <BottomNavigationAction
                    key={index}
                    onClick={() => {
                      document.title = capitalizeEachLetter(item.name.replace("-", " "));
                      setState({ ...state, openTab: item.name });
                      navigatePage(`${URL_LANDING_PAGE}/${item.name}`, navigate);
                    }}
                    classes={{
                      label:
                        state.openTab === item.name
                          ? classes.BottomNavigationLabelActiveMB
                          : classes.BottomNavigationLabelMB,
                    }}
                    label={item.title}
                    icon={
                      <img src={state.openTab === item.name ? item.logo.active : item.logo.disabled} alt={item.name} />
                    }
                  />
                );
              })}
            </BottomNavigation>
          </Paper>
        )}
      </Grid>
    );
  }
};

export default Layout;
