import { OpportunityReportReducerActionType } from "./opportunityReport.actions";
import { OpportunityReportActionTypes } from "./opportunityReport.action-types";
import { produce } from "immer";
import { isValidEmail } from "../../utils/helpers/regex";

export interface State {
  filterValues: { lastLiveProductsFilter: boolean; keywordCoverageFilter: boolean; exceedLimitFilter: number };
  loading: boolean;
  activeOpportunityTab: string;
  tableSorting: { sortBy: string; sortOrder: number };
  searchFilter: string;
}

const initialState: State = {
  filterValues: {
    lastLiveProductsFilter: true,
    keywordCoverageFilter: true,
    exceedLimitFilter: 30,
  },
  loading: false,
  activeOpportunityTab: "ASIN",
  tableSorting: { sortBy: "volumeChange", sortOrder: -1 },
  searchFilter: "",
};

const opportunityReportReducer = produce((state: State = initialState, action: OpportunityReportReducerActionType) => {
  switch (action.type) {
    case OpportunityReportActionTypes.setOpportunityReportFilters:
      let filter = action.payload;
      filter = {
        ...filter,
        exceedLimitFilter:
          filter.keywordCoverageFilter && filter.exceedLimitFilter === 0
            ? 10
            : filter.keywordCoverageFilter && filter.exceedLimitFilter !== 0
            ? filter.exceedLimitFilter
            : 0,
      };
      state.filterValues = filter;
      return state;
    case OpportunityReportActionTypes.reSetOpportunityReportFilters:
      state.filterValues = {
        lastLiveProductsFilter: true,
        keywordCoverageFilter: true,
        exceedLimitFilter: 10,
      };
      return state;
    case OpportunityReportActionTypes.setOpportunityProductsLoading:
      state.loading = action.payload;
      return state;
    case OpportunityReportActionTypes.setOpportunityActiveTab:
      state.activeOpportunityTab = action.payload;
      return state;
    case OpportunityReportActionTypes.setOpportunitySorting:
      state.tableSorting = action.payload;
      return state;
    case OpportunityReportActionTypes.setOpportunitySearchFilter:
      state.searchFilter = action.payload;
      return state;
    case OpportunityReportActionTypes.setOpportunitySearchReset:
      if (!isValidEmail(state.searchFilter)) {
        state.searchFilter = "";
      }
      return state;
    default:
      return state;
  }
});

export default opportunityReportReducer;
