import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import classes from "./index.module.css";
import moment from "moment";
import { formatNumberWithCommas } from "../../../../../utils/helpers/numbers";
import { useDeviceType } from "../../../../../hooks/useDeviceType";

interface BsrOverAverageProps {
  chartSeries: any;
  chartCategories: any;
  annotations: any;
  totalTickAmount: any;
  chartData: any;
  yTicks: any;
  tooltipData: any;
}
const colors = [
  "#F94144",
  "#90BE6D",
  "#43AA8B",
  "#2E93fA",
  "#66DA26",
  "#546E7A",
  "#9747FF",
  "#FF9800",
  "#4e79a7",
  "#f28e2c",
  "#e15759",
  "#76b7b2",
  "#59a14f",
  "#edc949",
  "#af7aa1",
  "#ff9da7",
  "#9c755f",
  "#bab0ab",
];
const BsrOverAverage: React.FC<BsrOverAverageProps> = ({
  // annotations,
  chartSeries,
  chartCategories,
  tooltipData,
  yTicks,
}) => {
  const { isMobile } = useDeviceType();
  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 400,
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    grid: {
      show: true,
      borderColor: "#D8D8D8",
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 0,
      },
    },
    fill: {
      opacity: 0.9,
      gradient: {
        inverseColors: true,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.9,
        opacityTo: 0.15,
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      type: "category",
      categories: chartCategories,
      tickAmount: 10,
      labels: {
        hideOverlappingLabels: true,
        showDuplicates: false,
        minHeight: 50,
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter: (value) => {
          return moment(value).format("DD MMM");
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      forceNiceScale: true,
      reversed: true,
      showForNullSeries: true,
      labels: {
        // show: true,
        align: "left",
        minWidth: isMobile ? 45 : 80,
        maxWidth: isMobile ? 80 : 200,
        offsetX: -20,
        rotate: 0,
        style: {
          colors: "#4F4F4F",
          fontWeight: 600,
          fontFamily: "Poppins",
          fontSize: isMobile ? "8px" : "10px",
        },
        formatter(val: number): any {
          console.log(formatNumberWithCommas, yTicks);
          if (val === 0) {
            return "1";
          } else {
            return formatNumberWithCommas(Math.round(val));
          }
        },
      },
    },
    legend: {
      show: true,
      horizontalAlign: "left",
      fontWeight: 600,
      fontSize: "12px",
      fontFamily: "Poppins",
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: function ({ dataPointIndex }) {
        const ranks = tooltipData.map((item: any, index: number) => {
          return `<div class="${classes.RowContainer}">
                <div class="${classes.RowContainer}">
                    <div class="${classes.Icon}" style="background: ${colors[index]}"></div>
                    <div>${item?.name}</div>
                </div>
                <div>
                       <strong>#${Math.round(item.data[dataPointIndex])}</strong>
                </div>
            </div>`;
        });

        const tooltipContent = `<div class="${classes.Container}">
            <div class="${classes.DateText}"><strong>Date: ${chartCategories[dataPointIndex]}</strong></div>
            ${ranks.join("")}
          </div>`;
        return tooltipContent;
      },
    },
  };
  return (
    <div>
      <ReactApexChart options={options} series={chartSeries} type="line" height={400} />
    </div>
  );
};
export default BsrOverAverage;
