import React, { useState } from "react";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { BulletFormatEnum, BulletsLengthFormatEnum, SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MTypography from "../../../components/Atoms/MTypography";
import { capitalizeEachLetter, formatBulletsPoints } from "../../../utils/helpers/strings";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { useNavigate } from "react-router-dom";
import { navigatePage } from "../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import classes from "./index.module.css";
import { bulletTypeText, formatBulletsPointsPreview, titleTypeText } from "./config";
import MCheckbox from "../../../components/Atoms/MCheckbox";
import MButton from "../../../components/Atoms/MButton";
import MBottomSheet from "../../../components/Atoms/MBottomSheet";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { DbUserSelector } from "../../../store/user/user.selector";
import { setUserBulletPointsFormatAction } from "../../../store/user/user.actions";
import { toast } from "react-toastify";
import MainLoader from "../../../components/Atoms/MainLoader";
import { Divider } from "@mui/material";

const BrandSettings = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dbUser = useSelector(DbUserSelector);

  const [state, setState] = useState({
    loading: false,
    openBottomSheet: false,
  });
  const [currentBullet, setCurrentBullet] = useState(dbUser?.bulletPointsFormat);
  const [bulletsLengthFormat, setBulletsLengthFormat] = useState(
    dbUser?.bulletsLengthFormat || BulletsLengthFormatEnum?.LONG
  );
  const [titleLengthFormat, setTitleLengthFormat] = useState(
    dbUser?.titleLengthFormat || BulletsLengthFormatEnum?.LONG
  );
  const isButtonDisabled =
    bulletsLengthFormat === dbUser?.bulletsLengthFormat &&
    currentBullet === dbUser?.bulletPointsFormat &&
    titleLengthFormat === dbUser?.titleLengthFormat;
  const navigateSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND}`, navigate);
  };

  const handleClickUpdate = () => {
    setState({ ...state, loading: true });
    dispatch(setUserBulletPointsFormatAction(dbUser, currentBullet, bulletsLengthFormat, titleLengthFormat))
      .then(() => {
        setState({ ...state, loading: false });
      })
      .catch((e: any) => {
        toast.error(e?.message);
        setState({ ...state, loading: false });
      });
  };

  const setSelectedBullet = (bullet: BulletFormatEnum) => {
    setCurrentBullet(bullet);
  };

  const setSelectedBulletType = (bullet: any) => {
    setBulletsLengthFormat(bullet);
  };

  const openBottomSheet = () => {
    setState({ ...state, openBottomSheet: true });
  };

  const closeBottomSheet = () => {
    setState({ ...state, openBottomSheet: false });
  };
  return (
    <div>
      {state.loading ? (
        <div>
          <MainLoader />
        </div>
      ) : (
        <div>
          {isDesktop ? (
            <div>
              <TabsToolbar
                title={SettingsEnum.CONTENT_FORMAT_SETTINGS}
                children={
                  <div className="d-flex align-items-center gap-4">
                    <div className={"cursor-pointer"} onClick={navigateSettingsTab}>
                      <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
                    </div>
                    <MButton variant="contained" onClick={handleClickUpdate} disabled={isButtonDisabled}>
                      Update
                    </MButton>
                  </div>
                }
              />
            </div>
          ) : (
            <div className={"d-flex flex-column p-2 m-2"}>
              <div className={"my-2 mx-2 d-flex justify-content-between align-items-center"}>
                <ArrowBackIcon onClick={navigateSettingsTab} />
                <MButton variant="contained" onClick={handleClickUpdate} disabled={isButtonDisabled}>
                  Update
                </MButton>
              </div>
              <div className={"my-2 mx-2"}>
                <MTypography variant={"h5"}>
                  {capitalizeEachLetter(SettingsEnum.CONTENT_FORMAT_SETTINGS.replace("-", " "))}
                </MTypography>
              </div>
            </div>
          )}
          <div className={` ${classes.Container} ${isDesktop ? " mx-5" : "mx-4 mt-2"}`}>
            <MTypography variant={"subtitle1"} customClass={classes.SectionHeading}>
              {"Title Length"}
            </MTypography>
            <MTypography variant={"subtitle1"} customClass={`${classes.BulletHeading} mt-2`}>
              Select the preferred format for title across your account.
            </MTypography>

            {Object.entries(BulletsLengthFormatEnum).map(([key, title]) => (
              <div key={key}>
                <div
                  onClick={() => setTitleLengthFormat(title)}
                  className={"d-flex align-items-center mt-2 cursor-pointer"}
                >
                  <MCheckbox checked={titleLengthFormat === title} variant={"circle"} />
                  <MTypography variant={"subtitle1"} customClass={classes.BulletHeadingBold}>
                    {titleTypeText[title].title}
                  </MTypography>
                </div>
                <div className={"d-flex"}>
                  <div className={classes["ML-50"]}></div>
                  <MTypography variant={"subtitle1"} customClass={classes.BulletHeading}>
                    {titleTypeText[title].subTitle}
                  </MTypography>
                </div>
              </div>
            ))}
          </div>
          <Divider sx={{ pt: "30px" }} />
          <div className={` ${classes.Container} ${isDesktop ? " mx-5" : "mx-4"}`}>
            <MTypography variant={"subtitle1"} customClass={classes.SectionHeading}>
              {"Bullet Point Length"}
            </MTypography>
            <MTypography variant={"subtitle1"} customClass={`${classes.BulletHeading} mt-2`}>
              Select the preferred format for bullet points across your account.{" "}
            </MTypography>

            {Object.entries(BulletsLengthFormatEnum).map(([key, bullet]) => (
              <div key={key}>
                <div
                  onClick={() => setSelectedBulletType(bullet)}
                  className={"d-flex align-items-center mt-2 cursor-pointer"}
                >
                  <MCheckbox checked={bulletsLengthFormat === bullet} variant={"circle"} />
                  <MTypography variant={"subtitle1"} customClass={classes.BulletHeadingBold}>
                    {bulletTypeText[bullet].title}
                  </MTypography>
                </div>
                <div className={"d-flex"}>
                  <div className={classes["ML-50"]}></div>
                  <MTypography variant={"subtitle1"} customClass={classes.BulletHeading}>
                    {bulletTypeText[bullet].subTitle}
                  </MTypography>
                </div>
              </div>
            ))}
          </div>
          <Divider sx={{ pt: "30px" }} />
          <div className={`d-flex ${isDesktop ? "flex-row" : "flex-column"} w-100`}>
            <div className={` ${classes.Container} ${isDesktop ? "w-25 mx-5" : "mx-4"}`}>
              <MTypography variant={"subtitle1"} customClass={classes.SectionHeading}>
                {"Bullet Input Format"}
              </MTypography>

              {Object.entries(BulletFormatEnum).map(([key, bullet]) => (
                <div
                  key={key}
                  onClick={() => setSelectedBullet(bullet)}
                  className={"d-flex align-items-center mt-2 cursor-pointer"}
                >
                  <MCheckbox checked={currentBullet === bullet} variant={"circle"} />
                  <MTypography variant={"subtitle1"} customClass={classes.BulletHeading}>
                    {bullet}
                  </MTypography>
                </div>
              ))}
            </div>
            {isDesktop ? (
              <div className={`mx-5 ${classes.Container} ${isDesktop ? "w-75" : "w-100"}`}>
                <MTypography variant={"subtitle1"} customClass={classes.SectionHeading}>
                  {"Preview"}
                </MTypography>
                {formatBulletsPointsPreview.map((bullet) => (
                  <div key={bullet} className={`${classes.PreviewBulletCard}`}>
                    <MTypography variant={"subtitle1"} customClass={classes.PreviewBullet}>
                      {formatBulletsPoints(bullet, currentBullet)}
                    </MTypography>
                  </div>
                ))}
              </div>
            ) : (
              <div className={`p-5 d-flex justify-content-center align-items-center w-100`}>
                <MButton variant={"text"} rootClass={classes.PreviewButtonM} fullWidth onClick={openBottomSheet}>
                  {"Preview"}
                </MButton>
              </div>
            )}
          </div>
          <MBottomSheet open={state.openBottomSheet}>
            <div className={"p-3"}>
              <div className={"d-flex justify-content-between align-items-center mb-2"}>
                <MTypography variant={"subtitle1"} customClass={classes.SectionHeading}>
                  {"Preview"}
                </MTypography>
                <CloseIcon onClick={closeBottomSheet} />
              </div>

              {formatBulletsPointsPreview.map((bullet) => (
                <div className={`${classes.PreviewBulletCardM}`} key={bullet}>
                  <MTypography variant={"subtitle1"} customClass={classes.PreviewBullet}>
                    {formatBulletsPoints(bullet, currentBullet)}
                  </MTypography>
                </div>
              ))}
            </div>
          </MBottomSheet>
        </div>
      )}
    </div>
  );
};

export default BrandSettings;
