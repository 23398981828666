import { UserProductTag } from "../../../../apis/types/tag";

export enum TagColorsEnum {
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  YELLOW = "#E6B540",
  GREEN = "#0CC683",
  LIGHT_GREEN = "#058527",
  LIGHT_GRAY = "#FFF3D8",
  ORANGE = "#FFB926",
  RED = "#FF0000",
  BLUE = "#38A4FF",
  DARK_GREEN = "#4CD964",
  PURPLE = "#331BAE",
  DARK_ORANGE = "#F3722C",
  TRANSPARENT_BLACK = "#00000059",
  LIGHT_PURPLE = "#B39EC4",
  TRANSPARENT_YELLOW = "#E6B54066",
  LIGHT_BLUE = "#AABDD9",
  BRIGHT_GREEN = "#76EE59",
  DARK_BLUE = "#052460",
  DARK_YELLOW = "#FBBC05",
  PINK = "#E3C2E0",
  LIGHT_PINK = "#D79DBB",
  DARK_LIGHT_BLUE = "#4285F4",
}

export function checkProductIds(productIds: string[], tagProductIds: string[]): boolean | undefined {
  const allIncluded = productIds.every((productId) => tagProductIds.includes(productId));

  if (allIncluded) {
    return true;
  } else if (productIds.some((productId) => tagProductIds.includes(productId))) {
    return undefined;
  } else {
    return false;
  }
}

export const getAllTagsOfProduct = (allTags: UserProductTag[], productId: string) => {
  return allTags.filter((tag) => !tag.productIDs?.includes(productId));
};
