import React, { useEffect } from "react";
export const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": "prctbl_1NksgHGUDQcfWIcd0YudQ2QV",
    "publishable-key":
      "pk_live_51Ncik3GUDQcfWIcd9rW4u2gCx9lrl75awqnzwndW5LsaqUa4jY3ize6GncDNig9pczDbhSp5ogm7WZg3nLjvxKNi00LgQ5uRJY",
  });
};
