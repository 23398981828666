export const defaultImage = "https://www.elargonauta.com/static/img/no-preview.jpg";

export const RolesTitlesEnum: any = {
  admin: "Admin",
  editor: "Editor",
  internal: "Internal",
  owner: "Owner",
  paid: "Paid", // paid user
  paid_editor: "Paid Editor",
  user: "User", // trail user
  company_admin: "Company Admin", // trail user
  trial : "Trial", // for trail company
};

export const maxCompetitorsLimit = 9;
