import React from "react";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";
import { useDeviceType } from "../../../hooks/useDeviceType";
interface SettingMenuCardProps {
  title: string;
  description: string;
  icon: any;
}
const SettingMenuCard = ({ description, title, icon }: SettingMenuCardProps) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={`d-flex cursor-pointer align-items-center ${!isDesktop && classes.CardContainerM}`}>
      <div
        className={`${classes.IconConatiner} ${
          isDesktop && classes.IconConatinerD
        } d-flex justify-content-center align-item-center p-4 m-2`}
      >
        <img src={icon} alt={title} />
      </div>
      <div className={`d-flex flex-column px-2`}>
        <MTypography variant={"h6"}>{title}</MTypography>
        <div className={"mb-2"}></div>
        <MTypography variant={"subtitle1"} customClass={classes.Description}>{description}</MTypography>
      </div>
    </div>
  );
};

export default SettingMenuCard;
