import React from "react";
import { useFormikContext } from "formik";

import classes from "./index.module.css";
import { Box } from "@mui/material";

interface FormCheckBoxButtonProps {
  options: any;
  name: string;
}

const FormCheckBoxButton: React.FC<FormCheckBoxButtonProps> = (props) => {
  const { options, name = "" } = props;

  const { values, setFieldValue }: any = useFormikContext();

  const selectedValues = values[name] || [];

  const isSelected = (item: string) => selectedValues.includes(item);

  const handleClick = (item: string) => {
    if (isSelected(item)) {
      const filteredOptions: any = selectedValues.filter((option: string) => option !== item);
      setFieldValue(name, filteredOptions);
    } else {
      setFieldValue(name, [...selectedValues, item]);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "20px", maxWidth: "100%", flexFlow: "wrap" }}>
      {options?.map((item: any) => (
        <Box
          component={"div"}
          className={`${classes.Container} ${isSelected(item.value) && classes.ContainerActive}`}
          key={item.value}
          onClick={() => handleClick(item.value)}
        >
          {item.value}
        </Box>
      ))}
    </Box>
  );
};

export default FormCheckBoxButton;
