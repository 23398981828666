import React, { useEffect, useState } from "react";
import MShowMoreLess from "../../../Atoms/MShowMoreLess/index";
import classes from "./index.module.css";
import MTypography from "../../../Atoms/MTypography";
import { CircularProgress, Divider, Grid } from "@mui/material";
import { TeamDataProps } from "../TeamDataGrid/TeamDataGrid";
import InfiniteScroll from "react-infinite-scroll-component";
import { toDateAndTimeObject } from "../../../../utils/helpers/date";
import NoResultsFound from "../../../Atoms/NoResultsFound/NoResultsFound";
import UserStatus from "../../../Molecules/UserStatus";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { RolesEnum, UserStatusEnum } from "../../../../utils/constants/enums";
import { clientsTabsValue } from "../../../../pages/Clients/config";

const TeamCardsContainerM: React.FC<TeamDataProps> = (props) => {
  const {
    membersList,
    setPageNumber,
    pageNumber,
    totalMembers,
    loading,
    handleUserClick,
    clientsTab = "",
    isPaddingZero = false,
  } = props;

  const [hasMore, setHasMore] = useState<boolean>(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if(!loading){
      setHasMore(totalMembers > membersList?.length);
    }
    // setRows((prevRows: any) => [...prevRows, ...membersList]);
  }, [membersList]);

  const fetchMore = () => {
    if (totalMembers > membersList?.length) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className={`d-flex flex-column h-auto ${!isPaddingZero && "p-2 m-2"}`} style={{ marginBottom: "75px" }}>
      <InfiniteScroll
        dataLength={membersList?.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={
          <div className={"d-flex justify-content-center my-3"}>
            {loading && <CircularProgress color={"primary"} />}
          </div>
        }
      >
        {membersList?.length ? (
          membersList?.map((user: any, index: number) => (
            <div
              className={"mt-2 py-3 px-3 d-flex flex-column mb-3 w-100"}
              style={{ backgroundColor: "white", borderRadius: "12px" }}
              key={index}
            >
              <div className={"d-flex mb-1 justify-content-between align-items-center cursor-pointer"} onClick={()=> handleUserClick(user)}>
                <MShowMoreLess
                  content={capitalizeEachLetter(user?.name)}
                  maxLength={40}
                  textVariant={"h4"}
                  textCustomClass={`${classes.PrimaryColor} ${classes.UserName}`}
                />
              </div>
              <div className={classes.ContentSpacing}>
                <MTypography variant={"subtitle1"} customClass={classes.CaptionColor}>
                  {user?.email || "--"}
                </MTypography>
              </div>
              {/* grid view */}
              <Grid container mt={"10px"} rowSpacing={2} columnSpacing={1}>
                <Grid item xs={4} className="h-50">
                  <MTypography variant={"body1"} customClass={classes.UserDetails}>
                    <UserStatus type={user?.status === UserStatusEnum.Pause ? UserStatusEnum.Inactive : user?.status} variant="small"/>
                  </MTypography>
                  <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                    Status
                  </MTypography>
                </Grid>
                <Grid item xs={8} className="h-50">
                  <MTypography variant={"body1"} customClass={classes.UserDetails}>
                    {/* {capitalizeEachLetter(user?.role?.replace("_", " ")) || "--"} */}
                    <>
                      {clientsTab === clientsTabsValue.individuals
                        ? user?.role === RolesEnum.USER
                          ? "Trial"
                          : capitalizeEachLetter(user?.role?.replace("_", " ")) || "--"
                        : capitalizeEachLetter(user?.role?.replace("_", " ")) || "--"}
                    </>
                  </MTypography>
                  <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                    Role
                  </MTypography>
                </Grid>
                {clientsTab === clientsTabsValue.companies && (
                  <Grid item xs={4} className="h-50">
                    <MTypography variant={"body1"} customClass={classes.UserDetails}>
                      {`${user?.totalMembers || 0}`}
                    </MTypography>
                    <MTypography
                      variant={"subtitle2"}
                      customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}
                    >
                      Members
                    </MTypography>
                  </Grid>
                )}
                <Grid item xs={4} className="h-50">
                  <MTypography variant={"body1"} customClass={classes.UserDetails}>
                    {`${user?.countContentGeneration || 0}`}{clientsTab && `/${user.contentGenerationLimit || 0}`}
                  </MTypography>
                  <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                    ASIN Limit
                  </MTypography>
                </Grid>
                <Grid item xs={4} className="h-50">
                  <MTypography variant={"body1"} customClass={classes.UserDetails}>
                    {`${clientsTab ? `${user?.countHelium10Calls}${clientsTab && `/${user.contentGenerationLimit || 0}`}` : user?.countKeywordRefreshes || 0}`}
                  </MTypography>
                  <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                    Keywords Refreshes
                  </MTypography>
                </Grid>
                <Grid item xs={4} className="h-50">
                  <MTypography variant={"body1"} customClass={classes.UserDetails}>
                    {`${user?.countContentRegeneration || 0}`}{clientsTab && `/${user.contentGenerationLimit || 0}`}
                  </MTypography>
                  <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                    Content Regeneration
                  </MTypography>
                </Grid>
              </Grid>
              <Divider flexItem sx={{ borderColor: "#D9D9D9", opacity: 1, my: "15px" }} />
              {!clientsTab && 
                <div className="d-flex justify-content-between align-items-center">
                  <MTypography variant={"body1"} customClass={classes.UserDetails}>
                    Last Updated
                  </MTypography>
                  <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                    {toDateAndTimeObject(user.updatedAt).date} @ {toDateAndTimeObject(user.updatedAt).time}
                  </MTypography>
                </div>
              }
            </div>
          ))
        ) : (
          <>{!loading && <NoResultsFound pt={4} />}</>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default TeamCardsContainerM;
