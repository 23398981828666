import React, { useState } from "react";
import { CardActions, Dialog, DialogContent, DialogTitle, Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import MButton from "../../../../Atoms/MButton";
import { CreateBrandObject, initialValue, validationSchema, inputName } from "./config";

import classes from "./index.module.css";
import FormTextField from "../../../../Molecules/FormTextField";
import FormSwitch from "../../../../Molecules/FormSwitch/FormSwitch";
import { BrandApis } from "../../../../../apis/brands";
import { errorHandler } from "../../../../../utils/helpers/apis";
import { SUCC_BRAND_CREATED, SUCC_BRAND_UPDATED } from "../../../../../utils/constants/messages/success";

interface AddBrandModalProps {
  open: boolean;
  onClose: () => void;
  edit?: boolean;
  fetchBrands: any;
  editValues?: any;
}

const AddBrandModal: React.FC<AddBrandModalProps> = (props) => {
  const { open, onClose, fetchBrands, edit, editValues = {} } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState<boolean>(false);

  console.log(open, onClose);
  const formInitialValues = edit
    ? {
        name: editValues?.name,
        alias: editValues?.alias,
        status: editValues?.alias?.length > 0,
      }
    : initialValue;

  const submitForm = async (values: CreateBrandObject, options: any) => {
    try {
      setLoading(true);
      if (edit) {
        await BrandApis.updateBrand({
          ...values,
          brandID: editValues?._id,
          userID: editValues?.userID,
          status: false,
        });
      } else {
        await BrandApis.createBrand({
          ...values,
          status: false,
        });
      }
      await fetchBrands();
      setLoading(false);
      options.resetForm();
      toast.success(edit ? SUCC_BRAND_UPDATED : SUCC_BRAND_CREATED);
      onClose();
    } catch (e) {
      setLoading(false);
      toast.error(errorHandler(e));
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values }) => (
          <Form autoComplete="off">
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={onClose}
              PaperProps={{
                style: {
                  maxWidth: "fit-content",
                },
              }}
            >
              <DialogTitle className={classes.Title}>
                <div className="flex-grow-1">{edit ? "Edit" : "Add"} Brand</div>
              </DialogTitle>
              <DialogContent className={classes.BoxConatiner} style={{ width: "620px" }}>
                <div className={"d-flex flex-column"} style={{ minHeight: "200px" }}>
                  <FormTextField name={inputName.name} label={"Brand Name"} disabled={edit} />
                  <FormSwitch label={"Add Alias"} name={inputName.status} />
                  {values.status && <FormTextField name={inputName.alias} label={"Alias"} />}
                </div>
              </DialogContent>
              <Divider sx={{ mb: 1 }} />
              <CardActions sx={{ alignItems: "flex-end", justifyContent: "flex-end", px: "24px" }}>
                <MButton onClick={() => onClose()} size={"small"} variant={"text"} disabled={loading}>
                  Cancel
                </MButton>
                <MButton onClick={handleSubmit} size={"small"} disabled={loading} isLoading={loading}>
                  {edit ? "Update" : "Add"}
                </MButton>
              </CardActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddBrandModal;
