import { createTheme } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = createBreakpoints({});

export const Colors = {
  primary: "#e6b540",
  primaryDark: "#ca961c",
  primaryLight: "#edca78",
  secondary: "#9fe8e0",
  secondaryLight: "#c5e3e7",
  error: "#FF3333",
  textFieldOutline: "#D9D9D9",
  white: "#ffffff",
  caption: "#7E7E7E",
};

//this is the theme object most of the values is for reference
export const mainTheme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    breakpoints: {
      values: {
        xs: 200,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
    palette: {
      primary: {
        main: "#e6b540",
        dark: Colors.primary,
        contrastText: Colors.white,
      },
      secondary: {
        main: Colors.secondary,
        contrastText: Colors.caption,
      },
      error: {
        main: Colors.error,
      },
    },
    typography: {
      fontFamily: "Poppins",
    },
    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    components: {
      MuiButton: {
        defaultProps: {
          // disableRipple: true,
          // disableElevation: true,
          // variant: "contained",
          // disableFocusRipple: true,
          size: "medium",
        },
        styleOverrides: {
          root: {
            padding: "10px 20px",
            borderRadius: "10px",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "27px",
            textTransform: "none",
            [breakpoints.down("md")]: {
              padding: "8px 16px",
              borderRadius: "8px",
              fontSize: "16px",
              lineHeight: "24px",
            },
          },
          containedPrimary: {
            background: Colors.primary,
            color: Colors.white,
          },
          outlinedPrimary: {
            background: "white",
            color: Colors.primary,
          },
          sizeSmall: {
            padding: "8px 16px",
            borderRadius: "8px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            [breakpoints.down("md")]: {
              fontSize: "14px",
              lineHeight: "20px",
            },
          },
          sizeLarge: {
            padding: "12px 24px",
            borderRadius: "12px",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "30px",
            [breakpoints.down("md")]: {
              padding: "10px 20px",
              borderRadius: "10px",
              fontSize: "18px",
              lineHeight: "27px",
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: false,
          disableFocusRipple: false,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: "#FDEFCF",
            },
          },
        },
      },
      MuiCircularProgress: {
        defaultProps: {
          color: "primary",
        },
      },

      MuiTypography: {
        styleOverrides: {
          h1: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "40px",
            lineHeight: "46px",
            letterSpacing: "-0.003em",
            color: "#000000",
            [breakpoints.down("md")]: {
              fontSize: "32px",
              lineHeight: "38px",
            },
          },
          h2: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "32px",
            lineHeight: "36%",
            letterSpacing: "-0.003em",
            color: "#000000",
            [breakpoints.down("md")]: {
              fontSize: "26px",
              lineHeight: "32px",
            },
          },
          h3: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "32px",
            letterSpacing: "-0.003em",
            color: "#000000",
            [breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
          h4: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "-0.002em",
            color: "#000000",
            [breakpoints.down("md")]: {
              fontSize: "20px",
              lineHeight: "26px",
            },
          },
          h5: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: "-0.002em",
            color: "#000000",
            [breakpoints.down("md")]: {
              fontSize: "18px",
              lineHeight: "24px",
            },
          },
          h6: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "-0.002em",
            color: "#000000",
            [breakpoints.down("md")]: {
              fontSize: "16px",
              lineHeight: "22px",
            },
          },
          body1: {
            /* body1 refers to Body 20-medium */
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: "-0.002em",
            color: "#111827",
            [breakpoints.down("md")]: {
              fontSize: "18px",
              lineHeight: "26px",
            },
          },
          body2: {
            /* body2 refers to Body 18-Regular */
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            color: "#111827",
            [breakpoints.down("md")]: {
              fontSize: "16px",
              lineHeight: "22px",
            },
          },
          paragraph: {
            /* refers to Paragraph Large */
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "121%",
            color: "rgba(0, 0, 0, 0.8)",
            [breakpoints.down("md")]: {
              fontSize: "14px",
              lineHeight: "110%",
            },
          },
          subtitle1: {
            /* refers to Paragraph Medium */
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "14px",
            color: "rgba(0, 0, 0, 0.8)",
            [breakpoints.down("md")]: {
              fontSize: "12px",
              lineHeight: "12px",
            },
          },
          subtitle2: {
            /* refers to Paragraph */
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "121%",
            color: "rgba(0, 0, 0, 0.8)",
            [breakpoints.down("md")]: {
              fontSize: "14px",
              lineHeight: "110%",
            },
          },
        },
      },

      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              width: "100%",
              "& fieldset": {
                borderColor: Colors.textFieldOutline, // Change the outline color
              },
              "&.Mui-focused fieldset": {
                borderColor: Colors.primary, // Change the outline color when focused
              },
            },
            "& .MuiIconButton-root": {
              padding: 0,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginDense: {
            "& .MuiOutlinedInput-input": {
              padding: "10px",
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "white",
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: "#B6B7B8",
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: "#e6b440",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: "#B6B7B8",

            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: "#e6b440",
            },
          },
        },
      },
    },
  }
);
