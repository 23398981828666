import { Stack } from "@mui/material"
import React from "react";

interface NoResultsFoundProps {
    pt?: string | number;
    message?: string;
}

const NoResultsFound: React.FC<NoResultsFoundProps> = (props) => {

    const { pt = '0px', message = "No results found." } = props;

    return (
        <Stack height="100%" alignItems="center" justifyContent="center" pt={pt}>
            {message}
        </Stack>
    )
}

export default NoResultsFound;
