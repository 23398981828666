import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import {
  ClientsActiveTab,
  ClientsRoleFilterSelector,
  ClientsStatsSelector,
} from "../../../store/clients/clients.selector";
import { setSelectedTeamMemberDispatch } from "../../../store/team-management/team-management.actions";
import { setClientsRoleFilterDispatch, setClientsStatsDispatch } from "../../../store/clients/clients.actions";

import { clientsTabsValue } from "../../../pages/Clients/config";

import { ClientsApis } from "../../../apis/clients";
import { GetAllClientsPayload } from "../../../apis/types/clients";

import { defaultPageSize, usePagination } from "../../../hooks/usePagination";
import { useDeviceType } from "../../../hooks/useDeviceType";

import TeamMembersCardSkeletonM from "../../Molecules/AnimatedSkeletonCard/TeamMembersCardSkeletonM";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";

import MTextField from "../../Atoms/MTextField";
import MTypography from "../../Atoms/MTypography";

import { TabsEnum } from "../../../utils/constants/enums";
import { navigatePage } from "../../../utils/helpers/common";

import GridSkeleton from "../SavedProducts/GridSkeleton/GridSkeleton";
import TeamDataGrid from "../TeamManagement/TeamDataGrid/TeamDataGrid";
import TeamCardsContainerM from "../TeamManagement/TeamCardsContainerM/TeamCardsContainerM";

import ClientsCardsContainer from "./ClientsCardsContainer/ClientsCardsContainer";
import ClientsFilterMenu from "./ClientsFilterMenu";
import classes from "./index.module.css";

interface filterStateType {
  showFilterMenu: boolean;
  anchorEl: null | HTMLElement;
}

const ClientsPage = () => {
  const activeTab = useSelector(ClientsActiveTab);
  const roleFilterValue = useSelector(ClientsRoleFilterSelector);
  const [search, setSearch] = useState<string>("");
  const { isDesktop } = useDeviceType();
  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy, setSortModel } = usePagination(
    isDesktop ? 50 : 20
  );
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allClients, setAllClients] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filterState, setFilterState] = useState<filterStateType>({ showFilterMenu: false, anchorEl: null });
  const [mobileFilterMenu, setMobileFilterMenu] = useState<boolean>(false);

  const clientsStats = useSelector(ClientsStatsSelector);

  const isInitialRender = useRef(true);

  const getFilters = (isReset: boolean = false, isMobile: boolean = false, filterValue: string) => {
    let filters = {
      offset: isReset ? 0 : offset,
      limit: isReset && isMobile ? defaultPageSize : pageSize,
      sortBy: "",
      sortOrder: 0,
      searchQuery: "",
      isIndividual: activeTab === clientsTabsValue.individuals,
      roleFilter: filterValue,
    };

    if (sortBy !== "") {
      filters = {
        ...filters,
        sortBy: isReset ? "" : sortBy,
        sortOrder: isReset ? 0 : sortOrder,
      };
    }

    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    dispatch(setClientsRoleFilterDispatch(filterValue));

    return filters;
  };

  const getAllClients = async (isReset = false, isMobile = false, filterValue = "") => {
    if (isReset) {
      setAllClients([]);
      setPageNumber(0);
    }
    const filters: GetAllClientsPayload = getFilters(isReset, isMobile, filterValue);
    setLoading(true);
    ClientsApis.getAllClients(filters)
      .then((res: any) => {
        const { clients, ...stats } = res;
        const clientsWithId = clients.map((e: any) => ({ ...e, id: e._id }));
        if (isMobile) {
          if (allClients?.length > 0 && clientsWithId?.length > 0) {
            const allClientsList = [...allClients, ...clientsWithId];
            setAllClients(allClientsList);
          } else {
            setAllClients(clientsWithId);
          }
        } else {
          setAllClients(clientsWithId);
        }
        dispatch(setClientsStatsDispatch(stats));
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        console.log("error while fetching all clients", e);
      });
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isDesktop) {
      getAllClients(false, false, roleFilterValue);
    } else {
      getAllClients(false, true, roleFilterValue);
    }
  }, [pageNumber, pageSize, offset, sortBy, sortOrder]);

  useEffect(() => {
    if (!isInitialRender.current) {
      getAllClients(true, false, roleFilterValue);
    }
  }, [searchQuery]);
  
  useEffect(() => {
    if (!isInitialRender.current) {
      getAllClients(true, false, "");
    } else {
      isInitialRender.current = false;
    }
  }, [activeTab]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 1500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleCellClick = (clientDetails: any) => {
    // console.log("selectedRow ", clientDetails);
    dispatch(setSelectedTeamMemberDispatch(clientDetails));
    if (activeTab === clientsTabsValue.companies) {
      navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}/company/${clientDetails?.id}`, navigate);
    } else {
      navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}/individual/${clientDetails?.id}`, navigate);
    }
  };

  const handleFilterClose = () => {
    if (isDesktop) {
      setFilterState({ showFilterMenu: false, anchorEl: null });
    } else {
      setMobileFilterMenu(false);
    }
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDesktop) {
      setFilterState({ showFilterMenu: !filterState.showFilterMenu, anchorEl: e.currentTarget });
    } else {
      setMobileFilterMenu(!mobileFilterMenu);
    }
  };

  const handleFilterChange = (value: string) => {
    if (isDesktop) {
      getAllClients(true, false, value);
    } else {
      getAllClients(true, false, value);
    }
  };

  return (
    <div>
      <ClientsCardsContainer loading={loading} />
      <div className={`${classes.ToolBarContainer} ${!isDesktop && classes.ToolBarContainerM}`}>
        <MTextField
          icon={"search"}
          position={"start"}
          onChange={handleChange}
          name={"customKeyword"}
          placeholder={"Search by name or email"}
          value={search}
          margin={"dense"}
          rootClass={classes.TextInput}
        />

        <div
          className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer ${
            false ? `pe-none ${classes.DisabledText}` : "pe-auto HoverEffectForButton"
          }`}
          onClick={handleFilterClick}
        >
          <FilterListIcon fontSize={"medium"} />
          <MTypography variant={"subtitle1"} color="inherit">
            {"Filters"}
          </MTypography>
        </div>
        <ClientsFilterMenu
          open={filterState.showFilterMenu}
          openMobileFilterMenu={mobileFilterMenu}
          anchorEl={filterState.anchorEl}
          onClose={handleFilterClose}
          // options={clientsFilterOption}
          handleFilterChange={handleFilterChange}
          activeRoleFilter={roleFilterValue}
          activeTab={activeTab}
        />
      </div>
      {loading ? (
        <>
          {isDesktop ? (
            <div>
              <GridSkeleton componentType={activeTab} />
            </div>
          ) : (
            <TeamMembersCardSkeletonM />
          )}
        </>
      ) : isDesktop ? (
        <TeamDataGrid
          membersList={allClients || []}
          totalMembers={(searchQuery ? clientsStats?.totalRecords : clientsStats?.totalClients) || 0}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setPageSize={setPageSize}
          offset={offset}
          sortOrder={sortOrder}
          sortBy={sortBy}
          setSortModel={setSortModel}
          loading={loading}
          handleUserClick={handleCellClick}
          clientsTab={activeTab}
          isPaddingZero
        />
      ) : (
        <div className={classes.MobileContainer}>
          <TeamCardsContainerM
            membersList={allClients || []}
            pageSize={pageSize}
            totalMembers={(searchQuery ? clientsStats?.totalRecords : clientsStats?.totalClients) || 0}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            offset={offset}
            sortOrder={sortOrder}
            sortBy={sortBy}
            setSortModel={setSortModel}
            loading={loading}
            handleUserClick={handleCellClick}
            clientsTab={activeTab}
          />
        </div>
      )}
    </div>
  );
};

export default ClientsPage;
