import { Grid, Typography } from "@mui/material";
import React from "react";
import "./twoColorText.style.scss";

interface TwoColorTextProps {
  text1: string;
  text2: string;
  text1Class?: string;
  text2Class?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const TwoColorText = ({ text1, text2, text1Class = "", text2Class = "", onClick }: TwoColorTextProps) => {
  return (
    <Grid className="two-color-text">
      <Typography className="font" classes={{ root: text1Class }}>
        {text1}&nbsp;
      </Typography>
      <Typography className="font color-text" classes={{ root: text2Class }} onClick={onClick}>
        {text2}
      </Typography>
    </Grid>
  );
};

export default TwoColorText;
