import React from "react";
import { useSelector } from "react-redux";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import TitleBarLogo from "./logo";
import { DbUserSelector } from "../../../store/user/user.selector";
import { AppState } from "../../../store/reducers";
import { CompanySelector } from "../../../store/company/company.selector";

import {
  showAuthorizedBarOnLayout,
  showAuthorizedBarOnLayoutCompany,
  showUpgradeNowBannerOnLayout,
  userRole,
} from "../../../utils/helpers/priviligesChecks";

import UpgradeNowBanner from "../../Organisms/FetchAsin/UpgradeNowBanner/UpgradeNowBanner";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { showUpgradeBannerForCompany } from "../../Layout/config";
import AuthorizeTopBar from "../AuthorizeBar/AuthorizeBar";

import classes from "./index.module.css";

interface TitleBarProps {
  centerElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  handleToggleSideBar?: () => void;
  showMenuButton?: boolean;
  hideAuthorizeBar?: boolean;
}

const AppBar: React.FC<TitleBarProps> = ({
  rightElement,
  centerElement,
  handleToggleSideBar,
  showMenuButton = false,
  hideAuthorizeBar = false,
}) => {
  const currentUser = useSelector(DbUserSelector);
  const currentCompany = useSelector(CompanySelector);

  const role = useSelector((state: AppState) => state.user.role);
  const { isDesktop } = useDeviceType();
  return (
    <>
      {/* {process.env.REACT_APP_ENV === "development" && ( */}
        <>
          {!hideAuthorizeBar &&
          !currentUser?.sellerPartnerAuthorized &&
          currentUser?.companyID &&
          showAuthorizedBarOnLayoutCompany(currentUser?.role, currentCompany?.role) ? (
            <AuthorizeTopBar />
          ) : (
            !hideAuthorizeBar &&
            !currentUser?.sellerPartnerAuthorized &&
            showAuthorizedBarOnLayout(currentUser?.role, currentUser?.email) &&
            !currentUser?.companyID && <AuthorizeTopBar />
          )}
        </>
      {/* )} */}
      {currentUser?.companyID ? (
        <>
          {currentCompany?.contentGenerationLimit &&
            showUpgradeBannerForCompany(role, currentCompany, "top_appbarBanner") && (
              <UpgradeNowBanner limit={currentCompany?.contentGenerationLimit} />
            )}
        </>
      ) : (
        <>
          {currentUser?.countContentGeneration === currentUser?.contentGenerationLimit &&
            showUpgradeNowBannerOnLayout(role) && <UpgradeNowBanner limit={currentUser?.contentGenerationLimit} />}
        </>
      )}
      <div
        className={`w-100 d-flex align-items-center justify-content-between ${
          isDesktop ? classes.Container : classes.MContainer
        }`}
      >
        <div className={`${isDesktop ? classes.Left : ""} d-flex justify-content-between align-items-center gap-2`}>
          {showMenuButton && (
            <div onClick={handleToggleSideBar} className="HoverEffectForButton">
              <MenuRoundedIcon htmlColor="#757575" fontSize="medium" className="cursor-pointer" />
            </div>
          )}
          <div>
            <TitleBarLogo />
          </div>
        </div>
        <div className={""}>
          <>{centerElement}</>
        </div>
        <div className={`${isDesktop ? classes.Right : classes.RightM}`}>
          {(userRole.isPaidOrPaidEditorOrUser(role) || currentUser?.companyID) && (
            <div className={isDesktop ? classes.LimitContainer : classes.LimitContainerM}>
              <>
                {currentUser?.companyID ? (
                  <>
                    {currentCompany?.countContentGeneration}/{currentCompany?.contentGenerationLimit}{" "}
                  </>
                ) : (
                  <>
                    {currentUser?.countContentGeneration}/{currentUser?.contentGenerationLimit}{" "}
                  </>
                )}
              </>
              <span className={classes.AsinText}>ASINs</span>
            </div>
          )}
          <div>{rightElement}</div>
        </div>
      </div>
    </>
  );
};

export default AppBar;
