import React, { useState } from "react";
import { FormControl, FormControlLabel, Grid, Menu, Typography } from "@mui/material";
import classes from "./index.module.css";
import MButton from "../../../../Atoms/MButton";
import MCheckbox from "../../../../Atoms/MCheckbox";

interface DefaultMenu {
  label: string;
  value: any;
}
interface Props {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  options: DefaultMenu[];
  handleApplyFilter: (resetFilters: boolean, filters: string[]) => void;
  appliedFilters: string[];  
}

const EventFilters: React.FC<Props> = (props) => {
  const { open, anchorEl, onClose, options, handleApplyFilter, appliedFilters } = props;

  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);

  const handleCheckboxClick = (event: string) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(event)) {
        return prevFilters.filter((e) => e !== event);
      } else {
        return [...prevFilters, event];
      }
    });
  };
  const handleCancle = () => {
    onClose();
    setSelectedFilters(appliedFilters)
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ padding: "8px 8px" }}
      >
        <FormControl component="fieldset">
          <Typography variant="h6" className={`${classes.Headers} pt-3 px-3 pb-2`}>
            Event Filters
          </Typography>
          <Grid container sx={{ maxWidth: "300px" }}>
            {options?.map((mItem: DefaultMenu, index: number) => (
              <Grid item xs={12} md={6} key={index}>
                <FormControlLabel
                  key={mItem.value}
                  value={mItem.value}
                  control={
                    <MCheckbox
                      variant={"square"}
                      checked={selectedFilters.includes(mItem.value)}
                      size="small"
                      onChange={() => handleCheckboxClick(mItem.value)}
                    />
                  }
                  label={mItem.label}
                  sx={{ height: "24px", mb: "10px", ml: 1 }}
                  classes={{
                    label: selectedFilters.includes(mItem.value) ? classes.ActiveLabel : classes.NonActiveLabel,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </FormControl>
        <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-4 pb-1 mt-3 ${classes.ButtonsContainer}`}>
          <MButton variant="outlined" size={"small"} onClick={handleCancle}>
            Cancel
          </MButton>
          <MButton size={"small"} onClick={() => handleApplyFilter(false, selectedFilters)}>
            Apply Filters
          </MButton>
        </div>
      </Menu>
    </>
  );
};

export default EventFilters;
