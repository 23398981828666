import React, { useEffect, useRef, useState } from "react";

import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

import { RolesEnum, SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import { navigatePage } from "../../../utils/helpers/common";

import { setTeamMembersAction, setTeamMembersList } from "../../../store/user/user.actions";
import { DbUserSelector, TeamMembersSelector, TeamStatsSelector } from "../../../store/user/user.selector";
import { setSelectedTeamMemberDispatch } from "../../../store/team-management/team-management.actions";

import { useDeviceType } from "../../../hooks/useDeviceType";
import { defaultPageSize, usePagination } from "../../../hooks/usePagination";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";

import { UserApis } from "../../../apis/user";

import { TeamMemberType } from "../../../interfaces/userInterfaces";

import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import MTypography from "../../../components/Atoms/MTypography";
import MButton from "../../../components/Atoms/MButton";
import TeamStatsContainer from "../../../components/Organisms/TeamManagement/TeamStatsContainer/TeamStatsContainer";
import TeamDataGrid from "../../../components/Organisms/TeamManagement/TeamDataGrid/TeamDataGrid";
import MTextField from "../../../components/Atoms/MTextField";
import InviteModalBox from "../../../components/Organisms/TeamManagement/InviteModal/InviteModalBox";
import TeamMembersCardSkeletonM from "../../../components/Molecules/AnimatedSkeletonCard/TeamMembersCardSkeletonM";
import GridSkeleton from "../../../components/Organisms/SavedProducts/GridSkeleton/GridSkeleton";
import TeamCardsContainerM from "../../../components/Organisms/TeamManagement/TeamCardsContainerM/TeamCardsContainerM";
import PendingInvitationsGrid from "../../../components/Organisms/TeamManagement/PendingInvitationsGrid/PendingInvitationsGrid";

import { showInviteMemberButtonOnClientsView } from "../../ClientDetail/config";

import classes from "./index.module.css";

interface TeamManagementProps {
  isGridOnly?: boolean;
  companyID?: string;
  type?: "company" | "individual";
  isClientsScreen?: boolean;
  refetchPendingList?: number;
}

const TeamManagement: React.FC<TeamManagementProps> = ({
  isGridOnly = false,
  companyID = "",
  isClientsScreen = false,
  refetchPendingList = 0,
}) => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [openInviteBox, setOpenInviteBox] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const allTeamMembers = useSelector(TeamMembersSelector);
  const teamMembersStats = useSelector(TeamStatsSelector);
  const currentUser = useSelector(DbUserSelector);
  const location = useLocation();

  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy, setSortModel } = usePagination(
    isDesktop ? 50 : 20
  );

  const [refetchPen, setRefreshPen] = useState(1);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // resetting the list
    dispatch(setTeamMembersList([]));
  }, []);

  const isInitialRender = useRef(true);

  const getTeamMembers = (isReset: boolean, isMobile: boolean) => {
    let filters: any = {
      offset: isReset ? 0 : offset,
      limit: isReset ? defaultPageSize : pageSize,
      sortBy: "",
      sortOrder: 0,
      searchQuery: "",
      companyID: "",
    };

    if (sortBy !== "") {
      filters = {
        ...filters,
        sortBy: sortBy === "countKeywordRefreshes" ? "countHelium10Calls" : sortBy,
        sortOrder,
      };
    }

    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    if (isGridOnly) {
      filters = {
        ...filters,
        companyID: companyID,
      };
    }
    setLoading(true);
    UserApis.getTeamMembers(filters)
      .then((res: any) => {
        dispatch(setTeamMembersAction(res, isReset, isMobile));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getTeamMembersCall = (isReset = false, isMobile = false) => {
    getTeamMembers(isReset, isMobile);
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isDesktop) {
      getTeamMembersCall(false, false);
    } else {
      getTeamMembersCall(false, true);
    }
  }, [pageNumber, pageSize, offset, sortBy, sortOrder]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInitialRender.current) {
      getTeamMembersCall(true);
    } else {
      isInitialRender.current = false;
    }
  }, [searchQuery]);

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };

  const handleInvite = () => {
    setOpenInviteBox(true);
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 1500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleRefreshPendingList = () => {
    setRefreshPen(refetchPen + 1);
  };

  const handleUserClick = (userDetails: TeamMemberType) => {
    // isPaddingZero
    const selectedMember = userDetails;
    dispatch(setSelectedTeamMemberDispatch(selectedMember));
    if (isClientsScreen) {
      navigatePage(
        `${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}/${SettingsEnum.TEAM_MANAGEMENT}/${selectedMember?._id}`,
        navigate,
        {
          state: {
            prevPath: location?.pathname,
          },
        }
      );
    } else {
      navigatePage(
        `${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.TEAM_MANAGEMENT}/${selectedMember?._id}`,
        navigate,
        {
          state: {
            prevPath: location?.pathname,
          },
        }
      );
    }
  };

  return (
    <div>
      {!isGridOnly && (
        <>
          {isDesktop ? (
            <TabsToolbar
              title={SettingsEnum.TEAM_MANAGEMENT}
              children={
                <div className="d-flex align-items-center gap-4">
                  <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
                    <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
                  </div>
                  <MButton variant="contained" onClick={handleInvite}>
                    Invite Member
                  </MButton>
                </div>
              }
            />
          ) : (
            <div className={"d-flex flex-column p-2 m-2"}>
              <div className={"my-2 mx-2 d-flex justify-content-between align-items-center"}>
                <ArrowBackIcon onClick={navigateToSettingsTab} />
                <MButton variant="contained" onClick={handleInvite}>
                  Invite Member
                </MButton>
              </div>
              <div className={"my-2 mx-2"}>
                <MTypography variant={"h5"}>
                  {capitalizeEachLetter(SettingsEnum.TEAM_MANAGEMENT.replace("-", " "))}
                </MTypography>
              </div>
            </div>
          )}
        </>
      )}

      <div>
        {!isGridOnly && <TeamStatsContainer isLoading={loading} />}
        <div
          className={`${!isGridOnly && classes.ToolBarContainer} ${
            isDesktop || isGridOnly ? "" : classes.ToolBarContainerM
          }`}
        >
          <MTextField
            icon={"search"}
            position={"start"}
            onChange={handleChange}
            name={"customKeyword"}
            placeholder={"Search by Name or Email"}
            value={search}
            margin={"dense"}
            rootClass={isDesktop ? classes.TextInput : classes.TextInputMobile}
          />
        </div>
        {((!isGridOnly && currentUser?.role === RolesEnum.COMPANY_ADMIN) ||
          showInviteMemberButtonOnClientsView(currentUser?.email)) && (
          <div style={{ padding: !isClientsScreen ? (isDesktop ? "0px 2rem" : "0px 1rem") : "" }}>
            <PendingInvitationsGrid
              refetchPendingList={refetchPendingList || refetchPen}
              getTeamMembersCall={getTeamMembersCall}
              companyID={companyID}
            />
          </div>
        )}
        {loading ? (
          <>
            {isDesktop ? (
              <div style={isGridOnly ? { padding: "0" } : { padding: "2rem" }}>
                <GridSkeleton componentType={"teamManagement"} />
              </div>
            ) : (
              <TeamMembersCardSkeletonM />
            )}
          </>
        ) : isDesktop ? (
          <TeamDataGrid
            membersList={allTeamMembers}
            totalMembers={(searchQuery ? teamMembersStats?.totalRecords : teamMembersStats?.totalMembers) || 0}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            offset={offset}
            sortOrder={sortOrder}
            sortBy={sortBy}
            setSortModel={setSortModel}
            loading={loading}
            handleUserClick={handleUserClick}
            isPaddingZero={isGridOnly}
          />
        ) : (
          <TeamCardsContainerM
            membersList={allTeamMembers}
            pageSize={pageSize}
            totalMembers={(searchQuery ? teamMembersStats?.totalRecords : teamMembersStats?.totalMembers) || 0}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            offset={offset}
            sortOrder={sortOrder}
            sortBy={sortBy}
            setSortModel={setSortModel}
            loading={loading}
            handleUserClick={handleUserClick}
            isPaddingZero={isGridOnly}
          />
        )}
        <InviteModalBox
          handleRefreshPendingList={handleRefreshPendingList}
          open={openInviteBox}
          onClose={() => setOpenInviteBox(false)}
          getTeamMembersCall={getTeamMembersCall}
        />
      </div>
    </div>
  );
};

export default TeamManagement;
