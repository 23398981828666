export const formatBulletsPointsPreview = [
  "HIGH PERFORMANCE HEPA GRADE FILTER: Captures 99% of pet dander, pollen, and as small as 3 microns from the air.",
  "EASY TO CLEAN: Air purifier has a permanent Hepa-grade filter that easily vacuums clean.",
  "COMPACT, STYLISH DESIGN: Allergen reducing air purifier is perfect in the office or in rooms up to 160 square feet",
  "VERSATILE AIR CARE THAT FITS ANYWHERE: Position the unit vertically or horizontally to best fit in your bedroom, den, nursery, hallway or living room at home or at the office.",
  "QUIET AND POWERFUL: Use whisper clean for extra quiet operation at night, the medium setting or the quick clean setting for more powerful air cleaning.",
  "3 VERSATILE SPEED SETTINGS: Air purifier has three versatile speed settings that give you ultimate control. Use quiet setting at night or quick setting for powerful air cleaning.",
];

export const bulletTypeText = {
  long: {
    title: "Long bullet points",
    subTitle: "Create bullet points with detailed information, combining primary and secondary details in 2-3 lines.",
  },
  short: {
    title: "Short bullet points",
    subTitle: "Create concise bullet points with only the primary details in 1-2 lines.",
  },
};

export const titleTypeText = {
  long: {
    title: "Long title",
    subTitle: "Comprehensive title highlighting multiple product features and attributes.",
  },
  short: {
    title: "Short title",
    subTitle: "Brief, focused title emphasizing 1-2 key product aspects (max 2 keywords).",
  },
};
