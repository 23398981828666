import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import { RolesEnum, SettingsEnum, TabsEnum } from "../../../../utils/constants/enums";
import { navigatePage } from "../../../../utils/helpers/common";
import MTypography from "../../../Atoms/MTypography";
// import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import NoApiAvailableView from "./NoApiAvailableView/NoApiAvailableView";
import AccessKeyTable from "./AccessKeyTable/AccessKeyTable";
import MButton from "../../../Atoms/MButton";
import classes from "./index.module.css";
import ActionBox from "../../../Molecules/ActionBox";
import { useEffect, useState } from "react";
import GenerateNewKeyBox from "./GenerateNewBox/GenerateNewKeyBox";
import AccessKeyM from "./AccessKeyM/AccessKeyM";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../../store/user/user.selector";
import { showAutomatoApiAccess } from "../../../../utils/helpers/priviligesChecks";
import { AccessKeyApis } from "../../../../apis/apiAccessKey";
import { ApiAccessKey } from "../../../../apis/types/apiAccessKey";
import { useDispatch } from "react-redux";
import { setAccessKeysList } from "../../../../store/accessKeys/accessKeys.actions";
import { apiAccessKeysList } from "../../../../store/accessKeys/accessKeys.selector";
import { toast } from "react-toastify";
import { generateRandomText, showGenNewKeyButton } from "./config";
import {
  SUCC_ACCESS_KEY_DELETE,
  SUCC_ACCESS_KEY_GENERATED,
  SUCC_ACCESS_KEY_UPDATED,
} from "../../../../utils/constants/messages/success";
import AccessKeySkeleton from "./AccessKeySkeletons";
import AccessSkeletonM from "../../../Molecules/AnimatedSkeletonCard/AccessSkeletonM";
import { TablePagination } from "@mui/material";
import { usePagination } from "../../../../hooks/usePagination";

type ModalState = {
  openDelete: boolean;
  openRerun: boolean;
  openGenerateBox: boolean;
};

const AutomatoApiAccess = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const dbUser = useSelector(DbUserSelector);
  const dispatch = useDispatch();
  const apiKeysList = useSelector(apiAccessKeysList);
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmModalState, setConfirmModalState] = useState<ModalState>({
    openDelete: false,
    openRerun: false,
    openGenerateBox: false,
  });
  const [selectedAccessKeyID, setSelectedAccessKeyID] = useState<string | null>(null);
  const [selectedAccessKey, setSelectedAccessKey] = useState<ApiAccessKey | null>(null);

  const { pageSize, setPageNumber, pageNumber, setPageSize } = usePagination(20);

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };

  const navigateToAccessKeysTab = () => {
    if (!showAutomatoApiAccess(dbUser?.role as RolesEnum)) {
      navigateToSettingsTab();
    } else {
      navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.API_ACCESS_KEYS}`, navigate);
    }
  };

  const getAllAccessKeysAction = async () => {
    setLoading(true);
    try {
      let res: ApiAccessKey[] | null = await AccessKeyApis.getAllAccessKeys();
      dispatch(setAccessKeysList(res));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getAllAccessKeysAction();
  }, []);

  // close box action for all boxes
  const colseConfirmBox = () => {
    setConfirmModalState({
      openDelete: false,
      openRerun: false,
      openGenerateBox: false,
    });
    setSelectedAccessKeyID(null);
  };

  const confirmDelete = async () => {
    setLoading(true);
    if (selectedAccessKeyID) {
      try {
        await AccessKeyApis.deleteAccessKey(selectedAccessKeyID);
        toast.success(SUCC_ACCESS_KEY_DELETE);
        setLoading(false);
        getAllAccessKeysAction();
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
    } else {
      toast.error("Please select a user", { autoClose: 3000 });
    }
    colseConfirmBox();
  };

  const confirmGenerateKey = async (userID: string) => {
    let apiKeyName = generateRandomText();
    if (userID) {
      setLoading(true);
      try {
        await AccessKeyApis.generateAccessKey({ userID, apiName: apiKeyName });
        toast.success(SUCC_ACCESS_KEY_GENERATED);
        setLoading(false);
        getAllAccessKeysAction();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      toast.error("Id cannot be null", { autoClose: 3000 });
    }
    colseConfirmBox();
  };

  const confirmReGenerate = async () => {
    if (selectedAccessKey !== null) {
      const userID = selectedAccessKey.userID;
      setLoading(true);
      try {
        await AccessKeyApis.reGenerateAccessKey(userID);
        toast.success(`New ${SUCC_ACCESS_KEY_GENERATED}`);
        setLoading(false);
        getAllAccessKeysAction();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    colseConfirmBox();
  };

  const hanldeSwitchChange = async (keyID: string, status: boolean) => {
    if (keyID) {
      const updatedStatus = !status;
      setLoading(true);
      try {
        await AccessKeyApis.updateAccessKeyStatus({ id: keyID, status: updatedStatus });
        toast.success(SUCC_ACCESS_KEY_UPDATED);
        setLoading(false);
        getAllAccessKeysAction();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      toast.error("Key ID cannot be null", { autoClose: 3000 });
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  return (
    <>
      {/* titles */}
      {isDesktop ? (
        <div className={`d-flex justify-content-between align-items-center ${classes.Container}`}>
          <MTypography variant={"h3"} customClass={classes.Heading}>
            {/* {capitalizeEachLetter(SettingsEnum.AUTOMATO_API_ACCESS.split("-").join(" "))} */}
            {"AutoMato Api Access"}
          </MTypography>
          <div className="d-flex align-items-center gap-3">
            <div className={"cursor-pointer"} onClick={navigateToAccessKeysTab}>
              <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
            </div>
            {/* update access */}
            {showGenNewKeyButton(dbUser?.role, apiKeysList?.length) && !loading && (
              <MButton
                size={"small"}
                onClick={() =>
                  showAutomatoApiAccess(dbUser?.role as RolesEnum)
                    ? setConfirmModalState({ ...confirmModalState, openGenerateBox: true })
                    : confirmGenerateKey(dbUser?._id)
                }
                rootClass={"d-none"}
                // temporarily hiding this button
              >
                Generate new Key
              </MButton>
            )}
          </div>
        </div>
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex align-items-center justify-content-between"}>
            <ArrowBackIcon onClick={navigateToAccessKeysTab} />
            {/* update access */}
            {showGenNewKeyButton(dbUser?.role, apiKeysList?.length) && !loading && (
              <MButton
                size={"small"}
                onClick={() =>
                  showAutomatoApiAccess(dbUser?.role as RolesEnum)
                    ? setConfirmModalState({ ...confirmModalState, openGenerateBox: true })
                    : confirmGenerateKey(dbUser?._id)
                }
                disabled={loading}
                rootClass={"d-none"}
                // temporarily hiding this button
              >
                Generate new Key
              </MButton>
            )}
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>
              {/* {capitalizeEachLetter(SettingsEnum.AUTOMATO_API_ACCESS.replace("-", " "))} */}
              {"AutoMato Api Access"}
            </MTypography>
          </div>
        </div>
      )}
      {/* views */}
      {apiKeysList?.length && !loading ? (
        <>
          {isDesktop ? (
            <div>
              <AccessKeyTable
                apiKeysList={apiKeysList}
                handleDelete={(accessKeyId: string) => {
                  setConfirmModalState({ ...confirmModalState, openDelete: true });
                  setSelectedAccessKeyID(accessKeyId);
                }}
                handleRerun={(accessKeyId: ApiAccessKey) => {
                  setConfirmModalState({ ...confirmModalState, openRerun: true });
                  setSelectedAccessKey(accessKeyId);
                }}
                hanldeSwitchChange={hanldeSwitchChange}
                pageSize={pageSize}
                pageNumber={pageNumber}
              />
            </div>
          ) : (
            <div>
              <AccessKeyM
                apiKeysList={apiKeysList}
                handleDelete={(accessKeyId: string) => {
                  setConfirmModalState({ ...confirmModalState, openDelete: true });
                  setSelectedAccessKeyID(accessKeyId);
                }}
                handleRerun={(accessKey: ApiAccessKey) => {
                  setConfirmModalState({ ...confirmModalState, openRerun: true });
                  setSelectedAccessKey(accessKey);
                }}
                hanldeSwitchChange={hanldeSwitchChange}
                pageSize={pageSize}
                pageNumber={pageNumber}
              />
            </div>
          )}
          <div className={isDesktop ? classes.PaginationContainer : ""}>
            <TablePagination
              sx={{
                "& .MuiInputBase-root": {
                  width: "auto",
                },
                "& .MuiTablePagination-root ": {
                  width: "100% !important",
                },
                "& .MuiTablePagination-selectLabel ": {
                  marginTop: "14px !important",
                },
                "& .MuiTablePagination-displayedRows ": {
                  marginTop: "14px !important",
                },
                "& .MuiTablePagination-actions": {
                  marginLeft: { xs: "0px !important", md: "20px !important" },
                },
                "& .MuiTablePagination-toolbar": {
                  paddingLeft: { xs: "0px !important", md: "16px !important" },
                  paddingRight: { xs: "0px !important", md: "2px !important" },
                  maxWidth: { xs: "100% !important" },
                },
              }}
              component="div"
              count={apiKeysList?.length}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[20, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </>
      ) : (
        <>
          {!loading ? (
            <div>
              <NoApiAvailableView
                handleClick={() => {
                  showAutomatoApiAccess(dbUser?.role as RolesEnum)
                    ? setConfirmModalState({ ...confirmModalState, openGenerateBox: true })
                    : confirmGenerateKey(dbUser?._id);
                }}
              />
            </div>
          ) : (
            <> {isDesktop ? <AccessKeySkeleton /> : <AccessSkeletonM />}</>
          )}
        </>
      )}

      {/* generate box */}
      <GenerateNewKeyBox
        open={confirmModalState.openGenerateBox}
        onClose={colseConfirmBox}
        handleGenerateClick={confirmGenerateKey}
      />
      {/* confirm delete  */}
      <ActionBox
        handleAction={confirmDelete}
        handleBack={colseConfirmBox}
        open={confirmModalState.openDelete}
        actionText={"Delete"}
        message={"Do you want to delete api key?"}
        title={"Delete Api Key"}
        backText={"Cancel"}
      />
      {/* rerun box */}
      <ActionBox
        handleAction={confirmReGenerate}
        handleBack={colseConfirmBox}
        open={confirmModalState.openRerun}
        actionText={"Confirm"}
        message={"Regenerating the Api key will override the previous one. Do you really want to regenerate api key?"}
        title={"Regenerate Api Key"}
        backText={"Cancel"}
      />
    </>
  );
};

export default AutomatoApiAccess;
