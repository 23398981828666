import React from "react";

import classes from "./index.module.css";

interface Props {
  label: string;
  type: "Beta" | "Success";
  noMargin?: boolean;
}

const TagLabel: React.FC<Props> = (props) => {
  const { label, type = "After", noMargin = false } = props;

  const styles: any = {
    Beta: {
      Grid: `${classes.TagAfter} ${noMargin ? "m-0" : ""}`,
      Typography: `${classes.TagAfterColor}`,
    },
    Success: {
      Grid: `${classes.TagSuccess} ${noMargin ? "m-0" : ""}`,
      Typography: `${classes.TagAfterColor}`,
    },
  };

  return (
    <div className={`${classes.TagGrid} ${styles[type].Grid}`}>
      <p className={`${classes.TagFont} ${styles[type].Typography}`}>{label}</p>
    </div>
  );
};
export default TagLabel;
