import React from "react";
import classes from "./index.module.css";
import MTypography from "../../../Atoms/MTypography";
import { Divider } from "@mui/material";
import DetailsCompetitorsCard from "./DetailsCompetitorsCard";
import { useSelector } from "react-redux";
import { Competitor } from "../../../../apis/types/generate-product";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";

const ProductDetailsCompetitors = () => {
  const completeProduct = useSelector(CompleteProductSelector);
  const { isMobile, isDesktop } = useDeviceType();
  const competitors = completeProduct?.generatedContentCompetitors;
  return (
    <div className={classes.CompetitorContainer}>
      {competitors && competitors.length > 0 ? (
        competitors.map((item: Competitor, index: number) => (
          <div key={item.asin} className={isMobile ? classes.CompetitorCardMainM : ""}>
            <DetailsCompetitorsCard competitor={item} key={item.asin} />
            {index !== competitors.length - 1 && isDesktop && <Divider className={classes.Divider} />}
          </div>
        ))
      ) : (
        <div className={"d-flex justify-content-center align-items-center w-100 h-100 p-5"}>
          <MTypography variant={"subtitle2"}>{"No competitors to display"}</MTypography>
        </div>
      )}
    </div>
  );
};
export default ProductDetailsCompetitors;
