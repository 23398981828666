import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { SettingsEnum, TabsEnum } from "../../../../utils/constants/enums";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import TabsToolbar from "../../../Molecules/TabsToolbar";
import MTypography from "../../../Atoms/MTypography";
import { navigatePage } from "../../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import MButton from "../../../Atoms/MButton";
import BrandAliasTable from "./BrandAliasTable/BrandAliasTable";
import AddBrandModal from "./AddBrandModal/AddBrandModal";
import BrandAliasCardsContainerM from "./BrandAliasCardsContainerM/BrandAliasCardsContainerM";
import { BrandApis } from "../../../../apis/brands";
import { usePagination } from "../../../../hooks/usePagination";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../utils/helpers/apis";
import { setSelectedBrandAliasDispatch } from "../../../../store/brands/brands.actions";
import { debounce } from "lodash";
import MTextField from "../../../Atoms/MTextField";
import classes from "./index.module.css";

export interface BrandListItem {
  alias: string;
  createdAt: string;
  id: string;
  name: string;
  status: boolean;
  updatedAt: string;
  userEmail: string;
  userID: string;
  userName: string;
  __v: number;
  _id: string;
}

const BrandAliasManagement = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const { pageSize, setPageNumber, pageNumber, setPageSize, sortOrder, offset, sortBy, setSortModel } = usePagination(
    isDesktop ? 50 : 20
  );
  const isInitialRender = useRef(true);
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [brands, setBrands] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigateToBrands = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND}`, navigate);
  };

  const openBrandModal = () => {
    dispatch(setSelectedBrandAliasDispatch(null));
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}/add-brand`, navigate);
    // setOpenModal(true)
  };

  const editBrand = (brand: any) => {
    setSelectedBrand(brand);
    dispatch(setSelectedBrandAliasDispatch(brand));
    navigatePage(
      `${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}/${brand?.id}`,
      navigate
    );
    // openBrandModal();
  };

  const handleCloseModal = () => {
    setSelectedBrand(null);
    setOpenModal(false);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isDesktop) {
      getAllBrands(false, false);
    } else {
      getAllBrands(false, true);
    }
  }, [pageNumber, pageSize, offset, sortBy, sortOrder]);

  useEffect(() => {
    if (!isInitialRender.current) {
      getAllBrands(true);
    } else {
      isInitialRender.current = false;
    }
  }, [searchQuery]);

  const getAllBrands = (isReset = false, isMobile = false) => {
    if (isReset) {
      setBrands([]);
      setPageNumber(0);
    }
    setLoading(true);
    BrandApis.getAllBrands({
      offset: offset,
      limit: pageSize,
      sortBy: sortBy || "name",
      searchQuery: searchQuery ? searchQuery : "",
      sortOrder,
    })
      .then((res: any) => {
        const brandsListWithId = res?.brands.map((brand: any) => ({ ...brand, id: brand?._id }));
        if (isMobile) {
          if (brands?.length > 0 && brands?.length > 0) {
            const allbrandsList = [...brands, ...brandsListWithId];
            setBrands(allbrandsList);
          } else {
            setBrands(brandsListWithId);
          }
        } else {
          setBrands(brandsListWithId);
        }
        // setBrands();
        setTotal(res?.totalRecords);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 1500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleHideToggle = (brand: any) => {
    setLoading(true);
    BrandApis.updateBrand({
      ...brand,
      status: !brand?.status,
      brandID: brand?._id,
    })
      .then(() => {
        getAllBrands(true, false);
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
      });
  };

  return (
    <div>
      {isDesktop ? (
        <TabsToolbar
          title={SettingsEnum.BRAND_ALIAS_MANAGEMENT}
          children={
            <div className="d-flex align-items-center gap-4">
              <div className={"cursor-pointer"} onClick={navigateToBrands}>
                <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
              </div>
              <MButton variant="contained" onClick={openBrandModal}>
                Add Brand
              </MButton>
            </div>
          }
        />
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex justify-content-between align-items-center"}>
            <ArrowBackIcon onClick={navigateToBrands} />
            <MButton variant="contained" onClick={openBrandModal}>
              Add Brand
            </MButton>
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>
              {capitalizeEachLetter(SettingsEnum.BRAND_ALIAS_MANAGEMENT.replace("-", " "))}
            </MTypography>
          </div>
        </div>
      )}

      <div className={`${classes.ToolBarContainer} ${!isDesktop && classes.ToolBarContainerM}`}>
        <MTextField
          icon={"search"}
          position={"start"}
          onChange={handleChange}
          name={"customKeyword"}
          placeholder={"Search by brand name or alias "}
          value={search}
          margin={"dense"}
          rootClass={classes.TextInput}
        />
      </div>
      <div className={``}>
        {isDesktop ? (
          <BrandAliasTable
            editBrand={editBrand}
            brandsList={brands}
            getAllBrands={getAllBrands}
            totalBrands={total}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            sortOrder={sortOrder}
            offset={offset}
            sortBy={sortBy}
            setSortModel={setSortModel}
            handleHideToggle={handleHideToggle}
            loading={loading}
          />
        ) : (
          <BrandAliasCardsContainerM
            editBrand={editBrand}
            brandsList={brands}
            getAllBrands={getAllBrands}
            totalBrands={total}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            sortOrder={sortOrder}
            offset={offset}
            sortBy={sortBy}
            setSortModel={setSortModel}
            handleHideToggle={handleHideToggle}
            loading={loading}
          />
        )}
      </div>
      <AddBrandModal
        edit={!!selectedBrand}
        editValues={selectedBrand}
        fetchBrands={getAllBrands}
        open={openModal}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default BrandAliasManagement;
