import TwoColorText from "../../../Molecules/TwoColorText";
import MTypography from "../../../Atoms/MTypography";
import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import classes from "./index.module.css";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { useDispatch, useSelector } from "react-redux";
import MTextField from "../../../Atoms/MTextField";
import { useState } from "react";
import { errorHandler } from "../../../../utils/helpers/apis";
import { toast } from "react-toastify";
import { ScrapeProductSelector } from "../../../../store/product/productScrape/productScrape.selectors";

import { updateProductBrandAction } from "../../../../store/product/productScrape/productScrape.actions";

const ProductBrand = () => {
  const { isDesktop } = useDeviceType();
  const dispatch = useDispatch();
  const scrapeProduct = useSelector(ScrapeProductSelector);

  const [brand, setBrand] = useState<string>(scrapeProduct?.product?.brand || "");
  const [editBrand, setEditBrand] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const updateProductBrand = () => {
    if (!loading) {
      setLoading(true);
      dispatch(updateProductBrandAction(brand, scrapeProduct))
        .then(() => {
          setLoading(false);
          setEditBrand(false);
          setBrand(brand);
        })
        .catch((e: any) => {
          toast.error(errorHandler(e));
        });
    }
  };

  return (
    <div className={""}>
      {editBrand ? (
        <div className={"d-flex align-items-center"}>
          <MTextField value={brand} onChange={(e) => setBrand(e.target.value)} name={"brand"} />
          <MTypography
            variant={"subtitle2"}
            customClass={isDesktop ? classes.editIconAndTextColor : classes.editIconAndTextColorM}
          >
            <div onClick={updateProductBrand} className={"d-flex align-items-center mx-1"}>
              <SaveAsIcon className={"p-1"} /> {"Save"}
            </div>
          </MTypography>
        </div>
      ) : (
        <div className={"d-flex align-items-center"}>
          {scrapeProduct?.product?.brand?.length ? (
            <div>
              <TwoColorText
                text1={"By:"}
                text2={scrapeProduct?.product?.brand as string}
                text1Class={isDesktop ? classes.text1Class : classes.text1ClassM}
                text2Class={isDesktop ? classes.text2Class : classes.text2ClassM}
              />
            </div>
          ) : (
            <div>
              <MTypography variant={"subtitle1"}>N/A</MTypography>
            </div>
          )}

          <div>
            <MTypography
              variant={"subtitle2"}
              customClass={isDesktop ? classes.editIconAndTextColor : classes.editIconAndTextColorM}
            >
              <div onClick={() => setEditBrand(true)} className={"d-flex align-items-center mx-1"}>
                <EditIcon className={"p-1"} /> {"Edit"}
              </div>
            </MTypography>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductBrand;
