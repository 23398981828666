export const getOpportunityStats = (data: any) => {
  try {
    // const length: number = data.length;
    if (data) {
      return {
        // totalSearch: .toFixed(2), // Total Search Volume Exposure
        averageVolume: data.totalVolumeChange.toFixed(2), // Total Search Volume Change
        averageKw: data.totalKeywordCoverageRatio.toFixed(2), // Total Keyword Coverage
        totalAsinLive: data.totalLiveProducts, // Live
      };
    }
    return {
      // totalSearch: 0,
      averageVolume: 0,
      averageKw: 0,
      totalAsinLive: 0,
    };
  } catch (e) {
    return {
      // totalSearch: 0,
      averageVolume: 0,
      averageKw: 0,
      totalAsinLive: 0,
    };
  }
};
