import { brandsActionTypes } from "./brands.action-types";

interface SetSelectedBrandAlias {
  type: brandsActionTypes.setSelectedBrandAlias;
  payload: any;
}

export const setSelectedBrandAliasDispatch = (brand: any): SetSelectedBrandAlias => ({
  type: brandsActionTypes.setSelectedBrandAlias,
  payload: brand,
});

export type BrandsReducerActionType = SetSelectedBrandAlias;
