import { useNavigate } from "react-router-dom";
import MTypography from "../../../components/Atoms/MTypography";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import { navigatePage } from "../../../utils/helpers/common";
import SettingMenuCard from "../../../components/Organisms/SettingsMenuCard";
import { useDeviceType } from "../../../hooks/useDeviceType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import { brandsMenu } from "./config";

const BrandsMenu = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate); 
  };

  const handleNavigate = (name: string) => {
    if (name === SettingsEnum.CONTENT_FORMAT_SETTINGS) {
      navigate(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.CONTENT_FORMAT_SETTINGS}`);
    } else if(name === SettingsEnum.BRAND_ALIAS_MANAGEMENT) {
      navigate(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}`);
    } else if(name === SettingsEnum.BRAND_VOICE){
      navigate(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_VOICE}`);
    }else{}
  };

  return (
    <div>
      <div>
        {isDesktop ? (
          <TabsToolbar
            title={SettingsEnum.BRAND}
            children={
              <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
                <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
              </div>
            }
          />
        ) : (
          <div className={"d-flex flex-column p-2 m-2"}>
            <div className={"my-2 mx-2"}>
              <ArrowBackIcon
              onClick={navigateToSettingsTab}
              />
            </div>
            <div className={"my-2 mx-2"}>
              <MTypography variant={"h5"}>
                {capitalizeEachLetter(SettingsEnum.BRAND.replace("-", " "))}
              </MTypography>
            </div>
          </div>
        )}

        <div className={`d-flex align-items-center ${isDesktop ? "flex-row" : "flex-column"}`}>
          {brandsMenu.map((menu) => (
            <div key={menu.title} onClick={() => handleNavigate(menu.name)} className={`${isDesktop && "m-5"}`}>
              <SettingMenuCard title={menu.title} description={menu.description} icon={menu.icon} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandsMenu;
