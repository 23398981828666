import React, { useEffect, useState } from "react";
import MTypography from "../../../Atoms/MTypography";
import classes from "./ProductCardSkeleton.module.css";

import { ContentStatusEnum, CountryDomainEnum, RolesEnum } from "../../../../utils/constants/enums";
import ProductStatus from "../../../Molecules/ProductStatus";
import { CountryDomainObject, getCountryDomainObjectByName } from "../../DomainDropDown/config";
import { Skeleton } from "@mui/material";
import ActionMenu from "./ActionMenu/ActionMenu";
import unSelectIcon from "../../../../assets/svgs/Checkbox/Square/rowUnSelectIcon.svg";
import selectIcon from "../../../../assets/svgs/Checkbox/Square/rowSelectIcon.svg";
import {
  showDeleteAndEditIconOnWaitingGrid,
  showUserEmailInProductsPage,
} from "../../../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";

type ProductCardSkeletonProps = {
  productDetail: any;
  handleDelete: (id: string) => void;
  handleEditClick: (productData: any) => void;
  handleMultiCardSelectionM: (id: string) => void;
  handleRestrictedAsinRerun: (productData: any) => void;
};

const ProductCardSkeleton: React.FC<ProductCardSkeletonProps> = ({
  productDetail,
  handleDelete,
  handleEditClick,
  handleRestrictedAsinRerun,
  handleMultiCardSelectionM,
}) => {
  // user
  const { productASIN, status, domain, _id } = productDetail;
  const [isWaiting, setIsWaiting] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const dbUser = useSelector(DbUserSelector);
  const userRole = useSelector(UserRoleSelector);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setIsWaiting(status === ContentStatusEnum.WAITING || status === ContentStatusEnum.FETCHING ? true : false);
  }, [status]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setIsSelected(false);
  }, [_id]);

  const handleMenuClick = (actionType: string) => {
    switch (actionType) {
      case "rerun":
        handleRestrictedAsinRerun(productDetail);
        break;
      case "edit":
        handleEditClick(productDetail);
        break;
      case "delete":
        handleDelete(_id);
        break;

      default:
        break;
    }
    setShowMenu(false);
  };

  return (
    <div className={"card mt-4 cursor-pointer " + classes.ProductCard}>
      {status !== ContentStatusEnum.WAITING && (
        <img
          src={isSelected ? selectIcon : unSelectIcon}
          alt={"row-select-icon"}
          className={classes.Checkbox}
          onClick={() => {
            setIsSelected(!isSelected);
            handleMultiCardSelectionM(_id);
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center">
        <ProductStatus type={status || ContentStatusEnum.WAITING} showInfo variant={"small"} />
        <div>
          {isWaiting ? (
            <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
          ) : (
            <>
              {/* // this is a seprate component so if we need to add more actions in it in future */}
              {(showDeleteAndEditIconOnWaitingGrid(dbUser?.role) || dbUser?.companyID) && (
                <ActionMenu
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                  handleMenuClick={handleMenuClick}
                  status={status}
                  email={productDetail?.userEmail}
                />
              )}
            </>
          )}
        </div>
      </div>

      {/* asin container */}
      <MTypography variant={"h6"} customClass={`pt-1 ${classes.ProductAsinMB}`}>
        {productASIN}
      </MTypography>

      <div className={"w-100 pt-1"}>
        {/* {isWaiting ?
          <Skeleton variant={"text"} sx={{ width: "50%" }} />
          : */}
        <>
          {(() => {
            const countryDomainObject = getCountryDomainObjectByName(
              domain || CountryDomainEnum.AMAZON_USA
            ) as CountryDomainObject;

            return (
              <div className={`d-flex align-items-center`}>
                <img src={countryDomainObject.flag} alt={`${countryDomainObject.name} Flag`} className={classes.Flag} />
                <MTypography variant={"subtitle2"} customClass={classes.FlagName}>
                  {countryDomainObject.marketPlaceName}
                </MTypography>
              </div>
            );
          })()}
        </>
        {/* } */}
      </div>
      {showUserEmailInProductsPage(userRole as RolesEnum) && (
        <div className={"w-100 pt-1"}>
          <div>{productDetail?.userEmail ? productDetail?.userEmail : <>---</>}</div>
        </div>
      )}
    </div>
  );
};
export default ProductCardSkeleton;
