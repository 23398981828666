import React from "react";
import { Tooltip } from "@mui/material";

import MTypography from "../../Atoms/MTypography";
import PercentageTag from "../../Atoms/PercentageTag";

import classes from "./index.module.css";
import { AsinKeywordHistoryObject } from "../../../store/product/product.reducers";
import { toDateOnly } from "../../../utils/helpers/date";

interface Props {
  keywordHistory: AsinKeywordHistoryObject[];
  children: JSX.Element;
}

const KeywordHistory: React.FC<Props> = (props) => {
  const { keywordHistory } = props;
  const tooltipContent = (
    <div>
      <div className={classes.Container}>
        <MTypography variant={"h6"} customClass={classes.Heading}>
          History
        </MTypography>
        <table className={classes.Table}>
          <tr>
            <th className={`${classes.TH} ${classes.DateColumn}`}></th>
            <th className={classes.TH}>Rank</th>
            <th className={classes.TH}>Rank Ease</th>
          </tr>
          {keywordHistory &&
            keywordHistory.length > 0 &&
            keywordHistory.map((keyword, index) => (
              <tr key={index}>
                <td className={`${classes.TD} ${classes.DateColumn}`}>{toDateOnly(keyword.date)}</td>
                <td className={classes.TD}>{keyword.rank}</td>
                <td className={classes.TD}>
                  <PercentageTag percentage={keyword.competitiveness} />
                </td>
              </tr>
            ))}
        </table>
      </div>
      <div className={classes.Footer}>
        <div className={"d-flex align-items-center gap-1"}>
          <div className={classes.ListingBox}></div>
          <p className={classes.Captions}>In Listing</p>
        </div>
        <div className={"d-flex align-items-center gap-1"}>
          <div className={classes.TitleBox}></div>
          <p className={classes.Captions}>In Title</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {keywordHistory && keywordHistory.length > 0 ? (
        <Tooltip
          classes={{ tooltip: classes.Tooltip, arrow: classes.TooltipArrow }}
          title={tooltipContent}
          placement="right"
          arrow
        >
          {props.children}
        </Tooltip>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default KeywordHistory;
