import * as yup from "yup";

export interface ContactUsObject {
  email: string;
  additionalInformation: string;
  type: string;
}

export const inputName = {
  email: "email",
  additionalInformation: "additionalInformation",
  type: "type",
};

export const initialValue: ContactUsObject = {
  email: "",
  additionalInformation: "",
  type: "",
};

export const validationSchema = yup.object().shape({
  additionalInformation: yup.string().trim().required("Additional information is required"),
  email: yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please add valid email address")
    .required("Work email is required"),
  type: yup.string().trim().required("Issue type is required"),
});

export const issueTypeOptions = [
  "Technical Issues and Troubleshooting",
  "Keyword Strategy and Optimization",
  "Product Listing Creation and Management",
  "Performance Tracking and Analytics",
];
