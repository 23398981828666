import React from "react";

import { useDeviceType } from "../../../hooks/useDeviceType";

interface HeadingUnderlineProps {
  content: string;
}

const HeadingUnderline: React.FC<HeadingUnderlineProps> = (props) => {
  const { content } = props;
  const { isDesktop } = useDeviceType();
  return (
    <div className={"my-3"}>
      <p
        className={"m-0"}
        style={isDesktop ? { fontSize: "16px", fontWeight: "600" } : { fontSize: "14px", fontWeight: "500" }}
      >
        {content}
      </p>
      <div
        style={{
          height: "1px",
          backgroundColor: "#DDD",
          marginTop: "16px",
        }}
      ></div>
    </div>
  );
};

export default HeadingUnderline;
