import React, { useEffect, useState } from "react";
import { Switch, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { RolesEnum, SettingsEnum, TabsEnum, UserStatusEnum } from "../../../utils/constants/enums";
import MTypography from "../../../components/Atoms/MTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import { useDeviceType } from "../../../hooks/useDeviceType";
import TeamUserStats from "../../../components/Organisms/TeamManagementUser/TeamUserStats";
// import { navigatePage } from "../../../utils/helpers/common";
// import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import {
  TeamManagementSelectedMemberSelector,
  TeamMemberStatsSelector,
} from "../../../store/team-management/team-management.selector";
// import { RolesTitlesEnum } from "../../../utils/constants/general-constants";

import classes from "./index.module.css";
import MTabs from "../../../components/Atoms/MTabs";
import { getTextBasedOnCompanyRole, teamManagementUserTabs, teamManagementUserTabsValue } from "./config";
import SavedProductsTab from "../../SavedProducts";
import {
  setSelectedTeamMemberDispatch,
  setTeamMemberStatsDispatch,
} from "../../../store/team-management/team-management.actions";
import { CompanyRoleSelector } from "../../../store/company/company.selector";
import { toast } from "react-toastify";
import { SUCC_USER_PROFILE_UPDATED } from "../../../utils/constants/messages/success";
import { UserApis } from "../../../apis/user";
import { setUserAction } from "../../../store/user/user.actions";
import RolesDropDown from "../../../components/Molecules/RolesDropdown";
import { UserRoleSelector } from "../../../store/user/user.selector";
import { userRole } from "../../../utils/helpers/priviligesChecks";
import { errorHandler } from "../../../utils/helpers/apis";
import { navigatePage } from "../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
// import { setUserAction } from "../../../store/user/user.actions";
const TeamManagementUser = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();

  const userData = useSelector(TeamManagementSelectedMemberSelector);
  const stats = useSelector(TeamMemberStatsSelector);
  const companyRole = useSelector(CompanyRoleSelector);
  const dispatch = useDispatch();
  const currentUserRole = useSelector(UserRoleSelector);
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<string>(teamManagementUserTabsValue.products);

  const [userStatus, setUserStatus] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState(userData?.role);

  const navigateToSettingsTab = () => {
    dispatch(setTeamMemberStatsDispatch(null));
    if (location?.state?.prevPath) {
      navigatePage(location?.state?.prevPath, navigate);
    } else {
      if(currentUserRole){
        currentUserRole === RolesEnum.COMPANY_ADMIN ?
        navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.TEAM_MANAGEMENT}`, navigate) :
        navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}`, navigate) 
      }else{
        navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate)
      }
    }    
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setSelectedRole(userData?.role);
  }, [userData]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!userData) {
      navigateToSettingsTab();
    }
  }, []);

  useEffect(() => {
    const userStatusInBool = userData?.status === UserStatusEnum.Active ? true : false;
    setUserStatus(userStatusInBool);
  }, [userData]);

  const handleSwitch = async (userStatus: UserStatusEnum) => {
    if (userData?.id) {
      try {
        const status = userStatus === UserStatusEnum.Active ? UserStatusEnum.Pause : UserStatusEnum.Active;
        const res = await UserApis.updateUserSettings({
          userID: userData?.id,
          status: status,
        });
        // @ts-ignore
        dispatch(setUserAction());
        if (res) {
          toast.success(SUCC_USER_PROFILE_UPDATED);
          dispatch(
            setSelectedTeamMemberDispatch({ ...res, id: res?._id, countKeywordRefreshes: res?.countHelium10Calls })
          );
        }
      } catch (e) {
        console.log("error Submit Form", e);
      }
    }
  };


  const handleRoleChange = async (role: any) => {    
    try {
      const res = await UserApis.updateUserSettings({
        userID: userData?.id,
        role,
      });
      if (!res[0]) {
        toast.success(SUCC_USER_PROFILE_UPDATED);
      }else{
        toast.error(res[0])
      }
    } catch (error: any) {
      toast.error(errorHandler(error))
    }
  };

  return (
    <div>
      {isDesktop ? (
        <TabsToolbar
          title={userData?.name || ""}
          children={
            <div className="d-flex align-items-center gap-4">
              <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
                <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
              </div>
            </div>
          }
        />
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex justify-content-between align-items-center"}>
            <ArrowBackIcon onClick={navigateToSettingsTab} />
          </div>
          <div className={`${isDesktop ? "my-2 mx-2" : "mx-2"}`}>
            <MTypography variant={"h5"}>
              {capitalizeEachLetter(userData?.name || "")}
            </MTypography>
          </div>
        </div>
      )}
      <div
        style={{ paddingRight: isDesktop ? "32px" : "" }}
        className={`d-flex ${isDesktop ? "flex-row align-items-center" : "flex-column"} justify-content-between`}
      >
        <div className={`${isDesktop ? classes.InputContainer : classes.InputContainerM} w-100`}>
          <RolesDropDown
            name={"role"}
            label={"User's Role"}
            value={selectedRole}
            handleChange={handleRoleChange}
            disabled={!userRole.isAdminOrOwner(currentUserRole)}
            selectableRoles={[RolesEnum.COMPANY_ADMIN, RolesEnum.USER]}
          />
          <p className={classes.TextInputSubtitle}>{getTextBasedOnCompanyRole(userData?.role, companyRole)}</p>
        </div>
        {(userData?.role !== RolesEnum.COMPANY_ADMIN || (currentUserRole === RolesEnum.ADMIN ||currentUserRole === RolesEnum.OWNER)) && (
          <div className={!isDesktop ? classes.SiwtchContainer : ""}>
            <span className={classes.ActiveInactive}>{"Active / Inactive "}</span>{" "}
            <Switch size="small" checked={userStatus} onChange={() => handleSwitch(userData?.status)} />
          </div>
        )}
      </div>
      <div>
        <TeamUserStats isLoading={false} userData={userData} stats={stats} />
        <div className={`${isDesktop && classes.Container} mt-3`}>
          <div className={"d-flex"}>
            <MTabs handleChange={handleActiveTab} value={activeTab} tabs={teamManagementUserTabs} isCamelCase={false} />
          </div>

          {activeTab === teamManagementUserTabsValue.basicDetails && (
            <div className={`mt-4 d-flex gap-4 ${isDesktop ? "flex-row" : "flex-column px-2 pb-4"}`}>
              <TextField className={"w-100"} value={capitalizeEachLetter(userData?.name || "")} label={"Full Name"} disabled />
              <TextField className={"w-100"} value={userData?.email} label={"Work Email"} disabled />
            </div>
          )}
          {activeTab === teamManagementUserTabsValue.products && (
            <div className={""}>
              <SavedProductsTab isTeamManagementUser teamUserId={userData?._id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamManagementUser;
