import usa from "../../../assets/svgs/flags/usa.svg";
import uk from "../../../assets/svgs/flags/uk.svg";
import canada from "../../../assets/svgs/flags/can.svg";
import { CountryDomainEnum } from "../../../utils/constants/enums";

export interface CountryDomainObject {
  flag: string;
  name: CountryDomainEnum;
  marketPlaceName: string;
}
export const countryDomain: CountryDomainObject[] = [
  { flag: usa, name: CountryDomainEnum.AMAZON_USA, marketPlaceName: "US" },
  { flag: uk, name: CountryDomainEnum.AMAZON_UK, marketPlaceName: "UK" },
  { flag: canada, name: CountryDomainEnum.AMAZON_CANADA, marketPlaceName: "CA" },
];

export const getCountryDomainObjectByName = (name: CountryDomainEnum) =>
  countryDomain.find((country) => country.name === name);
