import { useState, useCallback } from "react";

const useMTab = (tabs: string[]) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0] || "");

  const handleTabChange = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  return { handleTabChange, activeTab };
};
export default useMTab;
