import React from "react";
import { useFormikContext } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import classes from "./index.module.css";

interface FormPhoneInputProps {
  label: string;
  name: any;
  disabled?: boolean;
}

const FormPhoneInput: React.FC<FormPhoneInputProps> = (props) => {
  const { label, name, disabled } = props;
  const { values, setFieldValue }: any = useFormikContext();

  const handleOnChange = (value: any) => {
    setFieldValue(name, `+${value}`);
  };

  return (
    <div>
      <PhoneInput
        country={"us"}
        autoFormat={true}
        value={values[name]?.toString()}
        onChange={(value) => handleOnChange(value)}
        specialLabel={label}
        containerStyle={{ boxShadow: "none" }}
        dropdownStyle={{ textAlign: "left" }}
        containerClass={classes.ContainerClass}
        inputClass={classes.PhoneInputfield}
        disabled={disabled}
      />
    </div>
  );
};

export default FormPhoneInput;
