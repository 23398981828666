export interface ClientProfileObject {
  name: string;
  // userName: string;
  email: string;
  phoneNo: string;
  keywordTracking: boolean;
  asinLimit: number;
  keywordRefreshes: number;
  contentRegeneration: number;
}

export const inputName = {
  name: "name",
  // userName: "userName",
  email: "email",
  phoneNo: "phoneNo",
  keywordTracking: "keywordTracking",
  asinLimit: "asinLimit",
  keywordRefreshes: "keywordRefreshes",
  contentRegeneration: "contentRegeneration",
};

export const initialValue: ClientProfileObject = {
  name: "",
  // userName: "",
  email: "",
  phoneNo: "",
  keywordTracking: true,
  asinLimit: 0,
  keywordRefreshes: 0,
  contentRegeneration: 0,
};
