import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";
import classes from "./index.module.css";
import MTypography from "../../Atoms/MTypography";
import MButton from "../../Atoms/MButton";
type ActionButton = {
  action: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  element?: React.ReactNode;
};
type DialogBoxProps = DialogProps & {
  title?: string;
  beforeContent?: React.ReactNode;
  confirmButton: ActionButton;
  cancelButton?: ActionButton;
  hideCancelButton?: boolean;
};

const DialogBox = (props: DialogBoxProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
        <>
          {props?.title && <DialogTitle className={classes.Title}>{props.title}</DialogTitle>}
          {props.beforeContent}
          <DialogContent className={classes.BodyContainer}>
            <DialogContentText className={classes.Body}>{props.children}</DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <div className={"gap-3 d-flex align-items-center p-3"}>
              {!props.hideCancelButton && (
                <>
                  {props.cancelButton && (
                    <MTypography variant={"h2"} customClass={classes.Cancel}>
                      <div onClick={props.cancelButton.action as any}>{props.cancelButton.element}</div>
                    </MTypography>
                  )}
                </>
              )}

              <MButton
                onClick={props.confirmButton.action}
                size="small"
                disabled={props.confirmButton.disabled || false}
              >
                {props.confirmButton.element}
              </MButton>
            </div>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
};
export default DialogBox;
