import * as React from "react";
import { Navigate } from "react-router-dom";
import { KEY_USER_ACCESS_TOKEN } from "../utils/constants/store";

type ProtectedRouteProps = {
  Component: React.ReactElement;
};

const ProtectRoute = (props: ProtectedRouteProps): JSX.Element => {
  const { Component } = props;
  const user =
    sessionStorage.getItem(KEY_USER_ACCESS_TOKEN)?.length || localStorage.getItem(KEY_USER_ACCESS_TOKEN)?.length;

  if (user) return Component;

  return <Navigate to="/" replace />;
};

export default ProtectRoute;
