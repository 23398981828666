import React from "react";
import { useFormikContext } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

interface FormRadioGroupProps {
  name: string;
  options: any;
  label: string;
}

const FormRadioGroup: React.FC<FormRadioGroupProps> = (props) => {
  const { name = "", options, label = "" } = props;

  const { values, setFieldValue }: any = useFormikContext();

  const handleChange = (e: any) => {
    setFieldValue(name, e.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={values[name]}
        onChange={handleChange}
      >
        {options &&
          options.length > 0 &&
          options.map((option: any) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: 14,
                },
              }}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                />
              }
              label={option.label}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioGroup;
