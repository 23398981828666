import { useState, useCallback, useMemo, useEffect } from "react";

export const defaultPageSize = 20;

export const defaultPageSizeOptions = [20, 50, 100];

interface UsePaginationPayload {
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setSortModel: (sortBy: string, sortOrder: number) => void;
  resetFilters: () => void;
  offset: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortOrder: number;
}

export interface PaginationObject {
  offset: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortOrder: number;
}

export const usePagination = (
  initialPageSize?: number,
  isObject: boolean = false,
  paginationObject?: PaginationObject
): UsePaginationPayload => {
  const [pageSize, setPageSize] = useState<number>(initialPageSize || defaultPageSize);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [sortBy, setSortBy] = useState<string>("");

  const [sortOrder, setSortOrder] = useState<number>(1);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isObject) {
      setPageNumber(paginationObject?.pageNumber as number);
      setSortBy(paginationObject?.sortBy as string);
      setSortOrder(paginationObject?.sortOrder as number);
      setPageSize(paginationObject?.pageSize as number);
    }
  }, []);

  const setPageNumberAction = useCallback((pageSize: number) => {
    setPageNumber(pageSize);
  }, []);

  const setPageSizeAction = useCallback((pageNumber: number) => {
    setPageSize(pageNumber);
  }, []);

  const setSortModel = useCallback((sortBy: string, sortOrder: number) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  const offset = useMemo(() => {
    const offset = pageNumber * pageSize;
    return offset;
  }, [pageNumber, pageSize]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const resetFiltersAction = useCallback(() => {
    setPageNumber(0);
    setPageSize(initialPageSize || defaultPageSize);
  }, []);

  return {
    setSortModel,
    sortBy,
    sortOrder,
    setPageNumber: setPageNumberAction,
    setPageSize: setPageSizeAction,
    resetFilters: resetFiltersAction,
    pageSize,
    pageNumber,
    offset,
  };
};
