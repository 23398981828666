import { BulletFormatEnum } from "../constants/enums";

export const capitalizeEachLetter = (text: string) => {
  return text ? text.toLowerCase().replace(/\b\w+(?:'\w+)?/g, (m) => m.charAt(0).toUpperCase() + m.slice(1)) : text;
};

export const camelCaseToNormalSentence = (camelCaseString: string): string => {
  const sentence = camelCaseString.replace(/([A-Z])/g, " $1");
  const capitalizedSentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);

  return capitalizedSentence;
};
export function removeUnderscoreAndCapitalize(srt: string): string {
  // Split the string by underscores and convert it to an array of words
  const words = srt.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words with a space between them
  const result = capitalizedWords.join(" ");

  return result;
}
//Input:myVariableName Output: "My Variable Name"
export function camelCaseToPascalCaseWithSpaces(str: string): string {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (match) {
    return match.toUpperCase();
  });
}

export function caseInsensitiveSearch(array: string[], searchTerm: string) {
  if (array.length === 0) return [];

  searchTerm = searchTerm.toLowerCase(); // Convert search term to lowercase

  const result = array.filter((item) => {
    return item.toLowerCase().includes(searchTerm);
  });
  return result;
}

function capitalizeWords(text: string): string {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatBulletsPoints(
  input: string,
  format: BulletFormatEnum = BulletFormatEnum.UPPERCASE_COLON
): string {
  try {
    const text = input.split(":");
    const headline = text[0]
    text.shift()
    const content = text.join(' ')
    if(input[0] === '【'){
      return input
    }

    if (!headline || !content) {
      throw new Error("Input string must contain a colon separating the headline and content.");
    }

    let formattedHeadline: string;
    switch (format) {
      case BulletFormatEnum.UPPERCASE_COLON:
        formattedHeadline = headline.trim().toUpperCase();
        return `${formattedHeadline}: ${content.trim()}`;

      case BulletFormatEnum.PARENTHESIS_COLON:
        formattedHeadline = `【${capitalizeWords(headline.trim())}】`;
        return `${formattedHeadline} ${content.trim()}`;

      case BulletFormatEnum.DEFAULT_COLON:
        formattedHeadline = headline.trim().charAt(0).toUpperCase() + headline.trim().slice(1).toLowerCase();
        return `${formattedHeadline}: ${content.trim()}`;

      case BulletFormatEnum.UPPERCASE_HYPHEN:
        formattedHeadline = headline.trim().toUpperCase();
        return `${formattedHeadline} - ${content.trim()}`;

      case BulletFormatEnum.DEFAULT_HYPHEN:
        formattedHeadline = capitalizeWords(headline.trim());
        return `${formattedHeadline} - ${content.trim()}`;

      default:
        throw new Error("Unknown format type.");
    }
  } catch (error) {
    // If an error occurs, return the original string
    return input;
  }
}

export function endsWithComma(str: string) {
  const lastChar = str.trim().slice(-1); // Get the last character after removing leading/trailing spaces
  return lastChar === ",";
}
export function endsWithSpace(str: string) {
  const lastChar = str.slice(-1); // Get the last character after removing leading/trailing spaces
  return /\s/.test(lastChar);
}

export function removeComma(str: string) {
  return str.replace(/,/g, "");
}
export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// it was returning just 1st matching word in the longString
// will remove it later

// export function findSubstringIndices(longString: any, substring: any): any {
//   try {
//     // Find the start index of the substring
//     let startIndex = longString.toLowerCase().indexOf(substring.toLowerCase());

//     // If the substring is not found, return indices as -1
//     if (startIndex === -1) {
//       return [];
//     }

//     // Calculate the end index of the substring
//     let endIndex = startIndex + substring.length - 1;

//     return [
//       {
//         start: startIndex,
//         end: endIndex + 1,
//       },
//     ];
//   } catch (_e) {
//     return [];
//   }
// }

// new method that returns all the words index match in longString
export function findSubstringIndices(longString: any, substring: any): any {
  try {
    // Convert both the longString and substring to lowercase for case-insensitive search
    const lowerLongString = longString.toLowerCase();
    const lowerSubstring = substring.toLowerCase();

    // Initialize an array to store the indices of all matches
    const indices = [];

    const regex = new RegExp(`${lowerSubstring}`, "g");

    let match;
    while ((match = regex.exec(lowerLongString)) !== null) {
      indices.push({
        start: match.index,
        end: match.index + lowerSubstring.length,
      });
    }

    return indices;
  } catch (_e) {
    return [];
  }
}

// to change any string to camel case
export const toCamelCase = (str: string) => {
  let words = str?.split(" ");
  let camelCaseStr = words
    ?.map((word, index) => {
      if (index === 0) {
        return word?.toLowerCase();
      } else {
        return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
      }
    })
    ?.join("");

  return camelCaseStr;
};

export function replaceWhitespaceWithHTML(str: string): string {
  // Replace new lines (\n) with <br>
  let formattedStr = str.replace(/\n/g, "<br>");

  // Replace tabs (\t) with 4 non-breaking spaces (&nbsp;)
  formattedStr = formattedStr.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");

  return formattedStr;
}

// will add ' to the last character of first word like this
// amazonsChoice => Amazon's Choice
export function camelCaseToPascalCaseWithApostrophe(str: string) {
   // Convert camelCase to Pascal Case with spaces
   const pascalWithSpaces = str.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, (c) => c.toUpperCase());
   // Add apostrophe before the last character of the first word
   const firstWordWithApostrophe = pascalWithSpaces.replace(/^(\w+)(\w)(\s)/, "$1'$2$3");
   return firstWordWithApostrophe;
}
