import { CompleteProduct } from "../../../../../apis/types/generate-product";
import { ContentStatusEnum } from "../../../../../utils/constants/enums";

export const longTextNumber = 500;
export const maxTitleLength = 200;
export const maxSearchTermLength = 250;
export const maxDescriptionLength = 2000;
export const allBulletPointsLength = 2500;
export const isLongCharacter = (str: string = "", textNumber: number = longTextNumber): boolean => {
  return str.length > textNumber;
};

export const handleTitleLiveStatus = (product: CompleteProduct) => {
  if (
    product &&
    (product.status === ContentStatusEnum.APPROVED ||
      product.status === ContentStatusEnum.PUBLISHED) &&
    product?.productLive?.isTitleLive !== null &&
    product?.productLive?.isTitleLive !== undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const handleBulletPointsLiveStatus = (product: CompleteProduct) => {
  if (
    product &&
    (product.status === ContentStatusEnum.APPROVED ||
      product.status === ContentStatusEnum.PUBLISHED
    ) &&
    product?.productLive?.isBulletPointsLive !== null &&
    product?.productLive?.isBulletPointsLive !== undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const getSelectedTitleVariation = (product: CompleteProduct): string => {

  if (product.status === ContentStatusEnum.APPROVED || product.status === ContentStatusEnum.PUBLISHED) {
    return product?.titleVariations?.find(e => e.selected)?.title || "N/A";
  }
  // completeProduct.status !== ContentStatusEnum.PUBLISHED
  const editedContentTitle = product?.editedGeneratedProductContent?.titleVariations?.find(e => e.selected)?.title;
  if (editedContentTitle) return editedContentTitle;

  
  const productTitle = product?.titleVariations?.find(e => e.selected)?.title;
  if (productTitle) return productTitle;
  
  return product.generatedTitle || "";
};