import axios from "../axios";

import { errorHandler } from "../utils/helpers/apis";
import {
  GetKeywordTrackingPayload,
  UpdateHelium10KeywordsPayload,
  TriggerKeywordTrackingPayload,
  UpdateSellingKeywordsPayload,
  GetKeywordsPayload,
  GetKeywordsApi,
} from "./types/keywords";
import { BlacklistWordsEnum } from "../utils/constants/enums";

export const getListOfBlacklistedWords = async (type: string = BlacklistWordsEnum.EXACT) =>
  await axios.get(`/keywords/blacklisted-words/${type}`);

export const addBlacklistWords = async ({ words, type, userID }: any) =>
  await axios.post(`/keywords/blacklist-words`, { words, type, userID });

export const deleteBlackListWord = async (listId: string) =>
  await axios.delete(`/keywords/blacklisted-word`, {
    params: {
      blacklistedWordID: listId,
    },
  });

export const updateSelectedKeywordsAgainstProduct = async (payload: UpdateHelium10KeywordsPayload) =>
  await axios.post(`/keywords/select-keywords`, payload);

export const KeywordsApis = {
  async updateSellingKeywords(data: UpdateSellingKeywordsPayload) {
    return axios
      .put<any, UpdateSellingKeywordsPayload>(`/keywords/selling-keywords`, { ...data })
      .then((r: any) => r.data)
      .catch(errorHandler);
  },
  async getKeywordTracking({ productASIN, userID }: GetKeywordTrackingPayload) {
    return axios
      .get<any>(`/keyword-tracking/${productASIN}/${userID}`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  async triggerKeywordTracking(data: TriggerKeywordTrackingPayload) {
    return axios
      .put<any, UpdateSellingKeywordsPayload>(`/keyword-tracking/trigger-tracking`, { ...data })
      .then((r: any) => r.data);
  },
  async getHelium10BalanceCount() {
    return axios.get<any>(`/keywords/check-helium10-count`).then((r: any) => r.data.data as number);
  },
  async getKeywords({ productAsin, userID }: GetKeywordsPayload) {
    return axios
      .get<GetKeywordsPayload, GetKeywordsApi>(`/keywords/${productAsin}/${userID}`)
      .then((r: GetKeywordsApi) => r.data.data);
  },
  async getCompetitorsKeywords(payload: { competitorsASIN?: string[]; productASIN: string }) {
    return axios.post<any, any>(`/keywords`, payload).then((r: any) => r);
  },
};
