import * as yup from "yup";

export interface InternalUserFormObj {
  name:string;
  email: string;
  password: string;
  confirmPassword: string;
  status: boolean;
  userRole: string;
}
  
export const inputName = {
  name: "name",
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  status: "status",
  userRole: "userRole"
};
  
export const initialValue: InternalUserFormObj = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  status: true,
  userRole: "",
};
  
export const validationSchemaForInternalUser = yup.object().shape({
  name: yup.string().trim().required("Full name is required"),
  email: yup.string().trim().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please add valid email address").required("Work email is required"),
  userRole: yup.string().trim().required("User's Role is required"),
  password: yup
    .string()
    .trim()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .required("Please re-enter your new password")
    .oneOf([yup.ref("password")], "Passwords must match"),
});
  

  
