import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// import MTypography from "../../../Atoms/MTypography";
import { useDeviceType } from "../../../../hooks/useDeviceType";

import classes from "./index.module.css";
import AccountTypeCard from "./AccountTypeCard/AccountTypeCard";
import { cardsList } from "./config";

type AuthorizeAccountDialogeProps = DialogProps & {
  title?: string;
  open: boolean;
  onClose: () => void;
  handleTypeClick: (accountType: string) => void;
};

const AuthorizeAccountDialoge = (props: AuthorizeAccountDialogeProps) => {
  const { handleTypeClick } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isDesktop } = useDeviceType();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          maxWidth: "700px",
        },
      }}
    >
      {props?.title && (
        <DialogTitle className={classes.Title}>
          <div className="flex-grow-1">{props.title}</div>
          <IconButton
            sx={{
              padding: 0,
              color: "inherit",
              "&:hover": {
                background: "none",
              },
            }}
            onClick={props.onClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.BodyContainer}>
        <div
          className={`d-flex justify-content-center w-100 ${
            isDesktop ? "flex-row gap-4 pb-5 px-5" : "flex-column gap-3 align-items-center"
          }`}
        >
          {cardsList.map((item: any, i: number) => (
            <span key={i} onClick={() => handleTypeClick(item.type)} style={{ width: "fit-content" }}>
              <AccountTypeCard details={item} />
            </span>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default AuthorizeAccountDialoge;
