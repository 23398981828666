import React from "react";
import classes from "../index.module.css";
import { Skeleton } from "@mui/material";
import { useDeviceType } from "../../../../../hooks/useDeviceType";

const ExportPerRow = () => {

  const { isDesktop } = useDeviceType();

  const sampleData = [
    {
      id: 1,
      asin: "B0B6HC99BY",
      blocks: [1, 2, 3, 4],
    },
    {
      id: 2,
      asin: "B0B6HC99BY",
      blocks: [1, 2, 3, 4],
    },
    {
      id: 3,
      asin: "B0B6HC99BY",
      blocks: [1, 2, 3, 4],
    },
    {
      id: 4,
      asin: "B0B6HC99BY",
      blocks: [1, 2, 3, 4],
    },
    {
      id: 5,
      asin: "B0B6HC99BY",
      blocks: [1, 2, 3, 4],
    },
    {
      id: 6,
      asin: "B0B6HC99BY",
      blocks: [1, 2, 3, 4],
    },
  ];

  const sampleDataArray = isDesktop ? sampleData : sampleData.slice(0, 3);

  return (
    <div className={`${classes.SampleLayoutContainer}`}>
      {sampleDataArray.map((item, index) => (
        <div className="d-flex" style={{ fontSize: "10px", color: "#80828A" }} key={index}>
          <div className="p-1">{item.id}</div>
          <div className="p-1" style={{ color: index === 1 ? "#38A4FF" : "inherit" }}>
            {item.asin}
          </div>
          <div className="d-flex align-items-center gap-2" style={{ marginLeft: "10px" }}>
            {item.blocks.map((item) => (
              <Skeleton
                key={item}
                width={isDesktop ? "40px": "35px"}
                height={"20px"}
                sx={{ background: index === 1 ? "#C3C3C3" : "#F1F1F1" }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExportPerRow;
