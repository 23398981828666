import * as React from "react";
import { DialogProps } from "@mui/material/Dialog";
import { Divider, Grid, TextareaAutosize, Typography } from "@mui/material";
import "./feedbackDialogebox.style.scss";
import DialogeBox from "..";
import { useState } from "react";
import { generateProductApis } from "../../../../apis/generate-product";
import { useParams } from "react-router-dom";
import { ERR_ADD_FEEDBACK } from "../../../../utils/constants/messages/errors";
import { toast } from "react-toastify";
import { ParamsEnum } from "../../../../utils/constants/enums";

import { useDeviceType } from "../../../../hooks/useDeviceType";

interface FeedbackDialogueState {
  comment: string;
  feedbacks: string[];
  oldFeedback: string[];
  loading: boolean;
}

type FeedbackDialogueProps = DialogProps & {
  feedbacks: string[];
};
const FeedbackDialogebox = (props: FeedbackDialogueProps) => {
  const params = useParams();
  const [state, setState] = useState<FeedbackDialogueState>({
    comment: "",
    feedbacks: [...props.feedbacks].reverse(),
    oldFeedback: [...props.feedbacks],
    loading: false,
  });
  const { isDesktop } = useDeviceType();

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });

  return (
    <div>
      <DialogeBox
        open={props.open}
        onClose={props.onClose}
        title={"Notes Histroy"}
        beforeContent={
          <Grid className={isDesktop ? "feedback-dialoge-box" : "feedback-dialoge-box FeedbackDialogBoxM"}>
            {state.feedbacks.length > 0 && (
              <>
                <Grid className="feedbacks-container">
                  {state.feedbacks.map((feedback) => (
                    <Typography className="feedback">{feedback}</Typography>
                  ))}
                </Grid>
              </>
            )}
            <Divider />
          </Grid>
        }
        confirmButton={{
          action: async () => {
            if (state.comment.length) {
              setState({ ...state, loading: true });
              let oldFeedback = [...state.oldFeedback, state.comment];

              await generateProductApis
                .addFeedbackAgainstGeneratedProductByProductASIN({
                  feedbacks: oldFeedback,
                  userID: params[ParamsEnum.USER_ID] as string,
                  productASIN: params[ParamsEnum.ASIN] as string,
                })
                .then(() => {
                  let feedbacks = state.feedbacks;
                  feedbacks.unshift(state.comment);
                  setState({
                    ...state,
                    loading: false,
                    feedbacks,
                    comment: "",
                    oldFeedback,
                  });
                })
                .catch(() => {
                  setState({
                    ...state,
                    loading: false,
                  });
                  toast.error(ERR_ADD_FEEDBACK);
                });
            }
          },
          disabled: state.loading || !state.comment.length,
          element: "Add",
        }}
        cancelButton={{
          action: props.onClose as any,
          disabled: false,
          element: "Cancel",
        }}
      >
        <Grid className={isDesktop ? "feedback-dialoge-box" : "feedback-dialoge-box FeedbackDialogBoxM"}>
          <TextareaAutosize
            maxRows={3}
            name="comment"
            required
            className="feedback-input "
            placeholder="Add your note"
            value={state.comment}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
      </DialogeBox>
    </div>
  );
};
export default FeedbackDialogebox;
