import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
interface MBottomSheetProps {
  open: boolean;
  showFullHeight?: boolean;
  children: React.ReactNode;
}
const MBottomSheet = ({ open, children, showFullHeight }: MBottomSheetProps) => {
  return (
    <>
      <BottomSheet open={open} snapPoints={({ minHeight }) => [showFullHeight ? minHeight : minHeight / 1.25]}>
        {children}
      </BottomSheet>
    </>
  );
};

export default MBottomSheet;
