import React, { useState } from "react";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";
import { isPropertyExistInObject, navigatePage } from "../../../utils/helpers/common";
import {
  showDeleteIconOnProductPage,
  showLiveCheckButton,
} from "../../../utils/helpers/priviligesChecks";
import { useDispatch, useSelector } from "react-redux";
import { DbUserSelector, UserRoleSelector } from "../../../store/user/user.selector";
import {
  CatalogEnum,
  ContentStatusEnum,
  ProductLiveStatusEnum,
  RolesEnum,
  TabsEnum,
} from "../../../utils/constants/enums";
import { selectProductActionM } from "../../../store/product/product.actions";
import { SavedProductsSelector } from "../../../store/product/product.selector";
import { URL_PRODUCT_CATALOG } from "../../../routes/routes-path";
import { useLocation, useNavigate } from "react-router-dom";
import MShowMoreLess from "../../Atoms/MShowMoreLess";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { defaultImage } from "../../../utils/constants/general-constants";
import unSelectIcon from "../../../assets/svgs/Checkbox/Square/rowUnSelectIcon.svg";
import selectIcon from "../../../assets/svgs/Checkbox/Square/rowSelectIcon.svg";
import ProductStatus from "../ProductStatus";
import ProductStatsValues from "./ProductStatsValues/ProductStatsValues";
import { onImgErrorHandler } from "../../../utils/helpers/general-helpers";

interface DetailCardProps {
  productDetail: any;
  tab: TabsEnum;
  getProducts: (isReset: boolean) => void;
  selectionMode?: boolean;
}

interface ProductDetailCardState {
  loading: boolean;
  openDeleteDialogBox: boolean;
  openKeyWordsDialogBox: boolean;
}

const OpportunityProductDetailCard: React.FC<DetailCardProps> = ({ productDetail, tab, selectionMode }) => {
  const storeUser = useSelector(DbUserSelector);
  const storeRole = useSelector(UserRoleSelector);
  const allProducts = useSelector(SavedProductsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceType();
  const location = useLocation();

  const [state, setState] = useState<ProductDetailCardState>({
    openDeleteDialogBox: false,
    openKeyWordsDialogBox: false,
    loading: false,
  });

  const brand = location?.state?.productBrand;
  const handleAsinClick = () => {
    if (!state.loading) {
      if (productDetail.status === ContentStatusEnum.GENERATING) {
        setState({ ...state, openKeyWordsDialogBox: true });
        setTimeout(() => {
          setState({ ...state, openKeyWordsDialogBox: false });
        }, 5000);
      } else if (tab === TabsEnum.REVIEW)
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.REVIEW_PAGE}/${productDetail.productASIN}/${productDetail.userID}`,
          navigate
        );
      else
        navigatePage( 
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${productDetail.productASIN}/${productDetail.userID}`,
          navigate,
          // { state: { isChildComponent: TabsEnum.OPPORTUNITY_REPORT } }
          { state: { isChildComponent: TabsEnum.OPPORTUNITY_REPORT, productBrand: brand } }
        );
    }
  };

  const handleProductSelect = () => {
    dispatch(selectProductActionM(allProducts, productDetail?._id as string));
  };

  return (
    <div>
      <div className={"card flex-column mt-4 cursor-pointer " + classes.ProductCard}>
        {selectionMode && showLiveCheckButton(storeRole, productDetail.status) && (
          <img
            src={productDetail?.isSelected ? selectIcon : unSelectIcon}
            alt={"row-select-icon"}
            className={classes.Checkbox}
            onClick={handleProductSelect}
          />
        )}

        <div className="d-flex flex-row w-100 pb-3">
          <div className={classes.productImageMBMain}>
            <img src={productDetail.imageURL || defaultImage} onError={(e) => onImgErrorHandler(e, defaultImage)} alt="product" className={classes.productImageMB} />
          </div>
          <div className="d-flex flex-column ms-2 w-100">
            <div className={"d-flex justify-content-between"}>
              <div className={"d-flex gap-2 align-items-center"}>
                <ProductStatus type={productDetail.status} variant={"small"} />
                {productDetail?.isProductLiveBeingChecked !== undefined && (
                  <div className={"my-1 d-flex align-items-center"}>
                    <ProductStatus
                      type={
                        productDetail?.isProductLiveBeingChecked
                          ? ProductLiveStatusEnum.CHECKING
                          : (productDetail?.productLive?.isProductLive as ProductLiveStatusEnum)
                      }
                      showInfo
                      variant={"small"}
                    />
                  </div>
                )}
              </div>
              <div
                className={
                  showDeleteIconOnProductPage(
                    storeRole as RolesEnum,
                    isPropertyExistInObject(productDetail, "userEmail"),
                    storeUser?.email as string
                  )
                    ? "d-flex me-1"
                    : "d-none"
                }
              >
              </div>
            </div>
            <div onClick={handleAsinClick}>
              <MTypography variant={"h6"} customClass={classes.ProductAsinMB}>
                {productDetail.productASIN}
              </MTypography>
            </div>
            <MShowMoreLess
              content={productDetail.title ? productDetail.title : ""}
              maxLength={isMobile ? 70 : 100}
              textVariant={"subtitle2"}
              textCustomClass={"pt-1 pr-2 " + classes.ProductDescriptionMB}
            />
          </div>
        </div>
        <div className={`pt-3 ${classes.CardStatsContainer}`}>
          <ProductStatsValues
            productDetail={productDetail}
          />
        </div>
      </div>

    </div>
  );
};
export default OpportunityProductDetailCard;
