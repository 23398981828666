import React from "react";
import classes from "./index.module.css";

const Statusbar: React.FC<{ value: number }> = ({ value }) => {
  return (
    <div
      className={`${classes.Bar}`}
      style={{ background: `linear-gradient(to right, #FFB400 ${value}%, #d9d9d9 ${0}%)` }}
    />
  );
};

export default Statusbar;
