import { BrandsReducerActionType } from "./brands.actions";
import { brandsActionTypes } from "./brands.action-types";
import { produce } from "immer";

export interface State {
  selectedBrandAlias: any;
}

const initialState: State = {
  selectedBrandAlias: null,
};

const brandsReducer = produce((state: State = initialState, action: BrandsReducerActionType) => {
  switch (action.type) {
    case brandsActionTypes.setSelectedBrandAlias:
      state.selectedBrandAlias = action.payload;
      return state;
    default:
      return state;
  }
});

export default brandsReducer;
