import * as React from "react";
import { DialogProps } from "@mui/material/Dialog";
import DialogeBox from "..";

type ConfirmBoxProps = DialogProps & {
  confirm: React.MouseEventHandler<HTMLButtonElement>;
  cancel: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  confirmButtonText: string;
  showCancelButton?: boolean;
  cancelText?: string;
};
const ConfirmBox = (props: ConfirmBoxProps) => {
  const { cancelText = "Cancel" } = props;
  return (
    <div>
      <DialogeBox
        open={props.open}
        onClose={props.onClose}
        title={props.title}
        confirmButton={{
          action: props.confirm,
          element: props.confirmButtonText,
        }}
        cancelButton={{
          action: props.cancel as any,
          element: cancelText,
        }}
        hideCancelButton={Boolean(props.showCancelButton)}
      >
        {props.children}
      </DialogeBox>
    </div>
  );
};
export default ConfirmBox;
