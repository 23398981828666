import React from "react";

import { inputName } from "../../../../pages/Settings/Profile/config";
import FormTextField from "../../../Molecules/FormTextField";
import HeadingUnderline from "../../../Molecules/HeadingUnderline";
import { profileType } from "../../../../pages/Settings/Profile/config";

import classes from "./index.module.css";
import FormPhoneInput from "../../../Molecules/FormPhoneInput/FormPhoneInput";

interface ProfileFormProps {
  type: string;
  companyProfile?: any;
}

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const { type, companyProfile } = props;
  return (
    <div>
      <div>
        {/*<div>*/}
        {/*  <div>*/}
        {/*    <img src="" alt=""/>  */}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={"row"}>
          <div className={"col-md-6 col-sm-12"}>
            <FormTextField
              fullWidth
              name={inputName.name}
              label={type === "companyProfile" ? "Company Name*" : "Full Name*"}
            />
          </div>
          <div className={"col-md-6 col-sm-12"}>
            <FormTextField fullWidth name={inputName.email} label={"Work Email"} disabled />
          </div>
          {type === profileType.companyProfile && (
            <div className={"col-md-6 col-sm-12"}>
              <FormTextField fullWidth name={inputName.userName} label={"User Name*"} />
            </div>
          )}

          <div className={"col-md-6 col-sm-12 mt-3"}>
            {/* <FormTextField fullWidth name={inputName.phoneNo} label={"Phone Number (Optional)"} /> */}
            <FormPhoneInput label={"Phone Number (Optional)"} name={inputName.phoneNo}/>
          </div>
        </div>

        {type === "companyProfile" && (
          <>
            <HeadingUnderline content={"Limits"} />
            <div className={"row"}>
              <div className={"col-md-6 col-sm-12"}>
                <div className={"d-flex justify-content-end "}>
                  <p className={`m-0 ${classes.LimitText}`}>
                    {companyProfile?.countContentGeneration}/{companyProfile?.contentGenerationLimit}
                  </p>
                </div>
                <FormTextField fullWidth name={inputName.asinLimit} disabled label={"ASIN Limit"} />
              </div>

              <div className={"col-md-6 col-sm-12"}>
                <div className={"d-flex justify-content-end "}>
                  <p className={`m-0 ${classes.LimitText}`}>
                    {companyProfile?.countHelium10Calls}/{companyProfile?.helium10CallsLimit}
                  </p>
                </div>
                <FormTextField fullWidth name={inputName.keywordRefreshes} disabled label={"Keyword Refreshes"} />
              </div>

              <div className={"col-md-6 col-sm-12"}>
                <div className={"d-flex justify-content-end "}>
                  <p className={`m-0 ${classes.LimitText}`}>
                    {companyProfile?.countContentRegeneration}/{companyProfile?.contentRegenerationLimit}
                  </p>
                </div>
                <FormTextField fullWidth name={inputName.contentRegeneration} disabled label={"Content Regeneration"} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
