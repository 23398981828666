const productObject = {
  productASIN: "",
  title: "",
  domain: "",
  productBrand: "",
  productType: "",
  userEmail: "",
  status: "",
  lastProductGeneratedAt: "",
  delete: "",
  searchIncrease: "",
  volumeChange: "",
  keywordCoverageRatio: "",
  lastLiveDate: "",
  phrase: "",
  searchVolume: "",
  asin: "",
  relevancy: "",
  count: "",
  name: "",
  role: "",
  countHelium10Calls: "",
  countContentGeneration: "",
  countContentRegeneration: "",
  action: "",
};

export const generateProductGridSkeleton = (rowsLen: number): any => {
  let result = [];
  if (rowsLen > 0) {
    for (let i = 0; i < rowsLen; i++) {
      result[i] = { ...productObject, id: i };
    }
  }
  return result;
};
