import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import MuiPopover from "../../Atoms/Popover";
import { useNavigate } from "react-router-dom";
import robot from "../../../assets/svgs/automatoai/robot.svg";
import { logoutUser } from "../../../apis/auth";
import MenuOption from "../../Molecules/MenuOption";
import { useDispatch, useSelector } from "react-redux";
import { DbUserSelector } from "../../../store/user/user.selector";
import { toast } from "react-toastify";
import { SUCC_LOG_OUT } from "../../../utils/constants/messages/success";

import classes from "./index.module.css";

interface SignOutPopperState {
  anchorEl: HTMLImageElement | null;
  openPopper: boolean;
  loading: boolean;
}

const SignOutPopper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeUser = useSelector(DbUserSelector);
  const [state, setState] = useState<SignOutPopperState>({
    loading: false,
    openPopper: false,
    anchorEl: null,
  });

  const handleLogoutUser = async () => {
    await logoutUser(navigate, dispatch);
    toast.success(SUCC_LOG_OUT);
  };

  const capitalizeUserName = (userName: string) => {
    var words = userName.split(" ");

    var capitalizedWords = words.map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    var capitalizedName = capitalizedWords.join(" ");

    return capitalizedName;
  };

  return (
    <div className={"cursor-pointer"}>
      <MuiPopover
        open={state.openPopper}
        anchorEl={state.anchorEl}
        onClose={() => setState({ ...state, openPopper: false })}
      >
        <div>
          <MenuOption borderBottom={true}>
            <div className={classes.Text}>{storeUser?.name ? capitalizeUserName(storeUser.name) : ""}</div>
            <p className={classes.Subtitle}>{storeUser?.email}</p>
          </MenuOption>
          <div className="HoverEffectForButtonNoPadding">
            <MenuOption>
              <div
                onClick={handleLogoutUser}
                className={classes.Text + " cursor-pointer d-flex gap-2 align-items-center"}
              >
                <LogoutIcon />
                <p className={"m-0"}>{"Sign out"}</p>
              </div>
            </MenuOption>
          </div>
        </div>
      </MuiPopover>
      <img
        src={robot}
        alt="profile-logo"
        className="HoverEffectForButtonNoPadding"
        onClick={(e) => {
          setState({
            ...state,
            anchorEl: e.currentTarget,
            openPopper: !state.openPopper,
          });
        }}
      ></img>
    </div>
  );
};

export default SignOutPopper;
