import React from "react";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../../../../../store/user/user.selector";
import * as diff from "diff";
import { formatBulletsPoints } from "../../../../../../../utils/helpers/strings";
import classes from "../index.module.css";
import MTypography from "../../../../../../Atoms/MTypography";

interface HighlightChangesProps {
  oldStr: string;
  newStr: string;
  isInBulletFormat?: boolean;
}

const HighlightChanges: React.FC<HighlightChangesProps> = ({ oldStr, newStr, isInBulletFormat }) => {
  const currentUser = useSelector(DbUserSelector);
  const changes: any = isInBulletFormat
    ? diff.diffWords(
        formatBulletsPoints(oldStr, currentUser?.bulletPointsFormat),
        formatBulletsPoints(newStr, currentUser?.bulletPointsFormat)
      )
    : diff.diffWords(oldStr, newStr);
  return (
    <MTypography variant={"subtitle2"} customClass={`${classes.Body} ${classes.TransparentBorder}`}>
      {changes?.length &&
        changes?.map((part: any, index: number) => {
          const color = part.added ? "green" : part.removed ? "red" : "black";
          const textDecoration = part.removed ? "line-through" : "none";
          return (
            <span key={index} style={{ color, textDecoration, whiteSpace: "pre-line" }}>
              {part.value}
            </span>
          );
        })}
    </MTypography>
  );
};

export default HighlightChanges;
