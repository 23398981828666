import React from "react";
import Applogo from "../../../../assets/svgs/automatoai/logo-appbar.svg";
import { useNavigate } from "react-router-dom";
import { KEY_USER_ACCESS_TOKEN } from "../../../../utils/constants/store";
import { URL_PRODUCTS, URL_SIGN_IN } from "../../../../routes/routes-path";
import classes from "./index.module.css";
import { TabsEnum } from "../../../../utils/constants/enums";
import { useDeviceType } from "../../../../hooks/useDeviceType";

const TitleBarLogo = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const isAuthenticated = localStorage.getItem(KEY_USER_ACCESS_TOKEN)?.length;
  return (
    <div
      className={"d-flex align-items-center cursor-pointer"}
      onClick={() => {
        navigate(isAuthenticated ? URL_PRODUCTS : URL_SIGN_IN);
        document.title = TabsEnum.PRODUCTS;
      }}
    >
      <img src={Applogo} alt="app-logo" className={isDesktop ? classes.Image : classes.ImageM} />
    </div>
  );
};

export default TitleBarLogo;
