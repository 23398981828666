import React, { useEffect, useState } from "react";
import AppBar from "../../components/Molecules/AppBar";
import classes from "./index.module.css";
import { useDeviceType } from "../../hooks/useDeviceType";
import MTypography from "../../components/Atoms/MTypography";
import MOutlinedInput from "../../components/Atoms/MOutlinedInput";
import MButton from "../../components/Atoms/MButton";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../store/user/user.selector";
import { URL_CONGRATS, URL_SIGN_UP } from "../../routes/routes-path";
import { UserApis } from "../../apis/user";
import { navigatePage } from "../../utils/helpers/common";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/helpers/general-helpers";
import { SendInvitesPayload } from "../../apis/types/user";
import { ERR_FILL_ALL_EMAILS, ERR_INVALID_EMAILS } from "../../utils/constants/messages/errors";
import { SUCC_INVITATIONS_SENT } from "../../utils/constants/messages/success";
import { useDispatch } from "react-redux";
import { setCompanyAction } from "../../store/company/company.actions";

const AddTeamMembersPage = () => {
  const { isDesktop } = useDeviceType();
  const [workEmails, setWorkEmails] = useState<string[]>([""]);
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useSelector(DbUserSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddAnother = () => {
    setWorkEmails([...workEmails, ""]);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if(currentUser?.companyID){
      dispatch(setCompanyAction(currentUser?.companyID))
    }
  }, [currentUser])

  const handleChange = (email: string, index: number) => {
    const updatedEmails = [...workEmails];
    updatedEmails[index] = email;
    setWorkEmails(updatedEmails);
  };

  const handleSkip = () => {
    navigatePage(`${URL_CONGRATS}`, navigate, {
      replace: true,
    });
  };

  const submit = async () => {
    const validEmails = workEmails.filter((email) => email.trim() !== "");

    if (validEmails.length !== workEmails.length) {
      toast.error(ERR_FILL_ALL_EMAILS);
      return;
    }

    if (workEmails.some((email) => !validateEmail(email))) {
      toast.error(ERR_INVALID_EMAILS);
      return;
    }

    try {
      setLoading(true);
      const payload: SendInvitesPayload = {
        emails: workEmails,
        userID: currentUser?._id,
        url: `http://${window.location.host}${URL_SIGN_UP}`,
      };
      const res = await UserApis.sendInvitesToTeam(payload);
      if (!res) {
        toast.success(SUCC_INVITATIONS_SENT);
        navigatePage(`${URL_CONGRATS}`, navigate, {
          replace: true,
          state: { pageType: "addTeamMembers" },
        });
        setLoading(false);
      } else {
        toast.error(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error sending invites:", error);
      setLoading(false);
      toast.error("An error occurred while sending invites.");
    }
  };

  const handleRemoveField = (fieldIndex: number) => {
    const updatedEmails = [...workEmails];
    updatedEmails.splice(fieldIndex, 1);
    setWorkEmails(updatedEmails);
  };

  return (
    <div className={`${isDesktop ? classes.Container : classes.ContainerM}`}>
      {isDesktop && <AppBar />}
      <div className={isDesktop ? classes.CardsContainer : classes.CardsContainerM}>
        <MTypography variant={"body2"} customClass={`text-center ${classes.Title} ${!isDesktop && classes.TitleM}`}>
          Add Team Members
        </MTypography>
        <MTypography variant={"body2"} customClass={`text-center ${classes.ToBeginThis}`}>
          Invitees must complete registration to become active team members.
        </MTypography>
        <div className="d-flex justify-content-center flex-column align-items-center" style={{ marginTop: "40px" }}>
          <Box sx={{ width: { xs: "100%", md: "600px" } }}>
            {workEmails.map((email, index) => (
              <div key={index} className="d-flex align-items-center gap-2 pb-3">
                <MOutlinedInput
                  id={`workEmail${index}`}
                  name={`workEmail${index}`}
                  type="email"
                  placeholder="Work Email"
                  value={email}
                  rootClass={isDesktop ? `${classes.OutlineInputD}` : `${classes.OutlineInputMB}`}
                  onChange={(e) => handleChange(e.target.value, index)}
                />
                <Box component={"div"} sx={{ width: { sx: index === 0 ? "" : "40px", md: "40px" } }}>
                  {index !== 0 && (
                    <div onClick={() => handleRemoveField(index)} className="cursor-pointer">
                      <CloseRoundedIcon sx={{ color: "#80828A", fontSize: { xs: "26px", md: "32px" } }} />
                    </div>
                  )}
                </Box>
              </div>
            ))}
            <MButton variant="outlined" onClick={handleAddAnother}>
              Add Another
            </MButton>
            <div className={`d-flex justify-content-end mt-3 w-100 gap-2 ${!isDesktop && "flex-column-reverse mt-5"}`}>
              <MButton variant="text" onClick={handleSkip}>
                Skip
              </MButton>
              <MButton variant="contained" onClick={submit} isLoading={loading}>
                Add Members
              </MButton>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddTeamMembersPage;
