import { useNavigate } from "react-router-dom";
import MTypography from "../../../components/Atoms/MTypography";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import { navigatePage } from "../../../utils/helpers/common";
import { apiAccessKeysMenu } from "./config";
import SettingMenuCard from "../../../components/Organisms/SettingsMenuCard";
import { useDeviceType } from "../../../hooks/useDeviceType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AccessKeys = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };

  const handleNavigate = (menuName: string) => {
    if (menuName === SettingsEnum.AUTOMATO_API_ACCESS) {
      navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AUTOMATO_API_ACCESS}`, navigate);
    }
  };

  return (
    <div className="w-100">
      {isDesktop ? (
        <TabsToolbar
          // title={SettingsEnum.API_ACCESS_KEYS}
          title={"API Access Key"} 
          avoidCapitalization={true}
          children={
            <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
              <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
            </div>
          }
        />
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2"}>
            <ArrowBackIcon onClick={navigateToSettingsTab} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>
              {/* {capitalizeEachLetter(SettingsEnum.API_ACCESS_KEYS.replace("-", " "))} */}
              {"API Access Key"}
            </MTypography>
          </div>
        </div>
      )}

      {/* one more item will be added in this list */}
      {/* views are in organisms folder name AccessKeys */}
      <div className={`d-flex align-items-center ${isDesktop ? "flex-row" : "flex-column"}`}>
        {apiAccessKeysMenu.map((menu) => (
          <div key={menu.title} onClick={() => handleNavigate(menu.name)} className={`${isDesktop && "m-5"}`}>
            <SettingMenuCard title={menu.title} description={menu.description} icon={menu.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccessKeys;
