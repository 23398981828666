import moment from "moment";

export const getDateWithTime = (date: string, dateOnly?: boolean) => {
  const inputDate = new Date(date);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (dateOnly)
    return `${monthNames[inputDate.getMonth()]} ${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}, ${inputDate.getFullYear()}`;

  return `${monthNames[inputDate.getMonth()]} ${inputDate
    .getDate()
    .toString()
    .padStart(2, "0")}, ${inputDate.getFullYear()} @ ${inputDate.getHours().toString().padStart(2, "0")}:${inputDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
};

export const toDateAndTimeObject = (date: string) => {
  const inputDate = new Date(date);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return {
    time: `${inputDate.getHours().toString().padStart(2, "0")}:${inputDate.getMinutes().toString().padStart(2, "0")}`,
    date: `${monthNames[inputDate.getMonth()]} ${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}, ${inputDate.getFullYear()}`,
  };
};

export const toDateOnly = (date: string) => {
  return moment(date).format("MM/DD/YYYY");
};


// this method will return output like this "23 days ago"
export const getDifferenceFromCurrentDate = (date: string) => {
  const receivedDate = moment(date);
  const currentDate = moment();
  const daysAgo = currentDate.diff(receivedDate, 'days');
  return daysAgo;
}