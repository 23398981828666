import { Grid } from "@mui/material";
import React from "react";
import OpportunityReportCard from "../../../Atoms/OpportunityReportCard";
import { useSelector } from "react-redux";
import { OpportunityReportStatsSelector } from "../../../../store/product/product.selector";
import {
  formatNumberWithNearestWholeNumber,
  formatNumberWithTwoValuesAfterDecimal,
} from "../../../../utils/helpers/numbers";
import { OpportunityLoadingSelector } from "../../../../store/opportunity-report/opportunityReport.selector";

const OpportunityStats = () => {
  const stats = useSelector(OpportunityReportStatsSelector);
  const loading = useSelector(OpportunityLoadingSelector);
  return (
    <>
      <Grid
        container
        flexDirection={"row"}
        px={{ xs: 1, lg: 4 }}
        mt={{ lg: 2 }}
        spacing={{ xs: 1, md: 2 }}
        textAlign={"center"}
      >
        {/* <Grid item xs={6} lg={3}>
          <OpportunityReportCard title={"Total Search Volume Exposure "} volume={stats.totalSearch} showPercentSign />
        </Grid> */}
        <Grid item xs={4}>
          <OpportunityReportCard
            loading={loading && !stats.averageVolume}
            title={"Total Search Volume Change"}
            volume={formatNumberWithNearestWholeNumber(stats.averageVolume)}
          />
        </Grid>
        <Grid item xs={4}>
          <OpportunityReportCard
            loading={loading && !stats.averageKw}
            title={"Total Keyword Coverage"}
            volume={formatNumberWithTwoValuesAfterDecimal(stats.averageKw)}
            showPercentSign
          />
        </Grid>
        <Grid item xs={4}>
          <OpportunityReportCard
            loading={loading && !stats.totalAsinLive}
            title={"Live"}
            volume={formatNumberWithTwoValuesAfterDecimal(stats.totalAsinLive)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OpportunityStats;
