// import { Dispatch } from "redux";
import { ApiAccessKey } from "../../apis/types/apiAccessKey";
import { AccessKeysActionTypes } from "./accessKeys.action-types";


export interface SetAccessKeysList {
  type: AccessKeysActionTypes.setAccessKeysList;
  payload: ApiAccessKey[] | null,
}

export const setAccessKeysList = (dataList: ApiAccessKey[] | null): SetAccessKeysList => {
  return {
    type: AccessKeysActionTypes.setAccessKeysList,
    payload: dataList
  };
};



export type apiAccessKeysActionType =
  | SetAccessKeysList
