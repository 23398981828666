import * as React from "react";
import Popover, { PopoverProps } from "@mui/material/Popover";

type tpProps = PopoverProps & {
  children: React.ReactNode;
  anchorOrigin?: {
    vertical: string;
    horizontal: string;
  };
  transformOrigin?: {
    vertical: string;
    horizontal: string;
  };
  sx?: object;
};

const MuiPopover = (props: tpProps): JSX.Element => {
  return (
    <div>
      <Popover
        open={props.open}
        sx={props.sx ? props.sx : {}}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: props.anchorOrigin?.vertical || "bottom",
          horizontal: props.anchorOrigin?.horizontal || "center",
        }}
        transformOrigin={{
          vertical: props.transformOrigin?.vertical || "top",
          horizontal: props.transformOrigin?.horizontal || "center",
        }}
      >
        <>{props.children}</>
      </Popover>
    </div>
  );
};

export default MuiPopover;
