import { Rating, Tooltip } from "@mui/material";
import React from "react";
import classes from "./index.module.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import MTypography from "../../Atoms/MTypography";
import { CompetitorsPageState } from "../../../pages/Product/competitors";
import MuiTooltip from "../../Atoms/MuiTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { Competitor } from "../../../interfaces/scrapeProduct";
import MButton from "../../Atoms/MButton";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { maxCompetitorsLimit } from "../../../utils/constants/general-constants";

export type CompetitorProps = Competitor & {
  by?: string;
  selected: boolean;
  changeSelected: () => void;
  allCompetitors: CompetitorsPageState;
};

const CompetitorComp = (props: Partial<CompetitorProps>): JSX.Element => {
  const MAX_LIMIT = props.allCompetitors?.competitors?.filter((comp) => comp.selected).length === maxCompetitorsLimit;
  const { isDesktop } = useDeviceType();
  const handleCardClick = () => {
    if (isDesktop && props.changeSelected) {
      props.changeSelected();
    }
  };
  const handleCheckboxClick = () => {
    if (!isDesktop && props.changeSelected) {
      props.changeSelected();
    }
  };

  const handleViewDetails = () => {
    window.open(props?.link, "_blank");
  };

  return (
    <div
      onClick={handleCardClick}
      style={{
        border: props.selected ? "1px solid #E6B540" : "1px solid gray",
        opacity: !props.selected && MAX_LIMIT ? 0.4 : 1,
        cursor: !props.selected && MAX_LIMIT ? "not-allowed" : "pointer",
      }}
      className={`${isDesktop ? classes.Container : classes.ContainerM}`}
    >
      <div className={"d-flex flex-column p-4"}>
        <div className={`d-flex align-items-center justify-content-between ${classes.Relevancy}`}>
          {props.relevancy && (
            <div className={"d-flex align-items-center"}>
              <MTypography
                variant={"subtitle1"}
                customClass={classes.Percentage}
              >{`Relevancy : ${props.relevancy}%`}</MTypography>

              <MuiTooltip
                arrow={true}
                placement={"right"}
                content={
                  <div>{`The “competitor relevancy” is calculated by the similarity of your product’s content to that of each competitor. A 100% means close in semantic similarity and a 0% means “not similar at all semantically”`}</div>
                }
              >
                <InfoOutlinedIcon fontSize="small" className={"mb-1"} />
              </MuiTooltip>
            </div>
          )}

          <div onClick={handleCheckboxClick}>
            {props.selected ? (
              <CheckCircleRoundedIcon className={classes.SelectIcon} />
            ) : (
              <RadioButtonUncheckedRoundedIcon className={classes.SelectIcon} />
            )}
          </div>
        </div>

        <div className={"d-flex justify-content-between my-3"}>
          <div
            style={{
              width: "5.875rem",
              height: "5.5rem",
            }}
          >
            <img src={props.image} alt={"dummy-product"} className={classes.Image} />
          </div>
          <div className={classes.TitleGrid}>
            <Tooltip title={props.title} placement="top" arrow>
              <MTypography variant={"subtitle2"} customClass={classes.Title}>
                {props.title}
              </MTypography>
            </Tooltip>
          </div>
        </div>

        {/*<div>*/}
        {/*  {props.by && (*/}
        {/*    <div>*/}
        {/*      <MTypography variant={"subtitle1"}>*/}
        {/*        By <span style={{ color: "#E6B540" }}>{` ${props.by}`}</span>*/}
        {/*      </MTypography>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}

        <div className={`d-flex align-items-center mb-3 mt-3 ${classes.RatingGrid}`}>
          <Rating
            name="rating"
            value={props?.rating ?? 0}
            precision={0.5}
            readOnly
            sx={{ marginRight: 1 }}
            size={"small"}
          />
          <MTypography variant={"subtitle2"} customClass={classes.Rating}>
            {`${props.ratings_total ? props.ratings_total.toLocaleString("en-US") : 0} ratings`}
          </MTypography>
        </div>

        <div className={"d-flex justify-content-between align-items-center"}>
          <div>
            {props.price?.value && (
              <div>
                <MTypography variant={"subtitle1"} customClass={classes.Price}>{`${
                  props.price.symbol
                } ${props.price.value.toLocaleString("en-US")}`}</MTypography>
              </div>
            )}
          </div>
          <div>
            <MButton
              onClick={(e: any) => {
                e.stopPropagation();
                handleViewDetails();
              }}
              variant={"text"}
              size={"small"}
              color={"primary"}
            >
              View Detail &nbsp;
              <ArrowOutwardIcon className={"ml-2"} />
            </MButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorComp;
