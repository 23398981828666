import React from "react";
import MTypography from "../../components/Atoms/MTypography";
import classes from "./index.module.css";

const AccountStatus = () => {
  return (
    <>
      <div className={classes.StatusText}>
        <MTypography variant={"h4"}>
          Your account is paused. Kindly contact with AutoMatoAI sales team. Thank you!
        </MTypography>
      </div>
    </>
  );
};
export default AccountStatus;
