import { ScrapeProduct } from "../../interfaces/scrapeProduct";
import { CountryDomainEnum } from "../../utils/constants/enums";

export interface GenerateProductDebugPayload {
  productASIN: string;
  title: string;
  bulletPoints: string;
  keywords: string;
  description: string;
}
export interface UpdateProductBrandPayload {
  productASIN: string;
  brand: string;
}

export interface UpdateProductDomainPayload {
  productASIN: string;
  domain: CountryDomainEnum;
}

export interface UpdateSelectedCompetitorsPayload {
  competitorsASIN: string[];
  productASIN: string;
  userID: string | null;
}

interface ProductObject {
  title: string;
  feature_bullets: string[];
}

export interface UnattachedProductPayload {
  product: ProductObject;
  runType?: string;
}

export interface GetRainForestProductPayload {
  asin: string;
  domain: string;
  isGoGoAutomato: boolean;
  userID?: string | null;
  companyAdminID?: string | null;
  addedBy?: string | null;
}

export type GoGoProductPayload = Omit<GetRainForestProductPayload, 'asin'> & {
  products: any[]; 
};

export interface ErrorRerunPayload {
  asin: string;
  domain: string;
  userID?: string;
  isRerun?: boolean;
  updateCompetitors?: boolean;
}

export interface ScrapeProductApi {
  data: {
    data: ScrapeProduct;
    statusCode: number;
  };
}

export interface GetAllBrandsPayload {
  offset: number;
  limit: number;
  sortBy: string;
  sortOrder: number;
  searchKey: string;
  searchQuery: string;
}

export const initialGetAllProductPayload: GetAllBrandsPayload = {
  offset: 0,
  limit: 0,
  sortBy: "",
  sortOrder: 0,
  searchKey: "",
  searchQuery: "",
};

export interface BrandsObjectApi {
  generating: number;
  draft: number;
  approved: number;
  error: number;
  edited: number;
  total: number;
}

export interface GetAllBrandsApi {
  totalRecords: number;
  brands: {
    [key: string]: BrandsObjectApi;
  };
  totalKeywordCoverageRatio?: number;
  totalLiveProducts?: number;
  totalVolumeChange?: number;
  productCountsWithStatuses?: [];
}

export interface GetAllBrandsArrayApi {
  totalRecords: number;
  brands: {
    [key: string]: BrandsObjectApi;
  }[];
  totalKeywordCoverageRatio?: number;
  totalLiveProducts?: number;
  totalVolumeChange?: number;
  productCountsWithStatuses?: [];
}

export interface GenerateKeywordObject {
  phrase: string;
  searchVolume: number;
  average?: number;
  sellingKeyword?: boolean;
}

export interface ProductGeneratePayload {
  productASIN: string;
  titleKeywords: GenerateKeywordObject[];
  otherKeywords: GenerateKeywordObject[];
  searchTermKeywords: string[];
  userID?: string | null;
  isRerun?: boolean;
}

export interface GenerateMultipleGogo {
  productASINS: string[];
  userID: string;
  domain: string;
}


interface OperationObjectInterface {
  change: string,
  value: string | any
}

export interface publishOnAmazonPayload {
  productASIN: string;
  operations: OperationObjectInterface[]
}