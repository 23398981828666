import React, { useState } from "react";
import MCheckbox from "../../../../Atoms/MCheckbox";
import classes from "../index.module.css";
import MTypography from "../../../../Atoms/MTypography";
import MuiPopover from "../../../../Atoms/Popover";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CustomizedProductTagBox from "../../../../Molecules/CustomizedProductTagsBox";
import { UserProductTag } from "../../../../../apis/types/tag";
import { Product } from "../../../../../apis/types/generate-product";
import ConfirmBox from "../../../../mui/dialogebox/confirmBox";
import { useDispatch, useSelector } from "react-redux";
import { handleAssignUserTagsAction, handleDeleteUserTagsAction } from "../../../../../store/product/product.actions";
import { UserProductTagsSelector } from "../../../../../store/product/product.selector";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../../utils/helpers/apis";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { checkProductIds } from "../config";
interface ProductTagsProps {
  currentTag: UserProductTag;
  tagIndex: number;
  selectedProduct: Product[];
}
interface TagOptionsState {
  showPopper: boolean;
  anchorEl: HTMLDivElement | null;
}
const ProductTags: React.FC<ProductTagsProps> = ({ currentTag, tagIndex, selectedProduct }) => {
  const dispatch = useDispatch();
  const tags = useSelector(UserProductTagsSelector);
  const selectedProductsIds = selectedProduct.map((product) => product._id);
  const checkStatus = checkProductIds(selectedProductsIds, currentTag?.productIDs || []);
  const [openCustomizeTag, setOpenCustomizeTag] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<TagOptionsState>({
    showPopper: false,
    anchorEl: null,
  });

  const [openDeleteTagConfirmBox, setOpenDeleteTagConfirmBox] = useState<boolean>(false);

  const handleOptions = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTagOptions({ ...tagOptions, showPopper: true, anchorEl: e.currentTarget });
  };

  const handleCustomizedTagBox = () => {
    setOpenCustomizeTag(true);
  };
  const closeCustomizedTag = () => {
    setOpenCustomizeTag(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(handleAssignUserTagsAction(selectedProductsIds, currentTag, e.target.checked, tags))
      .then(() => {
        toast.success("Tags Updated");
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
      });
  };

  const openConfirmDeleteBox = () => setOpenDeleteTagConfirmBox(true);
  const closeConfirmDeleteBox = () => setOpenDeleteTagConfirmBox(false);

  const handleDeleteTag = () => {
    dispatch(handleDeleteUserTagsAction(tags, currentTag._id as string))
      .then(() => {
        closeConfirmDeleteBox();
        toast.success("Product Tag Deleted Successfully");
      })
      .catch((error: any) => {
        toast.error(errorHandler(error));
      });
  };
  return (
    <div>
      <div key={currentTag.color} className={"px-2 d-flex align-items-center w-100 my-2"}>
        <MCheckbox
          variant={"square"}
          checked={checkStatus}
          onChange={handleChange}
          indeterminate={checkStatus === undefined}
        />

        <Brightness1Icon style={{ color: currentTag.color }} className={classes.Circle} fontSize={"small"} />

        <MTypography variant={"subtitle2"} customClass={classes.TagOption}>
          <div className={"d-flex align-items-center justify-content-start gap-2"}>{currentTag.name}</div>
        </MTypography>

        <div key={currentTag.color}>
          <MuiPopover
            open={tagOptions.showPopper}
            anchorEl={tagOptions.anchorEl}
            onClose={() => setTagOptions({ ...tagOptions, showPopper: false })}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "0 2px 7px 0 rgba(0, 0, 0, 0.12)", // Remove the box shadow
              },
            }}
          >
            <div className={`${classes.OptionsContainer}`} key={currentTag.color}>
              <div className={`w-100 ${classes.MoreTagOptions}`} onClick={handleCustomizedTagBox}>
                <MTypography variant={"subtitle2"} customClass={`${classes.TagOption} cursor-pointer`}>
                  {"Edit"}
                </MTypography>
              </div>
              <div className={`w-100 ${classes.MoreTagOptions} cursor-pointer`} onClick={openConfirmDeleteBox}>
                <MTypography variant={"subtitle2"} customClass={`${classes.TagOption} `}>
                  {"Delete"}
                </MTypography>
              </div>
            </div>
          </MuiPopover>
          <div onClick={(e) => handleOptions(e)}>
            <MoreVertOutlinedIcon className={classes.Options} />
          </div>
        </div>
      </div>

      <CustomizedProductTagBox
        open={openCustomizeTag}
        onClose={closeCustomizedTag}
        tag={currentTag}
        tagIndex={tagIndex}
      />
      <ConfirmBox
        open={openDeleteTagConfirmBox}
        confirm={handleDeleteTag}
        cancel={closeConfirmDeleteBox}
        confirmButtonText={"Delete Tag"}
        title={"Confirm Tag Delete"}
      >
        {"Do you want to delete this tag?"}
      </ConfirmBox>
    </div>
  );
};

export default ProductTags;
