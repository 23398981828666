import "./centerElement.style.scss";
import { Grid } from "@mui/material";
import { PagesEnum, ParamsEnum } from "../../../../utils/constants/enums";
import React, { useEffect, useState } from "react";
import blueHalfArrow from "../../../../assets/svgs/ProductFetchSteps/Web/arrow-half-blue.svg";
import grayHalfArrow from "../../../../assets/svgs/ProductFetchSteps/Web/arrow-half-gray.svg";
import whiteFullArrow from "../../../../assets/svgs/ProductFetchSteps/Web/arrow-full.svg";
import blueFullArrow from "../../../../assets/svgs/ProductFetchSteps/Web/arrow-full-blue.svg";
import grayFullArrow from "../../../../assets/svgs/ProductFetchSteps/Web/arrow-full-gray.svg";

import {
  URL_FETCH_ASIN,
  URL_KEYWORDS,
  URL_PRODUCT_FETCHED,
  URL_PRODUCT_FETCHED_COMPETITORS,
} from "../../../../routes/routes-path";
import { useNavigate, useParams } from "react-router-dom";
import MTypography from "../../../Atoms/MTypography";
import { navigatePage } from "../../../../utils/helpers/common";

interface ICenterElementProps {
  page: PagesEnum;
}
interface ICenterElemntState {
  image1: any;
  image2: any;
  image3: any;
  image4: any;
  text1Color: string;
  text2Color: string;
  text3Color: string;
  text4Color: string;
  cursor1: string;
  cursor2: string;
  cursor3: string;
  cursor4: string;
}

const WHITE_COLOR = "#FFFFFF";
const BLUE_COLOR = "#3d4eac";
const BLACK_COLOR = "#3C4257";

const CURSOR_POINTER = "pointer";
const CURSOR_NOT_ALLOWED = "not-allowed";
const CURSOR_CONTEXT_MENU = "context-menu";

const CenterElement = (props: ICenterElementProps) => {
  const productOwnerId = localStorage.getItem("productOwnerId")
  
  const [state, setState] = useState<ICenterElemntState>({
    image1: blueHalfArrow,
    image2: whiteFullArrow,
    image3: whiteFullArrow,
    image4: whiteFullArrow,
    text1Color: WHITE_COLOR,
    text2Color: BLACK_COLOR,
    text3Color: BLACK_COLOR,
    text4Color: BLACK_COLOR,
    cursor1: CURSOR_NOT_ALLOWED,
    cursor2: CURSOR_NOT_ALLOWED,
    cursor3: CURSOR_NOT_ALLOWED,
    cursor4: CURSOR_NOT_ALLOWED,
  });
  const navigate = useNavigate();
  const params = useParams();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (productOwnerId) {
      switch (props.page) {
        case PagesEnum.COMPETITORS:
          setState({
            ...state,
            image1: grayHalfArrow,
            image2: blueFullArrow,
            text1Color: WHITE_COLOR,
            text2Color: BLUE_COLOR,
            cursor1: CURSOR_NOT_ALLOWED,
            cursor2: CURSOR_CONTEXT_MENU,
          });
          break;
        case PagesEnum.KEYWORDS:
          setState({
            ...state,
            image1: grayHalfArrow,
            image2: grayFullArrow,
            image3: blueFullArrow,
            text1Color: WHITE_COLOR,
            text2Color: WHITE_COLOR,
            text3Color: BLUE_COLOR,
            cursor1: CURSOR_POINTER,
            cursor2: CURSOR_POINTER,
            cursor3: CURSOR_CONTEXT_MENU,
          });
          break;
        case PagesEnum.PRIORITISE:
          setState({
            ...state,
            image1: grayHalfArrow,
            image2: grayFullArrow,
            image3: grayFullArrow,
            image4: blueFullArrow,
            text1Color: WHITE_COLOR,
            text2Color: WHITE_COLOR,
            text3Color: WHITE_COLOR,
            text4Color: BLUE_COLOR,
            cursor1: CURSOR_NOT_ALLOWED,
            cursor2: CURSOR_POINTER,
            cursor3: CURSOR_POINTER,
            cursor4: CURSOR_CONTEXT_MENU,
          });
          break;
      }
    } else {
      switch (props.page) {
        case PagesEnum.ENTER_ASIN:
          setState({
            ...state,
            image1: blueHalfArrow,
            text1Color: BLUE_COLOR,
            cursor1: CURSOR_CONTEXT_MENU,
          });
          break;
        case PagesEnum.COMPETITORS:
          setState({
            ...state,
            image1: grayHalfArrow,
            image2: blueFullArrow,
            text1Color: WHITE_COLOR,
            text2Color: BLUE_COLOR,
            cursor1: CURSOR_POINTER,
            cursor2: CURSOR_CONTEXT_MENU,
          });
          break;
        case PagesEnum.KEYWORDS:
          setState({
            ...state,
            image1: grayHalfArrow,
            image2: grayFullArrow,
            image3: blueFullArrow,
            text1Color: WHITE_COLOR,
            text2Color: WHITE_COLOR,
            text3Color: BLUE_COLOR,
            cursor1: CURSOR_POINTER,
            cursor2: CURSOR_POINTER,
            cursor3: CURSOR_CONTEXT_MENU,
          });
          break;
        case PagesEnum.PRIORITISE:
          setState({
            ...state,
            image1: grayHalfArrow,
            image2: grayFullArrow,
            image3: grayFullArrow,
            image4: blueFullArrow,
            text1Color: WHITE_COLOR,
            text2Color: WHITE_COLOR,
            text3Color: WHITE_COLOR,
            text4Color: BLUE_COLOR,
            cursor1: CURSOR_POINTER,
            cursor2: CURSOR_POINTER,
            cursor3: CURSOR_POINTER,
            cursor4: CURSOR_CONTEXT_MENU,
          });
          break;
      }
    }
  }, []);

  return (
    <Grid className="image-container">
      <Grid
        className="image-block"
        sx={{ cursor: state.cursor1 }}
        onClick={() => {
          if (state.cursor1 === CURSOR_POINTER) {
            navigatePage(URL_FETCH_ASIN, navigate);
          }
        }}
      >
        <img src={state.image1} alt="halfArrow" />
        <Grid className="image-text" sx={{ color: state.text1Color }}>
          <MTypography variant={"subtitle2"} color={state.text1Color}>
            {"Enter ASIN"}
          </MTypography>
        </Grid>
      </Grid>
      <Grid
        className="image-block"
        sx={{ cursor: state.cursor2 }}
        onClick={() => {
          if (state.cursor2 === CURSOR_POINTER) {
            navigatePage(
              `${URL_PRODUCT_FETCHED}/${params[ParamsEnum.DOMAIN] ?? "com"}/${
                params[ParamsEnum.ASIN]
              }${URL_PRODUCT_FETCHED_COMPETITORS}`,
              navigate
            );
          }
        }}
      >
        <img src={state.image2} alt="fullArrow" />
        <Grid className="image-text" sx={{ color: state.text2Color }}>
          <MTypography variant={"subtitle2"} color={state.text2Color}>
            {"Competitors"}
          </MTypography>
        </Grid>
      </Grid>
      <Grid
        onClick={() => {
          if (state.cursor3 === CURSOR_POINTER) {
            navigatePage(
              `${URL_PRODUCT_FETCHED}/${params[ParamsEnum.DOMAIN] ?? "com"}/${params[ParamsEnum.ASIN]}${URL_KEYWORDS}`,
              navigate
            );
          }
        }}
        className="image-block"
        sx={{ cursor: state.cursor3 }}
      >
        <img src={state.image3} alt="fullArrow" />
        <Grid className="image-text" sx={{ color: state.text3Color }}>
          <MTypography variant={"subtitle2"} color={state.text3Color}>
            {"Keywords"}
          </MTypography>
        </Grid>
      </Grid>
      <Grid className="image-block" sx={{ cursor: state.cursor4 }}>
        <img src={state.image4} alt="fullArrow" />
        <Grid className="image-text prioritise-text" sx={{ color: state.text4Color }}>
          {"Arrange Keywords"}
        </Grid>
        <span className={'optionalText'}>
          {"optional"}
        </span>
      </Grid>
    </Grid>
  );
};

export default CenterElement;
