import React from "react";
import classes from "../index.module.css";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const AllProductContentBox = () => {

  const headers = ["ASIN", "Brand", "Title", "Bullets", "", "", "", "Last version"];

  const randomASINs = ["B0B6HC99BY", "B0B6HC99BY", "B0B6HC99BY", "B0B6HC99BY", "B0B6HC99BY"];

  return (
    <div className={`${classes.SampleLayoutContainer}`}>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table aria-label="simple skeleton table" sx={{ borderCollapse: "collapse" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "none", padding: "4px" }} />
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: "none",
                    padding: "4px",
                    fontSize: {xs: "6px", md: "8px"},
                    fontWeight: 600,
                    minWidth: header ? "auto" : "10px",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {randomASINs.map((asin, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell sx={{ border: "none", padding: "0", fontSize: "8px", color: "#80828A" }}>{rowIndex + 1}</TableCell>{" "}
                <TableCell sx={{ border: "none", padding: "4px", fontSize: "8px", color: "#38A4FF" }}>
                  {asin}
                </TableCell>{" "}
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width="auto" />
                </TableCell>
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width="auto" />
                </TableCell>
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width="auto" />
                </TableCell>
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width={10} />
                </TableCell>
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width={10} />
                </TableCell>
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width={10} />
                </TableCell>
                <TableCell sx={{ border: "none", padding: "4px" }}>
                  <Skeleton variant="text" height={20} width="auto" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllProductContentBox;
