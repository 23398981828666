import { Dispatch } from "redux";
import { UserActionTypes } from "./user.action-types";
import { BulletFormatEnum, BulletsLengthFormatEnum, RolesEnum } from "../../utils/constants/enums";
import {
  DatabaseUser,
  GetTeamMembersResponse,
  REQ_UPDATE_USER_PRIVILEGE,
  UserCredentialsType,
} from "../../apis/types/user";
import { UserApis } from "../../apis/user";
import {
  canUpdateContentGeneration,
  canUpdateContentRegeneration,
  canUpdateHelium10Calls,
} from "../../utils/helpers/priviligesChecks";
import { TeamMemberType } from "../../interfaces/userInterfaces";

interface SetToken {
  type: UserActionTypes.setToken;
  payload: string;
}

export const setTokenDispatch = (token: string): SetToken => {
  return {
    type: UserActionTypes.setToken,
    payload: token,
  };
};

export const resetTokenDispatch = (token: string = ""): SetToken => {
  return {
    type: UserActionTypes.setToken,
    payload: token,
  };
};

//this is an example async thunk please call all the apis here
export const setTokenAction = () => async (dispatch: Dispatch<SetToken>) => {
  try {
    dispatch(setTokenDispatch(""));

    // const response = await axios.get('/api/user');
    // dispatch({ type: UserActionTypes.FETCH_USER_SUCCESS, payload: response.data });
  } catch (error) {
    // dispatch({ type: UserActionTypes.FETCH_USER_FAILURE, payload: error.message });
  }
};

interface SetRole {
  type: UserActionTypes.setRole;
  payload: RolesEnum;
}

export const setRoleDispatch = (role: RolesEnum): SetRole => ({
  type: UserActionTypes.setRole,
  payload: role,
});
interface SetUser {
  type: UserActionTypes.setUser;
  payload: object;
}
export const setUserDispatch = (user: object) => ({
  type: UserActionTypes.setUser,
  payload: user,
});
export const setUserAction =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      const res = await UserApis.getUser();
      dispatch(setUserDispatch(res));
    } catch (error) {}
  };

export interface UserList {
  type: UserActionTypes.setUserList;
  payload: DatabaseUser[];
}

export const userListDispatch = (userList: DatabaseUser[]) => {
  return {
    type: UserActionTypes.setUserList,
    payload: userList,
  };
};

export const getUserListAction = (): any => async (dispatch: Dispatch<any>) => {
  try {
    let userList: DatabaseUser[] = await UserApis.getUserList();
    userList = userList.map((user) => ({ ...user, id: user._id }));
    dispatch(userListDispatch(userList));

    return userList;
  } catch (error) {
    throw error;
  }
};

export const updateUserListAction =
  (userList: DatabaseUser[], updatedUser: DatabaseUser): any =>
  async (dispatch: Dispatch<any>) => {
    const updatedUserIndex: number = userList.findIndex((list) => list._id === updatedUser._id);

    const contentGenerationLimit = updatedUser?.contentGenerationLimit || 0;
    const helium10CallsLimit = updatedUser?.helium10CallsLimit || 0;
    const contentRegenerationLimit = updatedUser?.contentRegenerationLimit || 0;

    let reqObj: REQ_UPDATE_USER_PRIVILEGE = {
      userID: updatedUser._id,
      role: updatedUser.role,
      status: updatedUser.status,
    };
    if (canUpdateContentGeneration(updatedUser.role)) {
      reqObj = {
        ...reqObj,
        contentGenerationLimit,
        contentRegenerationLimit,
        helium10CallsLimit,
      };
    }
    if (canUpdateContentRegeneration(updatedUser.role)) {
      reqObj = {
        ...reqObj,
        contentRegenerationLimit,
      };
    }
    if (canUpdateHelium10Calls(updatedUser.role)) {
      reqObj = {
        ...reqObj,
        helium10CallsLimit,
      };
    }

    try {
      // if (contentGenerationLimit < updatedUser.countContentGeneration) {
      //   throw Error(
      //     "Limit is less than number of ASIN’s on user’s account. Delete some of user’s ASINs then try again."
      //   );
      // }
      // if (helium10CallsLimit < updatedUser.countHelium10Calls) {
      //   throw Error(
      //     "Limit is less than number of Helium10Call’s on user’s account. Delete some of user’s Helium10Calls then try again."
      //   );
      // }
      //
      // if (contentRegenerationLimit < updatedUser.countContentRegeneration) {
      //   throw Error(
      //     "Limit is less than number of Content Regeneration on user’s account. Delete some of user’s Content Regenerations then try again."
      //   );
      // }

      const resp = await UserApis.updateUserPrivilege(reqObj);

      if (resp?._id) {
        let updatedUserList = [...userList];
        updatedUserList[updatedUserIndex] = { ...resp, id: resp._id };
        dispatch(userListDispatch(updatedUserList));
      } else {
        throw Error(resp);
      }

      return userList;
    } catch (error) {
      console.error("Got Error: ", error);
      throw error;
    }
  };

interface SetBulletPointsFormat {
  type: UserActionTypes.setBulletPointsFormat;
  payload: any;
}

export const setBulletPointsFormatDispatch = (user: DatabaseUser) => {
  return {
    type: UserActionTypes.setBulletPointsFormat,
    payload: user,
  };
};

export const setUserBulletPointsFormatAction =
  (user: DatabaseUser, bulletPointsFormat: BulletFormatEnum, bulletsLengthFormat: BulletsLengthFormatEnum, titleLengthFormat: BulletsLengthFormatEnum): any =>
  async (dispatch: Dispatch<any>) => {
    let reqObj: REQ_UPDATE_USER_PRIVILEGE = {
      userID: user._id,
      role: user.role,
      status: user.status,
      bulletsLengthFormat,
      bulletPointsFormat,
      titleLengthFormat,
    };

    try {
      const resp = await UserApis.updateUserSettings(reqObj);
      if (resp?._id) {
        dispatch(setBulletPointsFormatDispatch(resp));
      } else {
        throw Error(resp);
      }

      return user;
    } catch (error) {
      console.error("Got Error: ", error);
      throw error;
    }
  };

interface SetFirstLogin {
  type: UserActionTypes.setFirstLogin;
  payload: boolean;
}

export const setFirstLoginDispatch = (payload: boolean): SetFirstLogin => ({
  type: UserActionTypes.setFirstLogin,
  payload,
});

interface SetUserCredentials {
  type: UserActionTypes.setUserCredentials;
  payload: UserCredentialsType;
}

export const setUserCredentials = (payload: UserCredentialsType): SetUserCredentials => ({
  type: UserActionTypes.setUserCredentials,
  payload,
});

interface SetTeamMembersList {
  type: UserActionTypes.setTeamMembers;
  payload: TeamMemberType[];
}

export const setTeamMembersList = (payload: TeamMemberType[]): SetTeamMembersList => ({
  type: UserActionTypes.setTeamMembers,
  payload,
});

interface SetTeamMembersStats {
  type: UserActionTypes.setTeamMembersStats;
  payload: any;
}

export const setTeamMembersStats = (payload: any): SetTeamMembersStats => ({
  type: UserActionTypes.setTeamMembersStats,
  payload,
});

export const setTeamMembersAction =
  (payload: GetTeamMembersResponse, isReset: boolean, isMobile: boolean): any =>
  async (dispatch: Dispatch<any>, getState: any) => {
    let completeRes = payload;
    let teamMembersList: TeamMemberType[] = [];
    if (completeRes?.users?.length) {
      if (isReset) {
        dispatch(setTeamMembersList([]));
      }

      teamMembersList = completeRes?.users?.map((teamMember: TeamMemberType) => ({
        ...teamMember,
        id: teamMember?._id,
      }));
    }
    if (isMobile) {
      const prevTeamMembers = getState()?.user?.allTeamMembers;
      if (prevTeamMembers.length > 0 && teamMembersList.length > 0) {
        const allTeamMembersWithPrev = [...prevTeamMembers, ...teamMembersList];
        dispatch(setTeamMembersList(allTeamMembersWithPrev));
      } else {
        dispatch(setTeamMembersList(teamMembersList));
      }
    } else {
      dispatch(setTeamMembersList(teamMembersList));
    }

    let stats = {
      totalRecords: completeRes?.totalRecords,
      totalActive: completeRes?.totalActive,
      totalInactive: completeRes?.totalInactive,
      totalInvitationPending: completeRes?.totalInvitationPending,
      totalMembers: completeRes?.totalMembers,
    };
    dispatch(setTeamMembersStats(stats));
  };

export type UserReducerPayloadType =
  | SetToken
  | SetRole
  | SetUser
  | UserList
  | SetBulletPointsFormat
  | SetFirstLogin
  | SetUserCredentials
  | SetTeamMembersList
  | SetTeamMembersStats;
