import React
, {
    useEffect,
    useState
}
    from "react";
import DialogBox from "../../../../mui/dialogebox";
import {
    Autocomplete, Box, DialogProps,
    TextField,
} from "@mui/material";
import classes from './index.module.css'
import { getUserListAction } from "../../../../../store/user/user.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UserListSelector } from "../../../../../store/user/user.selector";

type GenerateNewKeyBoxProps = DialogProps & {
    open: boolean;
    onClose: () => void;
    handleGenerateClick: (userID: string, userEmail: string) => void;
};

interface UserObjectState {
    email: string;
    id: string;
}

const GenerateNewKeyBox: React.FC<GenerateNewKeyBoxProps> = (props) => {
    const { open, onClose, handleGenerateClick } = props;
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<UserObjectState>({ email: '', id: '' })
    const [disableButton, setDisableButton] = useState<boolean>(true)

    const dispatch = useDispatch();

    const userList = useSelector(UserListSelector);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (open) {
            setSelectedUser({ email: '', id: '' });
            setLoading(true);
            dispatch(getUserListAction())
                .then(() => {
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                });
        }
    }, [open])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (loading || selectedUser.email === '') {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [loading, selectedUser])

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClose) onClose(event, "backdropClick");
    };

    const handleChange = (userDetails: any) => {
        if (userDetails) {
            setSelectedUser({ email: userDetails?.email || '', id: userDetails?._id || '' })
        } else {
            setSelectedUser({ email: '', id: '' })
        }
    };

    return (
        <Box component={'div'} sx={{ maxWidth: '100%' }}>
            <DialogBox
                open={open}
                onClose={onClose}
                cancelButton={{
                    action: handleCancel as any, element: "Back"
                }}
                confirmButton={{
                    action: () => handleGenerateClick(selectedUser.id, selectedUser.email),
                    element: <> {loading ? 'Loading...' : "Continue"}</>,
                    disabled: disableButton
                }}
                title={"Generates new Key"}
                sx={{ width: '630px' }}
            >
                <div className={`${classes.Container}`}>
                    <div>
                        <Autocomplete
                            id="useremail"
                            fullWidth
                            sx={{height: '55px',maxHeight: '100%'}}
                            options={userList}
                            disabled={loading}
                            onChange={(_, value: any) => handleChange(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="User email"
                                    variant="outlined"
                                    value={selectedUser.email}
                                    fullWidth
                                />
                            )}
                            renderOption={(props, option: any) => (
                                <Box
                                    component={"li"}
                                    {...props}
                                >
                                    {option?.email}
                                </Box>
                            )}
                            getOptionLabel={(option: any) => option?.email ? option?.email : ''}
                            noOptionsText={'No Results Found'}
                        />
                    </div>
                    {/* <div className="mt-2 d-flex align-items-center">
                     
                    </div> */}
                </div>
            </DialogBox>
        </Box>
    );
};

export default GenerateNewKeyBox;
