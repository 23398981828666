import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import classes from "./index.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MTypography from "../../../Atoms/MTypography";
import AllProductContentBox from "./AllProductContentBox/AllProductContentBox";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import ProductBsrHistoryBox from "./ProductBsrHistoryBox/ProductBsrHistoryBox";

type ExportAllAsinsDialogeProps = DialogProps & {
  title?: string;
  open: boolean;
  onClose: () => void;
  handleTypeClick: (exportType: string) => void;
};

export const exportAllProductCsvType = {
  all: "all",
  bsr: "bsr",
};

const ExportAllAsinsDialoge = (props: ExportAllAsinsDialogeProps) => {
  const { handleTypeClick } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isMobile } = useDeviceType();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          maxWidth: "fit-content",
        },
      }}
    >
      {props?.title && (
        <DialogTitle className={classes.Title}>
          <div className="flex-grow-1">{props.title}</div>
          <IconButton
            sx={{
              padding: 0,
              color: "inherit",
              "&:hover": {
                background: "none",
              },
            }}
            onClick={props.onClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.BodyContainer}>
        <MTypography variant="body1" customClass={classes.ExportOptionsTitle}>Export Options</MTypography>
        <MTypography variant="body1" customClass={classes.Description}>
          Choose the data type you want to export. You’ll receive an email with a link to download the file containing
          the products listed in the table. It may take a few minutes for the email to arrive.
        </MTypography>
        <div className={`d-flex justify-content-between ${isMobile ? "flex-column gap-2 align-items-center" : "flex-row gap-4"}`}>
          <div
            className={`${classes.BoxConatiner} ${classes.GrayBorder} cursor-pointer`}
            onClick={() => handleTypeClick(exportAllProductCsvType.all)}
          >
            <MTypography variant="subtitle2" customClass={classes.BoxTitle}>
              All Product Content
            </MTypography>
            <MTypography variant="subtitle1" customClass={classes.BoxDescription}>
              Exports a file containing all product content, including ASIN, title, and more.
            </MTypography>
            <AllProductContentBox />
          </div>
          <div
            className={`${classes.BoxConatiner} ${classes.GrayBorder} cursor-pointer`}
            onClick={() => handleTypeClick(exportAllProductCsvType.bsr)}
          >
            <MTypography variant="subtitle2" customClass={classes.BoxTitle}>
              Product BSR History
            </MTypography>
            <MTypography variant="subtitle1" customClass={classes.BoxDescription}>
              Exports a file containing the Best Seller Rank (BSR) history for each product.
            </MTypography>
            <ProductBsrHistoryBox />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ExportAllAsinsDialoge;
