import React from "react";
import MTypography from "../../../../Atoms/MTypography";
import classes from "./index.module.css";
import CardOverlay from "../../../../Atoms/CardOverlay";
import MButton from "../../../../Atoms/MButton";

const BrandVoiceDescription = () => {
  return (
    <div className={classes.Container}>
      <MTypography variant="subtitle1" customClass={classes.Title}>
        Description
      </MTypography>
      <div className="mt-4">
        <CardOverlay>
          <MTypography variant={"subtitle2"} customClass={`${classes.Limit} px-4 text-end mb-2`}>
            1000/4000
          </MTypography>
          <div className="px-4">
            <CardOverlay>
              <div className="px-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac risus sed turpis congue sagittis.
                Quisque nec consectetur dolor. Integer volutpat ultricies augue, vel scelerisque quam consequat a. Donec
                eget odio sit amet nulla molestie fermentum. Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Mauris sed lorem sit amet velit mollis consequat. Nulla facilisi.
                Proin eu vestibulum nisi. Duis ac justo neque. Phasellus auctor ante id ipsum ultricies, nec feugiat
                odio posuere. Sed tempus euismod ultricies. Sed sit amet nisl nec mauris dictum blandit id at sem.
                Curabitur in tortor lectus. Sed sit amet bibendum dolor. Vivamus in sapien ultrices, fermentum libero
                vitae, viverra est. Vivamus condimentum vehicula quam at consequat.
              </div>
            </CardOverlay>
          </div>
        </CardOverlay>
      </div>
      <div className={"d-flex justify-content-end gap-2 mt-4"}>
        <MButton
          size={"medium"}
          variant={"outlined"}
          onClick={() => {
            console.log("Delete");
          }}
        >
          Delete
        </MButton>
        <MButton
          size={"medium"}
          onClick={() => {
            console.log("Save This");
          }}
        >
          Save This
        </MButton>
      </div>
    </div>
  );
};

export default BrandVoiceDescription;
