import React, { useState } from "react";
import { URL_SIGN_IN } from "../../routes/routes-path";
import { Navigate, useNavigate } from "react-router-dom";
import { firebaseSendPasswordResetEmail } from "../../firebase/auth";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ERR_EMAIL_NOT_FOUND, ERR_FORGOT_PASSWORD_REQUEST } from "../../utils/constants/messages/errors";
import { SUCC_RESET_PASSWORD } from "../../utils/constants/messages/success";
import { KEY_LAST_TAB } from "../../utils/constants/store";
import { getLastOpenTab, navigatePage } from "../../utils/helpers/common";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import AppBar from "../../components/Molecules/AppBar";
import TwoColorText from "../../components/Molecules/TwoColorText";
import { useDeviceType } from "../../hooks/useDeviceType";
import MOutlinedInput from "../../components/Atoms/MOutlinedInput";
import MButton from "../../components/Atoms/MButton";
import MTypography from "../../components/Atoms/MTypography";
import classes from "./index.module.css";
import TitleBarLogo from "../../components/Molecules/AppBar/logo";

const ForgotPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();
  const storeUser = useSelector((state: AppState) => state.user);
  const { isDesktop, isMobile, isIpad } = useDeviceType();

  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
  });

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });

  const submit = async () => {
    setState({
      ...state,
      loading: true,
    });
    await firebaseSendPasswordResetEmail(state.email)
      .then(() => {
        setState({
          ...state,
          loading: false,
        });
        toast.success(SUCC_RESET_PASSWORD);
        sessionStorage.setItem(KEY_LAST_TAB, URL_SIGN_IN);
        navigate(URL_SIGN_IN);
      })
      .catch((error: AxiosError) => {
        const { message } = error;
        if (message.includes("not-found")) toast.error(ERR_EMAIL_NOT_FOUND);
        else toast.error(ERR_FORGOT_PASSWORD_REQUEST);
        setState({
          ...state,
          loading: false,
        });
      });
  };

  return !storeUser.user ? (
    <div style={{ backgroundColor: "#F0F2F5", height: isDesktop ? "100vh" : "calc(100vh - 54px)" }}>
      <div>
        {isDesktop ? (
          <AppBar
            rightElement={
              <TwoColorText
                text1={"Back to "}
                text2={"Sign in!"}
                onClick={() => {
                  navigatePage(URL_SIGN_IN, navigate);
                }}
              />
            }
          />
        ) : (
          <div className={isMobile || isIpad ? `d-flex justify-content-center` : ""}>
            <div className={classes.TitlebarM}>
              <TitleBarLogo />
            </div>
          </div>
        )}
      </div>
      <div
        className={
          isDesktop
            ? "d-flex align-items-center justify-content-center flex-column"
            : "d-flex align-items-center flex-column"
        }
        style={isDesktop ? { backgroundColor: "white", height: "calc(100vh - 80px)" } : {}}
      >
        <div className={"text-center "}>
          <MTypography variant={"h4"}>{"Reset your password"}</MTypography>
        </div>
        <div className={"text-center w-75"}>
          <MTypography variant={"subtitle1"} customClass={`${isDesktop ? classes.Caption : classes.CaptionM} `}>
            {" We’ll send you an email to reset your password"}
          </MTypography>
        </div>
        <div className={isMobile || isIpad ? "w-75 " : "w-25 "}>
          <MOutlinedInput
            id={"email"}
            name="email"
            type="email"
            placeholder="Work Email"
            value={state.email}
            onChange={(e) => handleChange(e)}
            rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
          />
        </div>

        <div className={isMobile || isIpad ? `w-75 ${classes.ForgotButton}` : `w-25 ${classes.ForgotButton}`}>
          <MButton onClick={submit} variant={"contained"} isLoading={state.loading} fullWidth>
            {"Reset Password"}
          </MButton>
        </div>

        {(isMobile || isIpad) && (
          <div className={"d-flex justify-content-center"}>
            <div className={""} style={{ position: "fixed", bottom: "36px" }}>
              <TwoColorText
                text1={"Back to "}
                text2={"Sign in!"}
                onClick={() => {
                  navigatePage(URL_SIGN_IN, navigate);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Navigate to={getLastOpenTab()} />
  );
};

export default ForgotPasswordPage;
