import React, { useContext, useState } from "react";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";
// import deleteIcon from "../../../assets/svgs/delete.svg";
import { Product } from "../../../apis/types/generate-product";
import { getExceptionError, isPropertyExistInObject, navigatePage } from "../../../utils/helpers/common";
import { getDateWithTime } from "../../../utils/helpers/date";
import {
  isExportButton,
  showDeleteIconOnProductPage,
  showLiveCheckButton,
  showPublishOnAmazonButton,
  showRerunButton,
  showUserEmailInProductsPage,
  userRole as userRoles,
} from "../../../utils/helpers/priviligesChecks";
import { useDispatch, useSelector } from "react-redux";
import { DbUserSelector, UserRoleSelector } from "../../../store/user/user.selector";
import {
  CatalogEnum,
  ContentStatusEnum,
  CountryDomainEnum,
  ProductFetchType,
  ProductLiveStatusEnum,
  RolesEnum,
  TabsEnum,
} from "../../../utils/constants/enums";
import KeywordsPageDialog from "../../mui/dialogebox/keywordsPageDialoge";
import {
  generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription,
  ProductApis,
} from "../../../apis/product";
import { selectProductActionM } from "../../../store/product/product.actions";
import { toast } from "react-toastify";
import { SUCC_DELETE_PRODUCT } from "../../../utils/constants/messages/success";
import { SavedProductsSelector } from "../../../store/product/product.selector";
import { URL_PRODUCT_CATALOG } from "../../../routes/routes-path";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MShowMoreLess from "../../Atoms/MShowMoreLess";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { CountryDomainObject, getCountryDomainObjectByName } from "../../Organisms/DomainDropDown/config";
import { defaultImage } from "../../../utils/constants/general-constants";
import { errorHandler } from "../../../utils/helpers/apis";
// import RefreshIcon from "@mui/icons-material/Refresh";
import { generateProductApis } from "../../../apis/generate-product";
import unSelectIcon from "../../../assets/svgs/Checkbox/Square/rowUnSelectIcon.svg";
import selectIcon from "../../../assets/svgs/Checkbox/Square/rowSelectIcon.svg";
import ProductStatus from "../ProductStatus";
import { KeywordsApis } from "../../../apis/keywords";
import ActionBox from "../ActionBox";
import PricingContext from "../../../context/PricingContext";
import {
  checkIsCompanyReachedHeliumOrContentRegenLimit,
  checkIsProductOwnerReachedHisLimit,
} from "../../../pages/SavedProducts/config";
import { UnlockGreaterPossibilities, UserReachedRegenLimit } from "../../../utils/constants/messages/errors";
import ToastError from "../../Atoms/ToastError";
import { onImgErrorHandler } from "../../../utils/helpers/general-helpers";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { CompanyRoleSelector } from "../../../store/company/company.selector";
import ProductActionsMenu from "../../Organisms/SavedProducts/ProductActionsMenu";
import { handleChangeKeywordsClick, showChangeKeywordButton } from "../../../pages/Product/ProductDetails/config";

interface DetailCardProps {
  productDetail: Product;
  tab: TabsEnum;
  getProducts: (isReset: boolean) => void;
  isRerun?: boolean;
  selectionMode?: boolean;
  isChildComponent: boolean;
  isTeamManagementUser: boolean;
  handleExportCsvClick: () => void
}

interface ProductDetailCardState {
  loading: boolean;
  openDeleteDialogBox: boolean;
  openKeyWordsDialogBox: boolean;
}

const ProductDetailCard: React.FC<DetailCardProps> = ({
  productDetail,
  tab,
  getProducts,
  isRerun,
  selectionMode,
  isChildComponent,
  isTeamManagementUser,
  handleExportCsvClick
}) => {
  const dbUser = useSelector(DbUserSelector);
  const storeRole = useSelector(UserRoleSelector);
  const allProducts = useSelector(SavedProductsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceType();
  const companyRole = useSelector(CompanyRoleSelector);

  const location = useLocation();

  const userRole = useSelector(UserRoleSelector);
  const { onOpen } = useContext(PricingContext);

  const countryDomainObject = getCountryDomainObjectByName(
    productDetail?.domain || CountryDomainEnum.AMAZON_USA
  ) as CountryDomainObject;

  const [state, setState] = useState<ProductDetailCardState>({
    openDeleteDialogBox: false,
    openKeyWordsDialogBox: false,
    loading: false,
  });
  const [selectedRerunProduct, setSelectedRerunProduct] = useState<any>();
  const [openRerun, setOpenRerun] = useState<boolean>(false);

  const closeKeywordPageDialog = () => setState({ ...state, openKeyWordsDialogBox: false });
  const handleDelete = () => {
    setState({ ...state, openDeleteDialogBox: true });
  };

  const closeRerunDialog = () => setOpenRerun(false);

  const closeConfirmDelete = () => setState({ ...state, openDeleteDialogBox: false });
  const confirmDeleteProduct = async () => {
    setState({ ...state, loading: true });
    generateProductApis
      .deleteGeneratedProducts([productDetail._id])
      .then(() => {
        getProducts && getProducts(true);
        toast.success(SUCC_DELETE_PRODUCT);
      })
      .catch((e) => {
        setState({ ...state, loading: false });
        closeConfirmDelete();
        getExceptionError(e);
      });
  };

  const handleAsinClick = () => {
    if (!state.loading) {
      if (productDetail.status === ContentStatusEnum.GENERATING) {
        setState({ ...state, openKeyWordsDialogBox: true });
        setTimeout(() => {
          setState({ ...state, openKeyWordsDialogBox: false });
        }, 5000);
      } else if (tab === TabsEnum.REVIEW) {
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.REVIEW_PAGE}/${productDetail.productASIN}/${productDetail.userID}`,
          navigate
        );
      } else {
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${productDetail.productASIN}/${productDetail.userID}`,
          navigate,
          {
            state: {
              isChildComponent: isTeamManagementUser
                ? location.pathname
                : isChildComponent
                ? TabsEnum.SAVED_ASIN
                : TabsEnum.SAVED,
            },
          }
        );
      }
    }
  };

  const reGenerateKeywords = async (product: any) => {
    if (product.productType === ProductFetchType.GOGO) {
      ProductApis.gogoAutomatoProductGeneration({
        userID: product.userID,
        domain: product.domain,
        asin: product.productASIN,
        isRerun: true,
        updateCompetitors: product.status === ContentStatusEnum.ERROR
      });
      setTimeout(() => {
        closeRerunDialog();
        getProducts && getProducts(true);
      }, 1000);
    } else {
      try {
        const keywords = await KeywordsApis.getKeywords({ productAsin: product?.productASIN, userID: product?.userID });

        const titleKeywords =
          keywords?.titleKeywords &&
          keywords?.titleKeywords?.map((e: any) => ({ phrase: e.phrase, searchVolume: e.searchVolume }));
        const otherKeywords =
          keywords?.otherKeywords &&
          keywords?.otherKeywords?.map((e: any) => ({ phrase: e.phrase, searchVolume: e.searchVolume }));

        const reGenPayload = {
          productASIN: product.productASIN,
          titleKeywords: titleKeywords,
          otherKeywords: otherKeywords,
          searchTermKeywords: [
            ...(keywords?.selectedHelium10Keywords?.map((keyword: any) => keyword.phrase) || []),
            ...(otherKeywords?.map((keyword: any) => keyword.phrase) || []),
          ].filter(Boolean),
          userID: product.userID,
          isRerun: true,
        };

        generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription(reGenPayload)
          .then(() => {
            closeRerunDialog();
            setTimeout(() => {
              getProducts && getProducts(true);
            }, 1000);
          })
          .catch((e) => {
            toast.error(errorHandler(e));
          });
      } catch (e: any) {
        toast.error(errorHandler(e));
      }
    }
  };

  const handleRegeneration = async (product: any) => {
    closeRerunDialog();
    // const isLimitReached = await checkIsProductOwnerReachedHisLimit(product.userID)
    const isLimitReached = product.companyID
      ? await checkIsCompanyReachedHeliumOrContentRegenLimit(product?.companyID)
      : await checkIsProductOwnerReachedHisLimit(product.userID);

    if (isLimitReached) {
      if (dbUser?._id === product.userID) {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
        onOpen();
      } else if (dbUser?._id !== product.userID) {
        toast.error(UserReachedRegenLimit);
      } else {
      }
    } else {
      reGenerateKeywords(product);
    }
  };

  const handleProductSelect = () => {
    dispatch(selectProductActionM(allProducts, productDetail?._id as string));
  };

  // const titleToShow =
  //   productDetail?.status !== ContentStatusEnum.APPROVED && productDetail?.editedGeneratedProductContent?.title
  //     ? productDetail?.editedGeneratedProductContent.title
  //     : productDetail?.title || " ";
  const titleToShow = productDetail?.title || " ";

  const handlePublishOnAmazonClick = () => {
    toast.success("Publish Click");
  };

  return (
    <div>
      <div className={"card flex-row mt-4 cursor-pointer " + classes.ProductCard}>
        {selectionMode && showLiveCheckButton(storeRole, productDetail.status) && (
          <img
            src={productDetail?.isSelected ? selectIcon : unSelectIcon}
            alt={"row-select-icon"}
            className={classes.Checkbox}
            onClick={handleProductSelect}
          />
        )}

        <div className="d-flex flex-row w-100">
          <div className={classes.productImageMBMain}>
            <img
              src={productDetail.imageURL || defaultImage}
              onError={(e) => onImgErrorHandler(e, defaultImage)}
              alt="product"
              className={classes.productImageMB}
            />
          </div>
          <div className="d-flex flex-column ms-2 w-100">
            <div className={"d-flex justify-content-between"}>
              <div className={"d-flex gap-2 align-items-center"}>
                <ProductStatus type={productDetail.status} variant={"small"} />
                {(productDetail?.isProductLiveBeingChecked !== undefined || productDetail?.productLive) && (
                  <div className={"my-1 d-flex align-items-center"}>
                    <ProductStatus
                      type={
                        productDetail?.isProductLiveBeingChecked
                          ? ProductLiveStatusEnum.CHECKING
                          : (productDetail?.productLive?.isProductLive as ProductLiveStatusEnum)
                      }
                      showInfo
                      variant={"small"}
                    />
                  </div>
                )}
              </div>
              <div>
                {productDetail?.status === ContentStatusEnum.GENERATING ? (
                  <></>
                ) : (
                  <ProductActionsMenu
                    showRegenerateOption={
                      (showRerunButton(userRole as RolesEnum, productDetail?.status) ||
                        userRoles.isDomainUser(dbUser?.email || "") ||
                        (dbUser?.companyID && companyRole === RolesEnum.PAID)) &&
                      // productDetail?.status !== ContentStatusEnum.GENERATING &&
                      isRerun
                    }
                    showDeleteOption={
                      showDeleteIconOnProductPage(
                        storeRole as RolesEnum,
                        isPropertyExistInObject(productDetail, "userEmail"),
                        dbUser?.email as string,
                        // companyRole
                      )
                      //  && productDetail?.status !== ContentStatusEnum.GENERATING
                    }
                    showChangeKeywordOption={
                      showChangeKeywordButton(productDetail, dbUser) &&
                      productDetail?.status !== ContentStatusEnum.ERROR &&
                      !state.loading
                        ? true
                        : false
                    }
                    showPublishOption={showPublishOnAmazonButton(
                      dbUser?.role,
                      productDetail?.status,
                      dbUser?.sellerPartnerAuthorized ? true : false,
                      dbUser?.email,
                    )}
                    handleRegenerateClick={() => {
                      setSelectedRerunProduct(productDetail);
                      setOpenRerun(true);
                    }}
                    handleDeleteClick={() => handleDelete()}
                    handleChangeKeywordClick={() =>
                      handleChangeKeywordsClick(productDetail, dispatch, navigate, location)
                    }
                    handlePublishOnAmazonClick={handlePublishOnAmazonClick}
                    showExportCsvOption={!isExportButton(userRole, dbUser?.companyID, companyRole)}
                    handleExportCsvClick={handleExportCsvClick}
                  />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div onClick={handleAsinClick}>
                <MTypography variant={"h6"} customClass={classes.ProductAsinMB}>
                  {productDetail.productASIN}
                </MTypography>
              </div>
              <div className="ms-1 pb-1">
                {!state.loading && productDetail.status !== ContentStatusEnum.GENERATING && (
                  <Link
                    to={`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${productDetail?.productASIN}/${productDetail?.userID}`}
                    target="_blank"
                  >
                    <ArrowOutwardIcon color="primary" fontSize="small" />
                  </Link>
                )}
              </div>
            </div>
            <div className={`d-flex align-items-center`}>
              <img src={countryDomainObject.flag} alt={`${countryDomainObject.name} Flag`} className={classes.Flag} />
              <MTypography variant={"subtitle2"} customClass={classes.FlagName}>
                {countryDomainObject.name}
              </MTypography>
            </div>
            <MShowMoreLess
              content={titleToShow ? titleToShow : ""}
              maxLength={isMobile ? 20 : 70}
              textVariant={"subtitle2"}
              textCustomClass={"pt-1 pr-2 " + classes.ProductDescriptionMB}
            />
            {(showUserEmailInProductsPage(storeRole as RolesEnum) || dbUser?.companyID) && (
              <MTypography variant={"subtitle1"} customClass={classes.productEmailMB}>
                {isPropertyExistInObject(productDetail, "userEmail") || ""}
              </MTypography>
            )}

            <div className={"d-flex justify-content-end mt-3 me-1"}>
              <MTypography variant={"subtitle1"} customClass={classes.productDateMB}>
                {getDateWithTime(isPropertyExistInObject(productDetail, "lastProductGeneratedAt") || "")}
              </MTypography>
            </div>
          </div>
        </div>
      </div>
      <KeywordsPageDialog open={state.openKeyWordsDialogBox} onClose={closeKeywordPageDialog} />

      <ActionBox
        handleAction={confirmDeleteProduct}
        handleBack={closeConfirmDelete}
        open={state.openDeleteDialogBox}
        actionText={"Delete"}
        message={"Do you want to delete your product?"}
        title="Confirm Delete Product"
        backText={"Cancel"}
      />

      <ActionBox
        // handleAction={() => reGenerateKeywords(selectedRerunProduct)}
        handleAction={() => handleRegeneration(selectedRerunProduct)}
        handleBack={closeRerunDialog}
        open={openRerun}
        actionText={"Confirm"}
        message={"Are you sure you want to regenerate your product?"}
        title="Confirm Regenerate Product"
        backText={"Cancel"}
      />
    </div>
  );
};
export default ProductDetailCard;
