export const formatNumbers = (num: number, digits: number = 2) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export const formatNumberWithCommas = (number: any): string => {
  return number ? number.toLocaleString() : number;
};

export const formatNumberWithTwoValuesAfterDecimal = (number: any): string => {
  let num = Number(number);
  if (isNaN(num)) {
    return '0';
  }
  const formattedNumber = num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return num % 1 === 0 ? formattedNumber.slice(0, -3) : formattedNumber;
};

export const formatNumberWithNearestWholeNumber = (number: any): string => {
  let num = Number(number);
  if (isNaN(num)) {
    return '0';
  }
  const roundedNumber = Math.round(num);
  return roundedNumber.toLocaleString('en-US');
};