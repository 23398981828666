import React, { ReactNode } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface Props extends ButtonProps {
  children: ReactNode;
  onClick?: any;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
  isLoading?: boolean;
  rootClass?: any;
  width?: "small" | "medium" | "large" | "default";
  disabled?: boolean;
}

const MButton: React.FC<Props> = (props) => {
  const {
    rootClass = {},
    children,
    fullWidth = false,
    isLoading = false,
    size = "medium",
    variant,
    onClick,
    width = "default",
    disabled = false,
  } = props;
  const circularSize = {
    small: 20,
    medium: 24,
    large: 28,
  };
  const btnWidth = {
    small: 16,
    medium: 24,
    large: 40,
    default: 0,
  };

  let styles: any = {};
  if (width !== "default") {
    styles = {
      ...styles,
      width: `${btnWidth[width] * 6}px`,
    };
  }

  return (
    <Button
      size={size ? size : "medium"}
      variant={variant ? variant : "contained"}
      onClick={onClick ? onClick : undefined}
      disabled={isLoading || disabled}
      fullWidth={fullWidth}
      style={styles}
      classes={{ root: rootClass }}
    >
      {isLoading ? (
        <>
          <CircularProgress size={circularSize[size]} />
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};

export default MButton;
