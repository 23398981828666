import React, { useEffect, useRef, useState } from "react";
import classes from "./index.module.css";
import TabsToolbar from "../../components/Molecules/TabsToolbar";
import { SettingsEnum, TabsEnum, UserStatusEnum } from "../../utils/constants/enums";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { DatabaseUser, InteralUserInterface } from "../../apis/types/user";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import EditUserPrivilegeBox from "../../components/mui/dialogebox/editUserPriviliges";
import { User } from "firebase/auth";
import { showActionIconOnUserManagement } from "../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../store/user/user.selector";
import { useDeviceType } from "../../hooks/useDeviceType";
import AccountManagementM from "../../components/Organisms/AccountManagement/AccountManagementM";
import UserStatus from "../../components/Molecules/UserStatus";
import { defaultPageSize, usePagination } from "../../hooks/usePagination";
import { UserApis } from "../../apis/user";
import ProductTable from "../../components/Organisms/SavedProducts/ProductDataGrid/ProductTable";
import { capitalizeEachLetter, capitalizeFirstLetter } from "../../utils/helpers/strings";
import MTextField from "../../components/Atoms/MTextField";
import { debounce } from "lodash";
import MTypography from "../../components/Atoms/MTypography";
import MButton from "../../components/Atoms/MButton";
import { useNavigate } from "react-router-dom";
import UsersGridSkeleton from "../../components/Molecules/GridSkeletons/UsersGridSkeleton/UsersGridSkeleton";
import { SUCC_USER_PROFILE_UPDATED } from "../../utils/constants/messages/success";
import ToastError from "../../components/Atoms/ToastError";
import { navigatePage } from "../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../routes/routes-path";
import { IconButton, Tooltip } from "@mui/material";

type TDatabaseUserWithId = DatabaseUser & { id: string };

export interface AccountManagementState {
  editMode: boolean;
  userList: TDatabaseUserWithId[];
  loading: boolean;
  userUpdate: boolean;
  selectedUser: TDatabaseUserWithId | DatabaseUser | {};
  user: User | null;
  userStatus: boolean;
}
export const EditUserDetails = (
  state: AccountManagementState,
  dispatch: React.Dispatch<React.SetStateAction<AccountManagementState>>,
  user: DatabaseUser
) => {
  dispatch({
    ...state,
    userStatus: user?.status === UserStatusEnum.Active,
    editMode: true,
    selectedUser: user,
  });
};
export const deleteUserAccount = (user: DatabaseUser) => {
  console.log(user);
  toast.warn(`Not Implemented yet`);
};

const AccountManagement = () => {
  const [state, setState] = useState<AccountManagementState>({
    editMode: false,
    userList: [],
    loading: true,
    selectedUser: {},
    userUpdate: false,
    user: null,
    userStatus: false,
  });

  // const dispatch = useDispatch();
  // const userList = useSelector(UserListSelector);
  const dbUser = useSelector(DbUserSelector);
  const { isDesktop, isMobile, isIpad } = useDeviceType();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy, setSortModel } = usePagination(
    isDesktop ? 50 : 20
  );

  const [internalUsersList, setInternalUsersList] = useState<InteralUserInterface[]>();
  const [totalUsers, setTotalUsers] = useState<any>(0);
  const navigate = useNavigate();

  const isInitialRender = useRef(true);

  const getFilters = (isReset: boolean) => {
    let filters = {
      offset: isReset ? 0 : offset,
      limit: isReset ? defaultPageSize : pageSize,
      sortBy: "",
      sortOrder: 0,
      searchQuery: "",
    };

    if (sortBy !== "") {
      filters = {
        ...filters,
        sortBy: sortBy,
        sortOrder,
      };
    }

    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    return filters;
  };

  const getInternalUsers = (isReset: boolean, isMobile: boolean) => {
    if (isReset) {
      setInternalUsersList([]);
      setTotalUsers(0);
    }

    const filters = getFilters(isReset);

    setState({ ...state, loading: true });
    UserApis.getInternalUsersList(filters)
      .then((res: any) => {
        setState({ ...state, loading: false, editMode: false });
        const usersList = res?.users;
        const usersListWithID = usersList?.map((e: any) => ({ ...e, id: e._id }));

        if (isMobile) {
          if (internalUsersList && internalUsersList?.length > 0 && usersListWithID?.length > 0) {
            const usersWithPrevList = [...internalUsersList, ...usersListWithID];
            setInternalUsersList(usersWithPrevList);
          } else {
            setInternalUsersList(usersListWithID);
          }
        } else {
          setInternalUsersList(usersListWithID);
        }
        setTotalUsers(res?.totalRecords);
      })
      .catch((e: any) => {
        setState({ ...state, loading: false, editMode: false });
        setInternalUsersList([]);
        console.log("error while fetching all clients", e);
      });
  };

  const callGetInteralUserApi = (isReset: boolean = false, isMobile: boolean = false) => {
    getInternalUsers(isReset, isMobile);
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isDesktop) {
      callGetInteralUserApi(false, false);
    } else {
      callGetInteralUserApi(false, true);
    }
  }, [pageNumber, pageSize, offset, sortBy, sortOrder]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInitialRender.current) {
      callGetInteralUserApi(true);
    } else {
      isInitialRender.current = false;
    }
  }, [searchQuery]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <div className={"d-flex flex-column"}>
          <span
            title={_params.row?.name || ""}
            className={`${classes.PrimaryColor} ${classes.CellLinesLimitContainer}`}
          >
            {capitalizeEachLetter(_params.row?.name || "")}
          </span>
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <span title={_params.row?.email || ""} className={`${classes.CellLinesLimitContainer}`}>
          {_params.row?.email || ""}
        </span>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <span title={_params.row?.role || ""} className={classes.CellLinesLimitContainer}>
          {capitalizeFirstLetter(_params.row?.role || "") || ""}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <UserStatus type={_params.row.status === UserStatusEnum.Pause ? UserStatusEnum.Inactive : _params.row.status} />
      ),
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <span>
          {showActionIconOnUserManagement(_params.row.role, _params.row?.email, dbUser.email) && (
            <div className={"w-100"}>
              <Tooltip title="Edit" placement="top">
                <IconButton onClick={() => EditUserDetails(state, setState, _params.row as DatabaseUser)}>
                  <EditIcon
                    color="primary"                    
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </span>
      ),
    },
  ];

  const updateUser = async (): Promise<any> => {
    setState({ ...state, loading: true });
    const userData: any = state.selectedUser;
    UserApis.updateUserSettings({
      ...userData,
      status: state.userStatus ? UserStatusEnum.Active : UserStatusEnum.Pause,
      userID: userData._id,
    })
      .then((res) => {
        if (res[0]) {
          toast.error(res[0]);
          setState({ ...state, userUpdate: false });
        } else {
          setState({ ...state, userUpdate: false });
          toast.success(SUCC_USER_PROFILE_UPDATED);
          callGetInteralUserApi();
        }
      })
      .catch((e: any) => {
        toast.error(<ToastError error={e} />);
        setState({ ...state, userUpdate: false });
      });
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 1500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleAddUser = () => {
    navigatePage(
      `${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.USERS}/${SettingsEnum.ADD_INTERNAL_USER}`,
      navigate
    );
  };

  const navigateToSettingsTab = () => {
    navigate(-1);
  };

  return (
    <>
      {isDesktop && (
        <div className="user-management">
          <div className="">
            <TabsToolbar
              title={SettingsEnum.USERS}
              children={
                <div className="d-flex align-items-center gap-4">
                  <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
                    <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
                  </div>
                  <MButton variant="contained" onClick={handleAddUser}>
                    Add Internal User
                  </MButton>
                </div>
              }
            />
          </div>
          {isDesktop && (
            <div className={`${classes.Container} pb-0 pt-3`}>
              <MTextField
                icon={"search"}
                position={"start"}
                onChange={handleChange}
                name={"customKeyword"}
                placeholder={"Search by name or email"}
                value={search}
                margin={"dense"}
                rootClass={isDesktop ? classes.TextInput : classes.TextInputMobile}
              />
            </div>
          )}
          {state.loading ? (
            <div className={classes.Container}>
              <UsersGridSkeleton />
            </div>
          ) : (
            <>
              <div className={classes.Container}>
                <ProductTable
                  total={totalUsers}
                  pageSize={pageSize}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  setPageSize={setPageSize}
                  columns={columns}
                  rows={internalUsersList ?? []}
                  sortModel={[
                    {
                      field: sortBy || "name",
                      sort: sortOrder ? (sortOrder === 1 ? ("asc" as any) : ("desc" as any)) : "asc",
                    },
                  ]}
                  setSortModel={setSortModel}
                />
              </div>
            </>
          )}
        </div>
      )}
      {(isMobile || isIpad) && (
        <AccountManagementM
          userList={internalUsersList ?? []}
          accountManagementState={state}
          setAccountManagementState={setState}
          loading={state.loading}
          handleAddUser={handleAddUser}
          handleChange={handleChange}
          search={search}
          total={totalUsers}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      )}
      <EditUserPrivilegeBox
        open={state.editMode}
        onClose={() => setState({ ...state, editMode: false })}
        setUserManagementState={setState}
        userManagementState={state}
        updateUser={updateUser}
      />
    </>
  );
};

export default AccountManagement;
