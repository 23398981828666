import { BrandsObjectApi, GetAllBrandsApi, GetAllBrandsArrayApi } from "../types/product";

export const transformGetAllBrandsApi = (brandsPayload: GetAllBrandsApi): GetAllBrandsArrayApi => {
  const finalBrands: {
    [key: string]: BrandsObjectApi;
  }[] = [];

  const brands = brandsPayload.brands;

  for (const key in brands) {
    finalBrands.push({
      [key]: {
        ...brands[key],
      },
    });
  }

  return {
    totalRecords: brandsPayload.totalRecords,
    brands: finalBrands,
    // adding for opportunity stats views
    totalKeywordCoverageRatio: brandsPayload.totalKeywordCoverageRatio ?? 0,
    totalLiveProducts: brandsPayload.totalLiveProducts ?? 0,
    totalVolumeChange: brandsPayload.totalVolumeChange ?? 0,
    productCountsWithStatuses: brandsPayload.productCountsWithStatuses ?? []
  };
};
