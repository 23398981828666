import React from "react";
import { Skeleton } from "@mui/material";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
import MTypography from "../../../../Atoms/MTypography";
import { formatNumberWithCommas } from "../../../../../utils/helpers/numbers";
import classes from "./index.module.css";

interface TeamStatCardProps {
  title: string;
  volume: number;
  isLoading: boolean;
}

const TeamStatCard: React.FC<TeamStatCardProps> = (props) => {
  const { title, volume, isLoading } = props;
  const { isDesktop } = useDeviceType();
  return (
    <div className={isDesktop ? classes.CardContainer : classes.CardContainerM}>
      <MTypography
        variant={"subtitle1"}
        customClass={`${isDesktop ? classes.CardTitle : classes.CardTitleM}`}
        color={"#4d4d4d"}
      >
        {title}
      </MTypography>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Skeleton variant="text" width={"50%"} height={20} />
        </div>
      ) : (
        <MTypography variant={"h1"} customClass={isDesktop ? classes.Count : classes.CountM}>
          {formatNumberWithCommas(volume || 0)}
        </MTypography>
      )}
    </div>
  );
};

export default TeamStatCard;
