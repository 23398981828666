import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import classes from "./index.module.css";
import MuiPopover from "../../../Atoms/Popover";
import MenuOption from "../../../Molecules/MenuOption";
import { ProductDetailsState } from "../../../../pages/Product/ProductDetails";
import MTypography from "../../../Atoms/MTypography";
import HourglassBottomTwoToneIcon from "@mui/icons-material/HourglassBottomTwoTone";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DeleteIcon from "../../../../assets/svgs/deleteIcon.svg";
import {
  showDeleteIconOnProductPage,
  showDeleteIconOnProductPageCompany,
  showLiveCheckButton,
  showTriggerHistroyButtonOnDetailPage,
} from "../../../../utils/helpers/priviligesChecks";
import { RolesEnum } from "../../../../utils/constants/enums";
import { useSelector } from "react-redux";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import { CompanySelector } from "../../../../store/company/company.selector";

interface DetailsActionsMenuProps {
  state: ProductDetailsState;
  setState: React.Dispatch<React.SetStateAction<ProductDetailsState>>;
  handleLiveCheck: () => void;
  handleDelete: () => void;
  handleFeedbackClick: () => void;
  triggerKeywordTracking: () => void;
}

const DetailsActionsMenu: React.FC<DetailsActionsMenuProps> = (props) => {
  const { state, setState, handleLiveCheck, handleDelete, handleFeedbackClick, triggerKeywordTracking } = props;
  const userRole = useSelector(UserRoleSelector);
  const completeProduct = useSelector(CompleteProductSelector);
  const currentUser = useSelector(DbUserSelector);

  const currentCompany = useSelector(CompanySelector);

  return (
    <div>
      <div className={`${classes.IconContainer}`}>
        <MoreVertIcon
          className={`${classes.IconColor}`}
          sx={{ rotate: { xs: "0", lg: "90deg" } }}
          onClick={(e) => {
            setState({
              ...state,
              anchorEl: e.currentTarget,
              openPopper: true,
            });
          }}
        />
      </div>
      <MuiPopover
        open={state.openPopper}
        anchorEl={state.anchorEl}
        onClose={() => setState({ ...state, openPopper: false })}
      >
        <div className={"py-2"}>
          {showLiveCheckButton(userRole as RolesEnum, completeProduct?.status) && (
            <MenuOption>
              <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                <div onClick={handleLiveCheck}>
                  {state.showLive ? <HourglassBottomTwoToneIcon /> : <OnlinePredictionOutlinedIcon />}
                  <span className={"ms-1"}>{state.showLive ? "Please Wait" : "Check Product Live"}</span>
                </div>
              </MTypography>
            </MenuOption>
          )}
          {showTriggerHistroyButtonOnDetailPage(currentUser?.role, completeProduct?.status) && (
            <MenuOption>
              <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                <div onClick={triggerKeywordTracking}>
                  {state.keywordsLoading ? <HourglassBottomTwoToneIcon /> : <PowerSettingsNewIcon />}
                  <span className={"ms-1"}>{state.keywordsLoading ? "Please Wait" : "Trigger Tracking"}</span>
                </div>
              </MTypography>
            </MenuOption>
          )}
          <MenuOption>
            <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
              <div onClick={handleFeedbackClick}>
                <ForumIcon />
                {/* previous title was feedback */}
                <span className={"ms-1"}>Take Notes</span>
              </div>
            </MTypography>
          </MenuOption>

          {/* updated */}
          {(currentUser?.companyID
            ? showDeleteIconOnProductPageCompany(currentCompany?.role, currentUser?.role)
            : showDeleteIconOnProductPage(currentUser?.role, completeProduct?.userEmail, currentUser?.email)) && (
            <MenuOption>
              <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                <div onClick={handleDelete}>
                  <img src={DeleteIcon} alt={"delete-icon"} />
                  <span className={"ms-1"}>Delete</span>
                </div>
              </MTypography>
            </MenuOption>
          )}
        </div>
      </MuiPopover>
    </div>
  );
};

export default DetailsActionsMenu;
