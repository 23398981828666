import React, { useContext } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import MButton from "../../../Atoms/MButton";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import classes from "./UpgradeNowBanner.module.css";
import PricingContext from "../../../../context/PricingContext";

interface UpgradeNowBannerDetailsPageProps {

}
const UpgradeNowBannerDetailsPage: React.FC<UpgradeNowBannerDetailsPageProps> = () => {
  const { isDesktop } = useDeviceType();
  const { onOpen } = useContext(PricingContext);

  return (
    <>
      <div className={isDesktop ? classes.Container : classes.ContainerMobile}>
        <div className={isDesktop ? "d-flex align-items-center gap-2 mr-4" : "d-flex mb-3 align-items-center gap-2 w-100"}>
          <InfoOutlinedIcon style={{ color: "#0052EA" }} />
          <div className={classes.Text}>If you want to see the full product content by AutoMato.</div>
        </div>
        <div
          className={isDesktop ? `${classes.Button} d-flex justify-content-end gap-3` : `d-flex justify-content-end`}
          style={{maxHeight: isDesktop ? '' : '40px'}}
        >
          <MButton size={isDesktop ? "medium" : "small"} variant="contained" onClick={onOpen}>
            {isDesktop ? "Upgrade Now" : "Upgrade"}
          </MButton>
        </div>
      </div>
    </>
  );
};

export default UpgradeNowBannerDetailsPage;
