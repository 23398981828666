import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classes from "./index.module.css";

import { GetAllBrandsArrayApi } from "../../../../apis/types/product";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";

interface BrandsTableProps {
  brandsData: GetAllBrandsArrayApi;
  onBrandClick: (brand: string) => void;
  opportunityBrands?: boolean;
}

const BrandsTable: React.FC<BrandsTableProps> = (props) => {
  const { brandsData, onBrandClick, opportunityBrands = false } = props;
  return (
    <TableContainer classes={{ root: classes.TableContainer }}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.TableHeading }} align="left" sx={opportunityBrands ? { width: '400px' } : {}}>
              Brand Name
            </TableCell>
            <TableCell classes={{ root: classes.TableHeading }} align="left">
              Total Products
            </TableCell>
            {/* restrictring below code for opportunity view */}
            {!opportunityBrands && <>
              < TableCell classes={{ root: classes.TableHeading }} align="center">
                Generating
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Error
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Draft
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Edited
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Approved{" "}
              </TableCell>
            </>
            }
            {/* an empty cell to manage opportunity view */}
            {opportunityBrands &&
              <TableCell classes={{ root: classes.TableHeading }} align="left">
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {brandsData.brands.map((row) => (
            <TableRow key={Object.keys(row)[0]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell onClick={() => onBrandClick(Object.keys(row)[0])} align="left" component="th" scope="row">
                <p className={`HoverEffectForLinks ${classes.TableCellPrimary}`}> {capitalizeEachLetter(Object.keys(row)[0])}</p>
              </TableCell>
              <TableCell classes={{ root: classes.TableCells }} align="center" sx={opportunityBrands ? { width: '140px' } : {}}>
                {row[Object.keys(row)[0]].total}
              </TableCell>
              {/* restrictring below code for opportunity view */}
              {!opportunityBrands &&
                <>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    {row[Object.keys(row)[0]].generating}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    {row[Object.keys(row)[0]].error}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    {row[Object.keys(row)[0]].draft}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    {row[Object.keys(row)[0]].edited}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    {row[Object.keys(row)[0]].approved}
                  </TableCell>
                </>
              }
              {opportunityBrands &&
                <TableCell classes={{ root: classes.TableCells }} align="left" sx={{}}>
                </TableCell>
              }
            </TableRow>
          ))}
          {brandsData.brands.length < 1 &&
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0, height: '100%', paddingTop: '10%' } }}>
              <TableCell colSpan={7} align="center" sx={{ verticalAlign: 'center', borderBottom: 'none' }} >
                No results found.
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default BrandsTable;
