import React, { useEffect, useState } from "react";
import MTypography from "../../../Atoms/MTypography";
import classes from "./index.module.css";
import { Divider } from "@mui/material";
import CustomizedProductTagBox from "../../../Molecules/CustomizedProductTagsBox";
import { checkProductIds, TagColorsEnum } from "./config";
import ProductTags from "./ProductTags";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import CloseIcon from "@mui/icons-material/Close";
import { UserProductTagsSelector } from "../../../../store/product/product.selector";
import { useDispatch, useSelector } from "react-redux";
import { Product } from "../../../../apis/types/generate-product";
import { handleClearAllTagsAction } from "../../../../store/product/product.actions";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../utils/helpers/apis";

interface CustomizeTagState {
  openDialog: boolean;
}
interface TagPopperProps {
  selectedProducts: Product[];
  onClose?: () => void;
}
const TagPopper: React.FC<TagPopperProps> = ({ onClose, selectedProducts }) => {
  const dispatch = useDispatch();
  const { isDesktop } = useDeviceType();

  const tags = useSelector(UserProductTagsSelector);
  const selectedProductsIds = selectedProducts.map((product) => product._id);
  const [showClearTags, setShowClearTags] = useState<boolean | undefined>();

  const [customizeTag, setCustomizeTag] = useState<CustomizeTagState>({
    openDialog: false,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let tagsProductIds: string[] = [];
    tags.map((t) => {
      if (t?.productIDs?.length) tagsProductIds = [...tagsProductIds, ...t.productIDs];
      return tags;
    });
    const status = checkProductIds(selectedProductsIds, tagsProductIds);
    setShowClearTags(status);
  }, [tags]);
  const handleCustomizedTagBox = () => {
    setCustomizeTag({ ...customizeTag, openDialog: true });
  };
  const closeCustomizedTag = () => {
    setCustomizeTag({ ...customizeTag, openDialog: false });
  };

  const handleClearTag = () => {
    dispatch(handleClearAllTagsAction(selectedProductsIds, tags))
      .then(() => {
        toast.success("All tags clear from selected products");
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
      });
  };

  return (
    <div className={`${classes.TagPopperContainer} ${!isDesktop && ""}`}>
      {!isDesktop && (
        <div className={"w-100 d-flex justify-content-end p-3"}>
          <CloseIcon onClick={onClose} />
        </div>
      )}
      <div>
        {tags.map((currentTag, index) => (
          <ProductTags currentTag={currentTag} key={index} tagIndex={index} selectedProduct={selectedProducts} />
        ))}
      </div>

      {tags?.length ? (
        <Divider color={"#D9D9D9"} />
      ) : (
        <div className={"p-5 d-flex align-items-center justify-content-center no-pointer-events"}>
          <MTypography variant={"subtitle2"} color={"grey"}>
            {"No Tags"}
          </MTypography>
          <Divider color={"#D9D9D9"} />
        </div>
      )}
      <div className={`cursor-pointer w-100`} onClick={() => handleCustomizedTagBox()}>
        <MTypography variant={"subtitle2"} customClass={`p-3 w-100 ${classes.CreateTag}`}>
          <div>{"Create a Tag"}</div>
        </MTypography>
      </div>
      {(showClearTags === true || showClearTags === undefined) && (
        <>
          <Divider color={"#D9D9D9"} />
          <div className={`cursor-pointer w-100 ${classes.MoreTagOptions}`} onClick={handleClearTag}>
            <MTypography variant={"subtitle2"} customClass={`py-2 w-100 ${classes.TagOption}`}>
              <div>{"Clear Tag"}</div>
            </MTypography>
          </div>
        </>
      )}
      <CustomizedProductTagBox
        open={customizeTag.openDialog}
        onClose={closeCustomizedTag}
        tag={{ color: TagColorsEnum.WHITE, name: "" }}
        tagIndex={-1}
      />
    </div>
  );
};

export default TagPopper;
