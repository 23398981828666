import { Select, MenuItem, SelectChangeEvent, FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RolesEnum } from "../../../../../utils/constants/enums";
import { AccountManagementState } from "../../../../../pages/AccountManagement";
import { DatabaseUser } from "../../../../../apis/types/user";
import { removeUnderscoreAndCapitalize } from "../../../../../utils/helpers/strings";

interface RolesDropDownWithoutOwnerProps {
  userManagementState: AccountManagementState;
  setUserManagementState: React.Dispatch<React.SetStateAction<AccountManagementState>>;
}
interface RolesDropDownWithoutOwnerState {
  currentRole: RolesEnum;
}
const RolesDropDownWithoutOwner = ({ setUserManagementState, userManagementState }: RolesDropDownWithoutOwnerProps) => {
  const { role } = userManagementState.selectedUser as DatabaseUser;
  const [state, setState] = useState<RolesDropDownWithoutOwnerState>({
    currentRole: role,
  });

  const handleChange = (evt: SelectChangeEvent) =>
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setUserManagementState({
      ...userManagementState,
      selectedUser: {
        ...userManagementState.selectedUser,
        role: state.currentRole,
      },
    });
  }, [state.currentRole]);
  return (
    <FormControl sx={{ minWidth: "100%" }} size="small">
      <Select
        labelId="roles-label"
        id="roles-select"
        value={state.currentRole}
        onChange={handleChange}
        name="currentRole"
        className={"w-100"}
      >
        {Object.values(RolesEnum).map(
          (role) =>
            (role === RolesEnum.ADMIN || role === RolesEnum.EDITOR || role === RolesEnum.INTERNAL) && (
              <MenuItem key={role} value={role}>
                {removeUnderscoreAndCapitalize(role)}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};

export default RolesDropDownWithoutOwner;
