import React from "react";
import { DatabaseUser } from "../../../apis/types/user";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";
import {
  showActionIconOnUserManagement,
} from "../../../utils/helpers/priviligesChecks";
import EditIcon from "@mui/icons-material/Edit";
import {  EditUserDetails, AccountManagementState } from "../../../pages/AccountManagement";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../store/user/user.selector";
import { useDeviceType } from "../../../hooks/useDeviceType";
import MShowMoreLess from "../../Atoms/MShowMoreLess";
import UserStatus from "../../Molecules/UserStatus";
import { UserStatusEnum } from "../../../utils/constants/enums";

interface UserDetailCardProps {
  user: DatabaseUser;
  accountManagementState: AccountManagementState;
  setAccountManagementState: React.Dispatch<React.SetStateAction<AccountManagementState>>;
}

const UserDetailCard = ({ user, setAccountManagementState, accountManagementState }: UserDetailCardProps) => {
  const dbUser = useSelector(DbUserSelector);
  const { isMobile } = useDeviceType();

  return (
    <div className={"mt-2 py-3 px-3 d-flex flex-column mb-3 w-100"} style={{ backgroundColor: "white" }}>
      <div className={"d-flex mb-1 justify-content-between align-items-center"}>
        <MShowMoreLess
          content={user?.name}
          maxLength={isMobile ? 20 : 70}
          textVariant={"h4"}
          textCustomClass={`${classes.PrimaryColor} ${classes.UserName}`}
        />
        <div className={"d-flex"}>
          {showActionIconOnUserManagement(user?.role, user?.email, dbUser.email) && (
            <EditIcon
              onClick={() => EditUserDetails(accountManagementState, setAccountManagementState, user)}
              className={`mx-2`}
              color="primary"
            />
          )}
        </div>
      </div>

      <div className={classes.ContentSpacing}>
        <MTypography variant={"subtitle1"} customClass={classes.CaptionColor}>
          {user?.email}
        </MTypography>
      </div>
      <div className="d-flex flex-wrap">
        <div className="col-6">
          <div className={classes.ContentSpacing}>
            <div>
              <MTypography variant={"body1"} customClass={classes.UserDetails}>
                {user?.role}
              </MTypography>
            </div>
            <div>
              <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
                {"Role"}
              </MTypography>
            </div>
          </div>
        </div>
        <div className="h-50 col-6">
          <div>
            <UserStatus type={user.status === UserStatusEnum.Pause ? UserStatusEnum.Inactive : user.status} />
          </div>
          <div>
            <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
              {"Status"}
            </MTypography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailCard;
