import React, { FC, useState } from "react";
import { IconButton } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import MTypography from "../../../Atoms/MTypography";
import CardOverlay from "../../../Atoms/CardOverlay";
import MSlider from "../../../Atoms/MSlider";
import { useDeviceType } from "../../../../hooks/useDeviceType";

import { aiModelsProbabilityConstants, marks, probabilityList } from "./config";
import classes from "./index.module.css";

interface AiModelProbabilityConfigProps {
  setSliderValues: React.Dispatch<React.SetStateAction<any>>;
  sliderValues: any;
}

const AiModelProbabilityConfigSliders: FC<AiModelProbabilityConfigProps> = (props) => {
  const { setSliderValues, sliderValues } = props;
  const { isDesktop } = useDeviceType();

  const [sliderActiveState, setSliderActiveState] = useState<any>({
    gpt4: false,
    gpt4o: false,
    claudeSonnet: false,
  });

  const handleSliderChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    const newValue = parseInt(value, 10);

    let newGpt4oSliderValue = sliderValues.gpt4o;
    let newClaudeSonnetSliderValue = sliderValues.claudeSonnet;
    let newGpt4SliderValue = sliderValues.gpt4;

    const remaining = 100 - newValue;

    if (name === aiModelsProbabilityConstants.gpt4.name) {
      if (!sliderActiveState.gpt4 && (!sliderActiveState.gpt4o || !sliderActiveState.claudeSonnet)) {
        if (sliderActiveState.gpt4o) {
          newClaudeSonnetSliderValue = remaining - sliderValues.gpt4o;
        } else if (sliderActiveState.claudeSonnet) {
          newGpt4oSliderValue = remaining - sliderValues.claudeSonnet;
        } else {
          newGpt4oSliderValue = remaining / 2;
          newClaudeSonnetSliderValue = remaining / 2;
        }

        if (newGpt4oSliderValue >= 0 && newClaudeSonnetSliderValue >= 0) {
          setSliderValues({ gpt4: newValue, gpt4o: newGpt4oSliderValue, claudeSonnet: newClaudeSonnetSliderValue });
        }
      }
    } else if (name === aiModelsProbabilityConstants.gpt4o.name) {
      if (!sliderActiveState.gpt4o && (!sliderActiveState.gpt4 || !sliderActiveState.claudeSonnet)) {
        if (sliderActiveState.gpt4) {
          newClaudeSonnetSliderValue = remaining - sliderValues.gpt4;
        } else if (sliderActiveState.claudeSonnet) {
          newGpt4SliderValue = remaining - sliderValues.claudeSonnet;
        } else {
          newGpt4SliderValue = remaining / 2;
          newClaudeSonnetSliderValue = remaining / 2;
        }

        if (newGpt4SliderValue >= 0 && newClaudeSonnetSliderValue >= 0) {
          setSliderValues({ gpt4: newGpt4SliderValue, gpt4o: newValue, claudeSonnet: newClaudeSonnetSliderValue });
        }
      }
    } else if (name === aiModelsProbabilityConstants.claudeSonnet.name) {
      if (!sliderActiveState.claudeSonnet && (!sliderActiveState.gpt4o || !sliderActiveState.gpt4)) {
        if (sliderActiveState.gpt4) {
          newGpt4oSliderValue = remaining - sliderValues.gpt4;
        } else if (sliderActiveState.gpt4o) {
          newGpt4SliderValue = remaining - sliderValues.gpt4o;
        } else {
          newGpt4SliderValue = remaining / 2;
          newGpt4oSliderValue = remaining / 2;
        }

        if (newGpt4SliderValue >= 0 && newGpt4oSliderValue >= 0) {
          setSliderValues({ gpt4: newGpt4SliderValue, gpt4o: newGpt4oSliderValue, claudeSonnet: newValue });
        }
      }
    } else {
    }
  };

  const handleSliderActive = (name: string, value: boolean) => {
    setSliderActiveState({ ...sliderActiveState, [name]: value });
  };

  return (
    <div className="mb-4">
      <div className={`pt-4`}>
        <MTypography variant={"subtitle1"} customClass={classes.Title}>
          AI Model Probability Configuration
        </MTypography>
        <MTypography variant={"subtitle1"} customClass={`${classes.Description}`}>
          Allocate usage percentages for each AI model to determine how frequently each model is used for content
          generation tasks.
        </MTypography>
      </div>
      <div className={`${classes.ListContainer}`}>
        <CardOverlay zeroPadding={true}>
          {probabilityList.map((item: any) => (
            <div className={`${isDesktop ? classes.ListItemContainer : classes.ListItemContainerM}`} key={item.title}>
              <div
                className={`d-flex ${
                  isDesktop ? "justify-content-between align-items-center w-100 h-100" : "flex-column pt-3 pb-3"
                } `}
              >
                <MTypography variant="body2" customClass={`${classes.TitleName} ${!isDesktop && classes.TitleNameM}`}>
                  {item.title}{" "}
                  <span className={`${classes.NickName} ${!isDesktop && classes.NickNameM}`}>
                    {" "}
                    {`(Nick name: ${item.nickName})`}
                  </span>
                </MTypography>
                <div style={{ width: "30%" }} className={`${!isDesktop && "w-100 ps-2 pt-2"}`}>
                  <MTypography variant="caption" customClass={"pb-2"}>
                    Probability: <span className="fw-semibold">{parseInt(sliderValues[item.name], 10)}%</span>
                  </MTypography>
                  <div className="w-100 d-flex gap-2">
                    <MSlider
                      value={sliderValues[item.name]}
                      onChange={handleSliderChange}
                      marks={marks}
                      min={0}
                      max={100}
                      name={item.name}
                      disabled={sliderActiveState[item.name]}
                    />
                    <div>
                      {sliderActiveState[item.name] ? (
                        <IconButton onClick={() => handleSliderActive(item.name, false)}>
                          <LockOutlinedIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleSliderActive(item.name, true)}>
                          <LockOpenOutlinedIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CardOverlay>
      </div>
    </div>
  );
};

export default AiModelProbabilityConfigSliders;
