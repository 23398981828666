import { OpportunityReportActionTypes } from "./opportunityReport.action-types";

export interface SetOpportunityReportFilter {
  type: OpportunityReportActionTypes.setOpportunityReportFilters;
  payload: any;
}

export const setOpportunityReportFilter = (filterVals: any): SetOpportunityReportFilter => {
  return {
    type: OpportunityReportActionTypes.setOpportunityReportFilters,
    payload: filterVals,
  };
};

export interface ReSetOpportunityReportFilter {
  type: OpportunityReportActionTypes.reSetOpportunityReportFilters;
}

export const reSetOpportunityReportFilter = (): ReSetOpportunityReportFilter => {
  return {
    type: OpportunityReportActionTypes.reSetOpportunityReportFilters,
  };
};

export interface SetOpportunityProductLoading {
  type: OpportunityReportActionTypes.setOpportunityProductsLoading;
  payload: boolean;
}

export const setOpportunityProductLoadingDispatch = (state: boolean): SetOpportunityProductLoading => {
  return {
    type: OpportunityReportActionTypes.setOpportunityProductsLoading,
    payload: state,
  };
};

interface SetOpportunityActiveTab {
  type: OpportunityReportActionTypes.setOpportunityActiveTab;
  payload: string;
}

export const setOpportunityActiveTab = (tab: string): SetOpportunityActiveTab => ({
  type: OpportunityReportActionTypes.setOpportunityActiveTab,
  payload: tab,
});

interface SetOpportunitySorting {
  type: OpportunityReportActionTypes.setOpportunitySorting;
  payload: any;
}

export const setOpportunitySorting = (sort: { sortBy: string; sortOrder: number }): SetOpportunitySorting => ({
  type: OpportunityReportActionTypes.setOpportunitySorting,
  payload: sort,
});

interface SetOpportunitySearchFilter {
  type: OpportunityReportActionTypes.setOpportunitySearchFilter;
  payload: any;
}

export const setOpportunitySearchFilterDispatch = (search: string): SetOpportunitySearchFilter => ({
  type: OpportunityReportActionTypes.setOpportunitySearchFilter,
  payload: search,
});

interface SetOpportunitySearchReset {
  type: OpportunityReportActionTypes.setOpportunitySearchReset;
}

export const setOpportunitySearchResetDispatch = (): SetOpportunitySearchReset => ({
  type: OpportunityReportActionTypes.setOpportunitySearchReset,
});

export type OpportunityReportReducerActionType =
  | ReSetOpportunityReportFilter
  | SetOpportunityReportFilter
  | SetOpportunityProductLoading
  | SetOpportunityActiveTab
  | SetOpportunitySearchFilter
  | SetOpportunitySearchReset
  | SetOpportunitySorting;
