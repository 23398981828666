import * as React from "react";
import classes from "./index.module.css";
import MTypography from "../../../../Atoms/MTypography";
import { useDispatch, useSelector } from "react-redux";
import { CompleteProductSelector } from "../../../../../store/product/productDetails/productDetails.selectors";
import { setHighlightedKeywordAction } from "../../../../../store/product/productDetails/productDetails.actions";
import { filterKeywordsUsingEdited } from "../../../../../pages/Product/ProductDetails/config";
import { DbUserSelector, UserRoleSelector } from "../../../../../store/user/user.selector";
import { checkIsTrailCompany, checkIsUser } from "../../../../../utils/helpers/priviligesChecks";
import PricingContext from "../../../../../context/PricingContext";
import { CompanyRoleSelector, CompanySelector } from "../../../../../store/company/company.selector";
import { RolesEnum } from "../../../../../utils/constants/enums";
import ToastError from "../../../../Atoms/ToastError";
import { toast } from "react-toastify";
import { UnlockGreaterPossibilities } from "../../../../../utils/constants/messages/errors";

interface DetailsKeywordsMProps {
  isTitle?: boolean;
}

const DetailsKeywordsM: React.FC<DetailsKeywordsMProps> = ({ isTitle }) => {
  const dispatch = useDispatch();
  const product = useSelector(CompleteProductSelector);
  const { selectedHelium10Keywords } = product;
  const currentUser = useSelector(DbUserSelector);
  const role = useSelector(UserRoleSelector);
  const companyRole = useSelector(CompanyRoleSelector);
  const { onOpen } = React.useContext(PricingContext);

  const currentCompany = useSelector(CompanySelector);
  const [showPricingPlan] = React.useState<boolean>(() =>
    currentUser?.companyID ? checkIsTrailCompany(currentCompany?.role) : checkIsUser(currentUser?.role)
  );

  let keywords = selectedHelium10Keywords?.length ? selectedHelium10Keywords : [];

  if (isTitle) {
    keywords = keywords?.filter((keyword) => keyword?.existInTitle);
    if (role !== RolesEnum.USER && companyRole !== RolesEnum.TRIAL) {
      keywords = filterKeywordsUsingEdited({ keywords, product, isTitle });
    }
  } else {
    keywords = keywords?.filter((keyword) => !keyword?.existInTitle);
    if (role !== RolesEnum.USER && companyRole !== RolesEnum.TRIAL) {
      keywords = filterKeywordsUsingEdited({ keywords, product, isTitle: false });
    }
  }

  const setHighlightedKeyword = (phrase: string) => {
    dispatch(setHighlightedKeywordAction(product, phrase));
  };

  const showUpgradeMessage = () => {
    if (currentUser?.companyID) {
      if (currentUser?.role === RolesEnum.COMPANY_ADMIN) {
        onOpen();
      } else {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
      }
    } else {
      onOpen();
    }
  };

  return (
    <div>
      {keywords?.length ? (
        keywords.map((keyword, index) => (
          <div className={classes.KeywordsCardM} key={index}>
            <div className={"d-flex flex-column p-3"}>
              <div
                className={`d-flex'}`}
                onClick={() => (showPricingPlan ? showUpgradeMessage() : setHighlightedKeyword(keyword.phrase))}
              >
                <MTypography
                  variant={"subtitle1"}
                  customClass={`${classes.KeywordCardTitleM} ${isTitle && classes.TitlePhraseCell}`}
                >
                  {keyword?.phrase || keyword}
                </MTypography>
              </div>
              <div className={"d-flex mt-3 justify-content-between"}>
                <div className={"d-flex flex-column"}>
                  <MTypography variant={"subtitle1"} customClass={`${classes.KeywordsRecordTitleM}`}>
                    <span className={""}>{keyword?.searchVolume || "-"}</span>
                  </MTypography>
                  <MTypography variant={"subtitle1"} customClass={classes.KeywordsRecordTextM}>
                    Search Volume
                  </MTypography>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={"d-flex justify-content-center align-items-center w-100 h-100 p-2"}>
          <MTypography variant={"subtitle2"}>{"No keywords to display"}</MTypography>
        </div>
      )}
    </div>
  );
};
export default DetailsKeywordsM;
