import React, { memo } from "react";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { showUserEmailInProductsPage } from "../../../../utils/helpers/priviligesChecks";
import { DbUserSelector } from "../../../../store/user/user.selector";
import ProductTable from "../../../Organisms/SavedProducts/ProductDataGrid/ProductTable";

import { usePagination } from "../../../../hooks/usePagination";
import { AppState } from "../../../../store/reducers";
import { generateProductGridSkeleton } from "../../../../utils/helpers/skeleton";

const UserGridSkeleton = () => {
  const dbUser = useSelector(DbUserSelector);
  const products: any = generateProductGridSkeleton(30);
  const totalProducts = useSelector((state: AppState) => state.product.totalProducts);

  const { pageSize, setPageNumber, pageNumber, setPageSize, setSortModel } = usePagination(50);

  const productsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1, 
      renderCell: () => (
        <div className={"w-75"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: () => (
        <div className={"w-75"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: () => (
        <div className={"w-50"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-50"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-25"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
  ];


  
  return (
    <div>
      <ProductTable
        total={totalProducts}
        pageSize={pageSize}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        columns={productsColumns}
        rows={products ?? []}
        initialState={{
          columns: {
            columnVisibilityModel: {
              userEmail: showUserEmailInProductsPage(dbUser?.role),
            },
          },
        }}
        setSortModel={setSortModel}
        isRowSelectable={() => false}
        withoutPagination={true}
      />
    </div>
  );
};

export default memo(UserGridSkeleton);
