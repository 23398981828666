import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription,
  ProductApis,
} from "../../../../apis/product";
import { errorHandler } from "../../../../utils/helpers/apis";
import { navigatePage } from "../../../../utils/helpers/common";
import { URL_PRODUCTS } from "../../../../routes/routes-path";
import ActionBox from "../../../Molecules/ActionBox";
import { ProductFetchType } from "../../../../utils/constants/enums";
import { KeywordsApis } from "../../../../apis/keywords";
import { useSelector } from "react-redux";
import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import PricingContext from "../../../../context/PricingContext";
import { checkIsCompanyReachedHeliumOrContentRegenLimit, checkIsProductOwnerReachedHisLimit } from "../../../../pages/SavedProducts/config";
import { DbUserSelector } from "../../../../store/user/user.selector";
import { UnlockGreaterPossibilities, UserReachedRegenLimit } from "../../../../utils/constants/messages/errors";
import ToastError from "../../../Atoms/ToastError";

interface InCompleteProductErrorProps {
  open: boolean;
  refetch?: any;
}
const InCompleteProductError: React.FC<InCompleteProductErrorProps> = ({ open: openDialog }) => {
  const navigate = useNavigate();
  const product = useSelector(CompleteProductSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(openDialog);
  const dbUser = useSelector(DbUserSelector);
  const { onOpen } = useContext(PricingContext);
  
  // const handleBack = () => {
  //   navigate(-1);
  // };
  const handleShowMeAnyway = () => {
    setOpen(false);
  }
  
  const reGenerateKeywords = async () => {
    const keywords = await KeywordsApis.getKeywords({ productAsin: product?.productASIN, userID: product?.userID });
    if (keywords?.selectedHelium10Keywords?.length < 5) {
      toast.warn('Not enough keywords for the content generation');
      setOpen(false);
    } else {
      setIsLoading(true);
      if (product.productType === ProductFetchType.GOGO) {
        ProductApis.gogoAutomatoProductGeneration({
          domain: product.domain as string,
          asin: product.productASIN,
          userID: product?.userID,
          isRerun: true,
          updateCompetitors: true
        });
        setIsLoading(false);
        setOpen(false);
        navigatePage(URL_PRODUCTS, navigate);
      } else {
        try {

          const titleKeywords = keywords?.titleKeywords && keywords?.titleKeywords?.map((e: any) => ({ phrase: e.phrase, searchVolume: e.searchVolume }));
          const otherKeywords = keywords?.otherKeywords && keywords?.otherKeywords?.map((e: any) => ({ phrase: e.phrase, searchVolume: e.searchVolume }));

          const reGenPayload = {
            productASIN: product.productASIN,
            titleKeywords: titleKeywords,
            otherKeywords: otherKeywords,
            searchTermKeywords: [
              ...(keywords?.selectedHelium10Keywords?.map((keyword: any) => keyword.phrase) || []),
              ...(keywords?.otherKeywords?.map((keyword: any) => keyword.phrase) || [])
            ].filter(Boolean),
            userID: product.userID,
            isRerun: true,
          }
          generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription(reGenPayload)
            .then(() => {
              setIsLoading(false);
              setOpen(false);
              navigatePage(URL_PRODUCTS, navigate);
            })
            .catch((e) => {
              toast.error(errorHandler(e));
              setIsLoading(false);
            });
        } catch (e: any) {
          toast.error(errorHandler(e));
          setIsLoading(false);
        }
      }
    }
  };

  const handleRegeneration = async () => {
    setOpen(false);
    // const isLimitReached = await checkIsProductOwnerReachedHisLimit(product?.userID)
    const isLimitReached = product?.companyID
      ? await checkIsCompanyReachedHeliumOrContentRegenLimit(product?.companyID)
      : await checkIsProductOwnerReachedHisLimit(product?.userID);

    if (isLimitReached) {
      if (dbUser?._id === product?.userID) {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
        onOpen();
      } else if (dbUser?._id !== product?.userID) {
        toast.error(UserReachedRegenLimit);
      }else{}
    } else {
      reGenerateKeywords();
    }
  }

  return (
    <ActionBox
      // handleAction={reGenerateKeywords}
      handleAction={handleRegeneration}
      // this is currently not using as a back handle handleBack function is avialable up here commented
      handleBack={handleShowMeAnyway}
      open={open}
      actionText={"Regenerate"}
      message={"An error occurred during the product content generation process. Please try again."}
      title={"Unable to generate content"}
      // this is currently not using as a back text
      backText={"Show me Anyway"}
      isLoading={isLoading}
    />
  );
};

export default InCompleteProductError;
