import React from "react";
import { Formik, Form } from "formik";
import { BottomNavigation, Paper } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import MButton from "../../../Atoms/MButton";
import FormTextField from "../../../Molecules/FormTextField";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { CustomKeywordsObject, initialValue, inputName, validationSchema } from "./config";
import Space from "../../../Atoms/Space";
import {
  generateKeywordsDebugMode,
  resetSelectedPrioritizeKeywordsDispatch,
} from "../../../../store/product/product.actions";
import { ParamsEnum, TabsEnum } from "../../../../utils/constants/enums";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import { errorHandler } from "../../../../utils/helpers/apis";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  closeManual: () => void;
  onOpen: () => void;
  onClose: () => void;
  setIsLoading: (status: boolean) => void;
  isLoading: boolean;
}
const CustomKeywordForm: React.FC<Props> = (props) => {
  const { closeManual, setIsLoading, onOpen, onClose, isLoading = false } = props;
  const { isDesktop } = useDeviceType();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const submitForm = (values: CustomKeywordsObject) => {
    onOpen();
    setIsLoading(true);
    dispatch(
      generateKeywordsDebugMode({
        productASIN: params[ParamsEnum.ASIN] || "",
        title: values.title,
        bulletPoints: values.bulletPoints,
        keywords: values.keywords,
        description: values.description,
      })
    )
      .then(() => {
        onClose();
        setIsLoading(false);
        dispatch(resetSelectedPrioritizeKeywordsDispatch());
        navigate(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, { replace: true });
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValue} onSubmit={submitForm} validationSchema={validationSchema}>
        {({ dirty, isValid, handleSubmit }) => (
          <Form autoComplete="off">
            <div className={isDesktop ? "d-flex flex-column" : "d-flex flex-column px-3"}>
              <FormTextField name={inputName.title} label={"Title *"} placeholder={"Title"} />
              <FormTextField
                name={inputName.description}
                label={"Description *"}
                placeholder={"Description"}
                multiline
                rows={3}
              />
              <FormTextField
                name={inputName.bulletPoints}
                label={"Bullet Points *"}
                placeholder={"Enter Bullet Points with , separate like (Bullet 1,Bullet 2,)"}
                multiline
                rows={3}
              />
              <FormTextField
                name={inputName.keywords}
                label={"Keywords *"}
                placeholder={"Enter keywords with , separate like (Keyword 1,Keyword 2,)"}
                multiline
                rows={3}
              />
              {!isDesktop && <Space bottom={"120px"} />}
              {isDesktop ? (
                <div className={"d-flex justify-content-end my-4"}>
                  <MButton onClick={closeManual} width={"large"} type={"button"} variant={"text"} fullWidth>
                    Cancel
                  </MButton>
                  <MButton
                    width={"large"}
                    type={"button"}
                    onClick={handleSubmit}
                    disabled={!dirty || !isValid}
                    fullWidth
                    isLoading={isLoading}
                  >
                    Submit
                  </MButton>
                </div>
              ) : (
                <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, height: "100px" }} elevation={3}>
                  <BottomNavigation
                    showLabels
                    sx={{
                      position: "absolute",
                      top: "25%",
                      left: "4%",
                      width: "92%",
                    }}
                  >
                    <MButton onClick={closeManual} width={"large"} size={"small"} variant={"text"}>
                      Cancel
                    </MButton>
                    <MButton
                      isLoading={isLoading}
                      width={"large"}
                      size={"small"}
                      variant={"contained"}
                      rootClass={"comp-button-mb"}
                      onClick={handleSubmit}
                      disabled={!dirty || !isValid}
                    >
                      Submit
                    </MButton>
                  </BottomNavigation>
                </Paper>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomKeywordForm;
