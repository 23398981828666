import axios from "../axios";
import { errorHandler } from "../utils/helpers/apis";

// import { CreateBrandObject } from "../components/Organisms/BrandSettings/BrandAliasManagement/AddBrandModal/config";

export const BrandApis = {
  async getAllBrands(brandsPayload: any) {
    return axios
      .post<any, any>(`brand`, {
        ...brandsPayload,
      })
      .then((r: any) => r.data.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
  async createBrand(brand: any) {
    return axios
      .post<any, any>(`brand/create`, {
        ...brand,
      })
      .then((r: any) => r.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
  async updateBrand(brand: any) {
    return axios
      .put<any, any>(`brand/update`, {
        ...brand,
      })
      .then((r: any) => r.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
};
