import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

interface Props {
  icon?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  name: string;
  placeholder?: string;
  label?: string;
  position?: "start" | "end";
  margin?: "dense" | "normal" | "none";
  value: any;
  rootClass?: any;
  type?: "number" | "text" | "password";
}

const Icons: any = {
  search: <Search />,
};

const MTextField: React.FC<Props> = (props) => {
  const {
    value = "",
    type = "text",
    onChange,
    name,
    placeholder = "",
    label,
    position = "start",
    icon = "",
    margin = "normal",
    rootClass = {},
  } = props;

  const inputProps: any = {
    [position === "start" ? "startAdornment" : "endAdornment"]: (
      <InputAdornment position={position} disablePointerEvents>
        <IconButton>{Icons[icon]}</IconButton>
      </InputAdornment>
    ),
  };

  return (
    <TextField
      name={name}
      placeholder={placeholder}
      label={label}
      onChange={onChange}
      InputProps={inputProps}
      margin={margin}
      value={value}
      classes={{ root: rootClass }}
      type={type}
    />
  );
};

export default MTextField;
