import app from "./firebase";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  fetchSignInMethodsForEmail,
  User,
} from "firebase/auth";
import { toast } from "react-toastify";
import { SUCC_LOG_OUT } from "../utils/constants/messages/success";
import { ERR_LOG_OUT } from "../utils/constants/messages/errors";
import { URL_ACCOUNT_STATUS, URL_SIGN_IN } from "../routes/routes-path";

export const firebaseSignInWithEmailAndPassword = async (email: string, password: string) =>
  await signInWithEmailAndPassword(getAuth(app), email, password);

export const firebaseUserToken = async () => {
  await new Promise((resolve) => {
    const unsubscribe = getAuth(app).onAuthStateChanged((user) => {
      unsubscribe(); // Unsubscribe to avoid memory leaks
      resolve(user);
    }); 
  });

  const user = getAuth(app).currentUser;
  if (user) {
    return await user.getIdTokenResult();
  } else {
    // User is not logged in
    sessionStorage.clear();
    localStorage.clear();
    return null;
  }
};

export const firebaseSetBrowserLocalPersistence = async () =>
  await setPersistence(getAuth(app), browserLocalPersistence);

export const firebaseSetSessionPersistence = async () => await setPersistence(getAuth(app), browserSessionPersistence);

export const firebaseCheckIsEmailAlreadyExist = async (email: string) => await fetchSignInMethodsForEmail(getAuth(app), email);

export const firebaseSignOut = async () => await signOut(getAuth(app));

export const firebaseSendPasswordResetEmail = async (email: string) =>
  await sendPasswordResetEmail(getAuth(app), email, { url: `${window.location.origin}${URL_SIGN_IN}` });

export const firebaseCreateUserWithEmailAndPassword = async (email: string, password: string) =>
  await createUserWithEmailAndPassword(getAuth(app), email, password);

export const firebaseDeleteUser = async () => {
  const auth = getAuth(app);
  const user = auth.currentUser;
  if (user) await deleteUser(user);
};

export const firebaseUpdateUserName = async (userName: string) => {
  const currentUser = getAuth(app).currentUser;

  if (currentUser)
    return await updateProfile(currentUser, {
      displayName: userName,
    });
};

export const firebaseEmailVerification = async (firebaseUser: User) =>
  await sendEmailVerification(firebaseUser, {
    url: window.location.hostname,
    handleCodeInApp: true,
  });

export const signOutHandler = (showSuccessMessage = true) => {
  firebaseSignOut()
    .then(() => {
      sessionStorage.clear();
      localStorage.clear();
      if (window.location.pathname !== URL_SIGN_IN) {
        window.location.href = URL_ACCOUNT_STATUS;
      }
      if(showSuccessMessage){
        toast.success(SUCC_LOG_OUT);
      }
    })
    .catch(() => {
      toast.error(ERR_LOG_OUT);
    });
};

