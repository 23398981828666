import * as Yup from "yup";
import { aiModelEnums } from "../../../utils/constants/enums";

export const validationSchema = Yup.object({
    competitorRelevance: Yup.string().required('Please Select AI Model'),
    keywordRelevance: Yup.string().required('Please Select AI Model'),
    keywordUsability: Yup.string().required('Please Select AI Model'),
    keywordBrandFilter: Yup.string().required('Please Select AI Model'),
    contentGeneration: Yup.string().required('Please Select AI Model'),
})

export const initialValues = {
    competitorRelevance: aiModelEnums.GPT4,
    keywordRelevance: aiModelEnums.GPT4,
    keywordUsability: aiModelEnums.GPT4,
    keywordBrandFilter: aiModelEnums.GPT4,
    contentGeneration: aiModelEnums.GPT4,
}