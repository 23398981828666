import axios from "../axios";
import { } from "./types/apiAccessKey";
import { errorHandler } from "../utils/helpers/apis";
import { toast } from "react-toastify";
import { UpdateAiModelPayload } from "./types/aiModel";

export const AiModelApis = {
    async getAiModel() {
        return axios
            .get<any, any>(`/aimodel`)
            .then((r: any) => r.data.data)
            .catch(errorHandler);
    },
    async updateAiModel(payload: UpdateAiModelPayload) {
        return axios
            .post<any, any>(`/aimodel`, payload)
            .then((r: any) => {
                return r.data.data
            })
            .catch((error: any) => {
                throw toast.error(errorHandler(error));
            });
    },
};