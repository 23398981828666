import { Box, Skeleton } from "@mui/material";
const TeamMembersCardSkeletonM = () => {
  const getSkeleton = () => {
    const skeletons = [];
    for (let index = 0; index < 10; index++) {
      skeletons.push(
        <div className={"card flex-row mt-4 cursor-pointer p-3 m-3"} key={index}>
          <div className="d-flex flex-row w-100 gap-2">
            <div className="w-100">
              <Box component={"div"} sx={{ width: "100%" }}>
                <Skeleton variant="text" width={"50%"} height={30} />
              </Box>
              <div className={"d-flex justify-content-between align-items-center mt-3 mb-2 gap-5"}>
                <Box component={"div"} sx={{ width: "50%" }}>
                  <Skeleton variant="text" width={"100%"} height={30} />
                  <Skeleton variant="text" width={"50%"} height={20} />
                </Box>
                <Box component={"div"} sx={{ width: "50%" }}>
                  <Skeleton variant="text" width={"100%"} height={30} />
                  <Skeleton variant="text" width={"50%"} height={20} />
                </Box>
              </div>
              <div className={"d-flex justify-content-between align-items gap-5"}>
                <Box component={"div"} sx={{ width: "50%" }}>
                  <Skeleton variant="text" width={"100%"} height={30} />
                  <Skeleton variant="text" width={"50%"} height={20} />
                </Box>
                <Box component={"div"} sx={{ width: "50%" }}>
                  <Skeleton variant="text" width={"100%"} height={30} />
                  <Skeleton variant="text" width={"50%"} height={20} />
                </Box>
                <Box component={"div"} sx={{ width: "50%" }}>
                  <Skeleton variant="text" width={"100%"} height={30} />
                  <Skeleton variant="text" width={"50%"} height={20} />
                </Box>
              </div>
              <Box component={"div"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%", pt: 1, mt: 2, borderTop: '1px solid #c7c8c9', }}>
                <Skeleton variant="text" width={"40%"} height={30} />
                <Skeleton variant="text" width={"30%"} height={20} />
              </Box>
            </div>
          </div>
        </div>
      );
    }
    return skeletons;
  };

  return <>{getSkeleton()}</>;
};

export default TeamMembersCardSkeletonM;
