import React from "react";
import { useNavigate } from "react-router";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import cross from "../../../../assets/svgs/cross-logo.svg";
import { navigatePage } from "../../../../utils/helpers/common";
import { TabsEnum } from "../../../../utils/constants/enums";

const CrossElement = () => {
  const navigate = useNavigate();
  return (
    <img
      src={cross}
      className={"cursor-pointer"}
      alt="cross"
      onClick={() => navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate)}
    />
  );
};

export default CrossElement;
