import React, { memo, useEffect, useState } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MTypography from '../../Atoms/MTypography';
import classes from './index.module.css';
import MuiTooltip from '../../Atoms/MuiTooltip';
import KeywordsTable from './KeywordsTable/KeywordsTable';
import { useSelector } from 'react-redux';
import { CompleteProductSelector } from '../../../store/product/productDetails/productDetails.selectors';
import { acceptableTitleCount, calculateKeywordDensities } from '../../../utils/helpers/keywordDensityHelper/keywordDensityChecker';
import { ContentStatusEnum, SwipeableTabTitlesEnums } from '../../../utils/constants/enums';
import { setHighlightedKeywordAction } from '../../../store/product/productDetails/productDetails.actions';
import { useDispatch } from 'react-redux';
import { useDeviceType } from '../../../hooks/useDeviceType';
import MBottomSheet from '../../Atoms/MBottomSheet';
import KeywordsDensityCardsM from './KeywordsDensityCardsM/KeywordsDensityCardsM';
import CloseIcon from "@mui/icons-material/Close";

export interface DensitiesList {
    word: string;
    occurrences: number;
    density: number;
}
export interface KeywordsDensityList {
    densities: DensitiesList[];
    isAnyExceeded: boolean;
}

interface KeywordDensityProps {
    title: string;
}

const KeywordDensity: React.FC<KeywordDensityProps> = (props) => {

    const { title } = props;

    const product = useSelector(CompleteProductSelector);
    const [densityList, setDensityList] = useState<DensitiesList[]>([])
    const [isLimitExceed, setIsLimitExceed] = useState<boolean>(false);
    const [openMBottomSheet, setOpenMBottomSheet] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { isDesktop } = useDeviceType();

    const getKeywordDensityData = (requiredFor: string) => {

        if (product.status !== undefined || product.status !== ContentStatusEnum.ERROR) {
            let data: KeywordsDensityList = { densities: [], isAnyExceeded: false }
            // for edited only 
            if (product.status === ContentStatusEnum.EDITED) {
                if (requiredFor === SwipeableTabTitlesEnums.TITLE) {
                    data = calculateKeywordDensities(product?.editedGeneratedProductContent?.title)
                    const isTitleKeywordCountExceed = data?.densities?.some((item: any) => item?.occurrences > acceptableTitleCount);
                    data = { ...data, isAnyExceeded: isTitleKeywordCountExceed }
                } else if (requiredFor === SwipeableTabTitlesEnums.BULLET_POINTS) {
                    data = calculateKeywordDensities(product?.editedGeneratedProductContent?.bulletPoints)

                } else if (requiredFor === SwipeableTabTitlesEnums.DESCRIPTION) {
                    data = calculateKeywordDensities(product?.editedGeneratedProductContent?.description)
                } else {
                    data = { densities: [], isAnyExceeded: false }
                }
            } else {
                // for draft and approved
                if (requiredFor === SwipeableTabTitlesEnums.TITLE) {
                    data = calculateKeywordDensities(product?.generatedTitle)
                    const isTitleKeywordCountExceed = data?.densities?.some((item: any) => item?.occurrences > acceptableTitleCount);
                    data = { ...data, isAnyExceeded: isTitleKeywordCountExceed }
                } else if (requiredFor === SwipeableTabTitlesEnums.BULLET_POINTS) {
                    data = calculateKeywordDensities(product?.bulletPointsByChat)
                } else if (requiredFor === SwipeableTabTitlesEnums.DESCRIPTION) {
                    data = calculateKeywordDensities(product?.generatedDescription)
                } else {
                    data = { densities: [], isAnyExceeded: false }
                }
            }
            return data;

        } else {
            return { densities: [], isAnyExceeded: false }
        }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const dataList = getKeywordDensityData(title);
        setDensityList(dataList.densities)
        setIsLimitExceed(dataList.isAnyExceeded)
        return () => setDensityList([])
    }, [product?.status])

    const onKeywordClick = (phrase: string, highlightClass: string) => {
        dispatch(setHighlightedKeywordAction(product, phrase, highlightClass));
        if (!isDesktop) {
            closeBottomSheet()
        }
    }

    // for mobile
    const openBottomSheet = () => {
        setOpenMBottomSheet(true);
    }
    const closeBottomSheet = () => {
        setOpenMBottomSheet(false);
    }
    
    return (
        <div className='cursor-pointer'>
            {isDesktop ?
                <MuiTooltip
                    arrow={true}
                    content={<KeywordsTable keywordsDensityList={densityList} onKeywordClick={onKeywordClick} title={title} />}
                    placement='right'
                >
                    <div className='d-flex align-items-center gap-1' style={{ color: isLimitExceed ? '#F93939' : '#0CC683' }}>
                        {isLimitExceed ? <ErrorOutlineRoundedIcon fontSize='small' color='inherit' /> : <CheckCircleOutlineRoundedIcon fontSize='small' color='inherit' />}
                        <MTypography variant={"subtitle1"} customClass={classes.KeywordDensity} color='inherit'>
                            Keyword Density
                        </MTypography>
                    </div>
                </MuiTooltip>
                :
                <div className='d-flex align-items-center gap-1' style={{ color: isLimitExceed ? '#F93939' : '#0CC683' }}>
                    {isLimitExceed ? <ErrorOutlineRoundedIcon fontSize='small' color='inherit' /> : <CheckCircleOutlineRoundedIcon fontSize='small' color='inherit' />}
                    <div onClick={openBottomSheet}>
                        <MTypography variant={"subtitle1"} customClass={classes.KeywordDensity} color='inherit'>
                            Density
                        </MTypography>
                    </div>
                    <MBottomSheet open={openMBottomSheet} showFullHeight>
                        <div className={"d-flex justify-content-between align-items-center mb-3"}>
                            <MTypography variant={"subtitle1"} customClass={classes.BottomSheetTitle}>
                                {"Keyword Density"}
                            </MTypography>
                            <CloseIcon onClick={closeBottomSheet} className={"mx-2 d-flex align-items-center"} />
                        </div>
                        <KeywordsDensityCardsM keywordsDensityList={densityList} onKeywordClick={onKeywordClick} title={title} />
                    </MBottomSheet>
                </div>
            }
        </div>
    )
}

export default memo(KeywordDensity);