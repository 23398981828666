import React, { MouseEvent, useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";

import MTypography from "../../../Atoms/MTypography";

import EventCardContainer from "./EventCardContainer";
import EventFilters from "./EventFilters";
import { eventFilterOptions } from "./config";
import classes from "./index.module.css";
import EventsDrawer from "./EventsDrawer";
import { useSelector } from "react-redux";
import {
  CompleteProductSelector,
  ProductEventsHistoryMessageSelector,
  ProductEventsHistorySelector,
} from "../../../../store/product/productDetails/productDetails.selectors";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getProductEventsHistoryAction } from "../../../../store/product/productDetails/productDetails.actions";
import { ProductEventsListInterface, ProductEvent } from "../../../../apis/types/generate-product";
import { errorHandler } from "../../../../utils/helpers/apis";
import { toast } from "react-toastify";
import ProductEventsWaitingCards from "../../../Molecules/AnimatedSkeletonCard/ProductEventsWaitingCards";
import { Divider } from "@mui/material";
import { capitalizeFirstLetter } from "../../../../utils/helpers/strings";

const ProductEventsHistory = () => {
  const [showEventFilters, setShowEventFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState<string[]>(
    eventFilterOptions
      .filter(e => e.value !== "Competitor Price")
      .map(e => e.value)
  );

  const completeProduct = useSelector(CompleteProductSelector);
  const productEventsList = useSelector(ProductEventsHistorySelector);
  const productEventsMessage = useSelector(ProductEventsHistoryMessageSelector);

  const [productEventsListLocalState, setProductEventsListLocalState] =
    useState<ProductEventsListInterface>(productEventsList);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setProductEventsListLocalState(productEventsList);
    handleApplyFilter(false, appliedFilters);
  }, [productEventsList]);

  const handleFilterClick = (e: MouseEvent<HTMLDivElement>) => {
    setShowEventFilters(!showEventFilters);
    setAnchorEl(e.currentTarget);
  };

  const dispatch = useDispatch();

  const productsEventsHistoryCall = (monthlyHistory: boolean) => {
    setIsLoading(true);

    const startDate = moment().subtract(6, "months").startOf("day");
    const endDate = moment().endOf("day");

    let payload: any = {
      productAsin: completeProduct.productASIN,
      userID: completeProduct?.userID,
    };

    if (monthlyHistory) {
      payload = {
        ...payload,
        startDate: startDate,
        endDate: endDate,
      };
    }

    dispatch(getProductEventsHistoryAction(payload))
      .then(() => {
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        toast.error(errorHandler(e));
      });
  };

  useEffect(() => {
    if (completeProduct.productASIN) {
      productsEventsHistoryCall(true);
    }
  }, []);

  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
    handleApplyFilter(false, appliedFilters);
    if (open) {
      productsEventsHistoryCall(false);
    }
  };

  const handleApplyFilter = (resetFilters: boolean = false, filters: string[]) => {
    if (resetFilters || !filters.length) {
      setProductEventsListLocalState(productEventsList);
    } else {
      const filteredData: any = {};

      Object.entries(productEventsList).forEach(([key, events]: any) => {
        filteredData[key] = events?.filter((event: ProductEvent) => {
          return event.tag ? filters.includes(event.tag) : false;
        });
      });

      setProductEventsListLocalState(filteredData);
    }
    setAppliedFilters(filters);
    setShowEventFilters(false);
  };

  return (
    <>
      <div className={classes.ProductEventsContainer}>
        <div>
          <div className={`${classes.TitleBarWithFilter}`}>
            <MTypography variant="body1" customClass={classes.ProdcutEventsTitle}>
              Product Events
            </MTypography>
            <MTypography variant="body1" customClass={classes.ProdcutEventsDescription}>
              {productEventsMessage.titleText}
            </MTypography>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div
              className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer HoverEffectForButton`}
              onClick={handleFilterClick}
            >
              <FilterListIcon fontSize={"small"} />
              <MTypography variant={"subtitle1"} color="inherit" customClass={classes.Filters}>
                {"Filters"}
              </MTypography>
            </div>
            <EventFilters
              open={showEventFilters}
              onClose={() => setShowEventFilters(false)}
              options={eventFilterOptions}
              anchorEl={anchorEl}
              handleApplyFilter={handleApplyFilter}
              appliedFilters={appliedFilters}
            />
            <div className={`${classes.SeeAll} HoverEffectForButton`} onClick={() => toggleDrawer(true)}>
              See All
            </div>
          </div>
          <Divider sx={{ mt: "8px", mb: "16px", opacity: 1 }} />
        </div>
        <div className={classes.EventsContaienr}>
          <>
            {isLoading ? (
              <ProductEventsWaitingCards />
            ) : (
              <>
                {Object.values(productEventsListLocalState)?.some((items: any[]) => items.length > 0) ? (
                  Object.entries(productEventsListLocalState || {})?.map(([key, items]: any) => (
                    <div key={key}>
                      {items.length ? (
                        <>
                          <div className="mb-2">
                            <MTypography variant="body2" customClass={classes.Title}>
                              {capitalizeFirstLetter(key || "")}
                            </MTypography>
                          </div>

                          {items.map((event: ProductEvent, index: number) => (
                            <div key={index}>
                              <EventCardContainer event={event} isActive={key === "today"} />
                            </div>
                          ))}
                          <Divider sx={{ my: "16px", opacity: 1 }} />
                        </>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))
                ) : (
                  <MTypography variant="subtitle1" customClass={classes.NoEventsAvailable}>
                    {productEventsMessage.noEventsText || "No events available"}
                  </MTypography>
                )}
              </>
            )}
          </>
        </div>
      </div>
      <EventsDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        handleFilterClick={handleFilterClick}
        isLoading={isLoading}
        productEventsList={productEventsListLocalState}
      />
    </>
  );
};

export default ProductEventsHistory;
