import classes from "./index.module.css";
import CrossElement from "../AppBar/crossElement";

import MTypography from "../../Atoms/MTypography";
import React from "react";


interface Props {
  donut: string;
  title: string;
  next: string;
  tabNumber: number;
}

const FetchProductHeader: React.FC<Props> = (props) => {
  const { donut, next, title, tabNumber } = props;

  return (
    <div className="mw-100">
      <div className={"card p-2 " + classes.FetchProductCardM}>
        <div className={"card-header" + classes.FetchProductCardHeaderM}>
          <div className={"d-flex justify-content-end"}>
            <CrossElement />
          </div>
        </div>
        <div className="card-body py-1">
          <div className={"d-flex"}>
            <div className={"position-relative"}>
              <img src={donut} alt={"product-donut"} />
              <MTypography variant={"h5"} customClass={classes.FetchProductDonutTextM}>
                {tabNumber} of 4
              </MTypography>
            </div>
            <div className={"d-flex flex-column"} style={{ marginTop: "20px", marginLeft: "12px" }}>
              <MTypography variant={"h5"} customClass={classes.FetchProductCardBodyTitle}>
                {title}
              </MTypography>
              <MTypography variant={"body2"} customClass={classes.FetchProductCardBodyDescription}>
                {next === "optional" ? <span className={classes.OptionalTag}>{next}</span> : `Next: ${next}`}
              </MTypography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FetchProductHeader;
