import * as yup from "yup";

export interface InviteUserObject {
  id: number;
  email: string;
}

export const inputName = {
  email: "email",
};

export const initialValue: InviteUserObject = {
  id: 0,
  email: "",
};

export interface InviteUser {
  invites: InviteUserObject[];
}

export const initialFormValue = {
  invites: [initialValue],
};

export const validationSchema = yup.object().shape({
  email: yup.string().trim().required("Email is required"),
  // role: yup.string().trim().required("Role  is required"),
});
