import { Box, Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import OpportunityFilterContainer from "../../../Molecules/OpportunityFilterContainer/OpportunityFilterContainer";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import MBottomSheet from "../../../Atoms/MBottomSheet";

interface Marks {
  label: string;
  value: any;
}
interface Props {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  handleFilterChange: (hideLastLive: boolean, hideKeywordCoverage: boolean, keywordExceedLimit: number) => void;
  hideLastLiveProducts: boolean;
  hideKeywordCoverageExceed: boolean;
  keywordExceedLimit: number;
  marks: Marks[];
}

const OpportunityFilterMenu: React.FC<Props> = (props) => {
  const {
    open,
    anchorEl,
    onClose,
    handleFilterChange,
    hideKeywordCoverageExceed,
    hideLastLiveProducts,
    keywordExceedLimit,
    marks,
  } = props;

  const [hideLastLive, setHideLastLive] = useState(true);
  const [hideKeywordCoverage, setHideKeywordCoverage] = useState(true);
  const [sliderValue, setSliderValue] = useState<number>(30);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setHideLastLive(hideLastLiveProducts);
    setHideKeywordCoverage(hideKeywordCoverageExceed);
    setSliderValue(keywordExceedLimit);
  }, [open]);

  const handleSliderChange = (event: any) => {
    setSliderValue(event.target.value);
  };

  const handleClick = (clickType: string) => {
    if (clickType === "applyFilters") {
      handleFilterChange(hideLastLive, hideKeywordCoverage, sliderValue);
      onClose();
    } else {
      onClose();
      setHideLastLive(hideLastLiveProducts);
      setHideKeywordCoverage(hideKeywordCoverageExceed);
      setSliderValue(keywordExceedLimit);
    }
  };

  const { isDesktop } = useDeviceType();

  if (isDesktop) {
    return (
      <>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ padding: "8px 16px", maxWidth: "300px" }}
        >
          <OpportunityFilterContainer
            handleClick={handleClick}
            handleSliderChange={handleSliderChange}
            hideLastLive={hideLastLive}
            setHideLastLive={setHideLastLive}
            hideKeywordCoverage={hideKeywordCoverage}
            setHideKeywordCoverage={setHideKeywordCoverage}
            sliderValue={sliderValue}
            marks={marks}
          />
        </Menu>
      </>
    )
  } else {
    return (
      <MBottomSheet open={open} showFullHeight={true}>
        <Box pb={2}>
          <OpportunityFilterContainer
            handleClick={handleClick}
            handleSliderChange={handleSliderChange}
            hideLastLive={hideLastLive}
            setHideLastLive={setHideLastLive}
            hideKeywordCoverage={hideKeywordCoverage}
            setHideKeywordCoverage={setHideKeywordCoverage}
            sliderValue={sliderValue}
            marks={marks}
          />
        </Box>
      </MBottomSheet>
    )
  }
};

export default OpportunityFilterMenu;
