import { Grid } from "@mui/material";
import classes from '../index.module.css'
import { manageKeywordCoverageRatio, manageLastContentLive, manageSearchIncrease, manageVolumeChange } from "../../../Organisms/OpportunityReport/OpportunityGrid/config";
import ProductStatus from "../../ProductStatus";
import { ProductLiveStatusEnum } from "../../../../utils/constants/enums";


interface ProductStatsValuesProps {
    productDetail: any
}

const ProductStatsValues = ({ productDetail }: ProductStatsValuesProps) => {

    return (
        <Grid container justifyContent="space-between" rowSpacing={2} px={2} pb={1}>
            <Grid item xs={6} className={classes.StatsValue}>
                {manageSearchIncrease(productDetail?.searchIncrease, productDetail?.volumeChange)}
                <div className={classes.StatsTitle}>
                    Search Increase
                </div>
            </Grid>
            <Grid item xs={6} className={classes.StatsValue} pl={1}>
                {manageVolumeChange(productDetail?.volumeChange)}
                <div className={classes.StatsTitle}>
                    Volume Change
                </div>
            </Grid>
            <Grid item xs={6} className={classes.StatsValue}>
                {manageKeywordCoverageRatio(productDetail?.keywordCoverageRatio)}
                <div className={classes.StatsTitle}>
                    Keyword Coverage Ratio
                </div>
            </Grid>
            <Grid item xs={6} className={classes.StatsValue} pl={1}>
                {manageLastContentLive(productDetail?.lastLiveDate) === ProductLiveStatusEnum.NEVER ? (
                    <ProductStatus variant="small" type={ProductLiveStatusEnum.NEVER} />
                ) : (
                    manageLastContentLive(productDetail?.lastLiveDate)
                )}
                <div className={classes.StatsTitle}>
                    Last Content Live
                </div>
            </Grid>
        </Grid>
    )

}

export default ProductStatsValues;