import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { URL_PRODUCT_CATALOG } from "../routes/routes-path";
import { CatalogEnum } from "../utils/constants/enums";

export const useSidebarToggle = () => {
  const [showDrawer, setShowDrawerValue] = useState<boolean>(false);
  const location = useLocation();

  const setShowDrawer = useCallback(() => {
    setShowDrawerValue(prevStat => !prevStat);
  }, []);

  const hideSidebar = (currentUrl: string): boolean => {
    if (currentUrl.includes(`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}`)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if(hideSidebar(location.pathname)){
      setShowDrawerValue(false);
    }
  }, [location.pathname])
  

  return { showDrawer, setShowDrawer }
}