import MoreVertIcon from "@mui/icons-material/MoreVert";
import MuiPopover from "../../../../Atoms/Popover";
import MenuOption from "../../../../Molecules/MenuOption";
import { useState } from "react";
import { ContentStatusEnum } from "../../../../../utils/constants/enums";
import {
  showDeleteIconOnWaitingGrid,
  showDeleteIconOnWaitingGridCompany,
} from "../../../../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../../../store/user/user.selector";
import { CompanySelector } from "../../../../../store/company/company.selector";

const ActionMenu = (props: any) => {
  const { showMenu, setShowMenu, status, userEmail } = props;
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const dbUser = useSelector(DbUserSelector);
  const currentCompany = useSelector(CompanySelector);

  return (
    <>
      <MoreVertIcon
        onClick={(e) => {
          setShowMenu(!showMenu);
          setAnchorEl(e.currentTarget);
        }}
        color="secondary"
        style={{ cursor: "pointer", color: "rgb(230, 181, 64)" }}
      />
      <MuiPopover
        open={showMenu}
        anchorEl={anchorEl}
        onClose={() => {
          setShowMenu(false);
          setAnchorEl(null);
        }}
      >
        <div className={"py-2"}>
          {status === ContentStatusEnum.RESTRICTED && (
            <MenuOption>
              <div className={"cursor-pointer"} onClick={() => props.handleMenuClick("rerun")}>
                Rerun
              </div>
            </MenuOption>
          )}
          {status === ContentStatusEnum.RESTRICTED || !!dbUser?.companyID ? (
            <></>
          ) : (
            <MenuOption>
              <div
                className={"cursor-pointer"}
                onClick={() => props.handleMenuClick("edit")}
                style={{
                  pointerEvents: status !== ContentStatusEnum.RESTRICTED ? "all" : "none",
                  opacity: status !== ContentStatusEnum.RESTRICTED ? 1 : 0.5,
                }}
              >
                Edit
              </div>
            </MenuOption>
          )}
          {(showDeleteIconOnWaitingGrid(dbUser?.role, userEmail, dbUser?.email) || 
            showDeleteIconOnWaitingGridCompany(currentCompany?.role, dbUser?.role)) && (
            <MenuOption>
              <div className={"cursor-pointer"} onClick={() => props.handleMenuClick("delete")}>
                Delete
              </div>
            </MenuOption>
          )}
        </div>
      </MuiPopover>
    </>
  );
};

export default ActionMenu;
