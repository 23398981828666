import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import MTypography from "../../Atoms/MTypography";
import Space from "../../Atoms/Space";
import DragAndDrop from "./DragAndDrop";

import { useDeviceType } from "../../../hooks/useDeviceType";
import { doAndDontKWPPage } from "../../../pages/Product/keywords/config";

import classes from "./index.module.css";

const PrioritiseKeywords: React.FC = () => {
  const { isDesktop } = useDeviceType();
  const [isManual, setIsManual] = useState<boolean>(false);
  const [isDoAndDont, setIsDoAndDont] = useState<boolean>(false);

  const handleManualKeywords = () => {
    setIsManual(!isManual);
  };

  const toggleDoAndDont = () => {
    setIsDoAndDont(!isDoAndDont);
  };
  return (
    <div className={isDesktop ? "d-flex flex-column w-100" : classes.Container}>
      <Space top={isDesktop ? "18px" : "12px"} />
      <div className={"d-flex flex-column align-items-center"}>
        <MTypography variant={"h4"}>{"Arrange Keywords"}</MTypography>
        <div
          className={`${isDoAndDont ? classes.SelectTextContainerActive : classes.SelectTextContainer} `}
          onClick={toggleDoAndDont}
        >
          <MTypography customClass={isDoAndDont ? classes.CaptionActive : classes.Caption} variant={"subtitle2"}>
            {isDesktop ? "Drag " : "Add "} your desired keywords into your product title and bullet points + description keywords sections
          </MTypography>
          {isDoAndDont ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>

        {isDoAndDont && (
          <div className={`${isDesktop ? "" : "m-2 mx-3"}`}>
            {doAndDontKWPPage.map((item) => (
              <p key={item.title} className={classes.DoAndDontText}>
                {item.do ? "✅" : "❌"}
                <span className={item.do ? classes.DoText : classes.DontText}>{item.do ? "DO" : "DO NOT"}</span>{" "}
                {item.title}
              </p>
            ))}
          </div>
        )}
      </div>

      <Space top={"40px"} />
      <>
        <div className={isDesktop ? "d-flex flex-row w-100" : classes.MobileContainer}>
          <DragAndDrop isManual={isManual} closeManual={handleManualKeywords} />
        </div>
      </>
    </div>
  );
};
export default PrioritiseKeywords;
