import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import classes from "./index.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MTypography from "../../../Atoms/MTypography";
import ExportPerTab from "./ExportPerTabBox/ExportPerTab";
import ExportPerRow from "./ExportPerRowBox/ExportPerRow";
import { useDeviceType } from "../../../../hooks/useDeviceType";

type ExportAsinsDialogeProps = DialogProps & {
  title?: string;
  open: boolean;
  onClose: () => void;
  handleTypeClick: (exportType: string) => void;
};

export const exportCsvType = {
  row: "row",
  sheet: "sheet",
};

const ExportAsinsDialoge = (props: ExportAsinsDialogeProps) => {
  const { handleTypeClick } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isDesktop } = useDeviceType();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          maxWidth: "fit-content",
        },
      }}
    >
      {props?.title && (
        <DialogTitle className={classes.Title}>
          <div className="flex-grow-1">{props.title}</div>
          <IconButton
            sx={{
              padding: 0,
              color: "inherit",
              "&:hover": {
                background: "none",
              },
            }}
            onClick={props.onClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.BodyContainer}>
        <div className={`d-flex justify-content-between ${isDesktop ? "flex-row gap-4" : "flex-column gap-2"}`}>
          <div
            className={`${classes.BoxConatiner} ${classes.GrayBorder} cursor-pointer`}
            onClick={() => handleTypeClick(exportCsvType.row)}
          >
            <MTypography variant="subtitle2" customClass={classes.BoxTitle}>
              Export ASIN per Row
            </MTypography>
            <MTypography variant="subtitle1" customClass={classes.BoxDescription}>
              This option enables you to export detailed information for each ASIN in a concise, single-row format.
            </MTypography>
            <ExportPerRow />
          </div>
          <div
            className={`${classes.BoxConatiner} ${classes.GrayBorder} cursor-pointer`}
            onClick={() => handleTypeClick(exportCsvType.sheet)}
          >
            <MTypography variant="subtitle2" customClass={classes.BoxTitle}>
              Export ASIN per Tab
            </MTypography>
            <MTypography variant="subtitle1" customClass={classes.BoxDescription}>
              This option enables you to export detailed information for each ASIN in a single-tab format.
            </MTypography>
            <ExportPerTab />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ExportAsinsDialoge;
