import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classes from "./index.module.css";

import { generateProductGridSkeleton } from "../../../../utils/helpers/skeleton";
import { Skeleton } from "@mui/material";

interface BrandsSkeletonProps {
  opportunityBrands?: boolean
}

const BrandsSkeleton: React.FC<BrandsSkeletonProps> = (props) => {
  const { opportunityBrands = false } = props;
  const products = generateProductGridSkeleton(50);
  return (
    <TableContainer classes={{ root: classes.TableContainer }}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.TableHeading }} align="left" sx={opportunityBrands ? { width: '400px' } : {}}>
              Brand Name
            </TableCell>
            <TableCell classes={{ root: classes.TableHeading }} align="center">
              Total Products
            </TableCell>
            {!opportunityBrands && <>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Generating
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Error
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Draft
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Edited
              </TableCell>
              <TableCell classes={{ root: classes.TableHeading }} align="center">
                Approved{" "}
              </TableCell>
            </>
            }
            {/* an empty cell to manage opportunity view */}
            {opportunityBrands &&
              <TableCell classes={{ root: classes.TableHeading }} align="left">
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((row: any) => (
            <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" component="th" scope="row">
                <div className={"w-100"}>
                  <Skeleton variant={"text"} />
                </div>
              </TableCell>
              <TableCell classes={{ root: classes.TableCells }} align="center" sx={opportunityBrands ? { width: '140px' } : {}}>
                <div className={"w-100 d-flex justify-content-center"}>
                  <Skeleton className={"w-25"} variant={"text"} />
                </div>
              </TableCell>
              {!opportunityBrands &&
                <>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    <div className={"w-100 d-flex justify-content-center"}>
                      <Skeleton className={"w-25"} variant={"text"} />
                    </div>
                  </TableCell>

                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    <div className={"w-100 d-flex justify-content-center"}>
                      <Skeleton className={"w-25"} variant={"text"} />
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    <div className={"w-100 d-flex justify-content-center"}>
                      <Skeleton className={"w-25"} variant={"text"} />
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    <div className={"w-100 d-flex justify-content-center"}>
                      <Skeleton className={"w-25"} variant={"text"} />
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCells }} align="center">
                    <div className={"w-100 d-flex justify-content-center"}>
                      <Skeleton className={"w-25"} variant={"text"} />
                    </div>
                  </TableCell>
                </>
              }
              {opportunityBrands &&
                <TableCell classes={{ root: classes.TableCells }} align="left" sx={{}}>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrandsSkeleton;
