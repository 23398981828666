import React, { useState } from "react";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";
import { Switch, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../store/user/user.selector";
import { RolesEnum } from "../../../utils/constants/enums";
import { showAutomatoApiAccess } from "../../../utils/helpers/priviligesChecks";
import { ApiAccessKey } from "../../../apis/types/apiAccessKey";
import { toDateAndTimeObject } from "../../../utils/helpers/date";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuOption from "../MenuOption";
import MuiPopover from "../../Atoms/Popover";
import { copyText } from "../../../utils/helpers/common";

interface AccessKeyMenuState {
  loading: boolean;
  openPopper: boolean;
  anchorEl: SVGSVGElement | null;
}

interface AccessKeyCardProps {
  item: ApiAccessKey;
  handleDelete: (accessKeyId: string) => void;
  handleRerun: (accessKey: ApiAccessKey) => void;
  hanldeSwitchChange: (keyID: string, status: boolean) => void;
}

const AccessKeyCard = ({ item, hanldeSwitchChange, handleDelete, handleRerun }: AccessKeyCardProps) => {

  const dbUser = useSelector(DbUserSelector);
  const hideCells = showAutomatoApiAccess(dbUser?.role as RolesEnum);

  const [state, setState] = useState<AccessKeyMenuState>({
    loading: false,
    openPopper: false,
    anchorEl: null,
  })

  const handleClick = (type: string) => {
    switch (type) {
      case 'copy':
        copyText(item.keyVal)
        break;
      case 're-gen':
        handleRerun(item)
        break;
      case 'delete':
        handleDelete(item?._id)
        break;
      default:
        break;
    }
    setState({
      ...state,
      anchorEl: null,
      openPopper: false,
    });
  }

  return (
    <div className={"mt-2 py-3 px-3 d-flex flex-column w-100"} style={{ backgroundColor: "white" }}>
      <div className={`d-flex mb-2 justify-content-between align-items-center`}>
        <div className="d-flex w-100 justify-content-between">
          <div style={{ maxWidth: '80%', overflow: 'hidden', display: 'block' }} className={`${classes.CellLinesLimitContainer} ${classes.AccessKey}`}>
            {item.keyVal}
          </div>
          <div className="pr-1">
            <MoreVertRoundedIcon color="primary" sx={{ fontSize: '24px' }}
              className={"cursor-pointer"}
              onClick={(e) => {
                setState({
                  ...state,
                  anchorEl: e.currentTarget,
                  openPopper: true,
                });
              }} />
            <MuiPopover
              open={state.openPopper}
              anchorEl={state.anchorEl}
              onClose={() => setState({ ...state, openPopper: false })}
            >
              <div className={"py-2"}>
                <MenuOption>
                  <div className={"cursor-pointer"} onClick={() => handleClick('copy')}>
                    {"Copy API"}
                  </div>
                </MenuOption>
                <MenuOption>
                  <div className={"cursor-pointer"} onClick={() => handleClick('re-gen')}>
                    {"Regenerate"}
                  </div>
                </MenuOption>
                {hideCells &&
                  <MenuOption>
                    <div className={"cursor-pointer"} onClick={() => handleClick('delete')}>
                      {"Delete"}
                    </div>
                  </MenuOption>
                }
              </div>
            </MuiPopover>
          </div>
        </div>
      </div>
      {hideCells &&
        <>
          <MTypography variant={"subtitle1"} customClass={classes.UserEmail}>
            {item.email}
          </MTypography>
          <div className="d-flex flex-wrap">
            <div>
              <>
                <div className={classes.SwitchContainer}>
                  <Tooltip title={"Enable"} placement="top">
                    <Switch checked={item.status} onChange={() => hanldeSwitchChange(item._id, item.status)} />
                  </Tooltip>
                </div>

                <MTypography variant={"subtitle2"} customClass={`${classes.EnableDisable}`}>
                  {"Enable/Disable"}
                </MTypography>
              </>
            </div>
          </div>
        </>
      }
      <div>
        <MTypography variant={"subtitle1"} customClass={classes.LastGeneratedContainer}>
          <span className="fw-semibold">Last generated:</span>  {toDateAndTimeObject(item.createdAt).date} @ {toDateAndTimeObject(item.createdAt).time}
          {/* {item.createdAt} */}
        </MTypography>
      </div>
    </div>
  );
};

export default AccessKeyCard;
