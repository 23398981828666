import { Grid } from "@mui/material";
import React from "react";
import TeamStatCard from "./TeamStatCard/TeamStatCard";
import { useSelector } from "react-redux";
import { TeamStatsSelector } from "../../../../store/user/user.selector";

interface TeamStatsContainerProps {
  isLoading: boolean;
}
const TeamStatsContainer = (props: TeamStatsContainerProps) => {
  const { isLoading } = props;

  const teamMembersStats = useSelector(TeamStatsSelector);

  return (
    <div>
      <Grid
        container
        flexDirection={"row"}
        px={{ xs: 2, lg: 4 }}
        mt={{ lg: 2 }}
        spacing={{ xs: 1.5, md: 2 }}
        textAlign={"center"}
      >
        <Grid item xs={6} lg={3}>
          <TeamStatCard title="Total Member" volume={teamMembersStats?.totalMembers || 0} isLoading={isLoading}  />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TeamStatCard title="Active" volume={teamMembersStats?.totalActive || 0} isLoading={isLoading}  />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TeamStatCard title="Inactive" volume={teamMembersStats?.totalInactive || 0} isLoading={isLoading} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TeamStatCard title="Invitation Pending" volume={teamMembersStats?.totalInvitationPending || 0} isLoading={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TeamStatsContainer;
