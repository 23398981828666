const gogoConfigs = {
  competitorsRelevancy: 0,
  keywordsRelevancy: 0,
  keywordsUsabilityScore: 0,
  brandFilterScore: 0,
  competitivenessScore: 0,
  currentRank: 0,
  searchVolume: 0,
  relevancyScore: 0,
};
type GogoConfigKeys = keyof typeof gogoConfigs;
interface GoGoConfigs {
  text: string;
  minValue: number;
  maxValue: number;
  marks: {
    value: number;
    label: string;
  }[];
  key: GogoConfigKeys;
  steps?: number;
}

export const GogoSettingSliders: GoGoConfigs[] = [
  {
    text: "Minimum Relevancy of Competitors",
    minValue: 0,
    maxValue: 100,
    marks: [
      {
        value: 0,
        label: "0%",
      },

      {
        value: 100,
        label: "100%",
      },
    ],
    key: "competitorsRelevancy",
  },
  {
    text: "Minimum Relevancy of Keywords",
    minValue: 0,
    maxValue: 100,
    marks: [
      {
        value: 0,
        label: "0%",
      },

      {
        value: 100,
        label: "100%",
      },
    ],
    key: "keywordsRelevancy",
  },
  {
    text: "Minimum Usability Score for Keywords",
    minValue: 0,
    maxValue: 100,
    marks: [
      {
        value: 0,
        label: "0%",
      },

      {
        value: 100,
        label: "100%",
      },
    ],
    key: "keywordsUsabilityScore",
  },
  {
    text: "Minimum brand filter score for keywords",
    minValue: 0,
    maxValue: 100,
    marks: [
      {
        value: 0,
        label: "0%",
      },

      {
        value: 100,
        label: "100%",
      },
    ],
    key: "brandFilterScore",
  },
];
export const GogoSettingKeywordsArrangeSliders: GoGoConfigs[] = [
  {
    text: "Competitiveness Score",
    minValue: 0,
    maxValue: 500,
    marks: [
      {
        value: 0,
        label: "0%",
      },
      {
        value: 250,
        label: "250%",
      },
      {
        value: 500,
        label: "500%",
      },
    ],
    key: "competitivenessScore",
    steps: 50,
  },
  {
    text: "Relevancy Score",
    minValue: 0,
    maxValue: 500,
    marks: [
      {
        value: 0,
        label: "0%",
      },
      {
        value: 250,
        label: "250%",
      },
      {
        value: 500,
        label: "500%",
      },
    ],
    key: "relevancyScore",
    steps: 50,
  },
  {
    text: "Current Rank",
    minValue: 0,
    maxValue: 500,
    marks: [
      {
        value: 0,
        label: "0%",
      },
      {
        value: 250,
        label: "250%",
      },
      {
        value: 500,
        label: "500%",
      },
    ],
    key: "currentRank",
    steps: 50,
  },
];
