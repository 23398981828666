import React, { memo, useEffect, useMemo, useState } from "react";
// import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IconButton, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { RolesEnum, UserStatusEnum } from "../../../../utils/constants/enums";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { SUCC_INVITE_LINK_DELETED, SUCC_REMINDER_SEND } from "../../../../utils/constants/messages/success";
import { ERR_UNABLE_TO_DELETE_INVITE_LINK } from "../../../../utils/constants/messages/errors";

// import { TeamStatsSelector } from "../../../../store/user/user.selector";
import { CompanySelector, InvitedEmailsOfCompanySelector } from "../../../../store/company/company.selector";
import { setCompanyAction } from "../../../../store/company/company.actions";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { usePagination } from "../../../../hooks/usePagination";

import { CompanyApis } from "../../../../apis/companies";
import { RemindInvitedCompanyUserPayload } from "../../../../apis/types/companies";

import { URL_SIGN_UP } from "../../../../routes/routes-path";

import reminderIcon from "../../../../assets/svgs/reminder-icon.svg";

import UserStatus from "../../../Molecules/UserStatus";
import ActionBox from "../../../Molecules/ActionBox";
import MTypography from "../../../Atoms/MTypography";

import ProductTable from "../../SavedProducts/ProductDataGrid/ProductTable";

import PendingInvitationCard from "./PendingInvitationCard/PendingInvitationCard";
import classes from "./index.module.css";

interface PendingInvitationsGridProps {
  getTeamMembersCall: (isReset: boolean, isMobile: boolean) => void;
  companyID?: string;
  refetchPendingList?: number;
}

// const defaultLimit = 10;
const PendingInvitationsGrid: React.FC<PendingInvitationsGridProps> = ({
  getTeamMembersCall,
  companyID = "",
  refetchPendingList = 1,
}) => {
  const { isDesktop } = useDeviceType();
  const currentCompany = useSelector(CompanySelector);
  // const teamMembersStats = useSelector(TeamStatsSelector);
  const invitedEmailsList = useSelector(InvitedEmailsOfCompanySelector);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [pendingEmails, setPendingEmails] = useState<any>([]);
  const [totalEmails, setTotalEmails] = useState<number>(0);
  // const [limit, setLimit] = useState<number>(defaultLimit);
  const [expanded, setExpanded] = React.useState(false);

  const [deleteState, setDeleteState] = useState({
    openDelete: false,
    selectedEmail: "",
  });
  const { pageSize, setPageNumber, pageNumber, setPageSize, setSortModel } = usePagination(100);
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const currentCompanyId = companyID ? companyID : currentCompany?._id;
  const fetchPendingEmails = () => {
    setLoading(true);
    const companyId: string = companyID ? companyID : currentCompany?._id;
    CompanyApis.getPendingEmails(companyId)
      .then((res: any) => {
        if (res.pendingEmails.length) {
          setPendingEmails([]);
          const emailsList = res.pendingEmails.map((email: any, index: any) => ({
            email: email,
            id: index,
            role: RolesEnum.USER,
            status: UserStatusEnum.Pending,
          }));
          setPendingEmails(emailsList);
          setTotalEmails(emailsList?.length);
        } else {
          setPendingEmails([]);
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        console.log("error while fetching pending emails", e);
      });
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentCompanyId) {
      fetchPendingEmails();
    }
  }, [currentCompanyId, refetchPendingList]);
  /* eslint-disable react-hooks/exhaustive-deps */
  // useEffect(() => {
  //   fetchPendingEmails();
  // }, [limit, currentCompany]);

  // const handleSeeMore = () => {
  //   if (totalEmails >= limit) {
  //     setLimit(limit + defaultLimit);
  //   }
  // };

  const deletePendingInvitation = async (invitationData: any) => {
    setDeleteState({
      openDelete: true,
      selectedEmail: invitationData?.email,
    });
  };

  const handleRemindInvitedUserClick = async (userData: any) => {
    const { email } = userData;
    try {
      setLoading(true);
      const companyId = companyID ? companyID : currentCompany?._id;
      const payload: RemindInvitedCompanyUserPayload = {
        companyID: companyId,
        email: email,
        url: `http://${window.location.host}${URL_SIGN_UP}`,
      };

      const res = await CompanyApis.remindInvitedUser(payload);
      if (!res) {
        toast.success(SUCC_REMINDER_SEND);
        setLoading(false);
      } else {
        toast.error(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("An error occurred while sending reminder.");
    }
  };

  const emailColumns: GridColDef[] = [];

  emailColumns.push({
    field: "email",
    headerName: "Email",
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <div title={params.row.email} className={`${classes.CellLinesLimitContainer} ${classes.CellText}`}>
        {params.row.email || "--"}
      </div>
    ),
  });

  emailColumns.push({
    field: "role",
    headerName: "Role",
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <div className={`${classes.CellText}`}>{capitalizeEachLetter(params?.row?.role?.replace("_", " ")) || "--"}</div>
    ),
  });

  emailColumns.push({
    field: "status",
    headerName: "Status",
    sortable: false,
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <>
        <UserStatus type={params.row.status} />
      </>
    ),
  });

  emailColumns.push({
    field: "action",
    headerName: "",
    flex: 1,
    renderCell: (_params: GridCellParams) => (
      <div className="d-flex gap-1">
        <div className={"w-100"}>
          <Tooltip arrow title={"Reminder"} placement="top">
            <IconButton
              onClick={() => handleRemindInvitedUserClick(_params.row)}
              style={{ pointerEvents: loading ? "none" : "auto" }}
            >
              <img src={reminderIcon} alt="keyword-icon" />
            </IconButton>
          </Tooltip>
        </div>
        <div className={"w-100"}>
          <Tooltip arrow title={"Delete"} placement="top">
            <IconButton
              onClick={() => deletePendingInvitation(_params.row)}
              style={{ pointerEvents: loading ? "none" : "auto" }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    ),
  });

  const confirmDelete = async () => {
    const updatedEmailsList = invitedEmailsList?.filter(
      (email: any) => email?.toLocaleLowerCase() !== deleteState?.selectedEmail?.toLocaleLowerCase() && email
    );
    const companyId = companyID ? companyID : currentCompany?._id;

    const res = await CompanyApis.updateCompanyProfile({
      ...currentCompany,
      invitedEmails: updatedEmailsList,
      companyID: companyId,
    });

    if (!res[0]) {
      toast.success(SUCC_INVITE_LINK_DELETED);
      fetchPendingEmails();
      if (isDesktop) {
        getTeamMembersCall(false, false);
      } else {
        getTeamMembersCall(true, true);
      }
      fetchPendingEmails();
      dispatch(setCompanyAction(companyId))
      colseConfirmBox();
    } else {
      toast.error(ERR_UNABLE_TO_DELETE_INVITE_LINK);
      colseConfirmBox();
    }
  };

  const colseConfirmBox = () => {
    setDeleteState({
      selectedEmail: "",
      openDelete: false,
    });
  };

  const invitationTable = useMemo(() => {
    return (
      <ProductTable
        total={totalEmails}
        pageSize={pageSize}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        columns={emailColumns}
        rows={pendingEmails ?? []}
        withoutPagination
        showHeaderCheckBox
      />
    );
  }, [totalEmails, pageSize, setPageNumber, setPageSize, emailColumns, pendingEmails, setSortModel]);

  return (
    <>
      {pendingEmails.length > 0 ? (
        <div className={`mt-4`}>
          <Accordion
            expanded={expanded}
            onChange={handleExpansion}
            classes={{
              root: expanded ? classes.Root : classes.RootNotActive,
            }}
            sx={{
              "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
              "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
            }}
          >
            <AccordionSummary
              className={expanded ? classes.AccordianHeader : ""}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <MTypography variant={"h4"} customClass={classes.AccordianHeaderText}>
                {totalEmails} Invitations Pending
              </MTypography>
            </AccordionSummary>
            {expanded && (
              <AccordionDetails sx={isDesktop ? {} : { maxWidth: "100vw", background: "#fffaef" }}>
                {isDesktop ? (
                  <>{invitationTable}</>
                ) : (
                  <>
                    {pendingEmails?.length > 0 && (
                      <>
                        {pendingEmails?.map((prod: any, index: number) => (
                          <div key={index}>
                            <PendingInvitationCard
                              inviteDetails={prod}
                              deletePendingInvitation={deletePendingInvitation}
                              handleRemindInvitedUserClick={handleRemindInvitedUserClick}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
                {/* {false && (
                <div className={"d-flex justify-content-end w-100" + classes.SeeMore}>
                  {loading ? (
                    <CircularProgress size={10} />
                  ) : (
                    <MButton
                      variant={"text"}
                      onClick={handleSeeMore}
                      isLoading={loading}
                      rootClass={classes.SeeMoreButtonText}
                    >
                      See More
                    </MButton>
                  )}
                </div>
              )} */}
              </AccordionDetails>
            )}
          </Accordion>
        </div>
      ) : (
        <div></div>
      )}
      <ActionBox
        handleAction={confirmDelete}
        handleBack={colseConfirmBox}
        open={deleteState.openDelete}
        actionText={"Delete"}
        message={
          isDesktop
            ? `Do you want to delete invitation link for this email : ${deleteState.selectedEmail}?`
            : `Do you want to delete invitation link?`
        }
        title={"Delete Invite Link"}
        backText={"Cancel"}
      />
    </>
  );
};

export default memo(PendingInvitationsGrid);
