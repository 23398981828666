import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import classes from "./index.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import Switch from "@mui/material/Switch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyText } from "../../../../../utils/helpers/common";
import { showAutomatoApiAccess } from "../../../../../utils/helpers/priviligesChecks";
import { RolesEnum } from "../../../../../utils/constants/enums";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../../../store/user/user.selector";
import { ApiAccessKey } from "../../../../../apis/types/apiAccessKey";
import { toDateAndTimeObject } from "../../../../../utils/helpers/date";

export interface AccessKeyTableProps {
  apiKeysList: ApiAccessKey[];
  handleDelete: (accessKeyId: string) => void;
  handleRerun: (accessKey: ApiAccessKey) => void;
  hanldeSwitchChange: (keyID: string, status: boolean) => void;
  pageSize: number;
  pageNumber: number;
}

const AccessKeyTable: React.FC<AccessKeyTableProps> = (props) => {
  const { apiKeysList, handleDelete, handleRerun, hanldeSwitchChange, pageNumber, pageSize } = props;
  const dbUser = useSelector(DbUserSelector);
  const hideCells = showAutomatoApiAccess(dbUser?.role as RolesEnum);

  return (
    <div className={classes.Container}>
      <TableContainer classes={{ root: classes.TableContainer }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{ root: classes.TableHeading }}
                align="left"
                sx={{ width: hideCells ? "350px" : "400px" }}
              >
                Access Key
              </TableCell>
              {hideCells && (
                <TableCell classes={{ root: classes.TableHeading }} align="left" sx={{ width: "350px" }}>
                  User
                </TableCell>
              )}
              <TableCell
                classes={{ root: classes.TableHeading }}
                align="left"
                sx={{ width: hideCells ? "350px" : "400px" }}
              >
                Last generated
              </TableCell>
              <TableCell
                classes={{ root: classes.TableHeading }}
                align="left"
                sx={{ width: hideCells ? "100px" : "400px" }}
              >
                {" "}
                {/* empty for actions title */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(pageSize > 0
              ? apiKeysList?.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
              : apiKeysList
            )?.map((item, i) => (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={i}>
                <TableCell align="left" component="th" scope="row">
                  <div className="d-flex">
                    <span
                      style={{ maxWidth: "300px", overflow: "hidden", display: "block" }}
                      className={classes.CellLinesLimitContainer}
                    >
                      {item.keyVal}
                    </span>
                    <ContentCopyIcon className={classes.CopyIcon} onClick={() => copyText(item.keyVal)} />
                  </div>
                </TableCell>
                {hideCells && (
                  <TableCell classes={{ root: classes.TableCells }} align="left">
                    {item.email}
                  </TableCell>
                )}
                <TableCell classes={{ root: classes.TableCells }} align="left">
                  {toDateAndTimeObject(item.createdAt).date} @ {toDateAndTimeObject(item.createdAt).time}
                  {/* {item.createdAt} */}
                </TableCell>
                <TableCell classes={{ root: classes.TableCells }} align="left">
                  <div className={"d-flex w-100 gap-2 align-items-center"}>
                    <>
                      {hideCells && (
                        <div>
                          <Tooltip title={item?.status ? "Enable" : "Disable"} placement="top">
                            <Switch
                              size="small"
                              checked={item?.status}
                              onChange={() => hanldeSwitchChange(item._id, item?.status)}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </>
                    <div>
                      <Tooltip title={"Rerun"} placement="top">
                        <RefreshIcon
                          onClick={() => {
                            handleRerun(item);
                          }}
                          color="secondary"
                          style={{ cursor: "pointer", color: "rgb(230, 181, 64)" }}
                        />
                      </Tooltip>
                    </div>
                    {hideCells && (
                      <div>
                        <Tooltip title={"Delete"} placement="top">
                          <DeleteIcon
                            onClick={() => {
                              handleDelete(item?._id);
                            }}
                            color="secondary"
                            style={{ cursor: "pointer", color: "rgb(230, 181, 64)" }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AccessKeyTable;
