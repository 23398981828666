import { useState } from "react";

interface TopBarHeight {
    mobile: string,
    desktop: string
}

// will use same hook in other ui components to manage height according to topbar

export const useTopBarHeight = () => {
    const [height, setHeight] = useState<TopBarHeight>({ desktop: "5rem", mobile: "54px" })
    const setTopBarHeight = (newHeight: TopBarHeight) => {
        setHeight(newHeight);
    };

    const getTopBarHeight = () => {
        return height;
    };

    return { setTopBarHeight, getTopBarHeight };
};