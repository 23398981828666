import React from "react";
import { TextField } from "@mui/material";

import { useFormikContext, Field } from "formik";

interface Props {
  icon?: string;
  name: string;
  placeholder?: string;
  label?: string;
  position?: "start" | "end";
  margin?: "dense" | "normal" | "none";
  rootClass?: any;
  multiline?: boolean;
  rows?: number | string;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: any;
}

const FormTextField: React.FC<Props> = (props) => {
  const {
    name,
    placeholder = "",
    label,
    fullWidth = false,
    margin = "normal",
    multiline = false,
    rows = 1,
    disabled = false,
    type = "text",
  } = props;

  const { touched, values, errors }: any = useFormikContext();

  return (
    <Field
      className={fullWidth ? "w-100" : ""}
      id={name}
      name={name}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      label={label}
      as={TextField}
      margin={margin}
      value={values[name]}
      error={touched[name] && errors[name]}
      helperText={touched[name] && errors[name] ? errors[name] : ""}
      disabled={disabled}
      type={type}
    />
  );
};

export default FormTextField;
