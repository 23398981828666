import { ProductsReducerActionType } from "./product.actions";
import { produce } from "immer";
import {
  initialColumnsPriortiseKeywords,
  sortFilters,
  sortResultByFilter,
} from "../../components/Organisms/PrioritiseKeywords/DragAndDrop/config";

import { ProductActionTypes } from "./product.action-types";
import { UserProductTag } from "../../apis/types/tag";
import { PaginationObject } from "../../hooks/usePagination";
import { isValidEmail } from "../../utils/helpers/regex";

export interface AsinKeywordHistoryObject {
  date: string;
  phrase: string;
  blacklistedWord: boolean;
  relevancy: number;
  count: number;
  competitiveness: number;
  rank: number;
}
export interface AsinKeywordHistory {
  [key: string]: AsinKeywordHistoryObject[];
}

export interface TotalProductStatuses {
  _id: string;
  count: number;
}
export interface State {
  savedProducts?: object[];
  opportunityProducts?: object[];
  userProductTags: UserProductTag[];
  selectedKeywords: { [key: string]: any }[];
  priortiseKeywords: any;
  sellingKeywords: string[];
  asinKeywordHistory: AsinKeywordHistory;
  totalProducts: number;
  totalOpportunityProduct: number;
  activeBrandTab: string;
  opportunityStats: any;
  changeKeywordsProductOwnerId: string | null;
  searchFilter: string;
  productsPagination: PaginationObject;
  totalProductsStatus: TotalProductStatuses[];
  isRefetchWaitingProducts: boolean
}

const initialState: State = {
  savedProducts: [],
  opportunityProducts: [],
  userProductTags: [],
  selectedKeywords: [],
  priortiseKeywords: initialColumnsPriortiseKeywords,
  sellingKeywords: [],
  asinKeywordHistory: {},
  totalProducts: 0,
  totalOpportunityProduct: 0,
  activeBrandTab: "ASIN",
  opportunityStats: {
    totalSearch: 0,
    averageVolume: 0,
    averageKw: 0,
    totalAsinLive: 0,
  },
  productsPagination: {
    pageSize: 100,
    pageNumber: 0,
    offset: 0,
    sortOrder: 0,
    sortBy: "",
  },
  searchFilter: "",
  changeKeywordsProductOwnerId: null,
  totalProductsStatus: [],
  isRefetchWaitingProducts: false,
};

const productReducer = produce((state: State = initialState, action: ProductsReducerActionType) => {
  switch (action.type) {
    case ProductActionTypes.setProducts:
      state.savedProducts = action.payload;
      return state;
    case ProductActionTypes.loadProducts:
      if (state.savedProducts && state.savedProducts.length > 0) {
        state.savedProducts = [...state.savedProducts, ...action.payload];
      } else {
        state.savedProducts = action.payload;
      }
      return state;
    case ProductActionTypes.setOpportunityProducts:
      state.opportunityProducts = action.payload;
      return state;
    case ProductActionTypes.loadOpportunityProducts:
      if (state.opportunityProducts && state.opportunityProducts.length > 0) {
        state.opportunityProducts = [...state.opportunityProducts, ...action.payload];
      } else {
        state.opportunityProducts = action.payload;
      }
      return state;
    case ProductActionTypes.resetProducts:
      state.savedProducts = [];
      state.totalProducts = 0;
      return state;
    case ProductActionTypes.resetOpportunityProducts:
      state.opportunityProducts = [];
      state.totalOpportunityProduct = 0;
      return state;
    case ProductActionTypes.setTotalProduct:
      state.totalProducts = action.payload;
      return state;
    case ProductActionTypes.setTotalOpportunityProduct:
      state.totalOpportunityProduct = action.payload;
      return state;
    case ProductActionTypes.setTags:
      state.userProductTags = action.payload;
      return state;
    // TODO: Will change back to competitiveness when we got it from backend
    case ProductActionTypes.setSelectedKeywords:
      const keywords = sortResultByFilter({ filter: sortFilters.searchVolume, array: action.payload });
      state.selectedKeywords = [...keywords];
      state.priortiseKeywords = {
        ...state.priortiseKeywords,
        suggestions: {
          ...state.priortiseKeywords.suggestions,
          list: keywords,
        },
      };
      return state;
    case ProductActionTypes.setPriortiseKeywords:
      state.priortiseKeywords = action.payload;
      return state;
    case ProductActionTypes.setSellingKeywords:
      state.sellingKeywords = action.payload;
      state.priortiseKeywords = {
        ...state.priortiseKeywords,
        selling: { ...state.priortiseKeywords.selling, list: action.payload },
      };
      return state;
    case ProductActionTypes.resetKeywords:
      state.selectedKeywords = [];
      state.priortiseKeywords = initialColumnsPriortiseKeywords;
      return state;
    case ProductActionTypes.setAsinKeywordHistory:
      state.asinKeywordHistory = action.payload;
      return state;
    case ProductActionTypes.setActiveBrandTab:
      state.activeBrandTab = action.payload;
      return state;
    case ProductActionTypes.setOpportunityStats:
      state.opportunityStats = action.payload;
      return state;
    case ProductActionTypes.setChangeKeywordsProductOwnerId:
      state.changeKeywordsProductOwnerId = action.payload;
      return state;
    case ProductActionTypes.setProductsPagination:
      state.productsPagination = action.payload;
      return state;
    case ProductActionTypes.setTotalProductsStatus:
      state.totalProductsStatus = action.payload;
      return state;
    case ProductActionTypes.setProductSearchFilter:
      state.searchFilter = action.payload;
      return state;
    case ProductActionTypes.setProductSearchReset:
      if (!isValidEmail(state.searchFilter)) {
        state.searchFilter = "";
      }
      return state;
    case ProductActionTypes.setWaitingProductsRefetch:
      state.isRefetchWaitingProducts = action.payload;
      return state;
    default:
      return state;
  }
});

export default productReducer;
