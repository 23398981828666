import { RolesEnum } from "../../../../utils/constants/enums";
import { showAutomatoApiAccess } from "../../../../utils/helpers/priviligesChecks";

export const showGenNewKeyButton = (userRole: RolesEnum, listLenght: number) => {
    if (showAutomatoApiAccess(userRole) && listLenght !== 0) {
        return true;
    } else if (!showAutomatoApiAccess(userRole) && listLenght === 1) {
        return false;
    } else {
        return false;
    }
}

export const generateRandomText = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

