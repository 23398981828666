import React, { useState } from "react";
import TabsToolbar from "../../components/Molecules/TabsToolbar";
import { Form, Formik } from "formik";
import CardOverlay from "../../components/Atoms/CardOverlay";
import FormTextField from "../../components/Molecules/FormTextField";
import { inputName, issueTypeOptions, validationSchema } from "./config";
import MButton from "../../components/Atoms/MButton";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import { initialValue } from "./config";
import FormTitleWithLabel from "../../components/Molecules/FormTitleWithLabel";
import { Divider } from "@mui/material";
// import UploadBox from "../../components/Molecules/UploadBox/UploadBox";
import classes from "./ContactUs.module.css";
import { useDeviceType } from "../../hooks/useDeviceType";
import { UserApis } from "../../apis/user";
import { toast } from "react-toastify";
import { errorHandler } from "../../utils/helpers/apis";
import SuccessToast from "../../components/Atoms/SuccessToast/SuccessToast";
import { useSelector } from "react-redux";
import { UserIdSelector } from "../../store/user/user.selector";
import { replaceWhitespaceWithHTML } from "../../utils/helpers/strings";
import { TabsEnum } from "../../utils/constants/enums";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MTypography from "../../components/Atoms/MTypography";
import { navigatePage } from "../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../routes/routes-path";
import { useNavigate } from "react-router-dom";
import UploadBox from "../../components/Molecules/UploadBox/UploadBox";
import FormRolesDropdown from "../../components/Molecules/FormRolesDropdown/FormRolesDropdown";
const ContactUs = () => {
  const { isDesktop } = useDeviceType();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isClear, setIsClear] = useState<any>(0);
  const navigate = useNavigate();

  const userID = useSelector(UserIdSelector);
  const submitForm = async (values: any, options: any) => {
    console.log("", selectedFile, setSelectedFile);
    try {
      setLoading(true);

      // Create a FormData object
      const formData = new FormData();

      // Append form fields to FormData
      formData.append("email", values.email);
      formData.append("type", values.type);
      formData.append("information", replaceWhitespaceWithHTML(values.additionalInformation));
      formData.append("userID", userID);

      // If there's a selected file, append it to the FormData
      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      await UserApis.contactSupport(formData);

      toast(
        <SuccessToast
          title={"Contact Support Request Submitted!"}
          subtitle={
            "Your support request has been successfully submitted. Our team will review your issue and get back to you shortly."
          }
        />,
        {
          type: "success",
        }
      );
      setSelectedFile(null);
      setIsClear(isClear + 1);
      options.resetForm();
      setLoading(false);
    } catch (e) {
      toast.error(errorHandler(e));
      setLoading(false);
    }
  };

  const onSchedule = () => {
    window.open("https://calendly.com/talk-to-payton/30min", "_blank");
  };

  const handleSaveFile = (file: any) => {
    setSelectedFile(file);
  };
  const navigateToMore = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.MORE}`, navigate);
  };

  return (
    <div className={"mb-5"}>
      <div>
        {isDesktop ? (
          <div>
            <TabsToolbar title={"Contact Us"} />
          </div>
        ) : (
          <div className={"d-flex flex-column p-2 m-2"}>
            <div className={"my-2 mx-2"}>
              <ArrowBackIcon onClick={navigateToMore} />
            </div>
            <div className={"my-2 mx-2"}>
              <MTypography variant={"h5"}>Contact Us</MTypography>
            </div>
          </div>
        )}

        {/*<TabsToolbar title={"Contact Us"}  />*/}

        <div className={"px-4"}>
          <div className={isDesktop ? classes.Container : classes.ContainerMobile}>
            <div
              className={`${isDesktop ? "d-flex align-items-center gap-2" : "d-flex align-items-center gap-2"} ${
                classes.TextContainer
              }`}
            >
              <EventAvailableIcon style={{ color: "#080D1C", width: "40px", height: "40px" }} />
              <div className={"d-flex flex-column gap-1"}>
                <div className={classes.Text}>Schedule a Meeting</div>
                <div className={classes.SubtitleText}>
                  Click on schedule button to book an appointment in available time slots.
                </div>
              </div>
            </div>
            <div
              className={
                isDesktop ? `${classes.Button} d-flex justify-content-end` : ` d-flex justify-content-end mt-2`
              }
            >
              <MButton onClick={onSchedule} size={"small"} variant="contained">
                Schedule
              </MButton>
            </div>
          </div>
        </div>

        <div className={"my-4"}>
          <Divider />
        </div>
        <div className={``}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValue}
            onSubmit={submitForm}
          >
            {({ handleSubmit, dirty }) => (
              <Form autoComplete="off">
                <div className={` px-4`}>
                  {isDesktop ? (
                    <FormTitleWithLabel title="Contact Support" label={""} />
                  ) : (
                    <MTypography variant={"h6"}>Contact Support</MTypography>
                  )}
                  <FormTitleWithLabel
                    isSubtitle
                    title="Fill the following form with a detailed issue description and relevant attachments."
                    label={""}
                  />

                  <CardOverlay>
                    <div className={"mt-3"}>
                      <div className={"mx-4"}>
                        <FormRolesDropdown
                          isMarginTop={false}
                          name={inputName.type}
                          minWidth={"100%"}
                          label="Issue Type*"
                          selectableRoles={issueTypeOptions}
                        />
                      </div>
                      <div className={"mx-4"}>
                        <FormTextField fullWidth name={inputName.email} label={"Work Email *"} />

                        <FormTextField
                          multiline
                          rows={5}
                          fullWidth
                          name={inputName.additionalInformation}
                          label={"Additional Information *"}
                        />
                      </div>
                      <div className={"mx-4 mt-2"}>
                        <UploadBox
                          isClear={isClear}
                          handleSaveFile={handleSaveFile}
                          clearSelectedFile={setSelectedFile}
                        />
                      </div>

                      <div className="d-flex justify-content-end mt-4 mx-4">
                        <MButton
                          isLoading={loading}
                          size={"small"}
                          variant={"contained"}
                          onClick={handleSubmit}
                          disabled={!dirty}
                        >
                          Submit
                        </MButton>
                      </div>
                    </div>
                  </CardOverlay>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
