import React from "react";

import MTypography from "../../../Atoms/MTypography";

import classes from "./index.module.css";
import { BrandsObjectApi } from "../../../../apis/types/product";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import Space from "../../../Atoms/Space";

interface BrandCardProps {
  brand: {
    [key: string]: BrandsObjectApi;
  };
  onBrandClick: (brandName: string) => void;
  opportunityBrands?: boolean
}
const BrandCard: React.FC<BrandCardProps> = (props) => {
  const { brand, onBrandClick, opportunityBrands = false } = props;
  const brandName = Object.keys(brand)[0];
  return (
    <div className={classes.Container} onClick={() => onBrandClick(brandName)}>
      <MTypography variant={"h4"} customClass={" cursor-pointer"} color={"primary"}>
        {capitalizeEachLetter(Object.keys(brand)[0])}
      </MTypography>
      <MTypography variant={"subtitle2"} color={"#80828A"} customClass={" mt-2 mb-3"}>
        {brand[brandName].total === 1 ? `${brand[brandName].total} Product` : `${brand[brandName].total} Products`}
      </MTypography>
      {!opportunityBrands &&
        <>
      <div className={"w-100"}>
        <hr className={"m-0"} />
      </div>
          <div className={"d-flex justify-content-between align-items-center mt-3"}>
            <div className={""}>
              <MTypography variant={"subtitle2"} color={"#80828A"} customClass={" mt-2 mb-3"}>
                Generating: <strong> {` ${brand[brandName].generating}`}</strong>
              </MTypography>
            </div>
            <div className={""}>
              <MTypography variant={"subtitle2"} color={"#80828A"} customClass={" mt-2 mb-3"}>
                Draft: <strong> {` ${brand[brandName].draft}`}</strong>
              </MTypography>
            </div>
            <div className={"c"}>
              <MTypography variant={"subtitle2"} color={"#80828A"} customClass={" mt-2 mb-3"}>
                Edited: <strong> {` ${brand[brandName].edited}`}</strong>
              </MTypography>
            </div>
          </div>
          <div className={"d-flex justify-content-between align-items"}>
            <div className={""}>
              <MTypography variant={"subtitle2"} color={"#80828A"} customClass={" mt-2 mb-3"}>
                Approved: <strong> {` ${brand[brandName].approved}`}</strong>
              </MTypography>
            </div>
            <div className={""}>
              <MTypography variant={"subtitle2"} color={"#80828A"} customClass={" mt-2 mb-3"}>
                Error: <strong> {` ${brand[brandName].error}`}</strong>
              </MTypography>
            </div>
            <div className={""}>
              <Space right={"3rem"} />
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default BrandCard;
