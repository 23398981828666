import { SetClientsStatsInterface } from "../../apis/types/clients";
import { clientsActionTypes } from "./clients.action-types";

interface SetClientsActiveTab {
  type: clientsActionTypes.setClientsActiveTab;
  payload: string;
}

export const setClientsActiveTab = (tab: string): SetClientsActiveTab => ({
  type: clientsActionTypes.setClientsActiveTab,
  payload: tab,
});

interface SetClientsStats {
  type: clientsActionTypes.setClientsStats;
  payload: SetClientsStatsInterface;
}

export const setClientsStatsDispatch = (stats: SetClientsStatsInterface): SetClientsStats => ({
  type: clientsActionTypes.setClientsStats,
  payload: stats,
});

interface SetClientsRoleFilter {
  type: clientsActionTypes.setClientsRoleFilter;
  payload: string | "";
}

export const setClientsRoleFilterDispatch = (stats: string | ""): SetClientsRoleFilter => ({
  type: clientsActionTypes.setClientsRoleFilter,
  payload: stats,
});

export type ClientsReducerActionType =
  | SetClientsActiveTab
  | SetClientsStats
  | SetClientsRoleFilter