export const ERR_INVALID_EMAIL_PASSWORD = "Invalid Email or Password ";
export const ERR_USER_NAME_EMPTY = "Username cannot be empty";
export const ERR_COMPANY_NAME_EMPTY = "Company Name cannot be empty";
export const ERR_EMAIL_EMPTY = "Email cannot be empty";
export const ERR_EMAIL = "Email is not valid";
export const ERR_PASSWORD_EMPTY = "Password cannot be empty";
export const ERR_PASSWORD_WEAK = "Weak Password. Password should be 6 charters long";
export const ERR_PASSWORD_ENHANCED = "For enhanced security, please create a stronger password using a mix of characters.";
export const ERR_EMAIL_ALREADY_EXIST =
  "That email address is already associated with an account. Please try a different email address";
export const ERR_LOG_OUT = "We're having trouble signing you out. Please try again later";
export const ERR_NO_DOMAIN = "For security reasons, please use a valid email address with @automatoai.com domain";
export const ERR_USER_CREATED = "Looks like there was a problem creating your account. Please try again";
export const ERR_EMAIL_NOT_FOUND =
  "That email address is not associated with any account. Please try a different email address";
export const ERR_INVALID_NETWORK_REQUEST = "System not connected to internet";
export const ERR_TOO_MANY_EMAIL_VERIFICATION_REQUEST =
  "Your email has not been verified, and we have sent multiple resend message requests. We are unable to send any more at the moment.";
export const ERR_EMAIL_NOT_VERIFIED = "You need to verify your email first, To verify check your email inbox";
export const ERR_USER_SESSION_EXPIRE = "Your current Session is Expire";
export const ERR_USER_NOT_IN_DB = "User not found in database";
export const ERR_FORGOT_PASSWORD_REQUEST = "Cannot reset password at this time. Please try later";
export const ERR_ASIN_EMPTY = "ASIN ID cannot be empty";
export const ERR_ASIN_INVALID_LENGTH = "ASIN ID length should be equal to 10";
export const ERR_INVALID_ASIN_LINK = "Cannot find any valid ASIN in provided input";

export const ERR_ASIN_INVALID_START = "ASIN ID must start with B0";
export const ERR_COMPETITORS_LIMIT = "You can select 9 competitors only";
export const CANNOT_CONTINUE_NO_COMPETITOR = "Cannot continue because this product does not have any competitors";
export const ERR_KEYWORDS_NOT_FOUND = "Cannot load keywords. Please try again later.";
export const ERR_ADD_FEEDBACK = "Cannot add feedback. please try later";
export const ASIN_LIMIT = "ASIN limit is 10";
export const INVALID_ASIN_INPUT = "Input value contain invalid ASIN";
export const ERR_INVALID_EMAILS = "Please enter valid email addresses.";
export const ERR_FILL_ALL_EMAILS = "Please fill in all email fields.";
export const ERR_UNABLE_TO_DELETE_INVITE_LINK = "Cannot delete invitation link. Please try again later.";

export const UserReachedRegenLimit = "The product owner's content regeneration limit has been reached.";
export const UnlockGreaterPossibilities =
  "Unlock greater possibilities! Elevate your experience with an upgraded plan. Need assistance? Contact our friendly sales team. _https://meetings.hubspot.com/mirjana2";
export const ERR_AMAZON_AUTHORIZATION =
  "Error with Amazon Authorization. Please double-check your Amazon Authorization or reach out to AutoMatoAI Customer Support.";
export const ERR_CSV_EXPORT =
  "Export must contain products, please check your filters applied.";
