import React from "react";

import { OutlinedInput } from "@mui/material";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";

type tpProp = {
  name: string;
  type: string;
  id?: string;
  value: string | number;
  placeholder?: string;
  endAdornment?: React.ReactNode;
  rootClass?: any;
  onChange(e?: any): void;
  inputProps?: InputBaseComponentProps;
  customClass?: any;
  sx?: any;
  readonly?: boolean;
  autoComplete?: string
};
const MOutlinedInput = ({
  placeholder,
  type,
  value,
  endAdornment,
  name,
  id,
  onChange,
  inputProps,
  readonly,
  customClass = "",
  rootClass = {},
  autoComplete = "",
  sx = {},
}: tpProp): JSX.Element => {
  return (
    <OutlinedInput
      type={type}
      id={id}
      placeholder={placeholder}
      endAdornment={endAdornment}
      name={name}
      value={value}
      sx={sx}
      classes={customClass ? customClass : { root: rootClass }}
      onChange={onChange}
      inputProps={inputProps}
      readOnly={readonly}
      autoComplete={autoComplete}
    />
  );
};

export default MOutlinedInput;
