import React, { useEffect } from "react";

import RolesDropDownWithoutOwner from "../rolesDropDown";
import { DatabaseUser } from "../../../../../apis/types/user";
import { AccountManagementState } from "../../../../../pages/AccountManagement";
// import MOutlinedInput from "../../../../Atoms/MOutlinedInput";
import MuiSwitch from "../../../../Atoms/Switch";

import classes from "./index.module.css";
import MTypography from "../../../../Atoms/MTypography";
// import { Divider } from "@mui/material";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
import {
  // canUpdateContentGeneration,
  // canUpdateContentRegeneration,
  // canUpdateHelium10Calls,
  isDomainUserAndNewRoleIsUser,
} from "../../../../../utils/helpers/priviligesChecks";

interface EditUserPrivilegesDialogContentProps {
  userManagementState: AccountManagementState;
  setUserManagementState: React.Dispatch<React.SetStateAction<AccountManagementState>>;
}
const EditUserPrivilegesDialogContent: any = ({
  setUserManagementState,
  userManagementState,
}: EditUserPrivilegesDialogContentProps) => {
  const { name, role, contentGenerationLimit, helium10CallsLimit, contentRegenerationLimit, email } =
    userManagementState.selectedUser as DatabaseUser;

  const { userStatus } = userManagementState;
  const { isDesktop } = useDeviceType();
  const changeStatus = () => {
    setUserManagementState((prevState) => ({ ...prevState, userStatus: !userStatus }));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isDomainUserAndNewRoleIsUser(email, role)) {
      setUserManagementState({
        ...userManagementState,
        selectedUser: {
          ...userManagementState.selectedUser,
          contentGenerationLimit: contentGenerationLimit ?? 5,
          contentRegenerationLimit: contentRegenerationLimit ?? 25,
          helium10CallsLimit: helium10CallsLimit ?? 10,
        },
      });
    }
  }, [role])

  return (
    <div className={`d-flex flex-column ${isDesktop && classes.dialogContainer} `}>
      <div className="d-flex w-100 align-items-center ">
        <div className="d-flex w-50">
          <MTypography variant="subtitle2" customClass={` ${classes.label} `}>
            {"Name: "}
          </MTypography>

          <MTypography variant="subtitle2" customClass={` ${classes.label} ${classes.name}`}>
            {name}
          </MTypography>
        </div>
        <div className={`w-50 d-flex justify-content-end ${classes.switch}`}>
          <MuiSwitch
            labelClassName={classes.label}
            name="status"
            label="Active / Inactive"
            checked={userStatus}
            onChange={changeStatus}
          />
        </div>
      </div>
      <div className={`d-flex flex-column gap-2 mt-1 w-100`}>
        <MTypography variant={"subtitle2"} customClass={` ${classes.label} `}>
          {"Role"}
        </MTypography>
        <RolesDropDownWithoutOwner
          userManagementState={userManagementState}
          setUserManagementState={setUserManagementState}
        />
      </div>
      {/* <div className={"mt-4"}>
        <MTypography variant={"subtitle2"} customClass={isDesktop ? classes.limits : classes.limitsM}>
          {"Set Limits"}
        </MTypography>
        <Divider className={`my-2 ${classes.divider}`} />
      </div>

      <div className=" d-flex mt-3 gap-4 flex-wrap">
        <div className={`d-flex flex-column gap-2 col-md-4`}>
          <MTypography variant={"subtitle2"} customClass={` ${classes.label} `}>
            {"ASIN Limit"}
          </MTypography>
          <MOutlinedInput
            sx={{
              "& .MuiInputBase-input": {
                padding: "9.5px 12px",
              },
            }}
            value={canUpdateContentGeneration(role) ? contentGenerationLimit || "" : "Unlimited"}
            name="contentGenerationLimit"
            type={canUpdateContentGeneration(role) ? "number" : "text"}
            onChange={(e) => {
              const inputValue = e.target.value;
              const parsedValue = Math.max(0, parseInt(inputValue, 10));

              setUserManagementState({
                ...userManagementState,
                selectedUser: {
                  ...userManagementState.selectedUser,
                  contentGenerationLimit: isNaN(parsedValue) ? "" : parsedValue,
                },
              });
            }}
            inputProps={{
              readOnly: !canUpdateContentGeneration(role),
            }}
          />
        </div>
        <div className={`d-flex flex-column gap-2 col-md-4`}>
          <MTypography variant={"subtitle2"} customClass={` ${classes.label} `}>
            {"Content Regeneration"}
          </MTypography>
          <MOutlinedInput
            // rootClass={classes.FormInput}
            sx={{
              "& .MuiInputBase-input": {
                padding: "9.5px 12px",
              },
            }}
            value={canUpdateContentRegeneration(role) ? contentRegenerationLimit || "" : "Unlimited"}
            name="contentRegeneration"
            type={canUpdateContentRegeneration(role) ? "number" : "text"}
            onChange={(e) => {
              const inputValue = e.target.value;
              const parsedValue = Math.max(0, parseInt(inputValue, 10));
              setUserManagementState({
                ...userManagementState,
                selectedUser: {
                  ...userManagementState.selectedUser,
                  contentRegenerationLimit: isNaN(parsedValue) ? "" : parsedValue,
                },
              });
            }}
            inputProps={{
              readOnly: !canUpdateContentRegeneration(role),
            }}
          />
        </div>
        <div className={`d-flex flex-column gap-2  col-md-4`}>
          <MTypography variant={"subtitle2"} customClass={` ${classes.label} `}>
            {"Helium10 Calls"}
          </MTypography>
          <MOutlinedInput
            // rootClass={classes.FormInput}
            sx={{
              "& .MuiInputBase-input": {
                padding: "9.5px 12px",
              },
            }}
            value={canUpdateHelium10Calls(role) ? helium10CallsLimit || "" : "Unlimited"}
            name="helium10CallsLimit"
            type={canUpdateHelium10Calls(role) ? "number" : "text"}
            onChange={(e) => {
              const inputValue = e.target.value;
              const parsedValue = Math.max(0, parseInt(inputValue, 10));
              setUserManagementState({
                ...userManagementState,
                selectedUser: {
                  ...userManagementState.selectedUser,
                  helium10CallsLimit: isNaN(parsedValue) ? "" : parsedValue,
                },
              });
            }}
            inputProps={{
              readOnly: !canUpdateHelium10Calls(role),
            }}
          />
        </div>
      </div>*/}
    </div>
  );
};

export default EditUserPrivilegesDialogContent;
