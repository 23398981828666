import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormControl, MenuItem, Select } from "@mui/material";

import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import { completeProductDispatch } from "../../../../store/product/productDetails/productDetails.actions";
import { generateProductApis } from "../../../../apis/generate-product";
import { SUCC_TITLE_UPDATED } from "../../../../utils/constants/messages/success";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { errorHandler } from "../../../../utils/helpers/apis";
import { copyText } from "../../../../utils/helpers/common";
import { Colors } from "../../../../utils/themes/main-theme";
import { disableTitleVariationDropdown } from "../../../../utils/helpers/priviligesChecks";
import { ContentStatusEnum } from "../../../../utils/constants/enums";
import CopyButton from "../../../Atoms/CopyButton/CopyButton";
import MTypography from "../../../Atoms/MTypography";
import { getTitleVariationHeader } from "./config";
import classes from "./index.module.css";

interface TitleVariationsDropdownProps {
  isEditing: boolean;
}

const TitleVariationsDropdown = ({ isEditing = false }: TitleVariationsDropdownProps) => {
  const { isDesktop } = useDeviceType();
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  const [titlesList, setTitlesList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const completeProduct = useSelector(CompleteProductSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (completeProduct?.titleVariations) {
      const titleVariationsList =
        completeProduct.status !== ContentStatusEnum.APPROVED &&
        completeProduct.status !== ContentStatusEnum.PUBLISHED &&
        completeProduct.editedGeneratedProductContent.titleVariations
          ? completeProduct.editedGeneratedProductContent.titleVariations
          : completeProduct.titleVariations;
      const updatedVariations = titleVariationsList?.map((item) => ({
        ...item,
        header: getTitleVariationHeader(item.titleType),
      }));
      setTitlesList(updatedVariations);
      const selectedItem = updatedVariations.find((item) => item.selected);
      setSelectedTitle(selectedItem || { header: "No variations available" });
    }
  }, [completeProduct]);

  const handleRoleChange = async (event: any) => {
    setIsLoading(true);
    const selectedObject = titlesList.find((item: any) => item.header === event.target.value);
    setSelectedTitle(selectedObject || { header: "No variations available" });
    try {
      const resp = await generateProductApis.updateSelectedTitleVariant({
        productId: completeProduct.id,
        titleType: selectedObject.titleType,
      });
      let updatedProduct = {
        ...completeProduct,
        ...resp
      };

      if (Object.keys(completeProduct?.editedGeneratedProductContent)?.length !== 0) {
        updatedProduct = {
          ...completeProduct,
          ...resp
        };
      }

      dispatch(completeProductDispatch(updatedProduct));
      setIsLoading(false);
      toast.success(SUCC_TITLE_UPDATED);
    } catch (error: any) {
      console.log("error : ", error);
      setIsLoading(false);
      toast.error(errorHandler(error));
    }
  };

  return (
    <>
      {completeProduct?.titleVariations?.length ? (
        <div className={`d-flex align-items-center gap-2 px-2 ${isDesktop ? "" : "justify-content-between w-100"}`}>
          <MTypography variant={"subtitle1"} customClass={classes.TitleVariation}>
            Title Variations
          </MTypography>
          <FormControl sx={{ width: "206px" }}>
            <Select
              id="title-variation-select"
              value={selectedTitle?.header || ""}
              color="primary"
              onChange={handleRoleChange}
              disabled={
                disableTitleVariationDropdown(titlesList.length, completeProduct.status as ContentStatusEnum) ||
                isLoading ||
                isEditing
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: `${Colors.primary} !important`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${Colors.primary} !important`,
                },
                ".MuiSelect-icon": {
                  color: `${Colors.primary} !important`,
                },
                ".MuiSelect-select": {
                  color: `${Colors.primary} !important`,
                },
                "&.Mui-disabled": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#828282cc !important",
                  },
                  ".MuiSelect-icon": {
                    borderColor: "#828282cc !important",
                    color: "#828282cc !important",
                  },
                  ".MuiSelect-select": {
                    color: "#828282cc !important",
                  },
                },
                height: "40px",
                fontSize: "14px",
              }}
              renderValue={(selected) => (selected ? selected : <em>Select a title</em>)}
            >
              {titlesList.length === 0 ? (
                <MenuItem disabled>
                  <em>No variations available for this product</em>
                </MenuItem>
              ) : (
                titlesList.map((item: any, index: number) => (
                  <MenuItem
                    key={index}
                    value={item.header}
                    className={`mt-2 mb-3 mx-3 ${!item.selected && classes.HoverEffect} ${
                      item.selected ? classes.ActiveMenuItem : classes.MenuItem
                    }`}
                  >
                    <div className={"d-flex w-100 justify-content-between align-items-center"}>
                      <div className={classes.Header}>{item.header}</div>
                      <CopyButton
                        onClick={(e: any) => {
                          copyText(item.title);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                    <div className={classes.Title}>{item.title}</div>
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default TitleVariationsDropdown;
