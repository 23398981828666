import React from "react";
import classes from "./index.module.css";
import { UserStatusEnum } from "../../../utils/constants/enums";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import MTypography from "../../Atoms/MTypography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MuiTooltip from "../../Atoms/MuiTooltip";

interface UserStatusProps {
  type: UserStatusEnum;
  showInfo?: boolean;
  variant?: "small" | "large";
  text?: string;
  isCompany?: boolean;
}
const UserStatus: React.FC<UserStatusProps> = ({
  showInfo = true,
  text,
  variant = "large",
  type,
  isCompany = false,
}) => {
  const { isDesktop } = useDeviceType();
  let statusType = "";
  if (type) statusType = capitalizeEachLetter(type.replace("_", " "));
  const StatusContent: any = {
    [UserStatusEnum.Active]: {
      name: "Active",
      content: isCompany ? "This company is active." : "This user is active.",
    },
    [UserStatusEnum.Inactive]: {
      name: "Inactive",
      content: isCompany ? "This company is inactive." : "This user is inactive.",
    },
    [UserStatusEnum.Pause]: { name: "Pause", content: isCompany ? "This company is pause." : "This user is pause." },
    [UserStatusEnum.Pending]: { name: "Pending", content: "User has not yet created an account to join the team." },
  };

  if (!type) {
    return <div />;
  } else {
    return (
      <div
        className={`${
          variant === "large"
            ? isDesktop
              ? classes.TagContainer
              : classes.TagContainerM
            : isDesktop
            ? classes.TagContainerS
            : classes.TagContainerSM
        } ${classes[statusType.replace(" ", "")]}`}
      >
        <MTypography
          variant={"subtitle2"}
          customClass={`${
            variant === "large"
              ? isDesktop
                ? classes.StatusText
                : classes.StatusTextS
              : isDesktop
              ? classes.StatusTextM
              : classes.StatusTextSM
          }`}
        >
          {text || StatusContent[type]?.name}
        </MTypography>
        {showInfo && (
          <MuiTooltip arrow={true} content={`${StatusContent[type]?.content}`} placement={"right"}>
            <InfoOutlinedIcon
              fontSize="small"
              className={`d-flex align-items-center justify-content-center cursor-pointer ${
                (variant === "small" || !isDesktop) && classes.SmallIcon
              } ${classes.LightStatusColor}`}
            />
          </MuiTooltip>
        )}
      </div>
    );
  }
};

export default UserStatus;
