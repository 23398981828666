import React from "react";

import MTypography from "../../Atoms/MTypography";

import classes from "./index.module.css";
import MuiTooltip from "../../Atoms/MuiTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
  mobile?: boolean
}

const WhatIsGoGoToolTip: React.FC<Props> = (props) => {
  const { mobile } = props;
  return (
    <div className={`d-flex w-100 gap-1 align-items-center ${mobile ? 'justify-content-center' : 'justify-content-end' }`} style={{ marginTop: '15px' }}>
      <MuiTooltip arrow={true} content={`The world’s first ever fully automated competitor research, keyword research, strategy and content creation tool. With just one click AutoMato will create fully optimized content for you.`} placement={"top"}>
        <InfoOutlinedIcon
          fontSize="small"
          className={`d-flex align-items-center justify-content-center cursor-pointer`}
        />
      </MuiTooltip>
      <MTypography variant="subtitle2" color="#7E7E7E" customClass={classes.WhatIsGoGoText}>
        What is Go Go AutoMato?
      </MTypography>
    </div>
  );
};

export default WhatIsGoGoToolTip;
