import React from "react";
import { Slider } from "@mui/material";
import { useDeviceType } from "../../../hooks/useDeviceType";

const lineSlider = {
  "& .MuiSlider-mark": {
    height: 8,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabel": { fontSize: "12px" },
};

interface MSliderProps {
  min?: number;
  max?: number;
  marks?:
    | boolean
    | {
        value: number;
        label?: React.ReactNode;
      }[];
  step?: number;
  value: number;
  name: string;
  onChange: (e: any) => void;
  showLines?: boolean;
  customClass?: any;
  disabled?: boolean;
}

const MSlider: React.FC<MSliderProps> = ({
  name,
  max,
  min,
  marks,
  value,
  onChange,
  step,
  showLines,
  customClass,
  disabled = false,
}) => {
  const { isDesktop } = useDeviceType();

  return (
    <Slider
      min={min}
      max={max}
      aria-label="Custom marks"
      marks={marks}
      valueLabelDisplay="auto"
      value={value}
      onChange={onChange}
      size={isDesktop ? "medium" : "small"}
      step={step}
      name={name}
      disabled={disabled}
      sx={showLines ? lineSlider : { "& .MuiSlider-markLabel": { fontSize: "12px" } }}
      classes={{ root: customClass }}
    />
  );
};

export default MSlider;
