import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTheme } from "@mui/material/styles";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";

import classes from "./index.module.css";
import FetchAsinForm from "../../../Organisms/FetchAsin/FetchAsinForm";
import { useDeviceType } from "../../../../hooks/useDeviceType";

type AddAsinDialogeProps = DialogProps & {
  title?: string;
  open: boolean;
  onClose: () => void;
  companyAdminID?: string | null;
};

const AddAsinDialoge = (props: AddAsinDialogeProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [productFetch, setProductFetch] = React.useState<boolean>(false);
  const { isDesktop } = useDeviceType();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          maxWidth: "fit-content",
        },
      }}
    >
      {props?.title && (
        <DialogTitle className={classes.Title}>
          <div className="flex-grow-1">{props.title}</div>
          <IconButton
            sx={{
              padding: 0,
              color: "inherit",
              "&:hover": {
                background: "none",
              },
            }}
            onClick={props.onClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={`${isDesktop ? classes.BodyContainer : ""}`}>
        <div className={``}>
          <FetchAsinForm
            isVisible={true}
            setProductFetch={setProductFetch}
            productFetch={productFetch}
            isModalView={true}
            companyAdminID={props.companyAdminID}
            closeAddAsinModal={props.onClose}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default AddAsinDialoge;
