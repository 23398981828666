import { produce } from "immer";
import { AccessKeysActionTypes } from "./accessKeys.action-types";
import { apiAccessKeysActionType } from "./accessKeys.actions";
import { ApiAccessKey } from "../../apis/types/apiAccessKey";

export interface State {
  allAccessKeys: ApiAccessKey[]
}

const initialState: State = {
  allAccessKeys: []
};

const accessKeysReducer = produce((state: State = initialState, action: apiAccessKeysActionType) => {
  switch (action.type) {
    case AccessKeysActionTypes.setAccessKeysList:
      const dataList = action.payload;
      // adding status because it is not comming in api res if status is true for key
      let afterAddingisActive: ApiAccessKey[] = []
      if (dataList) {
        afterAddingisActive = dataList?.map(e => ({
          ...e,
          status: typeof e.status !== 'undefined' ? e.status : true
        }))
      }
      state.allAccessKeys = (afterAddingisActive === null || afterAddingisActive === undefined) ? [] : afterAddingisActive.reverse()
      return state;
    default:
      return state;
  }
});

export default accessKeysReducer;
