import React from "react";

import classes from "./index.module.css";

interface Props {
  percentage: number;
}

const PercentageTag: React.FC<Props> = (props) => {
  const { percentage } = props;

  const getBackGroundColor = () => {
    if (percentage >= 0 && percentage <= 20) {
      return classes.Twenty;
    } else if (percentage > 20 && percentage <= 40) {
      return classes.Forty;
    } else if (percentage > 40 && percentage <= 60) {
      return classes.Sixty;
    } else if (percentage > 60 && percentage <= 80) {
      return classes.Eighty;
    } else if (percentage > 80 && percentage <= 100) {
      return classes.Hundred;
    } else {
      return classes.Twenty;
    }
  };

  return <span className={`${classes.Container} ${getBackGroundColor()}`}>{percentage || 0}%</span>;
};

export default PercentageTag;
