import { RolesEnum } from "../../../utils/constants/enums";

export const clientsFilterOptionForCompanies = [
    { label: "All", value: "" },
    { label: "Paid", value: RolesEnum.PAID },
    { label: "Trial ", value: RolesEnum.TRIAL },
];

export const clientsFilterOptionForIndividuals = [
    { label: "All", value: "" },
    { label: "Paid", value: RolesEnum.PAID },
    { label: "Paid editor", value: RolesEnum.PAID_EDITOR },
    { label: "Trial ", value: RolesEnum.USER },
];