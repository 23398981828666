import React from "react";
import NoBrandVoiceView from "../NoBrandVoiceView/NoBrandVoiceView";
import TabsToolbar from "../../../../Molecules/TabsToolbar";
import MTypography from "../../../../Atoms/MTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
import { URL_LANDING_PAGE } from "../../../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../../../utils/constants/enums";
import { useNavigate } from "react-router-dom";
import { navigatePage } from "../../../../../utils/helpers/common";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";
import BrandVoiceDescription from "../BrandVoiceDescription";

const BrandVoiceStarted: React.FC = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND}`, navigate);
  };

  const showNoBrand = false;
  return (
    <div>
      {isDesktop ? (
        <TabsToolbar
          title={"BRAND VOICE"}
          children={
            <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
              <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
            </div>
          }
        />
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2"}>
            <ArrowBackIcon onClick={navigateToSettingsTab} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>{capitalizeEachLetter(SettingsEnum.BRAND.replace("-", " "))}</MTypography>
          </div>
        </div>
      )}

      {showNoBrand ? <NoBrandVoiceView /> : <BrandVoiceDescription />}
    </div>
  );
};

export default BrandVoiceStarted;
