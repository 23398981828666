import React, { useState } from "react";
import { Grid } from "@mui/material";

import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";

import UserStatus from "../../../../Molecules/UserStatus";
import ActionMenuWithIconM from "../../../../Molecules/ActionMenuWithIconM/ActionMenuWithIconM";
import MenuOption from "../../../../Molecules/MenuOption";
import MTypography from "../../../../Atoms/MTypography";

import classes from "./index.module.css";

type PendingInvitationCardProps = {
  inviteDetails: any;
  deletePendingInvitation: (invitationData: any) => void;
  handleRemindInvitedUserClick: (invitationData: any) => void;
};

const PendingInvitationCard: React.FC<PendingInvitationCardProps> = ({ inviteDetails, deletePendingInvitation, handleRemindInvitedUserClick }) => {
  const { email, role, status, id } = inviteDetails;
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <div className={"card mt-4 cursor-pointer " + classes.ProductCard} key={id}>
      <div className="d-flex justify-content-between">
        <div
          style={{ maxWidth: "100%", overflow: "hidden", display: "block" }}
          className={`${classes.CellLinesLimitContainer} ${classes.Email}`}
        >
          {email}
        </div>
        <div>
          <ActionMenuWithIconM showMenu={showMenu} setShowMenu={setShowMenu}>
            <div className={"py-2"}>
              <MenuOption>
                <div
                  className={"cursor-pointer text-start"}
                  onClick={() => {
                    setShowMenu(false);
                    handleRemindInvitedUserClick({ email, role, status, id })
                  }}
                >
                  Reminder
                </div>
              </MenuOption>
              <MenuOption>
                <div
                  className={"cursor-pointer text-start"}
                  onClick={() => {
                    setShowMenu(false);
                    deletePendingInvitation({ email, role, status, id })
                  }}
                >
                  Delete
                </div>
              </MenuOption>
            </div>
          </ActionMenuWithIconM>
        </div>
      </div>

      <Grid container mt={"8px"} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={4} className="h-50">
          <MTypography variant={"body1"} customClass={classes.UserDetails}>
            {capitalizeEachLetter(role || " ")}
          </MTypography>
          <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
            Role
          </MTypography>
        </Grid>
        <Grid item xs={4} className="h-50">
          <MTypography variant={"body1"} customClass={classes.UserDetails}>
            <UserStatus type={status} showInfo variant={"small"} />
          </MTypography>
          <MTypography variant={"subtitle2"} customClass={`${classes.SubtitleColor} ${classes.UserAttributes}`}>
            Status
          </MTypography>
        </Grid>
      </Grid>

      <div className="d-flex justify-content-between align-items-center"></div>
      <div className={"w-100 pt-1"}></div>
    </div>
  );
};
export default PendingInvitationCard;
