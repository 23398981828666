import React, { useState } from "react";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { RolesEnum, SettingsEnum, TabsEnum, UserStatusEnum } from "../../../utils/constants/enums";
import MTypography from "../../../components/Atoms/MTypography";
import { useNavigate } from "react-router-dom";
import { useDeviceType } from "../../../hooks/useDeviceType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import { Form, Formik, FormikHelpers } from "formik";
import { InternalUserFormObj, initialValue, inputName, validationSchemaForInternalUser } from "./config";
import HeadingUnderline from "../../../components/Molecules/HeadingUnderline";
import FormTextField from "../../../components/Molecules/FormTextField";
import MButton from "../../../components/Atoms/MButton";
import FormSwitch from "../../../components/Molecules/FormSwitch/FormSwitch";
import FormRolesDropdown from "../../../components/Molecules/FormRolesDropdown/FormRolesDropdown";
import classes from "./index.module.css";
import { validateEmail } from "../../../utils/helpers/general-helpers";
import { ERR_INVALID_EMAILS } from "../../../utils/constants/messages/errors";
import { toast } from "react-toastify";
import { UserApis } from "../../../apis/user";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import { errorHandler } from "../../../utils/helpers/apis";

const AddInternalUser = () => {
  const navigate = useNavigate();
  const [initialFormValue, setInitialFormValue] = useState<InternalUserFormObj>(initialValue)
  const { isDesktop } = useDeviceType();

  const handleBackClick = () => {
    navigate(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.USERS}`);
  };

  const submitForm = async (values: InternalUserFormObj, { resetForm }: FormikHelpers<InternalUserFormObj>) => {
    try {
      if (!validateEmail(values.email)) {
        toast.error(ERR_INVALID_EMAILS);
        return false;
      }

      await UserApis.createInternalUser({
        isOfficialUser: true,
        isAgreeForMarketing: false,
        phoneNumber: "",
        name: values?.name,
        email: values?.email,
        password: values?.password,
        role: values?.userRole, 
        status: values?.status ? UserStatusEnum.Active : UserStatusEnum.Pause,
      }).then(() => {
        setInitialFormValue(initialValue)
        resetForm();
        setTimeout(() => {
          toast.success("User created successfully");
        }, 500);
        handleBackClick()
      }
      ).catch((error: any) => {
        toast.error(errorHandler(error))
      });

      return true;
    } catch (e: any) {
      toast.error(e);
      return false;
    }
  };

  return (
    <div>
      <div className="">
        {isDesktop ? (
          <TabsToolbar
            title={SettingsEnum.ADD_INTERNAL_USER}
            children={
              <div className="d-flex align-items-center gap-4">
                <div className={"cursor-pointer"} onClick={handleBackClick}>
                  <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
                </div>
              </div>
            }
          />
        ) : (
          <div className="p-2 m-2">
            <div className={"my-2 "}>
              <ArrowBackIcon onClick={handleBackClick} />
            </div>
            <div className={"my-2 mx-1 d-flex justify-content-between"}>
              <MTypography variant={"h5"}>
                {capitalizeEachLetter(SettingsEnum.ADD_INTERNAL_USER.split("-").join(" "))}
              </MTypography>
            </div>
          </div>
        )}
        <div>
          <Formik
            enableReinitialize
            initialValues={initialFormValue}
            onSubmit={submitForm}
            validationSchema={validationSchemaForInternalUser}
          >
            {({ handleSubmit, dirty, isValid }) => (
              <Form autoComplete="off">
                <div className={`${isDesktop && "pt-4"} px-4`}>
                  <>
                    <div className="row pb-3">
                      <div className={`col-md-6 col-sm-12 ${!isDesktop && "pb-2"}`}>
                        <FormRolesDropdown 
                          name={inputName.userRole}
                          minWidth={isDesktop ? "50%" : "100%"}
                          label="User's Role"
                          selectableRoles={[RolesEnum.ADMIN, RolesEnum.EDITOR, RolesEnum.INTERNAL]}
                        />
                        <p className={classes.TextLabel}>User roles determine user permissions.</p>
                      </div>
                      <div className={"col-md-6 col-sm-12 d-flex justify-content-end align-items-start"}>
                        <FormSwitch label={"Active / Inactive"} name={inputName.status} />
                      </div>
                    </div>
                    <HeadingUnderline content={"Basic Details"} />
                    <div className={"row"}>
                      <div className={"col-md-6 col-sm-12"}>
                        <FormTextField fullWidth name={inputName.name} label={"Full name"} />
                      </div>
                      <div className={"col-md-6 col-sm-12"}>
                        <FormTextField fullWidth name={inputName.email} label={"Work Email"} />
                      </div>
                      <HeadingUnderline content={"Create Password"} />
                      <div className={"col-md-6 col-sm-12"}>
                        <FormTextField fullWidth name={inputName.password} label={"Password"} />
                      </div>

                      <div className={"col-md-6 col-sm-12"}>
                        <FormTextField fullWidth name={inputName.confirmPassword} label={"Confirm Password"} />
                      </div>
                    </div>
                  </>
                  <div className="d-flex justify-content-end mt-4">
                    <MButton
                      // isLoading={isLoading}
                      size={"large"}
                      variant={"contained"}
                      onClick={handleSubmit}
                      disabled={!dirty || !isValid}
                    >
                      Add
                    </MButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddInternalUser;
