import { Box, DialogProps, TextField } from "@mui/material";
import DialogBox from "../../../../mui/dialogebox"
import DomainDropDown from "../../../DomainDropDown";
import { countryDomain, getCountryDomainObjectByName } from "../../../DomainDropDown/config";
import { useEffect, useState } from "react";
import { CountryDomainEnum } from "../../../../../utils/constants/enums";
import { UpdateBulkProductPayload } from "../../../../../apis/types/generate-product";
import { fetchAmazonASIN } from "../../../../../utils/helpers/regex";
import { toast } from "react-toastify";
import { ERR_ASIN_EMPTY, ERR_ASIN_INVALID_LENGTH, ERR_ASIN_INVALID_START, ERR_INVALID_ASIN_LINK } from "../../../../../utils/constants/messages/errors";

type UpdateWaitingProductBoxProps = DialogProps & {
    updateBulkProductAsin: (data: UpdateBulkProductPayload) => void;
    selectedRow: any;
};

export interface EditAsinState {
    asinId: string;
    loading: boolean;
    productResp: any;
    centerBl: boolean;
    selectedDomain: { name: CountryDomainEnum; flag: string };
    fetchProduct: boolean;
}

const UpdateWaitingProductBox = ({ open, onClose, updateBulkProductAsin, selectedRow }: UpdateWaitingProductBoxProps) => {

    const [asinInput, setAsinInput] = useState(selectedRow?.productASIN || "")
    const [state, setState] = useState<EditAsinState>({
        asinId: "",
        loading: false,
        productResp: {} as any,
        centerBl: true,
        selectedDomain: countryDomain[0],
        fetchProduct: false
    });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setState({
            ...state,
            asinId: selectedRow._id || "",
            selectedDomain: getCountryDomainObjectByName(selectedRow.domain) || countryDomain[0],
        })
        setAsinInput(selectedRow?.productASIN)

    }, [open, selectedRow])

    const handleConfirm = () => {

        const fetchAsin = fetchAmazonASIN(asinInput);

        if (fetchAsin && fetchAsin.length === 10 && fetchAsin.substring(0, 2) === "B0") {
            const data = {
                id: selectedRow?._id,
                productASIN: fetchAsin,
                userID: selectedRow?.userID,
                domain: state.selectedDomain.name,
            }
            updateBulkProductAsin(data)
        } else {
            if (!asinInput) toast.error(ERR_ASIN_EMPTY);
            else if (!fetchAsin) toast.error(ERR_INVALID_ASIN_LINK);
            else if (fetchAsin.length !== 10) toast.error(ERR_ASIN_INVALID_LENGTH);
            else toast.error(ERR_ASIN_INVALID_START);
        }
    };

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClose) onClose(event, "backdropClick");
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAsinInput(e.target.value)
    }
    return (
        <div style={{ maxWidth: '100%' }}>
            <DialogBox
                open={open}
                onClose={onClose}
                cancelButton={{
                    action: handleCancel as any, element: "Cancel"
                }}
                confirmButton={{
                    action: handleConfirm,
                    element: <> {"Update"}</>,
                }}
                title={"Edit ASIN"}
            >
                <Box sx={{ width: '580px', maxWidth: '100%', display: 'flex' }}>
                    <Box sx={{ maxWidth: { xs: "100%", md: '40%' } }}>
                        <DomainDropDown setFormState={setState} formState={state} />
                    </Box>
                    <Box sx={{ width: { xs: "100%" } }}>
                        <TextField
                            onChange={handleChange as any}
                            name={"tagName"}
                            value={asinInput}
                            placeholder={"Enter Asin"}
                            fullWidth
                            sx={{
                                "& .MuiOutlinedInput-input": { height: {xs: "21px !important", md: '21px !important', lg: '34px !important'} }
                            }}
                        />
                    </Box>

                </Box>
            </DialogBox>
        </div>
    )
}

export default UpdateWaitingProductBox;