import * as yup from "yup";

export const profileType = {
  userProfile: "userProfile",
  companyProfile: "companyProfile",
};

export interface ProfileObject {
  name: string;
  userName: string;
  email: string;
  phoneNo: string;
  password: string;
  newPassword: string;
  reEnterNewPassword: string;
  asinLimit: number;
  keywordRefreshes: number;
  contentRegeneration: number;
}

export const inputName = {
  name: "name",
  userName: "userName",
  email: "email",
  phoneNo: "phoneNo",
  password: "password",
  newPassword: "newPassword",
  reEnterNewPassword: "reEnterNewPassword",
  asinLimit: "asinLimit",
  keywordRefreshes: "keywordRefreshes",
  contentRegeneration: "contentRegeneration",
};

export const initialValue: ProfileObject = {
  name: "",
  userName: "",
  email: "",
  phoneNo: "",
  password: "",
  newPassword: "",
  reEnterNewPassword: "",
  asinLimit: 0,
  keywordRefreshes: 0,
  contentRegeneration: 0,
};

export const validationSchemaBasicDetails = yup.object().shape({
  name: yup.string().trim().required("Company name is required"),
  userName: yup.string().trim().required("User name  is required"),
});
export const validationSchemaBasicDetailsForUser = yup.object().shape({
  name: yup.string().trim().required("Full name is required"),
});

export const validationSchemaPassword = yup.object().shape({
  currentPassword: yup.string().trim().required("Current password is required"),
  newPassword: yup
    .string()
    .trim()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  reEnterNewPassword: yup
    .string()
    .trim()
    .required("Please re-enter your new password")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export const profileTabsValue = {
  basicDetails: "Basic Details",
  changePassword: "Security",
  subscription: "Subscription",
};
export const profileTabs = [
  profileTabsValue.basicDetails,
  // profileTabsValue.changePassword,
  // profileTabsValue.subscription,
];
