import * as yup from "yup";

export interface CreateProductObject {
  title: string;
  productContent: string;
}

export const inputName = {
  title: "title",
  productContent: "productContent",
};

export const initialValue: CreateProductObject = {
  title: "",
  productContent: "",
};

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required("Title is required"),
  productContent: yup.string().trim().required("Product Content is required."),
});
