import React from "react";
import { Variant } from "../../../../../interfaces/scrapeProduct";
import classes from "./index.module.css";
import MTypography from "../../../../Atoms/MTypography";
import { handleOpenLink } from "../../../../../utils/helpers/common";
const ProductVariant: React.FC<{ variant: Variant }> = ({ variant }) => {
  return (
    <div className={classes.Container}>
      <div className={""}>
        {variant?.main_image && <img src={variant?.main_image} alt={"variant"} className={classes.Image} />}
      </div>

      <div className={"d-flex justify-content-start flex-column"}>
        {variant?.title && (
          <MTypography variant={"subtitle2"} customClass={classes.Title}>
            {variant?.title}
          </MTypography>
        )}
        {variant.asin && (
          <MTypography variant={"subtitle2"} customClass={classes.ASIN}>
            <span onClick={() => handleOpenLink(variant?.link)}>{variant?.asin}</span>
          </MTypography>
        )}
      </div>
    </div>
  );
};

export default ProductVariant;
