import React from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface MDatePickerProps {
  onChange: any;
  disableFuture?: boolean;
  label?: string;
  value: any;
}
const MDatePicker: React.FC<MDatePickerProps> = (props) => {
  const { onChange, disableFuture = false, label = "", value } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disableFuture={disableFuture}
        label={label}
        views={["year", "month", "day"]}
        value={value}
        onChange={(newValue: any) => {
          onChange(newValue);
        }}
        renderInput={(params: any): any => <TextField margin={"dense"} {...params} />}
      />
    </LocalizationProvider>
  );
};

export default MDatePicker;
