import React from "react";
import MTypography from "../../../../Atoms/MTypography";
import classes from "./index.module.css";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
import { Competitor } from "../../../../../apis/types/generate-product";
import { defaultImage } from "../../../../../utils/constants/general-constants";
import { onImgErrorHandler } from "../../../../../utils/helpers/general-helpers";

interface DetailsCompetitorsCardProps {
  competitor: Competitor;
}

const DetailsCompetitorsCard = ({ competitor }: DetailsCompetitorsCardProps) => {
  const handleOpenLink = (link: string) => {
    window.open(link, "_blank")?.focus();
  };
  const { isDesktop } = useDeviceType();
  return (
    <div className={"d-flex"}>
      <div className={classes.ImageDiv}>
        <img src={competitor?.image || defaultImage} onError={(e) => onImgErrorHandler(e, defaultImage)} alt={"comp-img"} className={classes.Image} />
      </div>
      <div className={isDesktop ? classes.DetailGrid : classes.DetailGridM}>
        <div onClick={() => handleOpenLink(competitor?.link as string)}>
          <MTypography variant={"subtitle1"} customClass={classes.Asin}>
            {competitor?.asin}
          </MTypography>
        </div>
        <MTypography variant={"subtitle1"} customClass={classes.Title}>
          {competitor?.title}
        </MTypography>
      </div>
    </div>
  );
};

export default DetailsCompetitorsCard;
