import React from "react";
import { Skeleton } from "@mui/material";

import classes from "./index.module.css";
import MTypography from "../MTypography";
import { useDeviceType } from "../../../hooks/useDeviceType";

interface OpportunityReportCardProps {
  title?: string;
  volume?: number | string;
  showPercentSign?: boolean;
  loading?: boolean;
}

const OpportunityReportCard: React.FC<OpportunityReportCardProps> = ({
  loading,
  title,
  volume,
  showPercentSign = false,
}) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={isDesktop ? classes.CardContainer : classes.CardContainerM}>
      <div className={classes.CardTopDiv}>
        <MTypography
          variant={"subtitle1"}
          customClass={`${isDesktop ? classes.CardTitle : classes.CardTitleM}`}
          color={"#4d4d4d"}
        >
          {title}
        </MTypography>
      </div>
      <div className={`d-flex ${isDesktop ? "mt-3" : "mt-1"} justify-content-between align-items-center text-center`}>
        {!loading ? (
          <MTypography variant={"h1"} customClass={isDesktop ? classes.VolumeText : classes.VolumeTextM}>
            {volume}
            {showPercentSign && "%"}
          </MTypography>
        ) : (
          <Skeleton className={"w-100"} variant={"text"} />
        )}
      </div>
    </div>
  );
};

export default OpportunityReportCard;
