import React from "react";

interface SuccessToastProps {
  title: string;
  subtitle: string;
}

const SuccessToast: React.FC<SuccessToastProps> = ({ title = "", subtitle = "" }) => {
  return (
    <div className={"d-flex align-items-center gap-2"}>
      <div className={"d-flex flex-column"}>
        <p
          style={{
            color: "#2E2E2E",
            fontSize: "14px",
            fontWeight: 500,
            margin: 0,
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: "#80828A",
            fontSize: "12px",
            fontWeight: 400,
            margin: 0,
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default SuccessToast;
