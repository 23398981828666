import { Product } from "../../apis/types/generate-product";

import { generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription, ProductApis } from "../../apis/product";

import { KeywordsApis } from "../../apis/keywords";
import { generateProductApis } from "../../apis/generate-product";
import { Dispatch } from "redux";
import { createKeywordObject } from "../../pages/Product/keywords/config";
import { AsinKeywordHistory, TotalProductStatuses } from "./product.reducers";
import { AppState } from "../reducers";
import { URL_FETCH_ASIN } from "../../routes/routes-path";
import { RolesEnum, TagsActionEnums } from "../../utils/constants/enums";
import { setFirstLoginDispatch } from "../user/user.actions";
import { transformKeywordsHistory } from "../../utils/helpers/apis";
import { SetScrapeProduct } from "./productScrape/productScrape.actions";
import { ProductActionTypes } from "./product.action-types";
import { UserProductTag } from "../../apis/types/tag";
import { GeneratedProductTagsApis } from "../../apis/tag";
import { getOpportunityStats } from "../../utils/helpers/opportunity-report";
import { PaginationObject } from "../../hooks/usePagination";
import { setTeamMemberStatsDispatch } from "../team-management/team-management.actions";

export interface SavedProducts {
  type: ProductActionTypes.setProducts;
  payload: Product[];
}

export const savedProductsDispatch = (products: Product[]): SavedProducts => {
  return {
    type: ProductActionTypes.setProducts,
    payload: products,
  };
};

export interface SavedOpportunityProducts {
  type: ProductActionTypes.setOpportunityProducts;
  payload: Product[];
}

export const savedOpportunityProductsDispatch = (products: Product[]): SavedOpportunityProducts => {
  return {
    type: ProductActionTypes.setOpportunityProducts,
    payload: products,
  };
};
export interface SetTotalProduct {
  type: ProductActionTypes.setTotalProduct;
  payload: number;
}

export const setTotalProductDispatch = (total: number): SetTotalProduct => {
  return {
    type: ProductActionTypes.setTotalProduct,
    payload: total,
  };
};
export interface SetTotalOpportunityProduct {
  type: ProductActionTypes.setTotalOpportunityProduct;
  payload: number;
}

export const setTotalOpportunityProductDispatch = (total: number): SetTotalOpportunityProduct => {
  return {
    type: ProductActionTypes.setTotalOpportunityProduct,
    payload: total,
  };
};

export interface LoadProducts {
  type: ProductActionTypes.loadProducts;
  payload: Product[];
}
export const loadProductsDispatch = (products: Product[]): LoadProducts => {
  return {
    type: ProductActionTypes.loadProducts,
    payload: products,
  };
};

export interface LoadOpportunityProducts {
  type: ProductActionTypes.loadOpportunityProducts;
  payload: Product[];
}
export const loadOpportunityProductsDispatch = (products: Product[]): LoadOpportunityProducts => {
  return {
    type: ProductActionTypes.loadOpportunityProducts,
    payload: products,
  };
};

// working
export interface SetTotalProductsStatus {
  type: ProductActionTypes.setTotalProductsStatus;
  payload: any;
}
export const setTotalProductsStatusDispatch = (totalProductsStatus: TotalProductStatuses[]): SetTotalProductsStatus => {
  return {
    type: ProductActionTypes.setTotalProductsStatus,
    payload: totalProductsStatus,
  };
};

export const getUserProductAction =
  ({ filters, navigate, mobile = false, isTeamManagementUser, teamUserId, isCompany = false }: any): any =>
  async (dispatch: Dispatch<any>, getState: any) => {
    try {
      const user = getState().user;
      const dbUser = getState().user.dbUser;
      let payload = filters;
      if (isTeamManagementUser) {
        payload = { ...payload, [isCompany ? "companyID" : "userID"]: teamUserId };
      }
      let res = await generateProductApis.getUserProducts(payload);
      if (isTeamManagementUser) {
        dispatch(
          setTeamMemberStatsDispatch({
            totalApproved: res?.totalApproved,
            totalRecords: res?.totalRecords,
            productCountsWithStatuses: res?.productCountsWithStatuses,
            totalPublishedProducts: res?.totalPublishedProducts
          })
        );
      }
      let allProduct = res.completeProductsData;

      dispatch(setTotalProductDispatch(res.totalRecords));
      dispatch(setTotalProductsStatusDispatch(res.productCountsWithStatuses));
      if (user.role === RolesEnum.USER && allProduct.length === 0 && user.firstLogin && !dbUser?.companyID) {
        navigate(URL_FETCH_ASIN);
        dispatch(setFirstLoginDispatch(false));
      }
      if (allProduct?.length) allProduct = allProduct.map((product: Product) => ({ ...product, id: product._id }));

      if (mobile) {
        dispatch(loadProductsDispatch(allProduct));
      } else {
        dispatch(savedProductsDispatch(allProduct));
      }

      return allProduct;
    } catch (error) {
      return error;
    }
  };

export interface SetOpportunityStats {
  type: ProductActionTypes.setOpportunityStats;
  payload: any;
}
export const SetOpportunityStatsDispatch = (stats: any): SetOpportunityStats => {
  return {
    type: ProductActionTypes.setOpportunityStats,
    payload: stats,
  };
};

export const getOpportunityReport =
  ({ filters, navigate, mobile = false }: any): any =>
  async (dispatch: Dispatch<any>, getState: any) => {
    try {
      const user = getState().user;
      const dbUser = getState().user.dbUser;
      let res = await generateProductApis.getOpportunityReport(filters);
      let allProduct = res.productData;

      // dispatch(SetOpportunityStatsDispatch(getOpportunityStats(allProduct)));
      dispatch(SetOpportunityStatsDispatch(getOpportunityStats(res)));

      dispatch(setTotalOpportunityProductDispatch(res.totalRecords));
      if (user.role === RolesEnum.USER && allProduct.length === 0 && user.firstLogin && !dbUser?.companyID) {
        navigate(URL_FETCH_ASIN);
        dispatch(setFirstLoginDispatch(false));
      }
      if (allProduct?.length) allProduct = allProduct.map((product: Product) => ({ ...product, id: product._id }));

      if (mobile) {
        dispatch(loadOpportunityProductsDispatch(allProduct));
      } else {
        dispatch(savedOpportunityProductsDispatch(allProduct));
      }

      return allProduct;
    } catch (error) {
      return error;
    }
  };

export const selectProductActionM =
  (products: Product[], productId: string): any =>
  (dispatch: Dispatch<any>) => {
    try {
      const index = products.findIndex((p) => p._id === productId);
      const allProducts = [...products];
      if (allProducts[index]) {
        allProducts[index] = { ...allProducts[index], isSelected: !allProducts[index]?.isSelected };
      }
      dispatch(savedProductsDispatch(allProducts));
      return products;
    } catch (error) {
      throw error;
    }
  };

export const handleSelectAllProductActionM =
  (products: Product[], selectStatus: boolean): any =>
  (dispatch: Dispatch<any>) => {
    try {
      let allProducts = [...products];
      allProducts = allProducts?.map((product) => ({ ...product, isSelected: selectStatus }));
      dispatch(savedProductsDispatch(allProducts));
      return products;
    } catch (error) {
      throw error;
    }
  };

export const checkSelectedProductsLiveActions =
  (checkLiveASINsAndTheirUserIDs: { productASIN: string; userID: string }[]): any =>
  async () => {
    try {
      await generateProductApis.checkProductsLive(checkLiveASINsAndTheirUserIDs);
    } catch (error) {
      throw error;
    }
  };

export interface SetUserProductTags {
  type: ProductActionTypes.setTags;
  payload: UserProductTag[];
}
export const SetUserProductsTagsDispatch = (tags: UserProductTag[]) => {
  return {
    type: ProductActionTypes.setTags,
    payload: tags,
  };
};

export const GetAllUserTagsAction = (): any => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(SetUserProductsTagsDispatch(await GeneratedProductTagsApis.getAllUserTags()));
  } catch (error) {
    throw error;
  }
};

export const handleUserTagsAction =
  (oldTags: UserProductTag[], newTag: UserProductTag, tagIndex: number): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      let updatedTags = [...oldTags];
      if (tagIndex === -1) {
        const newlyCreatedTag = await GeneratedProductTagsApis.createUserTag({
          name: newTag.name,
          color: newTag?.color,
        });
        updatedTags.push(newlyCreatedTag);
      } else {
        await GeneratedProductTagsApis.updateUserTag({
          name: newTag?.name,
          color: newTag?.color,
          tagID: newTag?._id as string,
        });
        updatedTags.splice(tagIndex, 1, newTag);
      }

      dispatch(SetUserProductsTagsDispatch(updatedTags));
    } catch (error) {
      throw error;
    }
  };
export const handleDeleteUserTagsAction =
  (oldTags: UserProductTag[], tagId: string): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      await GeneratedProductTagsApis.deleteUserTag(tagId);
      const updatedTags = oldTags.filter((tag) => tag._id !== tagId);
      dispatch(SetUserProductsTagsDispatch(updatedTags));
    } catch (error) {
      throw error;
    }
  };

export const handleAssignUserTagsAction =
  (productIDs: string[], tag: UserProductTag, tagAction: boolean, allTags: UserProductTag[]): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      const resp = await GeneratedProductTagsApis.assignUserTagToProduct(
        tagAction ? TagsActionEnums.ADD : TagsActionEnums.REMOVE,
        { ...tag, tagID: tag?._id as string, productIDs }
      );
      const updatedTags = [...allTags];
      const tagIndex = allTags.findIndex((t) => t._id === tag._id);
      updatedTags.splice(tagIndex, 1, resp);
      dispatch(SetUserProductsTagsDispatch(updatedTags));
    } catch (error) {
      throw error;
    }
  };

export const handleClearAllTagsAction =
  (productIDs: string[], allTags: UserProductTag[]): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      await GeneratedProductTagsApis.clearAllUserTagToProduct(productIDs);
      const updatedTags = allTags.map((tag) => ({
        ...tag,
        productIDs: tag.productIDs?.filter((id) => !productIDs.includes(id)),
      }));
      dispatch(SetUserProductsTagsDispatch(updatedTags));
    } catch (error) {
      throw error;
    }
  };
export interface SetSelectedKeywords {
  type: ProductActionTypes.setSelectedKeywords;
  payload: any;
}

export const setSelectedKeywordsDispatch = (selectedKeywords: any) => ({
  type: ProductActionTypes.setSelectedKeywords,
  payload: selectedKeywords,
});

interface SetPriortiseKeywords {
  type: ProductActionTypes.setPriortiseKeywords;
  payload: any;
}

export const setPriortiseKeywordsDispatch = (priortiseKeywords: any) => ({
  type: ProductActionTypes.setPriortiseKeywords,
  payload: priortiseKeywords,
});

export const getPhrase = (keyword: any): string => {
  if (typeof keyword === "object") {
    return keyword.phrase;
  } else {
    return keyword;
  }
};

export const selectAndGenerateKeywordsAction =
  ({
    productASIN,
    titleKeywords,
    otherKeywords,
    selectedSellingKeywords,
    remainingSeoKeywords,
    userID,
    isRerun,
  }: any): any =>
  async () => {
    try {
      await generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription({
        userID,
        isRerun,
        productASIN: productASIN,
        titleKeywords: titleKeywords.map((keyword: any) => createKeywordObject(keyword)),
        otherKeywords: otherKeywords.map((keyword: any) => createKeywordObject(keyword)),
        searchTermKeywords: [
          ...otherKeywords.map((keyword: any) => getPhrase(keyword)),
          ...remainingSeoKeywords.map((keyword: any) => getPhrase(keyword)),
        ],
      });
      return await KeywordsApis.updateSellingKeywords({
        userID: userID,
        productASIN: productASIN,
        sellingKeywords: selectedSellingKeywords.map((item: any) => (typeof item === "object" ? item?.phrase : item)),
      });
    } catch (error) {
      throw error;
    }
  };

export const generateKeywordsDebugMode =
  ({ productASIN, title, bulletPoints, keywords, description }: any): any =>
  async () => {
    try {
      return await ProductApis.generateProductDebug({
        productASIN: productASIN,
        title,
        bulletPoints: bulletPoints.split(","),
        keywords: keywords.split(","),
        description,
      });
    } catch (error) {
      throw error;
    }
  };

interface SetSellingKeywords {
  type: ProductActionTypes.setSellingKeywords;
  payload: string[];
}

export const setSellingKeywordsDispatch = (sellingKeywords: string[]): SetSellingKeywords => ({
  type: ProductActionTypes.setSellingKeywords,
  payload: sellingKeywords,
});

interface ResetKeywords {
  type: ProductActionTypes.resetKeywords;
  payload: null;
}

export const resetSelectedPrioritizeKeywordsDispatch = (): ResetKeywords => ({
  type: ProductActionTypes.resetKeywords,
  payload: null,
});

interface SetAsinKeywordHistory {
  type: ProductActionTypes.setAsinKeywordHistory;
  payload: AsinKeywordHistory;
}

const setAsinKeywordHistoryDispatch = (asinKeywordHistory: AsinKeywordHistory) => ({
  type: ProductActionTypes.setAsinKeywordHistory,
  payload: asinKeywordHistory,
});

export const setAsinKeywordHistoryAction =
  ({ productASIN }: any): any =>
  async (dispatch: Dispatch, getState: AppState) => {
    try {
      const productOwnerId = localStorage.getItem("productOwnerId") ? localStorage.getItem("productOwnerId") : null;
      const userID = productOwnerId !== null ? productOwnerId : getState().user.dbUser._id;
      const keywordsHistory: any = await KeywordsApis.getKeywordTracking({ productASIN, userID });
      const keywordHistoryResult = transformKeywordsHistory(keywordsHistory, productASIN);
      dispatch(setAsinKeywordHistoryDispatch(keywordHistoryResult));

      return keywordsHistory;
    } catch (error) {
      throw error;
    }
  };

export interface ResetProducts {
  type: ProductActionTypes.resetProducts;
}

export const resetProductsDispatch = () => {
  return {
    type: ProductActionTypes.resetProducts,
  };
};
export interface ResetOpportunityProducts {
  type: ProductActionTypes.resetOpportunityProducts;
}

export const resetOpportunityProductsDispatch = () => {
  return {
    type: ProductActionTypes.resetOpportunityProducts,
  };
};

interface SetActiveBrandTab {
  type: ProductActionTypes.setActiveBrandTab;
  payload: string;
}

export const setActiveBrandTabDispatch = (tab: string): SetActiveBrandTab => ({
  type: ProductActionTypes.setActiveBrandTab,
  payload: tab,
});

interface SetChangeKeywordsProductOwnerId {
  type: ProductActionTypes.setChangeKeywordsProductOwnerId;
  payload: string | null;
}

export const setChangeKeywordsProductOwnerIdDispatch = (
  productOwnerId: string | null
): SetChangeKeywordsProductOwnerId => ({
  type: ProductActionTypes.setChangeKeywordsProductOwnerId,
  payload: productOwnerId,
});

interface SetProductsPagination {
  type: ProductActionTypes.setProductsPagination;
  payload: PaginationObject;
}

export const setProductsPaginationDispatch = (pagination: PaginationObject): SetProductsPagination => ({
  type: ProductActionTypes.setProductsPagination,
  payload: pagination,
});

interface SetProductSearchFilter {
  type: ProductActionTypes.setProductSearchFilter;
  payload: any;
}

export const setProductSearchFilterDispatch = (search: string): SetProductSearchFilter => ({
  type: ProductActionTypes.setProductSearchFilter,
  payload: search,
});

interface SetProductSearchReset {
  type: ProductActionTypes.setProductSearchReset;
}

export const setProductSearchResetDispatch = (): SetProductSearchReset => ({
  type: ProductActionTypes.setProductSearchReset,
});

interface SetWaitingProductRefetch {
  type: ProductActionTypes.setWaitingProductsRefetch;
  payload: boolean;
}

export const setWaitingProductRefetchDispatch = (isRefetch : boolean): SetWaitingProductRefetch => ({
  type: ProductActionTypes.setWaitingProductsRefetch,
  payload: isRefetch,
});

export type ProductsReducerActionType =
  | SavedProducts
  | LoadOpportunityProducts
  | SavedOpportunityProducts
  | SetTotalOpportunityProduct
  | SetUserProductTags
  | SetSelectedKeywords
  | SetPriortiseKeywords
  | ResetOpportunityProducts
  | SetSellingKeywords
  | ResetKeywords
  | SetAsinKeywordHistory
  | SetTotalProduct
  | LoadProducts
  | ResetProducts
  | SetScrapeProduct
  | SetActiveBrandTab
  | SetOpportunityStats
  | SetChangeKeywordsProductOwnerId
  | SetProductsPagination
  | SetProductSearchFilter
  | SetProductSearchReset
  | SetTotalProductsStatus
  | SetWaitingProductRefetch;
