import axios from "../axios";
import {
  AllUserProductTagsApi,
  AssignProductTagsPayload,
  CreateOrUpdatedTagApi,
  CreateUserProductTagsPayload,
  UpdateUserProductTagsPayload,
} from "./types/tag";
import { TagsActionEnums } from "../utils/constants/enums";

export const GeneratedProductTagsApis = {
  async updateUserTag(payload: UpdateUserProductTagsPayload) {
    return axios
      .put<UpdateUserProductTagsPayload, CreateOrUpdatedTagApi>(`/tag`, payload)
      .then((r: any) => r.data.data);
  },
  async assignUserTagToProduct(action: TagsActionEnums, payload: AssignProductTagsPayload) {
    return axios
      .put<UpdateUserProductTagsPayload, CreateOrUpdatedTagApi>(`/tag/update-tag-products/${action}`, payload)
      .then((r: any) => r.data.data);
  },
  async clearAllUserTagToProduct(payload: string[]) {
    return axios
      .put<UpdateUserProductTagsPayload, CreateOrUpdatedTagApi>(`/tag/clear-tag`, payload)
      .then((r: any) => r.data.data);
  },
  async createUserTag(payload: CreateUserProductTagsPayload) {
    return axios
      .post<CreateUserProductTagsPayload, CreateOrUpdatedTagApi>(`/tag`, payload)
      .then((r: any) => r.data.data);
  },
  async getAllUserTags() {
    return axios.get<any>(`/tag`).then((r: AllUserProductTagsApi) => r.data.data);
  },
  async deleteUserTag(tagID: string) {
    return axios.delete<any>(`/tag?tagID=${tagID}`).then((r: any) => r.data.data);
  },
};
