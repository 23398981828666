import axios from "../axios";
import { errorHandler } from "../utils/helpers/apis";

export const amazonAuthorizeApi = {
  async loginAmazon({ spapi_oauth_code, state, selling_partner_id }: any) {
    return axios
      .get<any, any>(`/sellerpartner/login?spapi_oauth_code=${spapi_oauth_code}&state=${state}&selling_partner_id=${selling_partner_id}`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  // this is only for showing consoles to backend
  async logAmazon(body: any) {
    return axios
      .post<any, any>(`/sellerpartner/log`, body)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
};
