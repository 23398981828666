import React from "react";

import classes from "./Unsubscrible.module.css";

import LikeImage from "../../assets/svgs/unsubscribe/green-like.svg";
import MTypography from "../../components/Atoms/MTypography";
import MButton from "../../components/Atoms/MButton";
import AppBar from "../../components/Molecules/AppBar";
import { navigatePage } from "../../utils/helpers/common";
import { URL_PRODUCTS } from "../../routes/routes-path";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeviceType } from "../../hooks/useDeviceType";
import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { TabsEnum } from "../../utils/constants/enums";

const pageTypes = {
  unsubscribe: "unsubscribe",
  signup: "signup",
};
interface UnsubscribeProps {
  pageType?: "unsubscribe" | "signup";
}

const UnsubscribePage: React.FC<UnsubscribeProps> = ({ pageType = pageTypes.unsubscribe }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFromAddTeamMembers = location?.state?.pageType === "addTeamMembers";
  const { isDesktop } = useDeviceType();
  return (
    <>
      {isDesktop && <AppBar />}
      <div className={classes.Container}>
        <img src={LikeImage} alt="" width={200} className="mb-2" />
        <MTypography variant={"h4"} color={"#E6B540"}>
          {pageType === pageTypes.unsubscribe
            ? "Unsubscribe Successfully!"
            : isFromAddTeamMembers
            ? "Members Invited!"
            : "Congratulations!"}
        </MTypography>
        <p className={classes.Text}>
          {pageType === pageTypes.unsubscribe
            ? "You have unsubscribed email notification successfully."
            : isFromAddTeamMembers
            ? "The members you've added have been successfully invited to join the team."
            : "Your account has been created successfully."}
        </p>
        <p className={classes.Subtitle}>
          {pageType === pageTypes.unsubscribe
            ? "You will no longer be receiving notifications from us."
            : isFromAddTeamMembers
            ? ""
            : "Thanks you for signing up. Now you can explore AutoMato by clicking the button."}
        </p>
        <MButton
          onClick={() => {
            navigatePage(URL_PRODUCTS, navigate, { replace: true });
            document.title = capitalizeEachLetter(TabsEnum.PRODUCTS.replace("-", " "))
          }}
        >
          Go to AutoMato
        </MButton>
      </div>
    </>
  );
};

export default UnsubscribePage;
