import * as React from "react";
import {
  DataGrid,
  DataGridProps,
  GridFooterContainer,
  GridPagination,
  GridRowParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
// import MuiCircularProgress from "../CircularProgress";
import MCheckbox from "../../Atoms/MCheckbox";
import "./dataGrid.style.scss";
import { forwardRef, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { DataTableRefInstance } from "../../../interfaces/datatable";
import dancingRobot from "../../../assets/gif/dancingRobot.gif";
import NoResultsFound from "../../Atoms/NoResultsFound/NoResultsFound";

type MuiDataTableProps = DataGridProps & {
  ref: DataTableRefInstance;
  withoutPagination?: boolean;
  selectedRows?: any;
};

export type MuiDataTableState = {
  pageSize: number;
  page: number;
  popOverAnchorEl: EventTarget | null;
  selectionModel?: GridSelectionModel;
  currentPageRows: any[];
  ref?: any;
  keepNonExistentRowsSelected?: boolean;
  selectedRows?: any[];
};

export const srColumnHeaderClick = (
  _state: MuiDataTableState,
  _dispatch: React.Dispatch<React.SetStateAction<MuiDataTableState>>,
  _params: any,
  e: any
): void => {
  if ((e.target as HTMLInputElement)?.type === "checkbox") {
    _dispatch({ ..._state, popOverAnchorEl: e.target });
  }
};

export const CustomDataGrid: any = (props: MuiDataTableProps): any => {
  const [state, setState] = useState<MuiDataTableState>({
    pageSize: props.withoutPagination ? 100 : props.pageSize ?? 10,
    popOverAnchorEl: null,
    page: 0,
    currentPageRows: [],
  });

  const isRowSelectable = (params: GridRowParams) => {
    return props.isRowSelectable?.(params) ?? true;
  };

  const getRowClassName = (params: GridRowParams) => {
    const isSelectable = isRowSelectable(params);
    // const value = params.row?.relevancy;

    if (!isSelectable) {
      return "not-selectable";
    }
    // else if (value >= 90) {
    //   return "green-row";
    // } else if (value >= 80 && value <= 89) {
    //   return "light-green-row";
    // }
    else {
      return "";
    }
  };
  const memoizedColumns = useMemo(() => props.columns, [props.columns]);

  const components = {
    ...props.components,
    BaseCheckbox: MCheckbox,
    LoadingOverlay: () => (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={dancingRobot}
          alt={"dancing-robot"}
          style={{ width: "100px", height: "100px", objectFit: "contain", zIndex: 1 }}
        />
        {/* <MuiCircularProgress /> */}
      </div>
    ),
    NoRowsOverlay: () => <NoResultsFound />,
    Footer: () => (
      <GridFooterContainer sx={{ margin: "0px 16px" }}>
        {props?.selectedRows?.length ? (
          <>
            <div>{`${
              props?.rows?.filter((keyword: any) =>
                props?.selectedRows?.map((selectedKW: any) => selectedKW.id)?.includes(keyword.id)
              )?.length || 0
            } rows selected out of ${props?.rows?.length || 0}`}</div>
            {/*@ts-ignore*/}
            <GridPagination />
          </>
        ) : (
          <div className="w-100 d-flex justify-content-end">
            {/*@ts-ignore*/}
            <GridPagination />
          </div>
        )}
      </GridFooterContainer>
    ),
  };
  return (
    <Grid className="mui-data-grid">
      <DataGrid
        {...state}
        columns={memoizedColumns}
        rowHeight={70}
        rows={props.rows}
        selectionModel={props.selectionModel}
        pageSize={state.pageSize}
        initialState={props.initialState ? props.initialState : {}}
        onPageSizeChange={(newPage: React.SetStateAction<number>) =>
          setState({ ...state, pageSize: newPage as number })
        }
        rowsPerPageOptions={[]}
        sx={
          props.withoutPagination
            ? {
                "& .MuiDataGrid-virtualScroller": {
                  overflowX: "hidden",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                  width: "0.5rem",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                  background: "#f1f1f1 !important",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                  background: "#edca78 !important",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
                "& .MuiTablePagination-displayedRows": {
                  display: "none",
                },
                "& .MuiTablePagination-actions": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "none",
                },
              }
            : {
                "& .MuiTablePagination-displayedRows": {
                  mb: 0,
                },
              }
        }
        pagination
        page={state.page}
        onColumnHeaderClick={(_params, e) => {
          if ((e.target as HTMLInputElement)?.type === "checkbox") {
            setState({ ...state, popOverAnchorEl: e.target });
          }
        }}
        checkboxSelection={props.checkboxSelection ?? false}
        keepNonExistentRowsSelected={props.keepNonExistentRowsSelected}
        isRowSelectable={props.isRowSelectable}
        components={components as any}
        loading={props.loading}
        onSelectionModelChange={props.onSelectionModelChange}
        onCellClick={props.onCellClick}
        getRowClassName={getRowClassName}
        onPageChange={(page) => {
          setState({ ...state, page });
        }}
        onSortModelChange={(_model, _details) => {}}
      />
    </Grid>
  );
};
const DataTable = forwardRef<any, any>(CustomDataGrid);

export default DataTable;
