import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import React, { useState, useEffect } from "react";

import { RolesEnum } from "../../../utils/constants/enums";
import { removeUnderscoreAndCapitalize } from "../../../utils/helpers/strings";

interface FromRolesDropdownProps {
  name: string;
  value: string;
  handleChange: any;
  label: string;
  selectableRoles: string[];
  disabled?: boolean;
}
const RolesDropDown: React.FC<FromRolesDropdownProps> = ({
  name,
  value,
  handleChange,
  label = "label",
  selectableRoles,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const inputChangeHandler = (e: any) => {
    setInputValue(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <FormControl sx={{ marginTop: "16px", width: { xs: "100%", md: "30%" } }}>
      <InputLabel id="roles-select">{label}</InputLabel>
      <Select
        labelId="roles-label"
        id="roles-select"
        label={label}
        value={inputValue}
        onChange={inputChangeHandler}
        name={name}
        defaultValue={value || ""}
        disabled={disabled}
      >
        {selectableRoles?.map(
          (role: any) =>
            role !== RolesEnum.OWNER && (
              <MenuItem key={role} value={role}>
                {removeUnderscoreAndCapitalize(role)}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};

export default RolesDropDown;
