import { Box, Skeleton } from "@mui/material";
import Space from "../../Atoms/Space";

const AccessSkeletonM = () => {

    const getSkeleton = () => {
        const skeletons = [];
        for (let index = 0; index < 10; index++) {
            skeletons.push(
                <div className={"card flex-row mt-4 cursor-pointer p-3 pl-2"} key={index}>
                    <div className="d-flex flex-row w-100 gap-2">
                        <div className="w-100">
                            <Skeleton
                                variant="text"
                                width={150}
                                height={30}
                            />
                            <Box component={'div'} className="w-100">
                                <Skeleton
                                    variant="text"
                                    width={80}
                                />
                            </Box>

                            <div className={"d-flex justify-content-between align-items-start flex-column mt-3 mb-2"}>
                                <Skeleton
                                    variant="text"
                                    width={80}
                                    height={20}
                                />
                                <Skeleton
                                    variant="text"
                                    width={80}
                                    height={20}
                                />
                            </div>
                            <Box component={'div'} className="w-100" sx={{ borderBottom: '1px solid #c7c8c9', mb: '16px', pb: '8px' }}>
                            </Box>
                            <div className={"d-flex justify-content-between align-items"}>
                                <Skeleton
                                    variant="text"
                                    width={100}
                                    height={20}
                                />
                                <Space right={"5rem"} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return skeletons;
    }

    return (
        <>
            {getSkeleton()}
        </>
    )
}

export default AccessSkeletonM;