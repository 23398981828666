export const URL_FETCH_ASIN = "/fetch-asin";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_KEYWORDS = "/keywords";
export const URL_PRODUCT_FETCHED = "/product";
export const URL_PRODUCT_FETCHED_COMPETITORS = "/competitors";
export const URL_LANDING_PAGE = "";
export const URL_REGISTER = "/register";
export const URL_SIGN_IN = "/sign-in";
export const URL_SIGN_UP = "/sign-up";
export const URL_CREATE_COMPANY = "/create-company";
export const URL_TEAM_MEMBERS = "/add-team-members";
export const PRIORITISE_KEYWORDS = "/prioritise-keywords";
export const URL_PRODUCTS = "/saved-products";
export const URL_CONTACT_US = "/contact-us";
export const URL_PRODUCT_CATALOG = "/product";
export const URL_ACCOUNT_STATUS = "/account-status";
export const URL_OPPORTUNITY_REPORT = "/opportunity-report";
export const URL_UNSUBSCRIBE = "/unsubscribed";
export const URL_CONGRATS = "/congrats-signup";

export const URL_COMPANY_PROFILE = "/company-profile";
export const URL_USER_PROFILE = "/user-profile";
export const URL_SETTINGS = "/settings";
