import { CompleteProduct, ProductEventsListInterface, ProductEventsListMessage } from "../../../apis/types/generate-product";
import { produce } from "immer";
import { ProductDetailsActionTypes, ProductsDetailsReducerActionType } from "./productDetails.actions";

export interface State {
  completeProduct: CompleteProduct;
  productEventHistory: ProductEventsListInterface
  productEventHistoryMessage: ProductEventsListMessage
}

const initialState: State = {
  completeProduct: {} as CompleteProduct,
  productEventHistory: {
    today: [],
    yesterday: [],
    earlier: []
  },
  productEventHistoryMessage: {
    titleText: "Showing events from the last 6 months.",
    noEventsText: "There are no events for this product in the past six months."
  }
};

const productDetailsReducer = produce((state: State = initialState, action: ProductsDetailsReducerActionType) => {
  switch (action.type) {
    case ProductDetailsActionTypes.setCompleteProduct:
      state.completeProduct = action.payload;
      return state;
    case ProductDetailsActionTypes.setProductEventHistory:
      state.productEventHistory = action.payload;
      return state;
    case ProductDetailsActionTypes.setProductEventHistoryMessage:
      state.productEventHistoryMessage = action.payload;
      return state;  
    default:
      return state;
  }
});

export default productDetailsReducer;
