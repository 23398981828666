import React from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import TabsToolbar from "../../components/Molecules/TabsToolbar";
import MTypography from "../../components/Atoms/MTypography";
import MTabs from "../../components/Atoms/MTabs";
import ClientsPage from "../../components/Organisms/Clients";

import { useDeviceType } from "../../hooks/useDeviceType";

import { TabsEnum } from "../../utils/constants/enums";
import { capitalizeFirstLetter } from "../../utils/helpers/strings";

import { ClientsActiveTab } from "../../store/clients/clients.selector";
import { setClientsActiveTab } from "../../store/clients/clients.actions";

import { clientsTabs } from "./config";
import classes from "./index.module.css";

const ClientsTab = () => {
  const { isDesktop } = useDeviceType();
  const dispatch = useDispatch();

  const handleTabChange = (tab: string) => {
    dispatch(setClientsActiveTab(tab));
  };
  const activeTab = useSelector(ClientsActiveTab);

  return (
    <>
      {isDesktop ? (
        <div className="d-flex align-items-center justify-content-between">
          <TabsToolbar title={TabsEnum.CLIENTS} />
        </div>
      ) : (
        <div className={"p-3 d-flex w-100 justify-content-between align-items-center"}>
          <MTypography variant={"h4"}>{capitalizeFirstLetter(TabsEnum.CLIENTS)}</MTypography>
        </div>
      )}

      <Box component="div" className={`${isDesktop && "mt-2"}`} mx={{ xs: "", md: "2rem", display: "flex" }}>
        <MTabs handleChange={handleTabChange} value={activeTab} tabs={clientsTabs} isCamelCase={false} />
      </Box>
      <div className={`${isDesktop ? classes.Container : ""}`}>
        <ClientsPage />
      </div>
    </>
  );
};

export default ClientsTab;
