import { produce } from "immer";

import { UserActionTypes } from "./user.action-types";
import { UserReducerPayloadType } from "./user.actions";
import { User } from "firebase/auth";
import { RolesEnum } from "../../utils/constants/enums";
import { DatabaseUser, UserCredentialsType } from "../../apis/types/user";
import { TeamMemberType } from "../../interfaces/userInterfaces";

export interface State {
  user?: User | null;
  role?: RolesEnum;
  token?: string;
  dbUser?: any;
  userList?: DatabaseUser[];
  firstLogin: boolean;
  userCredentials: UserCredentialsType;
  allTeamMembers: TeamMemberType[];
  teamMembersStats: any;
}

const initialState: State = {
  user: null,
  token: "",
  firstLogin: true,
  userCredentials: { email: "", password: "", companyID: null, companyName: null },
  allTeamMembers: [],
  teamMembersStats: {},
};
const userReducer = produce((state: State = initialState, action: UserReducerPayloadType) => {
  switch (action.type) {
    case UserActionTypes.setRole:
      state.role = action.payload;
      return state;
    case UserActionTypes.setToken:
      state.token = action.payload;
      return state;
    case UserActionTypes.setFirstLogin:
      state.firstLogin = action.payload;
      return state;
    case UserActionTypes.setUser:
      state.dbUser = action.payload;
      return state;
    case UserActionTypes.setUserList:
      state.userList = action.payload;
      return state;
    case UserActionTypes.setBulletPointsFormat:
      state.dbUser = action.payload;
      return state;
    case UserActionTypes.setUserCredentials:
      state.userCredentials = action.payload;
      return state;
    case UserActionTypes.setTeamMembers:
      state.allTeamMembers = action.payload;
      return state;
    case UserActionTypes.setTeamMembersStats:
      state.teamMembersStats = action.payload;
      return state;
    default:
      return state;
  }
});

export default userReducer;
