import React from "react";
import { AccessKeyTableProps } from "../AccessKeyTable/AccessKeyTable";
import AccessKeyCard from "../../../../Molecules/ApiAccessKeyCard/AccessKeyCard";

const AccessKeyM = ({
  apiKeysList,
  hanldeSwitchChange,
  handleDelete,
  handleRerun,
  pageNumber,
  pageSize,
}: AccessKeyTableProps) => {
  return (
    <div className={"d-flex flex-column h-auto p-2  m-2 mt-0 pt-0"} style={{ marginBottom: "75px" }}>
      <div>
        {(pageSize > 0
          ? apiKeysList?.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
          : apiKeysList
        )?.map((item, i) => (
          <AccessKeyCard
            key={i}
            item={item}
            hanldeSwitchChange={hanldeSwitchChange}
            handleDelete={handleDelete}
            handleRerun={handleRerun}
          />
        ))}
      </div>
    </div>
  );
};

export default AccessKeyM;
