import { TeamMemberType } from "../../interfaces/userInterfaces";
import { TeamManagementActionTypes } from "./team-management.action-types";

interface SetSelectedTeamMember {
  type: TeamManagementActionTypes.setSelectedTeamMember;
  payload: TeamMemberType;
}

export const setSelectedTeamMemberDispatch = (payload: TeamMemberType): SetSelectedTeamMember => ({
  type: TeamManagementActionTypes.setSelectedTeamMember,
  payload,
});

interface SetTeamMemberStats {
  type: TeamManagementActionTypes.setTeamMemberStats;
  payload: any;
}

export const setTeamMemberStatsDispatch = (payload: any): SetTeamMemberStats => ({
  type: TeamManagementActionTypes.setTeamMemberStats,
  payload,
});

export type TeamManagementReducerPayload = SetSelectedTeamMember | SetTeamMemberStats;
