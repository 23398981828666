import React from "react";

interface Props {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

const Space: React.FC<Props> = (props) => {
  const { top = "0px", left = "0px", right = "0px", bottom = "0px" } = props;

  return <div style={{ marginTop: top, marginBottom: bottom, marginRight: right, marginLeft: left }} />;
};

export default Space;
