import axios from "../axios";
import { CompleteCompanyDetailResponse, GetCompanyResponse, UpdateCompanyProfilePayload, RemindInvitedCompanyUserPayload } from "./types/companies";
import { errorHandler } from "../utils/helpers/apis";

export const CompanyApis = {
  controller: "/user",
  async getCompany(companyID: string) {
    return axios
      .get<any, GetCompanyResponse>(`/user/get-company/${companyID}`)
      .then((r: GetCompanyResponse) => r.data.data);
  },
  async updateCompanyProfile(obj: UpdateCompanyProfilePayload) {
    return axios
      .put<UpdateCompanyProfilePayload, any>(`${this.controller}/update-company`, obj)
      .then((r: any) => r.data.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
  async getCompanyCompleteDetail(companyID: string) {
    return axios
      .get<any, CompleteCompanyDetailResponse>(`/user/get-company-complete-detail/${companyID}`)
      .then((r: any) => r.data.data);
  },
  async getPendingEmails(companyID: string) {
    return axios
      .get<any, any>(`/user/get-pending-emails/${companyID}`) 
      .then((r: any) => r.data.data);
  },
  async remindInvitedUser(payload: RemindInvitedCompanyUserPayload) {
    return axios
    .post<RemindInvitedCompanyUserPayload, any>(`/user/remind-invited-user`, payload).then((r: any) => r.data.data);
  },
};
