import React from "react";
import { useDeviceType } from "../../../hooks/useDeviceType";
import classes from "./index.module.css";

interface FormTitleWithLabelProps {
  title: string;
  label?: string;
  isSubtitle?: boolean;
}

const FormTitleWithLabel: React.FC<FormTitleWithLabelProps> = (props) => {
  const { title = "Title", label, isSubtitle = false } = props;
  const { isDesktop } = useDeviceType();

  return (
    <div className={"mt-1 mb-2"}>
      {isSubtitle ? (
        <p className={`m-0 ${isDesktop ? classes.SubTitle : classes.SubTitleM}`}>
          {title} <span className={classes.Label}>{label ? `(${label})` : ""}</span>
        </p>
      ) : (
        <p className={`m-0 ${isDesktop ? classes.Title : classes.TitleM}`}>
          {title} <span className={classes.Label}>{label ? `(${label})` : ""}</span>
        </p>
      )}
    </div>
  );
};

export default FormTitleWithLabel;
