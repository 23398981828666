import { aiModelEnums } from "../../../../utils/constants/enums"

export const languageModelTitleNames = [
    {
        title: "Competitor Relevance",  
    },
    {
        title: "Keyword Relevance",
    },
    {
        title: "Keyword Usability",
    },
    {
        title: "Keyword Brand Filter",
    },
    {
        title: "Content Generation",
    },
]

export const languageModelDropdownList = [
    {
        value: aiModelEnums.GPT4,
        status: "available"
    },
    {
        value: aiModelEnums.GPT_4o_MINI,
        status: "available"
    },
    {
        value: aiModelEnums.GPT_3_5,
        status: "available"
    },
    {
        value: aiModelEnums.CLAUDE_3,
        status: "available"
    },
    {
        value: aiModelEnums.GOOGLE_GEMINI,
        status: "not available"
    },
    {
        value: aiModelEnums.GROK,
        status: "not available"
    },
    {
        value: aiModelEnums.Mistral,
        status: "not available"
    },
]