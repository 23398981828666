import React, { useContext, useEffect } from "react";
import classes from "./index.module.css";
import { isAxiosError } from "axios";
import { errorHandler } from "../../../utils/helpers/apis";
import PricingContext from "../../../context/PricingContext";
import { useSelector } from "react-redux";
import { UserRoleSelector } from "../../../store/user/user.selector";
import { RolesEnum } from "../../../utils/constants/enums";

interface Props {
  error: any;
  showPricingPlan?: boolean;
}
const ToastError: React.FC<Props> = (props) => {
  const { onOpen } = useContext(PricingContext);
  const userRole = useSelector(UserRoleSelector);
  const { error, showPricingPlan = true } = props;
  let message = "";
  let link = "";
  let errorMessage = "";
  let remain = "";
  try {
    if (isAxiosError(error)) {
      message = errorHandler(error);
    } else if (typeof error === "object") {
      message = error?.message || "";
    } else {
      message = error;
    }
    const messages = message.split("_");
    errorMessage = messages[0];
    link = messages[1];
    remain = messages.length > 2 ? messages[2] : "";
  } catch (e) {
    console.log("error inside ToastError", e, error);
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (message?.length > 0 && link && userRole !== RolesEnum.ADMIN && userRole !== RolesEnum.OWNER && showPricingPlan) {
      onOpen();
    }
  }, [message]);

  return (
    <>
      {link ? (
        <div>
          {errorMessage}
          <span
            className={classes.ClickErrorButton}
            onClick={() => {
              window.open(link, "_blank");
            }}
          >
            here.
          </span>
          {!!remain && <span>{remain}</span>}
        </div>
      ) : (
        <div>{message}</div>
      )}
    </>
  );
};

export default ToastError;
