import { RolesEnum } from "../../utils/constants/enums";
import { clientsTabsValue } from "../Clients/config";

export const adminClientValue = {
  basicDetails: "Basic Details",
  members: "Members",
  products: "Products",
};
export const adminClientTabs = [adminClientValue.basicDetails, adminClientValue.members, adminClientValue.products];

export const individualClientTabs = [adminClientValue.basicDetails, adminClientValue.products];

export const getTextBasedOnCompanyRole = (role: RolesEnum, isCompany: boolean) => {
  if (isCompany) {
    if (role === RolesEnum.TRIAL) {
      return "The company has limited access to features during the trial period."
    } else if (role === RolesEnum.PAID) {
      return "The company has full access to all platform features within the company."
    } else {
      return ""
    }
  } else {
    if (role === RolesEnum.USER) {
      return "The trial user has limited access to the platform.";
    } else if (role === RolesEnum.PAID) {
      return "The paid user can manage their own products fully.";
    } else if (role === RolesEnum.PAID_EDITOR) {
      return "The paid editor can manage and approve their own products.";
    } else {
      return ""
    }
  }
};

export const getSelectableRoles = (category: string) => {
  switch (category) {
    case clientsTabsValue.companies:
      return [
        RolesEnum.PAID,
        RolesEnum.TRIAL,
      ]
    case clientsTabsValue.individuals:
      return [
        // RolesEnum.USER, 
        RolesEnum.TRIAL,
        RolesEnum.PAID_EDITOR,
        RolesEnum.PAID,
      ]
    default:
      return [
        "Select"
      ]
  }
}

export const showInviteMemberButtonOnClientsView = (userEmail: string) => {

  const validEmails = [
    "abdul.khan@automatoai.com",
    "belal.elharazin@automatoai.com",
    "rami@automatoai.com",
    "mirjana@automatoai.com",
    "payton@automatoai.com",
    "mdanishjameel6@gmail.com",
  ]


  return validEmails.includes(userEmail);

}