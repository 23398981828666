import MoreVertIcon from "@mui/icons-material/MoreVert";
import MuiPopover from "../../Atoms/Popover";
// import MenuOption from "../../Molecules/MenuOption";
import { useState } from "react";

const ActionMenuWithIconM = (props: any) => {
  const { showMenu, setShowMenu, children } = props;
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  return (
    <>
      <MoreVertIcon
        onClick={(e) => {
          setShowMenu(!showMenu);
          setAnchorEl(e.currentTarget);
        }}
        color="secondary"
        style={{ cursor: "pointer", color: "rgb(230, 181, 64)" }}
      />
      <MuiPopover
        open={showMenu}
        anchorEl={anchorEl}
        onClose={() => {
          setShowMenu(false);
          setAnchorEl(null);
        }}
      >
        {children}
      </MuiPopover>
    </>
  );
};

export default ActionMenuWithIconM;
