import React, { ReactNode } from "react";
import { Dialog, DialogTitle, DialogActions, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import style from "./index.module.css";

interface DialogComponentProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  maxWidth?: string;
}

const MDialogComponent: React.FC<DialogComponentProps> = ({
  isOpen,
  onClose,
  title,
  children,
  maxWidth = "1100px",
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const renderChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === DialogActions) {
        // @ts-ignore
        return React.cloneElement(child, { onClose: onClose });
      }
    }
    return child;
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            zIndex: 10000,
            maxWidth: maxWidth,
          },
        },
      }}
    >
      {title && (
        <DialogTitle classes={{ root: style.DialogTitle }} id="responsive-dialog-title">
          <Typography color={"#FFFFFF"}>{title}</Typography>
        </DialogTitle>
      )}
      {renderChildren}
    </Dialog>
  );
};

export default MDialogComponent;
