import React, { useEffect, useState } from "react";
import {
  URL_CONGRATS,
  URL_SIGN_IN,
  URL_TEAM_MEMBERS,
} from "../../../routes/routes-path";

import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ERR_COMPANY_NAME_EMPTY, ERR_USER_NAME_EMPTY } from "../../../utils/constants/messages/errors";
import {
  getLastOpenTab,
  navigatePage,
} from "../../../utils/helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import TitleBarLogo from "../../../components/Molecules/AppBar/logo";
import MTypography from "../../../components/Atoms/MTypography";
import MOutlinedInput from "../../../components/Atoms/MOutlinedInput";

import MButton from "../../../components/Atoms/MButton";
import roboOnSkate from "../../../assets/png/robo-on-skate.png";
import { useDeviceType } from "../../../hooks/useDeviceType";

import classes from "../index.module.css";
import { useScreenSize } from "../../../hooks/useScreenSize";
import TwoColorText from "../../../components/Molecules/TwoColorText";
import MCheckbox from "../../../components/Atoms/MCheckbox";
import {
  establishUserLoginSession,
} from "../../../apis/auth";
import { setRoleDispatch, setUserDispatch } from "../../../store/user/user.actions";
import { UserCredentialSelector } from "../../../store/user/user.selector";
import { RolesEnum } from "../../../utils/constants/enums";
import MOutlinedPhoneInput from "../../../components/Atoms/MOutlinedPhoneInput";
import { UserApis } from "../../../apis/user";
import { errorHandler } from "../../../utils/helpers/apis";
import { setCompanyAction } from "../../../store/company/company.actions";

const CreateCompany = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop, isMobile, isIpad } = useDeviceType();
  const userCredentials = useSelector(UserCredentialSelector);
  const companyName = userCredentials?.companyName;

  const [state, setState] = useState({
    companyName: companyName ? companyName : "",
    userName: "",
    isAgreeForMarketing: true,
    isAgree: false,
    loading: false,
  });

  const [phoneNumber, setPhoneNumber] = useState<any>();

  const storeUser = useSelector((state: AppState) => state.user);

  const screenHeight = useScreenSize();
  const actualHeight = screenHeight < 800 ? 800 : screenHeight;

  useEffect(() => {
    document.title = "Sign Up"
  }, [])

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });

  // @ts-ignore
  const submit = async (): Promise<boolean> => {
    try {
      const { userName, companyName, isAgreeForMarketing } = state;

      if (companyName.length < 1) {
        toast.error(ERR_COMPANY_NAME_EMPTY);
        return false;
      }

      if (userName.length < 1) {
        toast.error(ERR_USER_NAME_EMPTY);
        return false;
      }

      setState({
        ...state,
        loading: true,
      });

      try {
        let responseCreateUser: any = null;
        if (userCredentials?.companyName && userCredentials?.companyID) {
          responseCreateUser = await UserApis.createInvitedUser({
            name: userName,
            companyID: userCredentials?.companyID,
            email: userCredentials?.email,
            password: userCredentials?.password,
            role: RolesEnum.USER,
            phoneNumber,
            isAgreeForMarketing,
          });
        } else {
          responseCreateUser = await UserApis.saveUser({
            name: userName,
            isOfficialUser: false,
            companyName,
            phoneNumber,
            isAgreeForMarketing,
            email: userCredentials?.email,
            password: userCredentials?.password,
          });
          console.log(responseCreateUser);
        }

        const resp = await establishUserLoginSession(
          responseCreateUser?.email, //email,
          userCredentials?.password // password,
        );

        if (resp) {
          dispatch(setUserDispatch(resp));
          dispatch(setRoleDispatch(resp?.role));
        }

        if(resp?.companyID){
          dispatch(setCompanyAction(resp?.companyID))
        }
        
        setTimeout(() => {
          toast.success("User created successfully");
        }, 1000);

        if (resp?.role === RolesEnum.COMPANY_ADMIN) {
          navigatePage(`${URL_TEAM_MEMBERS}`, navigate, {
            replace: true,
          });
        } else {
          navigatePage(`${URL_CONGRATS}`, navigate, {
            replace: true,
          });
        }

        setState({ ...state, loading: false });
      } catch (error: any) {
        toast.error(errorHandler(error));
        setState({ ...state, loading: false });
      }
    } catch (error: any) {
      toast.error(error);
      setState({ ...state, loading: false });
    }
  };

  return storeUser.user ? (
    <Navigate to={getLastOpenTab()} />
  ) : (
    <div
      className={isDesktop ? "d-flex flex-row" : "d-flex flex-column"}
      style={{
        height: `${actualHeight}px`,
        background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.60) 100%)",
      }}
    >
      <div
        style={{
          background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.6) 100%)",
          marginBottom: "-40px",
        }}
      >
        {(isMobile || isIpad) && (
          <div className={"m-4"}>
            <div className="col mx-auto d-flex justify-content-center align-items-center flex-column">
              <img
                src={roboOnSkate}
                alt="robot"
                className="robot"
                style={{ objectFit: "contain", width: "121px", height: "140px", marginBottom: "40px" }}
              />
            </div>
          </div>
        )}
      </div>

      <div className={`col`} style={{ backgroundColor: "white", borderRadius: isDesktop ? "" : "50px 50px 0px 0px" }}>
        <div className={isDesktop ? "m-5 mt-4" : `d-flex justify-content-center  ${classes.TitlebarM}`}>
          <TitleBarLogo />
        </div>
        {/*login-form*/}
        <div className={`d-flex align-items-center justify-content-center`}>
          <div className={`d-flex flex-column text-center ${isDesktop && "w-100"}`}>
            <MTypography variant={"h1"} customClass={isDesktop ? classes.PageTitleD : classes.PageTitleMB}>
              {"Get Started With AutoMato"}
            </MTypography>
            <div className={`${isDesktop ? "my-3" : `${classes.PageCaptionM}`}`}>
              <MTypography variant={"body2"}>{"You're one step away from content bliss"}</MTypography>
            </div>
            <div
              className={
                isDesktop
                  ? `d-flex flex-column align-items-center ${classes.PaddingX9}`
                  : "d-flex flex-column align-items-center p-3"
              }
            >
              <div className={"my-2 w-100"}>
                <MOutlinedInput
                  id={"companyName"}
                  name="companyName"
                  type="text"
                  readonly={companyName ? true : false}
                  placeholder={"Company Name"}
                  value={state.companyName}
                  rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className={"my-2 w-100"}>
                <MOutlinedInput
                  id={"userName"}
                  name="userName"
                  type="text"
                  placeholder={"User Name"}
                  value={state.userName}
                  rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className={"my-2 w-100"}>
                <MOutlinedPhoneInput setPhoneNumber={setPhoneNumber} />
              </div>
            </div>
            <div className={isDesktop ? ` ${classes.PaddingX9} my-2 text-start` : "p-3 text-start"}>
              <div className={classes.CheckBoxContainer}>
                <MCheckbox
                  variant={"square"}
                  checked={state.isAgreeForMarketing}
                  onClick={() => setState({ ...state, isAgreeForMarketing: !state.isAgreeForMarketing })}
                  id="agree-for-marketing"
                />
                <label className={`${classes.CheckBoxText} cursor-pointer`} htmlFor="agree-for-marketing">
                  I agree to receive Information about AutoMato, marketing help and marketing.
                </label>
              </div>
              <div className={classes.CheckBoxContainer}>
                <MCheckbox
                  variant={"square"}
                  checked={state.isAgree}
                  onClick={() => setState({ ...state, isAgree: !state.isAgree })}
                  id="agree-for-terms"
                />
                <p className={classes.CheckBoxText}>
                  <label htmlFor="agree-for-terms" className="cursor-pointer">I read and agreed to the &nbsp;</label>
                  <span
                    className={classes.TwoColorText}
                    onClick={() => {
                      window.open("https://www.automatoai.com/terms-and-conditions");
                    }}
                  >
                    Terms of Use
                  </span>
                  .
                </p>
              </div>
            </div>

            {!isDesktop ? (
              <>
                <div className={`px-3 mb-4 ${classes.CreateButton}`}>
                  <MButton
                    onClick={submit}
                    variant={"contained"}
                    isLoading={state.loading}
                    fullWidth
                    disabled={!state.isAgree}
                  >
                    {"Create Account"}
                  </MButton>
                </div>
              </>
            ) : (
              <div className={`mt-2 mb-2`}>
                <div className={`${classes.CreateButton} ${classes.PaddingX9}`}>
                  <MButton
                    onClick={submit}
                    variant={"contained"}
                    isLoading={state.loading}
                    fullWidth
                    disabled={!state.isAgree}
                    size={"large"}
                  >
                    {"Create Account"}
                  </MButton>
                </div>
              </div>
            )}

            <div className={"d-flex justify-content-center"}>
              <div className={"mt-2"} style={isDesktop ? {} : { marginTop: "2rem" }}>
                <TwoColorText
                  text1={"Have an account?"}
                  text2={"Sign in!"}
                  onClick={() => {
                    navigatePage(URL_SIGN_IN, navigate);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*right-side */}
      {isDesktop && (
        <div
          className="col mx-auto d-flex justify-content-center align-items-center flex-column"
          style={{ background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.60) 100%)" }}
        >
          <img src={roboOnSkate} alt="robot" className="robot" style={{ objectFit: "contain", width: "22rem" }} />
          <div className="m-5 text-center">
            <MTypography variant={"h1"} customClass={classes.TextOverImage}>
              {"Dynamic Content driven by real-time Intelligence."}
            </MTypography>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateCompany;
