import React, { useEffect, useState } from "react";
import BasicDetails from "./BasicDetails";
import CompleteFetchProductDetails from "./CompleteFetchProductDetails";
import { useDeviceType } from "../../../hooks/useDeviceType";
import classes from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ParamsEnum } from "../../../utils/constants/enums";
import MDialogComponent from "../../Atoms/Dialog";
import MBottomSheet from "../../Atoms/MBottomSheet";
import CompleteFetchProductDetailsM from "./CompleteFetchProductDetails/CompleteFetchProductDetailsM";
import { ScrapeProductSelector } from "../../../store/product/productScrape/productScrape.selectors";

import {
  getScrapeProductAction,
  rainForestProductAction,
} from "../../../store/product/productScrape/productScrape.actions";
// import { CompleteProductSelector } from "../../../store/product/productDetails/productDetails.selectors";

interface FetchProductDetailsProps {
  setIsLoading?: (loadingState: boolean) => void;
  onlyFetch?: boolean;
}
const FetchProductDetails: React.FC<FetchProductDetailsProps> = ({ setIsLoading, onlyFetch = false }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const { isDesktop } = useDeviceType();
  // const completeProduct = useSelector(CompleteProductSelector);
  const [showDetailsBox, setShowDetailsBox] = useState<boolean>(false);

  const changeKeywordOwnerId = localStorage.getItem("productOwnerId") 
    ? localStorage.getItem("productOwnerId")
    : undefined;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const asin = params[ParamsEnum.ASIN];
    const domain = params[ParamsEnum.DOMAIN];
    if (asin && domain) {
      // adding length check for manually created product without asin
      if (asin?.substring(0, 2) === "B0" || asin?.length === 13) {
        if (!scrapeProduct?.productFetch) {
          if (setIsLoading) {
            setIsLoading(true);
          }
          if (onlyFetch) {
            dispatch(getScrapeProductAction({ domain: `amazon.${domain}`, asin, userID: changeKeywordOwnerId })) 
              .then(() => {
                if (setIsLoading) {
                  setIsLoading(false);
                }
              })
              .catch(() => {
                if (setIsLoading) {
                  setIsLoading(false);
                }
              });
          } else {
            dispatch(rainForestProductAction({ domain: `amazon.${domain}`, asin, isGoGoAutomato: true }))
              .then(() => {
                if (setIsLoading) {
                  setIsLoading(false);
                }
              })
              .catch(() => {
                if (setIsLoading) {
                  setIsLoading(false);
                }
              });
          }
        }
      }
    }
  }, [scrapeProduct?.productFetch]);

  const hideDetailBox = () => setShowDetailsBox(false);
  const openDetailBox = () => setShowDetailsBox(true);

  return (
    <div>
      {isDesktop ? (
        <MDialogComponent isOpen={showDetailsBox} onClose={hideDetailBox}>
          <CompleteFetchProductDetails
            showDetailButton={showDetailsBox}
            openDetailBox={openDetailBox}
            hideDetailBox={hideDetailBox}
          />
        </MDialogComponent>
      ) : (
        <MBottomSheet open={showDetailsBox}>
          <CompleteFetchProductDetailsM hideDetailBox={hideDetailBox} />
        </MBottomSheet>
      )}
      {scrapeProduct?.product && !scrapeProduct?.isUnAttached && !showDetailsBox && (
        <div className={isDesktop ? classes.Container : classes.ContainerM}>
          <BasicDetails openDetailBox={openDetailBox} showDetailButton={showDetailsBox} />
        </div>
      )}
    </div>
  );
};

export default FetchProductDetails;
