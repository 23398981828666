import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import MTypography from "../MTypography";

type tpProp = SwitchProps & { label: string; labelClassName?: string };

const MuiSwitch = (props: tpProp): JSX.Element => {
  const { labelClassName } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={props.checked} onChange={props.onChange} name={props.name} />}
        label={
          <MTypography customClass={labelClassName} variant={"subtitle1"}>
            {props.label}
          </MTypography>
        }
      />
    </FormGroup>
  );
};
export default MuiSwitch;
