import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MTypography from "../../../components/Atoms/MTypography";
import LanguageModelConfigForm from "../../../components/Organisms/AiModelManagement/LanguageModelConfigForm";
import AiModelProbabilityConfigSliders from "../../../components/Organisms/AiModelManagement/AiModelProbabilityConfigSliders";
import MButton from "../../../components/Atoms/MButton";

import { TabsEnum } from "../../../utils/constants/enums";
import { navigatePage } from "../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import { useDeviceType } from "../../../hooks/useDeviceType";

import { initialValues, validationSchema } from "./config";
import classes from "./index.module.css";
import { AiModelApis } from "../../../apis/aiModel";

const AiModelManagement = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [sliderValues, setSliderValues] = useState<any>({
    gpt4: 0,
    gpt4o: 0,
    claudeSonnet: 0,
  });

  console.log('loading', loading)
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    AiModelApis.getAiModel()
      .then((res: any) => {
        console.log("res ******** ", res)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const navigateSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };

  const handleSubmit = (values: any) => {
    console.log("submit handler ****** ", sliderValues);
    console.log("submit handler values ****** ", values);
  };
  return (
    <div>
      {isDesktop ? (
        <div className={`d-flex justify-content-between align-items-center ${classes.Container}`}>
          <MTypography variant={"h3"} customClass={classes.Heading}>
            AI Model Management
          </MTypography>
          <div className={"cursor-pointer"} onClick={navigateSettingsTab}>
            <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
          </div>
        </div>
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex justify-content-between align-items-center"}>
            <ArrowBackIcon onClick={navigateSettingsTab} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>{"AI Model Management"}</MTypography>
          </div>
        </div>
      )}
      <div className={`${isDesktop ? classes.BodyContainer : classes.BodyContainerM}`}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <Form autoComplete="off">
              <>
                {/* only Language Model Config Form is handled using formik */}
                <LanguageModelConfigForm />
                <AiModelProbabilityConfigSliders sliderValues={sliderValues} setSliderValues={setSliderValues} />
                <div className="d-flex justify-content-end w-100 mb-5">
                  <MButton onClick={handleSubmit}>Save</MButton>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AiModelManagement;
