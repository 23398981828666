import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useLocation,
  // useLocation,
  useNavigate,
} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { BottomNavigation, CircularProgress } from "@mui/material";

import {
  OpportunityProductsSelector,
  TotalOpportunityProductSelector,
} from "../../../../store/product/product.selector";
import { Product } from "../../../../apis/types/generate-product";
import { ContentStatusEnum, TabsEnum } from "../../../../utils/constants/enums";
import MTypography from "../../../Atoms/MTypography";
// import MButton from "../../../Atoms/MButton";
// import menuDotIcon from "../../../../assets/svgs/menu-dots-icon.svg";
// import { URL_FETCH_ASIN } from "../../../../routes/routes-path";
// import { navigatePage } from "../../../../utils/helpers/common";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  getOpportunityReport,
  // getUserProductAction,
  handleSelectAllProductActionM,
  resetOpportunityProductsDispatch,
  // resetProductsDispatch,
} from "../../../../store/product/product.actions";
import { errorHandler, handleAuthError } from "../../../../utils/helpers/apis";
// import MuiPopover from "../../../Atoms/Popover";
// import MenuOption from "../../../Molecules/MenuOption";
import {
  checkLiveProductLimit,
  // , downloadOpportunityReportCSV
} from "../../../../pages/SavedProducts/config";
import classes from "./OpportunityReportM.module.css";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import {
  isExportButton,
  showAddTagOption,
  showLiveCheckButton,
  showMultipleProductDeleteButton,
} from "../../../../utils/helpers/priviligesChecks";
import { defaultPageSize, usePagination } from "../../../../hooks/usePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import { generateProductApis } from "../../../../apis/generate-product";
import ConfirmBox from "../../../mui/dialogebox/confirmBox";
import MBottomSheet from "../../../Atoms/MBottomSheet";
import TagPopper from "../../SavedProducts/TagPopper";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import OpportunityProductDetailCard from "../../../Molecules/OpporutityProductDetailCard";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { OpportunityReportFilter } from "../../../../store/opportunity-report/opportunityReport.selector";
import OpportunityFilterMenu from "../OpportunityFilterMenu/OpportunityFilterMenu";
import { 
  setOpportunityProductLoadingDispatch,
  setOpportunityReportFilter
 } from "../../../../store/opportunity-report/opportunityReport.actions";
import { filterMarks } from "../OpportunityGrid/config";
import MTextField from "../../../Atoms/MTextField";
import { debounce } from "lodash";
import OpportunityCardSkeleton from "../../../Molecules/AnimatedSkeletonCard/OpportunityCardSkeletonM";
import NoResultsFound from "../../../Atoms/NoResultsFound/NoResultsFound";
import { CompanyRoleSelector } from "../../../../store/company/company.selector";
import { SUCC_CSV_EXPORT } from "../../../../utils/constants/messages/success";
import { ERR_CSV_EXPORT } from "../../../../utils/constants/messages/errors";

interface SavedProductsState {
  loading: boolean;
  openPopper: boolean;
  anchorEl: HTMLImageElement | null;
}

interface SavedProductsMProps {
  isChildComponent?: boolean;
}
const OpportunityReportM: React.FC<SavedProductsMProps> = (props) => {
  const { isChildComponent = false } = props;
  const companyRole = useSelector(CompanyRoleSelector);

  const dispatch = useDispatch();
  const saveProducts = useSelector(OpportunityProductsSelector);
  // const location = useLocation();
  const totalProducts = useSelector(TotalOpportunityProductSelector);
  const dbUser = useSelector(DbUserSelector);
  const navigate = useNavigate();
  const { pageSize, setPageNumber, pageNumber, offset } = usePagination();
  const [state, setState] = useState<SavedProductsState>({
    loading: true,
    openPopper: false,
    anchorEl: null,
  });
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectionMode, setSelectionMode] = useState<boolean>(false);
  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);
  const [openTagsSheet, setOpenTagsSheet] = useState<boolean>(false);
  const userRole = useSelector(UserRoleSelector);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const opportunityFilters = useSelector(OpportunityReportFilter);
  // filter states
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const location = useLocation();
  const brand = location?.state?.productBrand;

  /* eslint-disable react-hooks/exhaustive-deps */
  const rows = useMemo(() => {
    setHasMore(totalProducts > saveProducts?.length);
    const allProducts = [...saveProducts];
    if (allProducts?.length) {
      const selected = allProducts?.filter((p: Product) => p?.isSelected);
      setSelectedProducts([...selected]);
    }
    return allProducts;
  }, [saveProducts]);

  const getProducts = async (isReset = true, concat = false) => {
    dispatch(setOpportunityProductLoadingDispatch(true));
    if (isReset) {
    }

    let filters: any = {
      offset: concat ? offset : 0,
      limit: defaultPageSize,
      searchQuery: searchQuery,
      sortBy: "volumeChange",
      sortOrder: -1,
      hideKeywordCoverageRatioGreaterThanEqualTo: opportunityFilters?.exceedLimitFilter,
      hideLastLiveProducts: opportunityFilters?.lastLiveProductsFilter,
    };

    if (opportunityFilters?.lastLiveProductsFilter) {
      filters = {
        ...filters,
        hideLastLiveProducts: opportunityFilters?.lastLiveProductsFilter,
      };
    }

    if (!opportunityFilters?.keywordCoverageFilter) {
      filters = {
        ...filters,
        hideKeywordCoverageRatioGreaterThanEqualTo: 0,
      };
    }

    if (searchQuery !== "") {
      filters = {
        ...filters,
        hideKeywordCoverageRatioGreaterThanEqualTo: 0,
        hideLastLiveProducts: false,
      };
    }

    if (brand) {
      filters = {
        ...filters,
        productBrand: brand === "Others" ? undefined : brand,
      };
    }

    dispatch(getOpportunityReport({ navigate, filters, mobile: concat }))
      .then(() => {
        setState({ ...state, loading: false });
        dispatch(setOpportunityProductLoadingDispatch(false));
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setState({ ...state, loading: false });
        dispatch(setOpportunityProductLoadingDispatch(false));
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(resetOpportunityProductsDispatch());
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState({ ...state, loading: true });
    getProducts(true, true);
  }, [pageNumber, pageSize, offset]);

  const isInitialRender = useRef(true); // Using a ref to track initial render
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInitialRender.current) {
      setState({ ...state, loading: true });
      getProducts(true, false);
    } else {
      isInitialRender.current = false;
    }
  }, [
    searchQuery,
    opportunityFilters?.lastLiveProductsFilter,
    opportunityFilters?.keywordCoverageFilter,
    opportunityFilters?.exceedLimitFilter,
  ]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selectionMode) {
      dispatch(handleSelectAllProductActionM(saveProducts, false));
    }
  }, [selectionMode]);

  const fetchMore = () => {
    if (totalProducts > saveProducts.length) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleCsvExport = async () => {
    if (rows?.length) {
      try {
        if (!csvLoading) {
          setCsvLoading(true);
          setState({
            ...state,
            anchorEl: null,
            openPopper: false,
          });
          await generateProductApis
            .getOpportunityReportDownloadUrl({
              offset: 0,
              limit: 0,
              searchQuery: "",
              sortBy: "volumeChange",
              hideKeywordCoverageRatioGreaterThanEqualTo: 0,
              hideLastLiveProducts: false,
              sortOrder: -1,
              productBrand: brand,
            })
            .then(() => {
              toast.success(SUCC_CSV_EXPORT);
              setCsvLoading(false);
              setState({
                ...state,
                openPopper: false,
              });
            })
            .catch((error) => {
              setCsvLoading(false);
              setState({
                ...state,
                openPopper: false,
              });
              throw new Error(handleAuthError(error));
            });
        } else {
          toast.warn("Exporting Data. Please Wait");
        }
      } catch (e: any) {
        setCsvLoading(false);
        toast.error(handleAuthError(e));
      }
    } else {
      toast.error(ERR_CSV_EXPORT);
    }
  };

  const disableSelectionMode = () => setSelectionMode(false);
  const handleProductLive = async () => {
    try {
      if (selectedProducts?.length) {
        const approved = selectedProducts?.filter((pro) => pro?.status === ContentStatusEnum.APPROVED);

        if (approved.length <= checkLiveProductLimit) {
          setState({ ...state, loading: true });
          await generateProductApis.checkProductsLive(
            approved.map((p: Product) => ({ productASIN: p?.productASIN || "", userID: p?.userID || "" }))
          );
          await getProducts(true);
          setState({ ...state, loading: false });
          setSelectionMode(false);
        } else {
          toast.warn(`Cannot select more than ${checkLiveProductLimit} products`);
        }
      } else toast.warn("No rows selected");
    } catch (error) {
      errorHandler(error);
    }
  };
  const handleDeleteAll = async () => {
    try {
      setOpenConfirmBox(false);
      setState({ ...state, loading: true });
      await generateProductApis.deleteGeneratedProducts(selectedProducts.map((p: Product) => p._id));
      await getProducts(true);
      setState({ ...state, loading: false });
      setSelectionMode(false);
      toast.success("Your selected products has been deleted");
    } catch (e) {
      errorHandler(e);
      setState({ ...state, loading: false });
      setSelectionMode(false);
      setOpenConfirmBox(false);
    }
  };
  const handleSelectAll = () => {
    dispatch(handleSelectAllProductActionM(saveProducts, selectedProducts?.length !== saveProducts?.length));
  };
  const handleOpenConfirmBox = () => {
    if (selectedProducts.length) {
      setOpenConfirmBox(true);
    } else {
      toast.warn("No Product Selected");
    }
  };
  const closeConfirmBox = () => setOpenConfirmBox(false);
  const closeTagsBottomSheet = () => setOpenTagsSheet(false);

  // filter implementation
  const handleFilterChange = (hideLastLive: boolean, hideKeywordCoverage: boolean, exceedLimit: number) => {
    try {
      dispatch(
        setOpportunityReportFilter({
          lastLiveProductsFilter: hideLastLive,
          keywordCoverageFilter: hideKeywordCoverage,
          exceedLimitFilter: !exceedLimit ? 0 : exceedLimit,
        })
      );
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowFilterMenu(!showFilterMenu);
    setAnchorEl(e.currentTarget);
  };
  const handleFilterClose = () => {
    setShowFilterMenu(false);
    setAnchorEl(null);
  };

  // search implementation
  const debouncedSearch = useRef(
    debounce((value: string) => {
      if (value.length > 0) {
        dispatch(resetOpportunityProductsDispatch());
      }
      setSearchQuery(value);
    }, 1500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <div className={"d-flex flex-column h-auto p-2 mt-2"} style={{ marginBottom: "95px" }}>
      <div className="d-flex flex-column ms-0 ">
        {selectionMode ? (
          <>
            <div className={"d-flex justify-content-between align-items-center"}>
              <div onClick={handleSelectAll}>
                <MTypography variant={"h4"} customClass={classes.SelectionOptions}>
                  {selectedProducts?.length === saveProducts?.length ? "Unselect All" : "Select All"}
                </MTypography>
              </div>

              <div className={"p-1"} onClick={disableSelectionMode}>
                <MTypography variant={"h4"} customClass={classes.SelectionOptions}>
                  {"Cancel"}
                </MTypography>
              </div>
            </div>
            <div className={"mt-4 mb-1"}>
              <MTypography variant={"h4"}>{`${
                saveProducts?.filter((p: Product) => p?.isSelected)?.length
              } Selected  `}</MTypography>
            </div>
          </>
        ) : (
          <div
            className={
              !isChildComponent
                ? "d-flex align-items-center justify-content-between gap-3"
                : "d-flex justify-content-end gap-3"
            }
          >
            <MTextField
              icon={"search"}
              position={"start"}
              onChange={handleChange}
              name={"search-field"}
              placeholder={"Search"}
              value={search}
              margin={"dense"}
              rootClass={classes.TextInput}
            />
            <div className="d-flex align-items-center gap-2">
              <div
                className={`cursor-pointer ${classes.IconContainer} ${
                  state.loading || isExportButton(userRole, dbUser?.companyID, companyRole)
                    ? `pe-none ${classes.DisabledText}`
                    : "pe-auto"
                }`}
                onClick={handleCsvExport}
              >
                {!csvLoading && <FileDownloadOutlinedIcon fontSize={"medium"} />}
              </div>
              <div>
                <div
                  className={`cursor-pointer ${classes.IconContainer} ${
                    state.loading ? `pe-none ${classes.DisabledText}` : "pe-auto"
                  }`}
                  onClick={handleFilterClick}
                >
                  <FilterListIcon fontSize={"medium"} />
                </div>
                <OpportunityFilterMenu
                  open={showFilterMenu}
                  anchorEl={anchorEl}
                  onClose={handleFilterClose}
                  handleFilterChange={handleFilterChange}
                  hideLastLiveProducts={opportunityFilters.lastLiveProductsFilter}
                  hideKeywordCoverageExceed={opportunityFilters.keywordCoverageFilter}
                  keywordExceedLimit={opportunityFilters.exceedLimitFilter}
                  marks={filterMarks}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {csvLoading && <div className={"d-flex align-items-centers justify-content-center p-3"}>Exporting CSV...</div>}

      <InfiniteScroll
        dataLength={saveProducts.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={
          <div className={"d-flex justify-content-center my-3"}>
            {state.loading && <CircularProgress color={"primary"} />}
          </div>
        }
      >
        <div>
          {state.loading ? (
            <>
              <OpportunityCardSkeleton />
            </>
          ) : rows.length > 0 ? (
            rows.map((product: any) => {
              return (
                <OpportunityProductDetailCard
                  getProducts={getProducts}
                  key={product._id}
                  productDetail={product}
                  tab={TabsEnum.SAVED}
                  selectionMode={selectionMode}
                />
              );
            })
          ) : (
            <>{!state.loading && <NoResultsFound pt={4} />}</>
          )}
        </div>
      </InfiniteScroll>

      {selectionMode && (
        <BottomNavigation className={`${classes.BottomNavigation}`}>
          <div className={`d-flex align-items-start justify-content-center gap-4 mt-2 w-100 p-3`}>
            {showLiveCheckButton(dbUser?.role, ContentStatusEnum.APPROVED) && (
              <MTypography
                variant={"h4"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                <div onClick={handleProductLive} className={"d-flex align-items-center cursor-pointer flex-column"}>
                  <OnlinePredictionOutlinedIcon className={classes.ToolbarIcon} />
                  {"Product Live"}
                </div>
              </MTypography>
            )}

            {showAddTagOption(dbUser?.role) && (
              <MTypography
                variant={"h4"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                ""
              </MTypography>
            )}

            {showMultipleProductDeleteButton(dbUser?.role) && (
              <MTypography
                variant={"h4"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                <div onClick={handleOpenConfirmBox} className={"d-flex align-items-center cursor-pointer flex-column"}>
                  <DeleteIcon className={classes.ToolbarIcon} />
                  {"Delete"}
                </div>
              </MTypography>
            )}
          </div>
        </BottomNavigation>
      )}

      <MBottomSheet open={openTagsSheet} showFullHeight>
        <TagPopper onClose={closeTagsBottomSheet} selectedProducts={selectedProducts} />
      </MBottomSheet>
      <ConfirmBox
        open={openConfirmBox}
        confirm={handleDeleteAll}
        cancel={closeConfirmBox}
        onClose={closeConfirmBox}
        title="Confirm Delete Products"
        confirmButtonText="Delete Selected"
      >
        {"Do you want to delete selected products?"}
      </ConfirmBox>
    </div>
  );
};

export default OpportunityReportM;
