import { Box, Skeleton } from "@mui/material";
const OpportunityCardSkeleton = () => {
    const getSkeleton = () => {
        const skeletons = [];
        for (let index = 0; index < 10; index++) {
            skeletons.push(
                <div className={"card flex-row mt-4 cursor-pointer p-3"}>
                    <div className="d-flex flex-row w-100 gap-2">
                        <div className="w-100">
                            <Box component={'div'} className="w-100" sx={{ borderBottom: '1px solid #c7c8c9', mb: '16px', pb: '24px' }}>
                                <div className="d-flex flex-row w-100 gap-2">
                                    <div className={'align-self-center'}>
                                        <Skeleton
                                            variant="rectangular"
                                            width={80}
                                            height={80}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width={150}
                                            height={30}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width={80}
                                            height={30}
                                        />
                                    </div>
                                </div>
                            </Box>
                            <div className={"d-flex justify-content-between align-items-center mt-3 mb-2 gap-5"}>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                            </div>
                            <div className={"d-flex justify-content-between align-items gap-5"}>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return skeletons;
    }

    return ( 
        <>
            {getSkeleton()}
        </>
    )
}

export default OpportunityCardSkeleton;