import React from "react";
import { BottomNavigation, Paper } from "@mui/material";
import classes from "./index.module.css";
interface MBottomNavigationProps {
  children?: React.ReactNode;
}
const MBottomNavigation: React.FC<MBottomNavigationProps> = ({ children }) => {
  return (
    <div>
      <Paper elevation={3} className={`position-fixed bottom-0 ${classes.BottomNavigation}`}>
        <BottomNavigation className={"px-4 mt-4 position-absolute w-100"}>{children}</BottomNavigation>
      </Paper>
    </div>
  );
};

export default MBottomNavigation;
