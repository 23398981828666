import { Box, Skeleton } from "@mui/material";

const ProductEventsWaitingCards = () => {
  const getSkeleton = () => {
    const skeletons = [];
    for (let index = 0; index < 10; index++) {
      skeletons.push(
        <div className={"card flex-row mt-4 cursor-pointer p-2 pl-0"} key={index}>
          <div className="d-flex flex-row w-100 gap-2">
            <div className="w-100">
              <Skeleton variant="text" width={150} height={20} />
              <Box component={"div"} className="w-100">
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"50%"} height={20} />
              </Box>

              <div className={"d-flex justify-content-between align-items-start mt-1 mb-0"}>
                <Skeleton variant="text" width={60} height={20} />
                <Skeleton variant="text" width={60} height={20} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return skeletons;
  };

  return <>{getSkeleton()}</>;
};

export default ProductEventsWaitingCards;
