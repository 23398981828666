import _ from "lodash";

export function insertElementInArray(array: any, index: number, element: any) {
  if (index === 0) {
    array.unshift(element);
    return array;
  }
  array.splice(index, 0, element);
  return array;
}

export function removeElementInArray(array: any, index: number) {
  if (array.length === 0) return [];

  return array.filter((_item: any, i: number) => i !== index);
}

export function deepCompareArrays(arr1: any, arr2: any) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const iteratorKey = arr1[0].hasOwnProperty("id") ? "id" : "phrase";

  // Sort the arrays to ensure consistent comparison
  const sortedArr1 = _.sortBy(arr1, iteratorKey);
  const sortedArr2 = _.sortBy(arr2, iteratorKey);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (!_.isEqual(sortedArr1[i], sortedArr2[i])) {
      return false;
    }
  }

  return true;
}

export const findKeywordsInRows = ({ keywords, rows }: any): any => {
  try {
    const result: any = [];

    keywords.forEach((keyword: any) => {
      rows.forEach((row: any) => {
        if (keyword.phrase === row.phrase) {
          result.push(row);
        }
      });
    });
    return result;
  } catch (e) {
    return [];
  }
};

export const stringifyJson = (param: any): string => JSON.stringify(param);

export const parseJson = (param: string) => JSON.parse(param);

export function countOccurrences(arr: any[], targetObject: any): number {
  return arr.reduce((count, object) => {
    return count + (object === targetObject ? 1 : 0);
  }, 0);
}

export const wordLengthWithOutSpaces = (body: string): number => {
  try {
    return body.replace(/\s/g, "").length;
  } catch (e) {
    console.log("error wordLengthWithOutSpaces", body);
    return 0;
  }
};

export const replaceSpacesInString = (str: string): string => {
  try {
    return str.replace(/\s/g, "");
  } catch (e) {
    console.log("error replaceSpacesInString", e);
    return "";
  }
};

export function extractASIN(input: string): string {
  try {
    // Check if the input is a valid Amazon ASIN
    const isASIN = /^[A-Za-z0-9]{10}$/.test(input);

    // Check if the input is a valid Amazon link with ASIN
    const match = input.match(/(?:dp|gp\/product|exec\/obidos\/ASIN)\/(\w{10})/);

    // Return ASIN if it's a valid ASIN, or extract it from the link
    return isASIN ? input : (match && match[1]) || "";
  } catch (e) {
    console.log("error extractASIN", e);
    return input;
  }
}

export const reduceImgSize = (imgUrl: string) => {
  let pattern = /\.[^.]+$/;
  let addition = "._AC_UL232_SR232,232";
  let modifiedImageUrl = imgUrl?.replace(pattern, (match) => addition + match);

  return modifiedImageUrl;
};

export const onImgErrorHandler = (e: React.SyntheticEvent<HTMLImageElement, Event>, imgUrl: string) => {
  const target = e.target as HTMLImageElement;
  target.src = imgUrl;
};

export function validateEmail(email: string): boolean {
  // Regular expression for a basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the provided email against the regex
  return emailRegex.test(email);
}
