import React from "react";
import { Switch } from "@mui/material";

import { useFormikContext } from "formik";

interface Props {
  name: string;
  label?: string;
  isLabelRight?: boolean;
}

const FormSwitch: React.FC<Props> = (props) => {
  const { name = "", label, isLabelRight = false } = props;

  const { values, setFieldValue }: any = useFormikContext();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.checked);
  };
  return (
    <div className={"d-flex gap-2 align-items-center"}>
      {label && !isLabelRight && (
        <div style={{ color: "#80828A", fontSize: " 16px", fontWeight: "normal" }}>{label}</div>
      )}
      <Switch checked={values[name]} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
      {label && isLabelRight && (
        <div style={{ color: "#80828A", fontSize: " 16px", fontWeight: "normal" }}>{label}</div>
      )}
    </div>
  );
};

export default FormSwitch;
