import React from "react";
import classes from "./index.module.css";
import { Skeleton } from "@mui/material";
import { useDeviceType } from "../../../hooks/useDeviceType";
import MTypography from "../../Atoms/MTypography";

interface CardWithSideChartProps {
  title: string;
  volume: number;
  isLoading: boolean;
  showChart?: boolean;
}

const CardWithSideChart: React.FC<CardWithSideChartProps> = (props) => {
  const { title, volume, isLoading, showChart } = props;
  const { isDesktop } = useDeviceType();
  return (
    <div className={`${isDesktop ? classes.CardContainer : classes.CardContainerM} d-flex flex-row`}>
      <div className={`h-100 d-flex justify-content-between flex-column ${!showChart && "w-100"}`}>
        <MTypography
          variant={"subtitle1"}
          customClass={`${isDesktop ? classes.CardTitle : classes.CardTitleM}`}
          color={"#4d4d4d"}
        >
          {title}
        </MTypography>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Skeleton variant="text" width={"50%"} height={20} />
          </div>
        ) : (
          <MTypography variant={"h1"} customClass={isDesktop ? classes.Count : classes.CountM}>
            {volume}
          </MTypography>
        )}
      </div>
      {showChart && <div>chart</div>}
    </div>
  );
};

export default CardWithSideChart;
