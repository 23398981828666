import { DialogProps } from "@mui/material";
import React, { useState } from "react";
import DialogeBox from "..";
import EditUserPrivilegesDialogContent from "./editUserPrivilegesDialogboxContent";
import { AccountManagementState } from "../../../../pages/AccountManagement";
import { DatabaseUser } from "../../../../apis/types/user";
import { RolesEnum } from "../../../../utils/constants/enums";

type EditUserPrivilegeBoxProps = DialogProps & {
  userManagementState: AccountManagementState;
  setUserManagementState: React.Dispatch<React.SetStateAction<AccountManagementState>>;
  updateUser: (contentGenerationLimit: number) => Promise<void>;
};
interface EditUserPrivilegeBoxState {
  contentGenerationLimit: number;
  role: RolesEnum;
}

const EditUserPrivilegeBox = ({
  open,
  onClose,
  updateUser,
  userManagementState,
  setUserManagementState,
}: EditUserPrivilegeBoxProps) => {
  const { contentGenerationLimit, role } = userManagementState.selectedUser as DatabaseUser;
  const [state] = useState<EditUserPrivilegeBoxState>({
    contentGenerationLimit: contentGenerationLimit,
    role: role,
  });

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) onClose(event, "backdropClick");
  };

  const handleConfirm = () => {
    updateUser(state.contentGenerationLimit) as any;
  };
  return (
    <DialogeBox
      open={open}
      title={"Modify User Privilege"}
      confirmButton={{
        action: handleConfirm,
        disabled: userManagementState.loading,
        element: <> {"Update"}</>,
      }}
      cancelButton={{ action: handleCancel as any, element: "Cancel" }}
    >
      {" "}
      <EditUserPrivilegesDialogContent
        userManagementState={userManagementState}
        setUserManagementState={setUserManagementState}
      />
    </DialogeBox>
  );
};

export default EditUserPrivilegeBox;
