import * as yup from "yup";

export interface CustomKeywordsObject {
  title: string;
  description: string;
  bulletPoints: string;
  keywords: string;
}

export const inputName = {
  title: "title",
  description: "description",
  bulletPoints: "bulletPoints",
  keywords: "keywords",
};

export const initialValue: CustomKeywordsObject = {
  title: "",
  description: "",
  bulletPoints: "",
  keywords: "",
};

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required("title is required"),
  description: yup.string().trim().required("Description is required"),
  bulletPoints: yup.string().trim().required("Bullet Points are required"),
  keywords: yup.string().trim().required("Keywords are required"),
});
