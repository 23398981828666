import { aiModelEnums, aiModelNickNameEnums } from "../../../../utils/constants/enums";

export const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];

export const aiModelsProbabilityConstants = {
  gpt4: {
    name: "gpt4",
    title: aiModelEnums.GPT4,
    nickName: aiModelNickNameEnums.GPT4_NICK_NAME
  },
  gpt4o: {
    name: "gpt4o",
    title: aiModelEnums.GPT_4o,
    nickName: aiModelNickNameEnums.GPT_4o_NICK_NAME
  },
  claudeSonnet: {
    name: "claudeSonnet",
    title: aiModelEnums.CLAUDE_SONNET,
    nickName: aiModelNickNameEnums.CLAUDE_SONNET_NICK_NAME
  }
}


export const probabilityList = [
  {
    name: aiModelsProbabilityConstants.gpt4.name,
    title: aiModelsProbabilityConstants.gpt4.title,
    nickName: aiModelsProbabilityConstants.gpt4.nickName,
  },
  {
    name: aiModelsProbabilityConstants.gpt4o.name,
    title: aiModelsProbabilityConstants.gpt4o.title,
    nickName: aiModelsProbabilityConstants.gpt4o.title,
  },
  {
    name: aiModelsProbabilityConstants.claudeSonnet.name,
    title: aiModelsProbabilityConstants.claudeSonnet.title,
    nickName: aiModelsProbabilityConstants.claudeSonnet.title,
  },
];