import { ClientsReducerActionType } from "./clients.actions";
import { clientsActionTypes } from "./clients.action-types";
import { produce } from "immer";
import { clientsTabsValue } from "../../pages/Clients/config";
import { SetClientsStatsInterface } from "../../apis/types/clients";

export interface State {
  activeClientsTab: string;
  clientsStats: SetClientsStatsInterface;
  clientRoleFiler: string | ""
}

const initialState: State = {
  activeClientsTab: clientsTabsValue.companies,
  clientsStats: {
    totalClients: 0,
    totalRecords: 0,
    totalApprovedProducts: 0,
    totalLivedProducts: 0,
    totalProducts: 0,
    totalPublishedProducts: 0
  },
  clientRoleFiler: ""
};

const clientsReducer = produce((state: State = initialState, action: ClientsReducerActionType) => {
  switch (action.type) {
    case clientsActionTypes.setClientsActiveTab:
      state.activeClientsTab = action.payload;
      return state;
    case clientsActionTypes.setClientsStats:
      state.clientsStats = action.payload;
      return state;
    case clientsActionTypes.setClientsRoleFilter:
      state.clientRoleFiler = action.payload;
      return state;
    default:
      return state;
  }
});

export default clientsReducer;
