import { BottomNavigation, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AppBar from "../../../components/Molecules/AppBar";
import { CANNOT_CONTINUE_NO_COMPETITOR, ERR_COMPETITORS_LIMIT } from "../../../utils/constants/messages/errors";
import CrossElement from "../../../components/Molecules/AppBar/crossElement";
import CenterElement from "../../../components/Molecules/AppBar/centerElement";
import { PagesEnum, ParamsEnum } from "../../../utils/constants/enums";
import { useDeviceType } from "../../../hooks/useDeviceType";
import FetchProductHeader from "../../../components/Molecules/FetchProductHeader";
import Donut from "../../../assets/svgs/ProductFetchSteps/Mobile/Donut2.svg";
import MTypography from "../../../components/Atoms/MTypography";
import MButton from "../../../components/Atoms/MButton";
import CompetitorComp, { CompetitorProps } from "../../../components/Organisms/Competitor";
import "./competitors.style.scss";
import classes from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { errorHandler } from "../../../utils/helpers/apis";
import { Competitor } from "../../../interfaces/scrapeProduct";
import { useNavigate, useParams } from "react-router-dom";
import { URL_KEYWORDS, URL_PRODUCT_FETCHED } from "../../../routes/routes-path";
import { navigatePage } from "../../../utils/helpers/common";
import FetchProductDetails from "../../../components/Organisms/FetchProductDetails";
import { ScrapeProductSelector } from "../../../store/product/productScrape/productScrape.selectors";

import { updateProductSelectedCompetitorsAction } from "../../../store/product/productScrape/productScrape.actions";
import { maxCompetitorsLimit } from "../../../utils/constants/general-constants";
interface CompetitorsPageProps { }

export interface CompetitorsPageState {
  competitors: Partial<CompetitorProps>[];
  showUnSelected: boolean;
  loading: boolean;
  nextLoading: boolean;
  maxSelect: number;
  limit: boolean;
  oldSelectedCompetitors: string[];
  loadingAnimation: boolean;
  productFound: boolean;
}

const CompetitorsPage: React.FC<CompetitorsPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const [loading, setLoading] = useState<boolean>(false)
  // const { isDesktop, isIpad } = useDeviceType();

  const [state, setState] = useState<CompetitorsPageState>({
    competitors: [],
    showUnSelected: false,
    loading: false,
    nextLoading: false,
    maxSelect: 0,
    limit: false,
    oldSelectedCompetitors: [],
    loadingAnimation: true,
    productFound: false,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (scrapeProduct?.productFetch) {
      const selectedCompetitors = scrapeProduct?.selectedCompetitors;
      const allCompetitors = scrapeProduct?.allCompetitors;

      let selected: Partial<CompetitorProps>[] = [];
      if (selectedCompetitors?.length)
        selected = selectedCompetitors.map((comp) => ({
          ...comp,
          selected: true,
        }));
      let unSelected: Partial<CompetitorProps>[] = [];
      if (allCompetitors?.length)
        unSelected = allCompetitors
          .filter((competitor) => !selected.some((unComp) => unComp.asin === competitor.asin))
          .map((comp) => ({
            ...comp,
            selected: false,
          }));

      const competitors = [...selected, ...unSelected];
      const oldSelectedCompetitors = selected?.map((comp) => comp.asin);
      setState({ ...state, oldSelectedCompetitors: oldSelectedCompetitors as string[], competitors });
    }
  }, [scrapeProduct?.productFetch, scrapeProduct?.allCompetitors]);

  const changedSelectedCompetitors = (asinId: string) => {
    let selected = state.competitors.filter((comp) => comp.selected).length;

    if (selected === maxCompetitorsLimit && !state.competitors.find((comp) => comp.asin === asinId && comp.selected)) {
      toast.warn(ERR_COMPETITORS_LIMIT);
    } else {
      setState((prevState) => {
        const updatedCompetitors = prevState.competitors.map((competitor) => {
          if (competitor.asin === asinId) {
            return { ...competitor, selected: !competitor.selected };
          }
          return competitor;
        });
        return {
          ...prevState,
          competitors: updatedCompetitors,
        };
      });
    }
  };

  const handleNext = async (): Promise<void | null> => {
    try {
      const selected = state.competitors.filter((comp) => comp.selected);
      if (state.competitors.length < 1) {
        toast.warn(CANNOT_CONTINUE_NO_COMPETITOR);
        return null;
      }
      if (selected.length <= maxCompetitorsLimit) {
        setState({ ...state, nextLoading: true });

        const productASIN = scrapeProduct?.productASIN ? scrapeProduct?.productASIN : scrapeProduct?.product?.asin;
        dispatch(updateProductSelectedCompetitorsAction(selected as Competitor[], scrapeProduct))
          .then(() => {
            setState({ ...state, nextLoading: false });
            navigatePage(
              `${URL_PRODUCT_FETCHED}/${params[ParamsEnum.DOMAIN] ?? "com"}/${productASIN}${URL_KEYWORDS}`,
              navigate
            );
          })
          .catch((e: any) => {
            setState({ ...state, nextLoading: false });
            toast.error(errorHandler(e));
          });
      } else {
        toast.warn(ERR_COMPETITORS_LIMIT);
      }
    } catch (e) {
      toast.error(errorHandler(e));
    }
  };
  const { isDesktop, isMobile, isIpad } = useDeviceType();

  const setIsLoading = (loadingState: boolean) => {
    setLoading(loadingState)
  }
  return (
    <div>
      {isDesktop && (
        <div>
          <AppBar
            centerElement={<CenterElement page={PagesEnum.COMPETITORS} />}
            rightElement={<CrossElement />}
          ></AppBar>
        </div>
      )}
      {(isMobile || isIpad) && (
        <FetchProductHeader donut={Donut} next={"Keywords"} tabNumber={2} title={"Competitors"} />
      )}

      <div className={`w-100 ${classes.Container} ${isMobile && classes.ContainerM}`}>
        <div className={"d-flex justify-content-center p-3"}>
          <FetchProductDetails
            setIsLoading={setIsLoading}
          />
        </div>
        <div className={"my-2 text-center"}>
          <MTypography variant={"h4"}>{"Suggested Competitors"}</MTypography>
        </div>

        <div className={"m-4 text-center"}>
          <MTypography
            variant={isDesktop ? "body2" : "h6"}
            customClass={"fw-normal"}
            color={isDesktop ? "rgba(126, 126, 126, 1)" : ""}
          >
            {`Select maximum `} <span className={"fwBold600"}>{maxCompetitorsLimit}</span>
            {` competitors that are most closely related to your product`}
          </MTypography>
        </div>

        <>
          <div className={isDesktop ? "row gx-4 gy-4 mx-5" : "row gx-4 gy-4 mx-2"}>
            <>
              {state.competitors.length > 0 && !loading &&
                state.competitors
                  .sort((a, b) => (b.relevancy || 0) - (a.relevancy || 0))
                  .map((item, index) => (
                    <div className={"col-sm-12 col-xs-12 col-md-6 col-lg-4 col-xl-3"} key={index}>
                      <CompetitorComp
                        asin={item.asin}
                        image={item.image}
                        title={item.title}
                        rating={item.rating}
                        ratings_total={item.ratings_total}
                        price={item.price}
                        link={item?.link}
                        by={item.by}
                        selected={item.selected}
                        changeSelected={() => {
                          changedSelectedCompetitors(item.asin ?? "");
                        }}
                        relevancy={item.relevancy}
                        allCompetitors={state}
                      />
                    </div>
                  ))}
            </>
          </div>

          <>
            {isMobile && (
              <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, height: "100px", maxWidth: '100vw' }} elevation={3}>
                <BottomNavigation
                  showLabels
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: "4%",
                    width: "92%",
                  }}
                >
                  <div>
                    <MButton
                      onClick={handleNext}
                      isLoading={state.loading || state.nextLoading || loading}
                      size={"medium"}
                      variant={"contained"}
                      rootClass={"comp-button-mb"}
                    >
                      {"Next"}
                    </MButton>
                  </div>
                </BottomNavigation>
              </Paper>
            )}
            {(isDesktop || isIpad) && (
              <div className={`d-flex justify-content-end w-100 my-5 px-5`}>
                <div className={"w-25"}>
                  <MButton onClick={handleNext} isLoading={state.loading || state.nextLoading || loading} disabled={state.loading || state.nextLoading || loading} fullWidth>
                    {"Next"}
                  </MButton>
                </div>
              </div>
            )}
          </>
        </>        
      </div>
    </div>
  );
};

export default CompetitorsPage;
