import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Menu, Radio, RadioGroup, Typography } from "@mui/material";

import MButton from "../../../Atoms/MButton";
import MBottomSheet from "../../../Atoms/MBottomSheet";

import { clientsTabsValue } from "../../../../pages/Clients/config";

import { useDeviceType } from "../../../../hooks/useDeviceType";

import { clientsFilterOptionForCompanies, clientsFilterOptionForIndividuals } from "../config";

import classes from "./index.module.css";

interface DefaultMenu {
  label: string;
  value: any;
}
interface Props {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  // options: DefaultMenu[];
  handleFilterChange: (value: string) => void;
  activeTab: string;
  activeRoleFilter: string;
  openMobileFilterMenu: boolean;
}

const ClientsFilterMenu: React.FC<Props> = (props) => {
  const {
    open,
    anchorEl,
    onClose,
    // options,
    handleFilterChange,
    activeTab,
    activeRoleFilter,
    openMobileFilterMenu = false,
  } = props;
  const [selectedRadio, setSelectedRadio] = React.useState<string>(activeRoleFilter);

  const [optionsList, setOptionsList] = useState<DefaultMenu[]>([]);
  const { isDesktop } = useDeviceType();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (activeTab === clientsTabsValue.companies) {
      setOptionsList(clientsFilterOptionForCompanies);
    } else if (activeTab === clientsTabsValue.individuals) {
      setOptionsList(clientsFilterOptionForIndividuals);
    } else {
    }
  }, [activeTab]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSelectedRadio(activeRoleFilter);
  }, [activeRoleFilter]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  const handleClick = (clickType: string) => {
    console.log(clickType);
    if (clickType === "applyFilters") {
      handleFilterChange(selectedRadio);
      onClose();
    } else {
      onClose();
      setSelectedRadio(activeRoleFilter);
    }
  };

  // IMPORTANT **** : ONCE ITS GET APPROVED THEN CREATE SEPRATE SUB COMPONENT OF ITEMS
  return (
    <>
      {isDesktop ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ padding: "8px 8px" }}
        >
          <FormControl component="fieldset">
            <Typography variant="h6" className={`${classes.Headers} pt-3 px-3 pb-2`}>
              {activeTab === clientsTabsValue.companies ? "Company Role" : "Individual Role"}
            </Typography>
            <RadioGroup
              aria-label="role-filter"
              name="role-filter"
              value={selectedRadio}
              onChange={(e) => {
                handleRadioChange(e);
              }}
              sx={{ paddingLeft: "16px" }}
            >
              {optionsList?.map((mItem: DefaultMenu) => (
                <FormControlLabel
                  key={mItem.label}
                  value={mItem.value}
                  control={<Radio />}
                  label={mItem.label}
                  sx={{ height: "24px", mb: "10px" }}
                  classes={{
                    label: selectedRadio === mItem.value ? classes.ActiveLabel : classes.NonActiveLabel,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-4 pb-1 mt-3 ${classes.ButtonsContainer}`}>
            <MButton variant="outlined" size={"small"} onClick={() => handleClick("cancel")}>
              Cancel
            </MButton>
            <MButton size={"small"} onClick={() => handleClick("applyFilters")}>
              Apply Filters
            </MButton>
          </div>
        </Menu>
      ) : (
        <MBottomSheet open={openMobileFilterMenu} showFullHeight={true}>
          <FormControl component="fieldset">
            <Typography variant="h6" className={`${classes.Headers} pt-3 px-3 pb-2`}>
              {activeTab === clientsTabsValue.companies ? "Company Role" : "Individual Role"}
            </Typography>
            <RadioGroup
              aria-label="role-filter"
              name="role-filter"
              value={selectedRadio}
              onChange={(e) => {
                handleRadioChange(e);
              }}
              sx={{ paddingLeft: "16px" }}
            >
              {optionsList?.map((mItem: DefaultMenu) => (
                <FormControlLabel
                  key={mItem.label}
                  value={mItem.value}
                  control={<Radio />}
                  label={mItem.label}
                  sx={{ height: "24px", mb: "10px" }}
                  classes={{
                    label: selectedRadio === mItem.value ? classes.ActiveLabel : classes.NonActiveLabel,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-4 pb-2 mt-3 ${classes.ButtonsContainer}`}>
            <MButton variant="outlined" size={"small"} onClick={() => handleClick("cancel")}>
              Cancel
            </MButton>
            <MButton size={"small"} onClick={() => handleClick("applyFilters")}>
              Apply Filters
            </MButton>
          </div>
        </MBottomSheet>
      )}
    </>
  );
};

export default ClientsFilterMenu;
