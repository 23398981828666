import { produce } from "immer";
import { ProductScrapeActionTypes, ProductsScrapeReducerActionType } from "./productScrape.actions";
import { ScrapeProduct } from "../../../interfaces/scrapeProduct";

export interface State {
  scrapeProduct: ScrapeProduct;
  rainforestProduct: any;
}

const initialState: State = {
  scrapeProduct: {} as ScrapeProduct,
  rainforestProduct: {},
};

const productScrapeReducer = produce((state: State = initialState, action: ProductsScrapeReducerActionType) => {
  switch (action.type) {
    case ProductScrapeActionTypes.setScrapeProduct:
      state.scrapeProduct = action.payload;
      return state;
    case ProductScrapeActionTypes.setRainForestProduct:
      state.rainforestProduct = action.payload;
      return state;
    default:
      return state;
  }
});

export default productScrapeReducer;
