import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import { Divider } from "@mui/material";
import MTypography from "../../../../Atoms/MTypography";
import { useSelector } from "react-redux";
import { TotalProductsStatusSelector } from "../../../../../store/product/product.selector";
import { ProductLiveStatusEnum } from "../../../../../utils/constants/enums";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
// import { TotalProductStatuses } from "../../../../../store/product/product.reducers";

function ProductStatusCounter() {
  const allStatuses = useSelector(TotalProductsStatusSelector);
  const { isDesktop } = useDeviceType();
  const [counts, setCounts] = useState<any>({
    live: 0,
    partially_live: 0,
    inactive: 0,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      setCounts({
        live: 0,
        partially_live: 0,
        inactive: 0,
      });
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const newCounts = { ...counts };
    allStatuses.forEach((status: any) => {
      newCounts[status._id] = status.count || 0;
    });
    Object.keys(newCounts).forEach((key) => {
      if (!allStatuses.some((status: any) => status._id === key)) {
        newCounts[key] = 0;
      }
    });
    setCounts(newCounts);
  }, [allStatuses]);

  return (
    <div className={isDesktop ? classes.Container : classes.ContainerM}>
      <div className={`d-flex ${!isDesktop && "gap-4"}`}>
        <div className="d-flex align-items-center">
          <MTypography variant="subtitle2" customClass={classes.Title}>
            Live
          </MTypography>
          <span className={`${classes.StatusCircle} ${classes.LiveCircle}`}>{counts[ProductLiveStatusEnum.LIVE].toLocaleString()}</span>
        </div>
        {isDesktop && (
          <Divider flexItem orientation="vertical" sx={{ borderColor: "#D9D9D9", opacity: 1, mx: "15px" }} />
        )}
        <div className="d-flex align-items-center">
          <MTypography variant="subtitle2" customClass={classes.Title}>
            Partially Live
          </MTypography>
          <span className={`${classes.StatusCircle} ${classes.PartiallyLiveCircle}`}>
            {counts[ProductLiveStatusEnum.PARTIALLY_LIVE].toLocaleString()}
          </span>
        </div>
        {isDesktop && (
          <Divider flexItem orientation="vertical" sx={{ borderColor: "#D9D9D9", opacity: 1, mx: "15px" }} />
        )}
        <div className="d-flex align-items-center">
          <MTypography variant="subtitle2" customClass={classes.Title}>
            {/* Inactive */}
            Unpublished
          </MTypography>
          <MTypography variant="caption" customClass={`${classes.StatusCircle} ${classes.InactiveCircle}`}>
            {counts[ProductLiveStatusEnum.INACTIVE].toLocaleString()}
          </MTypography>
        </div>
      </div>
    </div>
  );
}

export default ProductStatusCounter;
