import React from "react";

import AppBar from "../../../components/Molecules/AppBar";
import CenterElement from "../../../components/Molecules/AppBar/centerElement";
import CrossElement from "../../../components/Molecules/AppBar/crossElement";
import PrioritiseKeywordsDesktop from "../../../components/Organisms/PrioritiseKeywords";

import { useDeviceType } from "../../../hooks/useDeviceType";
import { PagesEnum } from "../../../utils/constants/enums";
import FetchProductHeader from "../../../components/Molecules/FetchProductHeader";
import Donut from "../../../assets/svgs/ProductFetchSteps/Mobile/Donut4.svg";
import FetchProductDetails from "../../../components/Organisms/FetchProductDetails";

const PrioritiseKeywordsPage: React.FC = () => {
  const { isDesktop } = useDeviceType();
  // const scrapeProduct = useSelector(ScrapeProductSelector);
  // const changeKeywordOwnerId = useSelector(ChangeKeywordProductOwnerId);

  return (
    <div>
      {isDesktop ? (
        <AppBar centerElement={<CenterElement page={PagesEnum.PRIORITISE} />} rightElement={<CrossElement />}></AppBar>
      ) : (
        <FetchProductHeader donut={Donut} next={"optional"} title={"Arrange Keywords"} tabNumber={4} />
      )}

      <div style={isDesktop ? { padding: "20px 100px" } : { padding: "20px 14px" }}>
        <FetchProductDetails onlyFetch />
      </div>
      <PrioritiseKeywordsDesktop />
    </div>
  );
};
export default PrioritiseKeywordsPage;
