import React, { useEffect } from "react";

import OpportunityGrid from "./OpportunityGrid/OpportunityGrid";
import { TabsEnum } from "../../../utils/constants/enums";

import { useDeviceType } from "../../../hooks/useDeviceType";
import OpportunityReportM from "./OpportunityReportM/OpportunityReportM";

import { useDispatch } from "react-redux";
import {
  GetAllUserTagsAction,
  savedProductsDispatch,
  SetOpportunityStatsDispatch,
} from "../../../store/product/product.actions";
import { toast } from "react-toastify";
import { errorHandler } from "../../../utils/helpers/apis";
import TabsToolbar from "../../Molecules/TabsToolbar";
import OpportunityStats from "./OpportunityStatsContainer";
import MTypography from "../../Atoms/MTypography";
import MTabs from "../../Atoms/MTabs";
import { brandTabs, brandTabsValue } from "../SavedProducts/Brands/config";
import { Box } from "@mui/material";
import { setOpportunityActiveTab } from "../../../store/opportunity-report/opportunityReport.actions";
import { OpportunityActiveTab } from "../../../store/opportunity-report/opportunityReport.selector";
import { useSelector } from "react-redux";
import Brands from "../SavedProducts/Brands";
import { useLocation, useNavigate } from "react-router-dom";
import classes from './index.module.css'
import { URL_OPPORTUNITY_REPORT } from "../../../routes/routes-path";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import backIcon from "../../../assets/svgs/back-icon.svg"
import { completeProductDispatch } from "../../../store/product/productDetails/productDetails.actions";
import { CompleteProduct } from "../../../apis/types/generate-product";

interface Props {
  isChildComponent?: boolean;
}

const OpportunityReport: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isChildComponent = false } = props;
  const { isDesktop } = useDeviceType();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useSelector(OpportunityActiveTab);
  const brand = location?.state?.productBrand;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // TODO: Set this to false will enable when we active tags module
    if (false) {
      dispatch(GetAllUserTagsAction()).catch((e: any) => {
        toast.error(errorHandler(e));
      });
    }
    const product = {} as CompleteProduct
    dispatch(completeProductDispatch(product))
    dispatch(savedProductsDispatch([]));
    dispatch(
      SetOpportunityStatsDispatch({
        totalSearch: 0,
        averageVolume: 0,
        averageKw: 0,
        totalAsinLive: 0,
      })
    );
  }, []);

  const handleTabChange = (tab: string) => {
    dispatch(setOpportunityActiveTab(tab))
  }

  const handleBack = () => {
    navigate(`${URL_OPPORTUNITY_REPORT}`);
  }

  return (
    <div>
      {/* Header Sections */}
      {isDesktop ?
        <div className="d-flex align-items-center justify-content-between">
          <TabsToolbar
            title={brand || TabsEnum.OPPORTUNITY_REPORT} 
          />
          {brand !== undefined &&
            < Box p={'1.5rem 2rem 0.5rem'} onClick={handleBack} className={"cursor-pointer"}>
              <MTypography variant={"body2"} customClass={classes.BackButton}>
                {"<Back"}
              </MTypography>
            </Box>
          }
        </div>
        :
        <div className={"p-3 d-flex w-100 justify-content-between align-items-center"}>
          <MTypography variant={"h4"}>{capitalizeEachLetter(brand || "Opportunity Report")}</MTypography>
          {brand !== undefined &&
            < Box onClick={handleBack} className={"cursor-pointer"}>
              <MTypography variant={"body2"} customClass={classes.BackButton}>
                <img src={backIcon} alt={"back-icon"} className={classes.BackIcon} />
              </MTypography>
            </Box>
          }
        </div>
      }

      <OpportunityStats />
      {
        brand === undefined &&
        <Box component='div' className="mt-2" mx={{ xs: '', md: '2rem', display: 'flex' }}>
          <MTabs handleChange={handleTabChange} value={activeTab} tabs={brandTabs} isCamelCase={false} />
        </Box>
      }

      {/* Opportunity Report Asins View */}
      {(activeTab === brandTabsValue.asin || brand !== undefined) &&
        <>
          {isDesktop ?
            <div>
              <OpportunityGrid
                isChildComponent={isChildComponent}
                isRerun
                tab={TabsEnum.SAVED}
              />
            </div>
            :
            <>
              <OpportunityReportM isChildComponent={isChildComponent} />
            </>
          }
        </>
      }
      {/* Opportunity Report Brands View */}
      {(activeTab === brandTabsValue.brand && brand === undefined) &&
        <div>
          <Brands opportunityBrands={true} />
        </div>
      }
    </div >
  );
};

export default OpportunityReport;
