import { Skeleton } from "@mui/material";

const AsinsCardAnimation = () => {
    const getSkeleton = () => {
        const skeletons = [];
        for (let index = 0; index < 10; index++) {
            skeletons.push(
                <div className={"card flex-row mt-4 cursor-pointer p-2"} key={index}>
                    <div className="d-flex flex-row w-100 gap-2">
                        <div className={'align-self-center'}>
                            <Skeleton
                                variant="rectangular"
                                width={100}
                                height={120}
                            />
                        </div>
                        <div className="w-100">
                            <div className="w-100 d-flex flex-row justify-content-between">
                                <Skeleton
                                    variant="text"
                                    width={80}
                                />
                                <div className="d-flex flex-row gap-2">
                                    <Skeleton
                                        variant="rectangular"
                                        width={25}
                                        height={25}
                                    />
                                    {/* <Skeleton
                                        variant="rectangular"
                                        width={25}
                                        height={25}
                                    /> */}
                                </div>
                            </div>
                            <div className="w-100">
                                <Skeleton
                                    variant="text"
                                    width={150}
                                    height={30}
                                />
                            </div>
                            <div className="w-100 w-100 d-flex flex-row gap-2 align-items-center">
                                <Skeleton
                                    variant="rectangular"
                                    width={20}
                                    height={20}
                                />
                                <Skeleton
                                    variant="text"
                                    width={100}
                                    height={20}
                                />
                            </div>
                            <div className="w-100">
                                <Skeleton
                                    variant="text"
                                    width={200}
                                    height={30}
                                />
                                <Skeleton
                                    variant="text"
                                    width={180}
                                    height={20}
                                />
                            </div>
                            <div className="w-100 d-flex justify-content-end">
                                <Skeleton
                                    variant="text"
                                    width={140}
                                    height={20}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            )
        }
        return skeletons;
    }

    return (
        <>
            {getSkeleton()} 
        </>
    )
}

export default AsinsCardAnimation;