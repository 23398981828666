import { createContext } from "react";

interface PricingContextObject {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const PricingContext = createContext<PricingContextObject>({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

export default PricingContext;
