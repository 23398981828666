import React from "react";
import { Typography } from "@mui/material";

interface Props {
  children: React.ReactNode;
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline";
  customClass?: any;
  color?: string;
  title?: string;
}

const MTypography: React.FC<Props> = (props) => {
  const { children, variant, customClass = {}, color, title = "" } = props;
  return (
    <Typography title={title} classes={{ root: customClass }} variant={variant} color={color}>
      {children}
    </Typography>
  );
};

export default MTypography;
