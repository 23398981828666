import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Skeleton, Typography } from "@mui/material";
import classes from "../index.module.css";
import { useDeviceType } from "../../../../../hooks/useDeviceType";

const ExportPerTab = () => {
  const { isDesktop } = useDeviceType();

  const sampleData = [
    {
      id: 1,
      blocks: [1, 2, 3, 4, 5],
    },
    {
      id: 2,
      blocks: [1, 2, 3, 4, 5],
    },
    {
      id: 3,
      blocks: [1, 2, 3, 4, 5],
    },
    {
      id: 4,
      blocks: [1, 2, 3, 4, 5],
    },
    {
      id: 5,
      blocks: [1, 2, 3, 4, 5],
    },
  ];

  const sampleDataArray = isDesktop ? sampleData : sampleData.slice(0, 3);

  return (
    <div className={`${classes.SampleLayoutContainer} p-0`}>
      <div style={{ padding: "15px 15px 0px 15px" }}>
        {sampleDataArray.map((item) => (
          <div className="d-flex" style={{ gap: "10px", fontSize: "10px", color: "#80828A" }} key={item.id}>
            <Skeleton width={"20px"} height={"23px"} />
            <div className="d-flex align-items-center gap-2" style={{ marginLeft: "10px" }}>
              {item.blocks.map((item) => (
                <Skeleton key={item} width={isDesktop ? "40px": "35px"} height={"23px"} />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div
        className="d-flex align-items-center gap-2"
        style={{
          background: "#F6F6F6",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          padding: "6px 16px",
        }}
      >
        <div style={{ color: "#C3C3C3" }}>
          <MenuRoundedIcon color="inherit" />
        </div>
        <div className="d-flex align-items-center" style={{ background: "#EFF8FF", padding: "2px 0px 4px 4px" }}>
          <Typography sx={{ fontSize: "7px", lineHeight: "12px", color: "#4285F4" }} pt={"2px"} className={classes.BottomAsinsFontSize}>
            B0B6HC99BY
          </Typography>
          <div style={{ color: "#4285F4" }}>
            <ArrowDropDownIcon color="inherit" sx={{ p: 0 }} />
          </div>
        </div>
        <div className="d-flex align-items-center" style={{ background: "#eeeeee", padding: "2px 0px 4px 4px" }}>
          <Typography sx={{ fontSize: "7px", lineHeight: "12px", color: "#80828A" }} pt={"2px"} className={classes.BottomAsinsFontSize}>
            B0B6HC99BY
          </Typography>
          <div style={{ color: "#80828A" }}>
            <ArrowDropDownIcon color="inherit" sx={{ p: 0 }} />
          </div>
        </div>
        {isDesktop && (
          <div className="d-flex align-items-center" style={{ background: "#eeeeee", padding: "2px 0px 4px 4px" }}>
            <Typography sx={{ fontSize: "7px", lineHeight: "12px", color: "#80828A" }} pt={"2px"} className={classes.BottomAsinsFontSize}>
              B0B6HC99BY
            </Typography>
            <div style={{ color: "#80828A" }}>
              <ArrowDropDownIcon color="inherit" sx={{ p: 0 }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExportPerTab;
