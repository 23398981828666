import { Box, MenuItem, Typography } from "@mui/material"
import MCheckbox from "../../Atoms/MCheckbox"
import MTypography from "../../Atoms/MTypography"
import MSlider from "../../Atoms/MSlider"
import MButton from "../../Atoms/MButton"
import classes from './index.module.css';
import { useDeviceType } from "../../../hooks/useDeviceType"


interface Marks {
    label: string;
    value: any;
}
interface Props {
    handleClick: (clickType: string) => void;
    handleSliderChange: (event: any) => any;
    hideLastLive: boolean;
    setHideLastLive: React.Dispatch<React.SetStateAction<boolean>>;
    hideKeywordCoverage: boolean;
    setHideKeywordCoverage: React.Dispatch<React.SetStateAction<boolean>>;
    sliderValue: number;
    marks: Marks[];
}

const OpportunityFilterContainer: React.FC<Props> = (props) => {

    const {
        marks,
        handleClick,
        handleSliderChange,
        hideLastLive,
        setHideLastLive,
        hideKeywordCoverage,
        setHideKeywordCoverage,
        sliderValue,
    } = props;

    const { isDesktop } = useDeviceType();

    return (
        <>
            <Box mb={2}>
                <Typography variant="h6" className={`${classes.Headers} pt-2 px-3 pb-1 `}>
                    Hide Products
                </Typography>
                <Box
                    sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    onClick={() => setHideLastLive(!hideLastLive)}
                >
                    <MenuItem
                        value={"keywordCoverage"}
                        className={`p-0 ${classes.MenuItem}`}
                        sx={{ paddingLeft: "6px !important" }}
                    >
                        <MCheckbox variant={"square"} checked={hideLastLive} size="small" />
                    </MenuItem>
                    <MTypography
                        variant="subtitle1"
                        customClass={`${hideLastLive ? classes.ActiveColor : classes.NonActiveColor} ${isDesktop ? classes.CheckboxLabel : classes.CheckboxLabelM} `}
                    >
                        Hide live products if the last change was within 30 days
                    </MTypography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6" className={`${classes.Headers} pt-1 px-3 pb-1 `}>
                    Hide Keyword Coverage
                </Typography>
                <Box
                    sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    onClick={() => setHideKeywordCoverage(!hideKeywordCoverage)}
                >
                    <MenuItem
                        value={"keywordCoverage"}
                        className={`p-0 ${classes.MenuItem}`}
                        sx={{ paddingLeft: "6px !important" }}
                    >
                        <MCheckbox variant={"square"} checked={hideKeywordCoverage} size="small" />
                    </MenuItem>
                    <MTypography
                        variant="subtitle1"
                        customClass={`${hideKeywordCoverage ? classes.ActiveColor : classes.NonActiveColor} ${isDesktop ? classes.CheckboxLabel : classes.CheckboxLabelM} `}
                    >
                        Hide those products whose keyword coverage exceed.
                    </MTypography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                    <MSlider
                        min={10}
                        max={50}
                        marks={marks}
                        step={10}
                        value={sliderValue}
                        name={"testing"}
                        onChange={handleSliderChange}
                        customClass={classes.Slider}
                    />
                </Box>
            </Box>
            <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-3 pb-1 mt-2 ${classes.ButtonsContainer}`}>
                <MButton variant="outlined" size={"small"} onClick={() => handleClick("cancel")}>
                    Cancel
                </MButton>
                <MButton size={"small"} onClick={() => handleClick("applyFilters")}>
                    Apply Filters
                </MButton>
            </div>
        </>
    )
}

export default OpportunityFilterContainer;