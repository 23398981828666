import React, { useState } from "react";
import classes from "./index.module.css";
import { GridCellParams, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import ProductTable from "../../SavedProducts/ProductDataGrid/ProductTable";
import { toDateAndTimeObject } from "../../../../utils/helpers/date";
import { RolesEnum, UserStatusEnum } from "../../../../utils/constants/enums";
import UserStatus from "../../../Molecules/UserStatus";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { TeamMemberType } from "../../../../interfaces/userInterfaces";
import { clientsTabsValue } from "../../../../pages/Clients/config";
// same using for mobile
export interface TeamDataProps {
  membersList: any;
  pageSize: any;
  setPageNumber: any;
  pageNumber: number;
  setPageSize: any;
  offset: number;
  sortOrder: number;
  sortBy: any;
  setSortModel: any;
  totalMembers: number;
  loading: boolean;
  handleUserClick: (userDetails: TeamMemberType) => void;
  clientsTab?: string;
  isPaddingZero?: boolean;
}

const TeamDataGrid: React.FC<TeamDataProps> = (props) => {
  const {
    membersList,
    pageSize,
    setPageNumber,
    pageNumber,
    setPageSize,
    sortOrder,
    sortBy,
    setSortModel,
    totalMembers,
    handleUserClick,
    // added for clients tabs
    clientsTab = "",
    isPaddingZero = false,
  } = props;
  const tableColumns: GridColDef[] = [];

  const [setSelectedRows] = useState<any>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    setSelectedRows(membersList.filter((row: any) => selectedIDs.has(row.id)));
    setSelectionModel(e);
  };

  const handleCellClick = async (gridCellParams: GridCellParams) => {
    const selectedMember = gridCellParams?.row;
    handleUserClick(selectedMember);
  };

  tableColumns.push({
    field: "name",
    headerName: "Name",
    width: 160,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams) => (
      <>
        <div
          className={`${classes.Name} ${classes.CellLinesLimitContainer} cursor-pointer HoverEffectForLinks`}
          onClick={() => handleCellClick(params)}
        >
          {capitalizeEachLetter(params.row.name)}
        </div>
      </>
    ),
  });
  tableColumns.push({
    field: "email",
    headerName: "Email",
    width: 200,
    sortable: clientsTab === clientsTabsValue.companies ? false : true,
    renderCell: (params: GridCellParams) => (
      <>
        {/* {userRole !== RolesEnum.USER ? ( */}
        <div title={params.row.email} className={`${classes.CellLinesLimitContainer} ${classes.CellText}`}>
          {params.row.email || "--"}
        </div>
        {/* ) : (
          <div></div>
        )} */}
      </>
    ),
  });
  tableColumns.push({
    field: "role",
    headerName: "Role",
    width: 130,
    renderCell: (params: GridCellParams) => (
      <>
        {clientsTab === clientsTabsValue.individuals ? (
          <div className={`${classes.CellText}`}>
            {/* {capitalizeEachLetter(params?.row?.role?.replace("_", " ")) || "--"} */}
            {params?.row?.role === RolesEnum.USER
              ? "Trial"
              : capitalizeEachLetter(params?.row?.role?.replace("_", " ")) || "--"}
          </div>
        ) : (
          <div className={`${classes.CellText}`}>
            {capitalizeEachLetter(params?.row?.role?.replace("_", " ")) || "--"}
          </div>
        )}
      </>
    ),
  });
  if (clientsTab === clientsTabsValue.companies) {
    tableColumns.push({
      field: "totalMembers",
      headerName: "Members",
      align: "center",
      width: 100,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <div className={`${classes.CellText}`}>{params.row.totalMembers || 0}</div>
        </>
      ),
    });
  }
  tableColumns.push({
    field: "countContentGeneration",
    headerName: "ASIN limit",
    align: "center",
    width: 100,
    renderCell: (params: GridCellParams) => (
      <>
        <div className={`${classes.CellText}`}>
          {params.row.countContentGeneration || 0}
          {clientsTab && `/${params.row.contentGenerationLimit || 0}`}
        </div>
      </>
    ),
  });
  tableColumns.push({
    field: clientsTab ? "countHelium10Calls" : "countKeywordRefreshes",
    headerName: "Keywords refreshes",
    width: 160,
    align: "center",
    renderCell: (params: GridCellParams) => (
      <>
        <div className={`${classes.CellText}`}>
          {clientsTab
            ? `${params.row.countHelium10Calls || 0}/${params.row.helium10CallsLimit || 0}`
            : params.row.countKeywordRefreshes || 0}
        </div>
      </>
    ),
  });
  tableColumns.push({
    field: "countContentRegeneration",
    headerName: "Content regeneration",
    width: 180,
    align: "center",
    renderCell: (params: GridCellParams) => (
      <>
        <div className={`${classes.CellText}`}>
          {params.row.countContentRegeneration || 0}
          {clientsTab && `/${params.row.contentRegenerationLimit || 0}`}
        </div>
      </>
    ),
  });
  tableColumns.push({
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 150,
    renderCell: (params: GridCellParams) => ( 
      <>
        <UserStatus type={params.row.status === UserStatusEnum.Pause ? UserStatusEnum.Inactive : params.row.status} isCompany={clientsTab === clientsTabsValue.companies} />
        {/* {params.row.status}</div> */}
      </>
    ),
  });
  if (!clientsTab) {
    tableColumns.push({
      field: "updatedAt",
      headerName: "Last Updated",
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <div className={`${classes.CellText} ${classes.CellLinesLimitContainer}`}>
            {toDateAndTimeObject(params.value).date} <br />@ {toDateAndTimeObject(params.value).time}
          </div>
        </>
      ),
    });
  }

  return (
    <div className={isPaddingZero ? "" : classes.Container}>
      <ProductTable
        total={totalMembers}
        pageSize={pageSize}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        columns={tableColumns}
        rows={membersList ?? []}
        sortModel={[
          {
            field: sortBy || "name",
            sort: sortOrder ? (sortOrder === 1 ? ("asc" as any) : ("desc" as any)) : "asc",
          },
        ]}
        // onCellClick={handleCellClick}
        setSortModel={setSortModel}
        onSelectionModelChange={onSelectedRows}
        selectionModel={selectionModel}
      />
    </div>
  );
};

export default TeamDataGrid;
