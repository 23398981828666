import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import classes from "./index.module.css";

import { camelCaseToPascalCaseWithSpaces } from "../../../utils/helpers/strings";

interface Props {
  handleChange: (e: string) => void;
  tabs: string[];
  value: string;
  isCamelCase?: boolean;
}

const MTabs: React.FC<Props> = (props) => {
  const { handleChange, tabs, value = "", isCamelCase = true } = props;

  return (
    <>
      <Box>
        <Tabs
          classes={{ root: classes.Tabs }}
          value={value}
          onChange={(_event: React.SyntheticEvent, value: any) => handleChange(value)}
        >
          {tabs.map((tab: string) => (
            <Tab
              classes={{ root: classes.Tab, selected: classes.Selected }}
              label={isCamelCase ? camelCaseToPascalCaseWithSpaces(tab) : tab}
              value={tab}
              key={tab}
            />
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default MTabs;
