import axios from "../axios";
import {
    GenerateAccessKeyPayload,
    GenerateAccessKeyResponse,
    GetAllAccessKeyResponse,
    UpdateAccessKeyStatusPayload,
} from "./types/apiAccessKey";
import { errorHandler } from "../utils/helpers/apis";
import { toast } from "react-toastify";

export const AccessKeyApis = {
    async generateAccessKey({ userID = '', apiName = ''}: GenerateAccessKeyPayload) {
        return axios
            .post<any, any>(`/apigateway`, {
                userID,
                apiName,
            })
            .then((r: GenerateAccessKeyResponse) => {
                return r.data.data
            })
            .catch((error: any) => {
                throw toast.error(errorHandler(error));
            });
    },
    async reGenerateAccessKey(userID: string) {
        return axios
            .post<any, any>(`/apigateway/refresh`, {
                userID,
            })
            .then((r: GenerateAccessKeyResponse) => {
                return r.data.data
            })
            .catch((error: any) => {
                throw toast.error(errorHandler(error));
            });
    },
    async getAllAccessKeys() {
        return axios
            .get<any, any>(`/apigateway`)
            .then((r: GetAllAccessKeyResponse) => r.data.data)
            .catch(errorHandler);
    },

    async updateAccessKeyStatus({ id, status }: UpdateAccessKeyStatusPayload) {
        return axios
            .post<any, any>(`/apigateway/status`, {
                id,
                status
            })
            .then((r: GetAllAccessKeyResponse) => r.data.data)
            .catch((error: any) => {
                throw toast.error(errorHandler(error));
            });
    },

    async deleteAccessKey(accessKeyID: string) {
        return axios
            .delete<any, any>(`/apigateway/${accessKeyID}`)
            .then((r: GetAllAccessKeyResponse) => r.data.data)
            .catch((error) => {

                throw toast.error(errorHandler(error));
            });
    },
};