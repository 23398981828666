import React from "react";
import "./mainLoader.style.scss";
import robot from "../../../assets/gif/dancingRobot.gif";

interface Props {
  isMobile?: boolean;
  dancingRobot?: boolean;
}

const MainLoader: React.FC<Props> = ({ isMobile = false, dancingRobot = false }) => {
  return (
    <div className={"position-relative h-100"}>
      {dancingRobot ? (
        <div className={"position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center"}>
          <img
            src={robot}
            alt={"dancing-robot"}
            style={{ width: "100px", height: "100px", objectFit: "contain", zIndex: 1 }}
          />
        </div>
      ) : (
        <div className={isMobile ? "mobile-loader" : "main-loader"} style={{ zIndex: +1 }}>
          <div className="cssload-loader">
            <div className="cssload-inner cssload-one"></div>
            <div className="cssload-inner cssload-two"></div>
            <div className="cssload-inner cssload-three"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainLoader;
