import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MTypography from "../../../../Atoms/MTypography";
import classes from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberWithCommas } from "../../../../../utils/helpers/numbers";
import KeywordHistory from "../../../../Molecules/KeywordHistory";
import { CompleteProductSelector } from "../../../../../store/product/productDetails/productDetails.selectors";
import { setHighlightedKeywordAction } from "../../../../../store/product/productDetails/productDetails.actions";
import { filterKeywordsUsingEdited } from "../../../../../pages/Product/ProductDetails/config";
import { DbUserSelector, UserRoleSelector } from "../../../../../store/user/user.selector";
import { checkIsTrailCompany, checkIsUser } from "../../../../../utils/helpers/priviligesChecks";
import PricingContext from "../../../../../context/PricingContext";
import { CompanyRoleSelector, CompanySelector } from "../../../../../store/company/company.selector";
import { toast } from "react-toastify";
import ToastError from "../../../../Atoms/ToastError";
import { UnlockGreaterPossibilities } from "../../../../../utils/constants/messages/errors";
import { RolesEnum } from "../../../../../utils/constants/enums";

interface DetailsKeywordsTableProps {
  isTitle?: boolean;
}
const DetailsKeywordsTable: React.FC<DetailsKeywordsTableProps> = ({ isTitle }) => {
  const dispatch = useDispatch();
  const product = useSelector(CompleteProductSelector);
  const currentUser = useSelector(DbUserSelector);
  const role = useSelector(UserRoleSelector);
  const companyRole = useSelector(CompanyRoleSelector);
  const { onOpen } = React.useContext(PricingContext);
  const currentCompany = useSelector(CompanySelector);
  const [showPricingPlan] = React.useState<boolean>(() =>
    currentUser?.companyID ? checkIsTrailCompany(currentCompany?.role) : checkIsUser(currentUser?.role)
  );

  const { trackingKeywords, selectedHelium10Keywords } = product;

  let keywords = selectedHelium10Keywords?.length ? selectedHelium10Keywords : [];

  if (isTitle) {
    keywords = keywords?.filter((keyword) => keyword?.existInTitle);
    if (role !== RolesEnum.USER && companyRole !== RolesEnum.TRIAL) {
      keywords = filterKeywordsUsingEdited({ keywords, product, isTitle });
    }
  } else {
    keywords = keywords?.filter((keyword) => !keyword?.existInTitle);
    if (role !== RolesEnum.USER && companyRole !== RolesEnum.TRIAL) {
      keywords = filterKeywordsUsingEdited({ keywords, product, isTitle: false });
    }
  }

  const setHighlightedKeyword = (phrase: string) => {
    dispatch(setHighlightedKeywordAction(product, phrase));
  };

  const showUpgradeMessage = () => {
    if (currentUser?.companyID) {
      if (currentUser?.role === RolesEnum.COMPANY_ADMIN) {
        onOpen();
      } else {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
      }
    } else {
      onOpen();
    }
  };

  return (
    <div>
      <div>
        {!!keywords?.length ? (
          <Table className={classes.TableContainer}>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    padding: "0.56rem",
                    borderBlockColor: "#F2F2F2",
                  },
                  "& th:first-child": {
                    borderLeft: "1rem solid transparent",
                  },
                  "& th:last-child": {
                    borderRight: "1.13rem solid transparent",
                  },
                }}
              >
                <TableCell width={"70%"}>{""}</TableCell>
                <TableCell width={"30%"}>
                  <div className={"d-flex flex-column justify-content-center"}>
                    <MTypography variant={"subtitle1"} customClass={classes.TableHeader}>
                      {"Search Volume"}
                    </MTypography>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywords?.length &&
                keywords.map((keyword, index) => (
                  <TableRow
                    key={keyword?.phrase + index}
                    sx={{
                      "&:last-child td": { border: 0 },
                      "& td": {
                        padding: "1.06rem",
                        borderBlockColor: "#F2F2F2",
                      },
                      "& td:first-child": {
                        borderLeft: "1rem solid transparent",
                      },
                      "& td:last-child": {
                        borderRight: "1.13rem solid transparent",
                      },
                    }}
                  >
                    <TableCell
                      onClick={() =>
                        showPricingPlan ? showUpgradeMessage() : setHighlightedKeyword(keyword?.phrase || "")
                      }
                      component={"div"}
                      width={"70%"}
                    >
                      <KeywordHistory
                        keywordHistory={
                          trackingKeywords?.hasOwnProperty(keyword?.phrase || keyword)
                            ? trackingKeywords[keyword.phrase]
                            : []
                        }
                      >
                        <div className={"w-100"}>
                          <MTypography
                            variant={"subtitle2"}
                            customClass={`${classes.TableCell} ${classes.PhraseCell} ${classes.UnderlineCell} ${
                              isTitle && classes.TitlePhraseCell
                            }`}
                          >
                            {keyword?.phrase || keyword}
                          </MTypography>
                        </div>
                      </KeywordHistory>
                    </TableCell>

                    <TableCell component={"div"} width={"30%"}>
                      <MTypography variant={"subtitle2"} customClass={`${classes.TableCell}`}>
                        {formatNumberWithCommas(keyword?.searchVolume) || "-"}
                      </MTypography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <div className={"d-flex justify-content-center align-items-center w-100 h-100 p-5"}>
            <MTypography variant={"subtitle2"}>{"No keywords to display"}</MTypography>
          </div>
        )}
      </div>
    </div>
  );
};
export default DetailsKeywordsTable;
