import { toast } from "react-toastify";

export const suggestionsTabsValue = {
  seo: "seoKeywords",
  selling: "sellingKeywords",
};
export const suggestionsTabs = [suggestionsTabsValue.seo, suggestionsTabsValue.selling];

export const keywordsTypes = {
  seoKeyword: "seoKeyword",
  sellingKeywords: "sellingKeywords",
  selectedKeywords: "selectedKeywords",
};

export const initialColumnsPriortiseKeywords: any = {
  suggestions: {
    id: "suggestions",
    list: [],
  },
  selling: {
    id: "selling",
    list: [],
  },
  selectedKeywords: {
    id: "selectedKeywords",
    list: [],
  },
  restKeywords: {
    id: "restKeywords",
    list: [],
  },
};

export const titleSlots = ["Title slot 1", "Title slot 2", "Title slot 3", "Title slot 4"];
export const keywordSlots = ["Keyword Slot 1", "Keyword Slot 2", "Keyword Slot 3", "Keyword Slot 4", "Keyword Slot 5"];

export interface DragAndDropsPageInputs {
  search: string;
  customKeyword: string;
}

export function seoKeywordSearch(list: any, searchTerm: string, filter: string) {
  if (list.length === 0) return [];

  searchTerm = searchTerm.toLowerCase(); // Convert search term to lowercase

  const array = list.filter((item: any) => {
    return item.phrase.toLowerCase().includes(searchTerm);
  });

  return sortResultByFilter({ filter, array });
}

export const shuffleElementsInArray = ({
  index1,
  index2,
  array,
}: {
  array: any;
  index1: number;
  index2: number;
}): any => {
  const element1 = array[index1];
  const element2 = array[index2];
  array[index1] = element2;
  array[index2] = element1;
  return array;
};

export const maxSelectedKeywords = 3;

export const maxRestKeywords = 5;

export const maxTotalKeywordsAllowed = 20;

export const maxTotalKeywordWarning = `Maximum ${maxTotalKeywordsAllowed} total keywords allowed`;

export const warningMaxTitleSlots = `Maximum ${maxSelectedKeywords} title keyword allowed`;
export const warningMaxRest = `Maximum ${maxRestKeywords} keywords allowed`;

export const minTitleKeywords = 2;
export const warningMessageTitle = `Atleast ${minTitleKeywords} title keywords required`;

function getAsinIDFromURL() {
  const parts = window.location.href.split("/");
  const asinIDIndex = parts.indexOf("product") + 2;
  return parts[asinIDIndex];
}

export const sortFilters = {
  searchVolume: "searchVolume",
  competitiveness: "Competitiveness",
  currentRank: "rank",
};
export const filterOptions = [
  { label: "Search Volume", value: sortFilters.searchVolume },
  // Competitiveness changed to Rank Ease
  { label: "Rank Ease", value: sortFilters.competitiveness }, // TODO: Remove for time being. Will be added in future 
  { label: "Current Rank", value: sortFilters.currentRank },
];
export const sortResultByFilter = ({ filter, array }: any) => {
  try {
    const currentRank = getAsinIDFromURL();
    switch (filter) {
      case sortFilters.searchVolume:
        return [...array].sort((a: any, b: any) => b.searchVolume - a.searchVolume);
      case sortFilters.competitiveness:
        return [...array].sort((a: any, b: any) => b.competitiveness - a.competitiveness);
      case sortFilters.currentRank:
        const sortedArray = array
          .filter((item: any) => item.currentRank !== 0 && item[currentRank] !== null && item[currentRank] !== 999)
          .sort((a: any, b: any) => a[currentRank] - b[currentRank]);

        return [...sortedArray, ...array.filter((item: { currentRank: number }) => !sortedArray.includes(item))];
      default:
        return [...array];
    }
  } catch (e) {
    return [...array];
  }
};

const filterKeywords = ({ selectedKeywords, allKeywords }: any): any => {
  const result: any = allKeywords.filter((item1: any) => {
    // Check if item1 is not present in array2
    return selectedKeywords.every((item2: any) => {
      if (item1.hasOwnProperty("phrase")) {
        return item1.phrase !== item2.phrase;
      } else {
        return item1 !== item2;
      }
    });
  });
  return result;
};

export const moveKeywords = ({ columns, selectedItem, moveToKeyword }: any): any => {
  let result;
  const list: any = [...selectedItem.suggestions.list, ...selectedItem.selling.list];

  const selectedSuggestions: any = [...selectedItem.suggestions.list];
  const selectedSellings: any = [...selectedItem.selling.list];
  const selectedSelectedKeywords: any = [...selectedItem.selectedKeywords.list];
  const selectedRestKeyword: any = [...selectedItem.restKeywords.list];

  const filteredSuggestions = filterKeywords({
    selectedKeywords: selectedSuggestions,
    allKeywords: columns.suggestions.list,
  });
  const filteredSellings = filterKeywords({ selectedKeywords: selectedSellings, allKeywords: columns.selling.list });

  const filteredSelected = filterKeywords({
    selectedKeywords: selectedSelectedKeywords,
    allKeywords: [...columns.selectedKeywords.list],
  });
  const filteredRest = filterKeywords({
    selectedKeywords: selectedRestKeyword,
    allKeywords: [...columns.restKeywords.list],
  });

  console.log("test ick hit", columns, selectedItem, moveToKeyword);

  result = {
    ...columns,
    suggestions: {
      id: "suggestions",
      list: filteredSuggestions,
    },
    selling: {
      id: "selling",
      list: filteredSellings,
    },
  };
  if (moveToKeyword === "restKeywords") {
    result = {
      ...result,
      restKeywords: {
        id: "restKeywords",
        list: [...list, ...columns.restKeywords.list],
      },
    };
  } else if (moveToKeyword === "selectedKeywords") {
    result = {
      ...result,
      selectedKeywords: {
        id: "selectedKeywords",
        list: [...list, ...columns.selectedKeywords.list],
      },
    };
  } else if (moveToKeyword === "moveBack") {
    const items: any = [...selectedItem.selectedKeywords.list, ...selectedItem.restKeywords.list];
    const suggestions: any = [];
    const selling: any = [];
    items.forEach((item: any) => {
      if (item.hasOwnProperty("phrase")) {
        suggestions.push(item);
      } else {
        selling.push(item);
      }
    });
    result = {
      ...result,
      suggestions: {
        id: "suggestions",
        list: [...suggestions, ...columns.suggestions.list],
      },
      selling: {
        id: "selling",
        list: [...selling, ...columns.selling.list],
      },
      selectedKeywords: {
        id: "selectedKeywords",
        list: filteredSelected,
      },
      restKeywords: {
        id: "restKeywords",
        list: filteredRest,
      },
    };
  }
  return result;
};

export const moveValidation = ({ columns, selectedItem, moveToKeyword }: any): boolean => {
  try {
    if (moveToKeyword === "selectedKeywords") {
      if (
        columns.selectedKeywords.list.length + selectedItem.suggestions.list.length + selectedItem.selling.list.length >
        3
      ) {
        toast.warning(warningMaxTitleSlots);
        return false;
      }
    }
    if (
      columns.selectedKeywords.list.length +
        columns.restKeywords.list.length +
        selectedItem.suggestions.list.length +
        selectedItem.selling.list.length >
      20
    ) {
      toast.warning(maxTotalKeywordWarning);
      return false;
    }
    return true;
  } catch (e) {
    return true;
  }
};
