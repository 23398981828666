import React from "react";
import MTypography from "../../../../Atoms/MTypography";
import classes from "../index.module.css";
import Statusbar from "./Statusbar";

interface RatingBreakDownProps {
  percentage: number;
  value: number;
}
const RatingBreakDown: React.FC<RatingBreakDownProps> = ({ value, percentage }) => {
  return (
    <div className={`d-flex align-items-center justify-content-evenly ${value !== 1 && "mb-2"}`}>
      <div className={""}>
        <MTypography variant={"body2"} customClass={`${classes.Body}`}>
          {`${value} star`}
        </MTypography>
      </div>

      <div className={"w-75 mx-1"}>
        <Statusbar value={percentage} />
      </div>
      <div className={""}>
        <MTypography variant={"body2"} customClass={`${classes.Body}`}>
          {`${percentage}%`}
        </MTypography>
      </div>
    </div>
  );
};

export default RatingBreakDown;
