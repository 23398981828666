import React from "react";
import classes from "./index.module.css";
import DetailsKeywordsTable from "./DetailsKeywordsTable";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import DetailsKeywordsM from "./DetailsKeywordsM";
interface ProductDetailsKeywordsProps {
  isTitle?: boolean;
}
const ProductDetailsKeywords: React.FC<ProductDetailsKeywordsProps> = ({ isTitle }) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={isDesktop ? classes.KeywordsContainer : ""}>
      {isDesktop ? <DetailsKeywordsTable isTitle={isTitle} /> : <DetailsKeywordsM isTitle={isTitle} />}
    </div>
  );
};

export default ProductDetailsKeywords;
