import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SortIcon from "../../../assets/svgs/sort-by-icon.svg";
import { Colors } from "../../../utils/themes/main-theme";
import MTypography from "../MTypography";
import { Radio } from "@mui/material";

interface DefaultSelect {
  label: string;
  value: any;
}
interface Props {
  handleChange: (e: SelectChangeEvent) => void;
  value: any;
  label?: string;
  showFullWidth?: boolean;
  options: DefaultSelect[];
  margin?: "dense" | "none";
  variant?: "icon" | "inputfield";
}

const MSelect: React.FC<Props> = (props) => {
  const { handleChange, value, label = "", options, margin = "none", showFullWidth, variant = "inputfield" } = props;

  return (
    <>
      {variant === "inputfield" ? (
        <FormControl margin={margin} sx={{ m: 1, minWidth: showFullWidth ? "100%" : "" }} error>
          <InputLabel id="select-input-field">{label}</InputLabel>
          <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `#D9D9D9 !important`,
              },
              "&:active .MuiOutlinedInput-notchedOutline": {
                borderColor: `${Colors.primary} !important`,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${Colors.primary} !important`,
              },
            }}
            color={"primary"}
            margin={margin}
            labelId="select-input-field"
            id="select-input-field"
            value={value}
            label={label}
            onChange={handleChange}
          >
            {options.map((item: DefaultSelect) => (
              <MenuItem value={item.value} key={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl margin={margin} sx={{ m: 1, minWidth: showFullWidth ? "100%" : "" }}>
          {/* <InputLabel id="select-input-field">{label}</InputLabel> */}
          <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:active .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-icon": {
                display: "none",
              },
              "& .MuiSelect-select": {
                padding: 0,
                background: "none",
                display: "flex",
                alignItems: "center",
                paddingRight: "0px !important",
              },
              "&.MuiOutlinedInput-root": {
                backgroundColor: "transparent !important", // Ensure the root background is transparent
                padding: 0,
                paddingRight: 0,
              },
            }}
            renderValue={() => {
              return (
                <span style={{ display: "flex", alignItems: "center", gap: "8px" }} className="HoverEffectForButton">
                  <img src={SortIcon} alt="sort-icon" srcSet="" />{" "}
                  <MTypography variant={"subtitle1"} color="inherit">
                    Sort by
                  </MTypography>
                </span>
              );
            }}
            displayEmpty
            color={"primary"}
            margin={margin}
            labelId="select-input-field"
            id="select-input-field"
            value={value}
            label={label}
            onChange={handleChange}
          >
            {options.map((item: DefaultSelect) => (
              <MenuItem value={item.value} key={item.label} sx={{ backgroundColor: "transparent" }}>
                <Radio
                  checked={value === item.value}
                  value={item.value}
                  // size="small"
                  color="primary"
                  sx={{width: "32px", height: "32px"}}
                />
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default MSelect;
