import { produce } from "immer";

import { CompanyActionTypes } from "./company.action-types";
import { UserReducerPayloadType } from "./company.actions";
import { RolesEnum } from "../../utils/constants/enums";
import { CompanyData } from "../../apis/types/companies";

export interface State {
  currentCompany?: CompanyData | null;
  companyRole?: RolesEnum | string;
  invitedEmailsOfComapny?: string[]
}

const initialState: State = {
  currentCompany: null,
  companyRole: "",
  invitedEmailsOfComapny: []
};
const companyReducer = produce((state: State = initialState, action: UserReducerPayloadType) => {
  switch (action.type) {
    case CompanyActionTypes.setCompanyRole:
      state.companyRole = action.payload;
      return state;
    case CompanyActionTypes.setCompany:
      state.currentCompany = action.payload;
      return state;
    case CompanyActionTypes.setInvitedEmailsOfCompany:
      state.invitedEmailsOfComapny = action.payload;
      return state;
    default:
      return state;
  }
});

export default companyReducer;
