import { produce } from "immer";

import { TeamManagementActionTypes } from "./team-management.action-types";
import { TeamMemberType } from "../../interfaces/userInterfaces";
import { TeamManagementReducerPayload } from "./team-management.actions";

export interface State {
  selectedTeamMember: TeamMemberType | null;
  stats: any;
}

const initialState: State = {
  selectedTeamMember: null,
  stats: null,
};
const teamManagementReducer = produce((state: State = initialState, action: TeamManagementReducerPayload) => {
  switch (action.type) {
    case TeamManagementActionTypes.setSelectedTeamMember:
      state.selectedTeamMember = action.payload;
      return state;
    case TeamManagementActionTypes.setTeamMemberStats:
      state.stats = action.payload;
      return state;
    default:
      return state;
  }
});

export default teamManagementReducer;
