import React, { FC } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import classes from "./index.module.css";

interface MOutlinedPhoneInputProps {
  setPhoneNumber: React.Dispatch<any>;
}

const MOutlinedPhoneInput: FC<MOutlinedPhoneInputProps> = (props) => {
  const { setPhoneNumber } = props;

  const handleOnChange = (value: any) => {
    setPhoneNumber(`+${value}`);
  };

  return (
    <div>
      <PhoneInput
        country={"us"}
        autoFormat={true}
        onChange={(value) => handleOnChange(value)}
        specialLabel="Phone Number (Optional)"
        containerStyle={{ backgroundColor: "white", boxShadow: "none" }}
        dropdownStyle={{ textAlign: "left" }}
        containerClass={classes.ContainerClass}
        inputClass={classes.PhoneInputfield}
      />
    </div>
  );
};

export default MOutlinedPhoneInput;
