import React, { ChangeEvent, useState, DragEvent, useEffect } from "react";
import DialogBox from "../../../mui/dialogebox";
import { Box, DialogProps } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import classes from "./index.module.css";
import { toast } from "react-toastify";
import MTypography from "../../../Atoms/MTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  checkFileSizeLessThan10Mbs,
  formatUploadFileSize,
  validUploadedFileTypes,
} from "../../../../utils/helpers/common";
import { handleDownload, instructionsList, bulkAsinsLimitUsingFile, parseFile } from "./config";

type BulkImportBoxProps = DialogProps & {
  open: boolean;
  onClose: () => void;
  handleContinueClick: () => void;
  setSelectedAsinsArray: React.Dispatch<React.SetStateAction<string[]>>;
  selectedAsinsArray: string[];
};

const BulkImportBox: React.FC<BulkImportBoxProps> = (props) => {
  const { open, onClose, handleContinueClick, setSelectedAsinsArray, selectedAsinsArray } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableAction, setDisableAction] = useState<boolean>(true);
  const [showLimitExceedMsg, setShowLimitExceedMsg] = useState<boolean>(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      setSelectedFile(null);
      setSelectedAsinsArray([]);
      setShowLimitExceedMsg(false);
    }
  }, [open]);
  useEffect(() => {
    if (selectedAsinsArray?.length !== 0 && selectedFile !== null) {
      setDisableAction(true);
    } else {
      setDisableAction(false);
    }
  }, [selectedAsinsArray, selectedFile]);

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) onClose(event, "backdropClick");
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const droppedFiles = event.target.files;
    if (droppedFiles && droppedFiles.length > 0) {
      saveFile(droppedFiles[0]);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      saveFile(droppedFiles[0]);
    }
  };

  const saveFile = (uploadedFile: File) => {
    const file = uploadedFile;
    const fileType = file.type;
    const isValidSize = checkFileSizeLessThan10Mbs(file?.size);
    const isValidFileType = validUploadedFileTypes(fileType);
    if (isValidFileType && isValidSize) {
      setSelectedFile(file);
      setLoading(true);
      parseFile(file)
        .then((asinsArray: any) => {
          const selectedNumberOfAsins = asinsArray?.slice(0, bulkAsinsLimitUsingFile);
          if (asinsArray && asinsArray?.length >= 1) {
            setShowLimitExceedMsg(asinsArray.length > bulkAsinsLimitUsingFile ? true : false);
            setSelectedAsinsArray(selectedNumberOfAsins);
          } else {
            setSelectedAsinsArray([]);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      let errorMsg = "Please attach a XLSX or XLS file with a max size of 10MB.";
      toast.error(errorMsg, { autoClose: 3000 });
      setSelectedAsinsArray([]);
      setSelectedFile(null);
    }
  };

  const handleDeleteClick = () => {
    setSelectedFile(null);
    setSelectedAsinsArray([]);
    setShowLimitExceedMsg(false);
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <DialogBox
        open={open}
        onClose={onClose}
        cancelButton={{
          action: handleCancel as any,
          element: "Back",
        }}
        confirmButton={{
          action: handleContinueClick,
          element: <> {"Continue"}</>,
          disabled: disableAction ? false : true,
        }}
        title={"Bulk Import"}
        sx={{ width: "630px" }}
      >
        <div className={classes.Container}>
          {/* instructions */}
          <div className={classes.ListContainer}>
            <ol>
              {instructionsList.map((ins, i) => (
                <li key={i}>{ins}</li>
              ))}
            </ol>
          </div>
          {/* upload box */}
          <Box
            component={"div"}
            className={classes.UploadContainer}
            onClick={() => {
              document.getElementById("fileInput")?.click();
            }}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <UploadFileIcon color="primary" />
            <Box component={"div"} py={"10px"} sx={{ fontSize: "16px", lineHeight: "24px" }}>
              <span className={classes.ClickToUpload}>Click to upload</span> or drag and drop
            </Box>
            <Box component={"div"} className={classes.CsvOrXLS}>
             XLSX or XLS (max. 10MB)
            </Box>
            <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileChange} />
          </Box>
          {/* samples */}
          <Box component={"div"} className={classes.SampleDownloadContainer}>
            {/* <Box component="div" onClick={() => handleDownload("csv")} className="cursor-pointer">
              <GetAppOutlinedIcon fontSize="small" color="primary" /> Sample CSV File
            </Box> */}
            <Box component="div" onClick={() => handleDownload("xlsx")} className="cursor-pointer">
              <GetAppOutlinedIcon fontSize="small" color="primary" /> Sample XLS File
            </Box>
          </Box>
          {/* file details section */}
          {selectedFile && (
            <Box component={"div"} sx={{ mt: "15px" }}>
              <MTypography variant="h6" customClass={classes.UploadedFile}>
                Uploaded File
              </MTypography>
              <Box component={"div"} className={classes.FileDetailBox}>
                <Box component={"div"}>
                  <UploadFileIcon sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: "16px" }} />
                </Box>
                <Box component={"div"} className={classes.FileDetails}>
                  <Box component={"div"} sx={{ color: "#000000", fontSize: "16px", lineHeight: "24px" }}>
                    {selectedFile?.name}
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ color: "rgba(rgba(0, 0, 0, 0.6)", fontSize: "14px", lineHeight: "20px" }}
                  >
                    {formatUploadFileSize(selectedFile?.size)} {loading ? " . Loading..." : ""}
                    {!loading && selectedAsinsArray?.length < 1 && (
                      <span className={classes.ErrorMessage}>{" Insufficient ASINs are detected."}</span>
                    )}
                    {showLimitExceedMsg && (
                      <span
                        className={classes.InfoMessage}
                      >{` Only first ${bulkAsinsLimitUsingFile} valid asins are selected.`}</span>
                    )}
                  </Box>
                </Box>
                <Box component={"div"} display={"flex"} alignItems={"center"} gap={1}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DeleteIcon
                      sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: "20px", cursor: "pointer" }}
                      onClick={handleDeleteClick}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </div>
      </DialogBox>
    </div>
  );
};

export default BulkImportBox;
