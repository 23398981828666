import { UserApis } from "../../../../apis/user";
import { userRole } from "../../../../utils/helpers/priviligesChecks";

export const isOwnerReachedContentGenLimit = async (userID: string) => {
    return UserApis.getProductOwner(userID)
      .then((res: any) => {
        if(userRole.isAdminOrEditorOrInternalOrOwner(res?.role)){
            return false;
        }else{
            return res?.contentGenerationLimit === res?.countContentGeneration;
        }
      })
      .catch((error: any) => {
        console.log("error while fetching product owner", error)
      });
  }