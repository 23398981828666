import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./index.module.css";
import { useSelector } from "react-redux";
import { calculateDiscountPercentage, VariantsIncreaseLimits } from "../../config";
import MTypography from "../../../../Atoms/MTypography";
import ProductBrand from "../../ProductBrand";
import MRating from "../../../../Atoms/MRating";
import { Divider } from "@mui/material";
import ProductVariant from "../Variants";
import RatingBreakDown from "../RatingBreakdown";
import dummyUser from "../../../../../assets/svgs/dummyAmazonUser.svg";
import { getDateWithTime } from "../../../../../utils/helpers/date";
import MButton from "../../../../Atoms/MButton";
import { ScrapeProductSelector } from "../../../../../store/product/productScrape/productScrape.selectors";

interface CompleteProductFetchDetailsMProps {
  hideDetailBox: () => void;
}
const CompleteProductFetchDetailsM: React.FC<CompleteProductFetchDetailsMProps> = ({ hideDetailBox }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const scrapeProduct = useSelector(ScrapeProductSelector);

  const [showAllReviews, setShowAllReviews] = useState<boolean>(false);
  const actualLength = scrapeProduct?.product?.variants?.length || 0;
  const [variantsLimits, setVariantsLimits] = useState(
    actualLength <= VariantsIncreaseLimits ? actualLength : VariantsIncreaseLimits
  );
  useEffect(() => {
    let timer: any;
    if (ref?.current) {
      timer = setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [ref]);
  const increaseVariantsLimits = () => {
    setVariantsLimits(
      variantsLimits + VariantsIncreaseLimits < actualLength ? variantsLimits + VariantsIncreaseLimits : actualLength
    );
  };
  const resetLimit = () =>
    setVariantsLimits(actualLength <= VariantsIncreaseLimits ? actualLength : VariantsIncreaseLimits);
  const handleReviewsVisibility = () => setShowAllReviews(!showAllReviews);
  return (
    <div className={`${classes.MainContainer}`}>
      <div className={`${classes.CloseContainer}`} ref={ref} id="closeIconContainer">
        <CloseIcon onClick={hideDetailBox} className={classes.CloseIcon} />
      </div>
      <div className={classes.DetailsContainer}>
        {scrapeProduct?.product?.main_image?.link && (
          <div className={classes.ImageContainer}>
            <img src={scrapeProduct?.product?.main_image?.link} alt={"product"} className={classes.MainImage} />
            {scrapeProduct?.product?.buybox_winner?.rrp?.value && (
              <div className={classes.Coupon}>
                <MTypography variant={"subtitle2"} customClass={classes.Discount}>
                  {`${calculateDiscountPercentage(
                    scrapeProduct?.product?.buybox_winner?.rrp?.value,
                    scrapeProduct?.product?.buybox_winner?.price?.value
                  )}% off`}
                </MTypography>
              </div>
            )}
          </div>
        )}
        {/*TODO: Will be added in future*/}
        {/*{scrapeProduct?.product?.images?.length && (*/}
        {/*  <div className={"d-flex align-items-center"}>*/}
        {/*    {scrapeProduct?.product?.images?.map((image, index) => (*/}
        {/*      <div key={index}>*/}
        {/*        {index < 6 && (*/}
        {/*          <div*/}
        {/*            className={` ${classes.OtherImageContainer} ${*/}
        {/*              areImageIdsEqual(scrapeProduct?.product?.main_image?.link, image?.link) && classes.CurrentImage*/}
        {/*            } ${index === 5 && classes.LastImageContainer}`}*/}
        {/*          >*/}
        {/*            <img*/}
        {/*              src={image?.link}*/}
        {/*              alt={"other"}*/}
        {/*              className={`${classes.OtherImage} ${index === 5 && classes.LastImage}`}*/}
        {/*            />*/}
        {/*            {index === 5 && (*/}
        {/*              <MTypography variant={"body2"} customClass={classes.ImageCount}>*/}
        {/*                {`${scrapeProduct?.product?.images_count - 5} +`}*/}
        {/*              </MTypography>*/}
        {/*            )}*/}
        {/*          </div>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}
        {scrapeProduct?.product?.title && (
          <div className={"mt-2"}>
            <MTypography variant={"h5"} customClass={classes.ProductTitle}>
              {scrapeProduct?.product?.title}
            </MTypography>
          </div>
        )}
        <div className={"mt-1"}>
          <ProductBrand />
        </div>

        <div className={"mt-1 d-flex align-items-center justify-content-between"}>
          <div className={"d-flex align-items-center"}>
            <MRating value={scrapeProduct?.product?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
            {scrapeProduct?.product?.ratings_total && (
              <MTypography
                variant={"body2"}
                customClass={classes.RatingText}
              >{`${scrapeProduct?.product?.ratings_total?.toLocaleString()} ratings`}</MTypography>
            )}
            {scrapeProduct?.product?.top_reviews?.length && (
              <MTypography
                variant={"body2"}
                customClass={`${classes.ReviewsText} `}
              >{`${scrapeProduct?.product?.top_reviews?.length?.toLocaleString()} reviews`}</MTypography>
            )}
          </div>
        </div>

        <div className={"d-flex align-items-center mt-1"}>
          {scrapeProduct?.product?.buybox_winner?.rrp?.raw ? (
            <div className={"d-flex align-items-center"}>
              {scrapeProduct?.product?.buybox_winner?.price?.raw && (
                <MTypography
                  variant={"body2"}
                  customClass={classes.ActualPrice}
                >{`${scrapeProduct?.product?.buybox_winner?.rrp?.raw}`}</MTypography>
              )}
              {scrapeProduct?.product?.buybox_winner?.price?.raw && (
                <MTypography
                  variant={"body2"}
                  customClass={classes.Price}
                >{`${scrapeProduct?.product?.buybox_winner?.price?.raw}`}</MTypography>
              )}
            </div>
          ) : (
            <div>
              {scrapeProduct?.product?.buybox_winner?.price?.raw && (
                <MTypography
                  variant={"body2"}
                  customClass={classes.Price}
                >{`${scrapeProduct?.product?.buybox_winner?.price?.raw}`}</MTypography>
              )}
            </div>
          )}
        </div>

        <Divider className={classes.Divider} />

        {scrapeProduct?.product?.feature_bullets?.length && (
          <div>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Product Details"}
            </MTypography>

            <ul>
              {scrapeProduct?.product?.feature_bullets?.map((bullet, index) => (
                <li key={index} className={classes.Bullet}>
                  <MTypography variant={"subtitle2"} customClass={classes.Body}>
                    {bullet}
                  </MTypography>
                </li>
              ))}
            </ul>
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.description && (
          <div>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Product Description"}
            </MTypography>
            <MTypography variant={"subtitle2"} customClass={classes.Body}>
              {scrapeProduct?.product?.description}
            </MTypography>
            <Divider className={classes.Divider} />
          </div>
        )}

        {scrapeProduct?.product?.variants?.length && (
          <div>
            <MTypography variant={"subtitle2"} customClass={classes.Title}>
              {"Variants"}
            </MTypography>
            <div className={"d-flex align-items-center gap-3 flex-wrap"}>
              {scrapeProduct?.product?.variants?.map((variant, index) => (
                <div key={variant?.asin} className={index < variantsLimits ? "" : "d-none"}>
                  <ProductVariant variant={variant} />
                </div>
              ))}
            </div>
            {scrapeProduct?.product?.variants?.length >= VariantsIncreaseLimits && (
              <div className={"d-flex align-items-center justify-content-center mt-4"}>
                {variantsLimits === actualLength ? (
                  <MButton variant={"outlined"} onClick={resetLimit} size={"small"}>
                    {"View Less"}
                  </MButton>
                ) : (
                  <MButton variant={"outlined"} onClick={increaseVariantsLimits} size={"small"}>
                    {"View More"}
                  </MButton>
                )}
              </div>
            )}
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.important_information?.sections?.length && (
          <div>
            {scrapeProduct?.product?.important_information?.sections?.map((section, index) => (
              <div key={index} className={`${index !== 0 && "mt-3"}`}>
                {section?.title && (
                  <MTypography variant={"subtitle2"} customClass={classes.Title}>
                    {section.title}
                  </MTypography>
                )}
                {section?.title && (
                  <MTypography variant={"subtitle2"} customClass={classes.Body}>
                    {section.body}
                  </MTypography>
                )}
              </div>
            ))}
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.rating_breakdown && (
          <div>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Reviews"}
            </MTypography>

            <div className={""}>
              <div className={`mb-2`}>
                {scrapeProduct?.product?.rating_breakdown?.five_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.five_star?.percentage}
                    value={5}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.four_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.four_star?.percentage}
                    value={4}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.three_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.three_star?.percentage}
                    value={3}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.two_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.two_star?.percentage}
                    value={2}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.one_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.one_star?.percentage}
                    value={1}
                  />
                )}
              </div>
              <div className={""}>
                <div
                  className={` d-flex align-items-center flex-column justify-content-center ${classes.OverallRating} `}
                >
                  <MTypography variant={"body2"} customClass={classes.OverallText}>
                    {"Overall review ratings"}
                  </MTypography>
                  <MTypography variant={"body2"} customClass={classes.OverallRatingText}>
                    {scrapeProduct?.product?.rating}
                  </MTypography>
                  <MRating value={scrapeProduct?.product?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
                </div>
              </div>
            </div>
          </div>
        )}

        {scrapeProduct?.product?.top_reviews?.length && (
          <div className={"mt-5"}>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Top Reviews"}
            </MTypography>
            {scrapeProduct?.product?.top_reviews?.map((review, index) => (
              <div key={index}>
                {index < 2 && (
                  <div className={classes.ReviewContainer}>
                    <div className={"d-flex justify-content-between align-items-start"}>
                      <div className={"d-flex align-items-start"}>
                        <div>
                          <img
                            src={review?.profile?.image || dummyUser}
                            alt={"reviewer"}
                            className={classes.ReviewerImage}
                          />
                        </div>
                        <div>
                          {review?.profile?.name && (
                            <MTypography variant={"subtitle2"} customClass={classes.ReviewerName}>
                              {review?.profile?.name}
                            </MTypography>
                          )}
                          {review?.rating && (
                            <MRating value={review?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
                          )}
                        </div>
                      </div>
                      <div>
                        {review?.date?.utc && (
                          <MTypography variant={"subtitle2"} customClass={classes.ReviewDate}>{`${getDateWithTime(
                            review?.date?.utc?.toString(),
                            true
                          )}`}</MTypography>
                        )}
                      </div>
                    </div>
                    {review?.body && (
                      <div>
                        <MTypography variant={"body2"} customClass={classes.ReviewBody}>
                          {review?.body}
                        </MTypography>
                      </div>
                    )}
                  </div>
                )}
                {index > 1 && showAllReviews && (
                  <div className={classes.ReviewContainer}>
                    <div className={"d-flex justify-content-between align-items-start"}>
                      <div className={"d-flex align-items-start"}>
                        <div>
                          <img
                            src={review?.profile?.image || dummyUser}
                            alt={"reviewer"}
                            className={classes.ReviewerImage}
                          />
                        </div>
                        <div>
                          {review?.profile?.name && (
                            <MTypography variant={"subtitle2"} customClass={classes.ReviewerName}>
                              {review?.profile?.name}
                            </MTypography>
                          )}
                          {review?.rating && (
                            <MRating value={review?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
                          )}
                        </div>
                      </div>
                      <div>
                        {review?.date?.utc && (
                          <MTypography variant={"subtitle2"} customClass={classes.ReviewDate}>{`${getDateWithTime(
                            review?.date?.utc?.toString(),
                            true
                          )}`}</MTypography>
                        )}
                      </div>
                    </div>
                    {review?.body && (
                      <div>
                        <MTypography variant={"body2"} customClass={classes.ReviewBody}>
                          {review?.body}
                        </MTypography>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div className={"d-flex justify-content-center mt-1"}>
              <MTypography variant={"body2"} customClass={classes.ShowMoreReview}>
                <span onClick={handleReviewsVisibility}>{showAllReviews ? "View Less" : "View All"}</span>
              </MTypography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompleteProductFetchDetailsM;
