import React, { useState, useEffect } from "react";

import MCheckbox from "../../../../../Atoms/MCheckbox";

import classes from "./CheckboxFilters.module.css";

//sample Data for checkboxes

// const checkBoxes: any = [
//   { name: "BSR", value: "bsr", color: "#F94144" },
//   { name: "Conversation Rate", value: "conversationRate", color: "#4285F4" },
//   { name: "Traffic", value: "traffic", color: "#90BE6D" },
//   { name: "Revenue", value: "revenue", color: "#D87B00" },
// ];

interface CheckboxFiltersProps {
  setCheckbox: any;
  checkBoxes: any;
  initialValue: any;
  noColor?: boolean;
}

const CheckboxFilters: React.FC<CheckboxFiltersProps> = ({ setCheckbox, checkBoxes, initialValue, noColor = true }) => {
  const [selected, setSelected] = useState<string[]>(initialValue);

  const isChecked = (value: string): boolean => {
    return selected.includes(value);
  };

  const handleCheckboxes = (value: string) => {
    if (isChecked(value)) {
      const filteredCheckboxes = selected.filter((item: string) => item !== value);
      setSelected(filteredCheckboxes);
    } else {
      setSelected([...selected, value]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setCheckbox(selected);
  }, [selected]);

  return (
    <div className={"d-flex gap-1 my-1"}>
      {checkBoxes.map((checkbox: any) => (
        <div key={checkbox.value} className={"d-flex align-items-center gap-1"}>
          <MCheckbox
            variant={"square"}
            checked={isChecked(checkbox.value)}
            onClick={() => handleCheckboxes(checkbox.value)}
          />
          {!noColor && <div className={classes.Dot} style={{ backgroundColor: checkbox.color }}></div>}
          <div className={classes.Text}>{checkbox.name}</div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxFilters;
