import { CompanyActionTypes } from "./company.action-types";
import { RolesEnum } from "../../utils/constants/enums";
import { Dispatch } from "redux";
import { CompanyData } from "../../apis/types/companies";
import { CompanyApis } from "../../apis/companies";

interface SetCompanyRole {
  type: CompanyActionTypes.setCompanyRole;
  payload: RolesEnum | string;
}

export const setCompanyRoleDispatch = (role: string): SetCompanyRole => ({
  type: CompanyActionTypes.setCompanyRole,
  payload: role,
});

interface SetCompany {
  type: CompanyActionTypes.setCompany;
  payload: CompanyData;
}
export const setCompanyDispatch = (company: CompanyData): SetCompany => ({
  type: CompanyActionTypes.setCompany,
  payload: company,
});

interface SetInvitedEmailsOfCompany {
  type: CompanyActionTypes.setInvitedEmailsOfCompany;
  payload: string[];
}
export const setInvitedEmailsOfCompanyDispatch = (pendingList: string[]): SetInvitedEmailsOfCompany => ({
  type: CompanyActionTypes.setInvitedEmailsOfCompany,
  payload: pendingList,
});

export const setCompanyAction = (companyID: string): any => async (dispatch: Dispatch<any>) => {
  try {
    let companyData: CompanyData = await CompanyApis.getCompany(companyID);
    dispatch(setCompanyDispatch(companyData));
    dispatch(setCompanyRoleDispatch(companyData?.role))
    dispatch(setInvitedEmailsOfCompanyDispatch(companyData?.invitedEmails))
    return companyData;
  } catch (error) {
    throw error;
  }
};

export type UserReducerPayloadType =
  | SetCompanyRole
  | SetCompany
  | SetInvitedEmailsOfCompany