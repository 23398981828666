import React, { useRef, useEffect, useState } from "react";
import MTypography from "../../Atoms/MTypography";
import MButton from "../../Atoms/MButton";
import { CSSTransition } from "react-transition-group";

import classes from "./index.module.css";
// import { useDeviceType } from "../../../hooks/useDeviceType";

interface Props {
  onClick: () => void;
  showDrawer?: boolean;
}

const UpgradeCard: React.FC<Props> = (props) => {
  const { onClick, showDrawer } = props;

  const [showTrans, setShowTrans] = useState(showDrawer);
  // const { isMobile, isIpad } = useDeviceType();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!showDrawer) {
      setTimeout(() => {
        setShowTrans(showDrawer);
      }, 50);
    } else {
      setShowTrans(showDrawer);
    }
  }, [showDrawer]);

  const nodeRef = useRef(null);

  return (
    <div className={showDrawer ? "p-4" : "p-2"}>
      <CSSTransition
        in={showTrans}
        nodeRef={nodeRef}
        timeout={{
          appear: 30,
          enter: 0,
          exit: 10,
        }}
        classNames="alert"
        unmountOnExit
      >
        <div ref={nodeRef} className={classes.Container}>
          <MTypography variant={"h6"} customClass={"text-center"}>
            {"Start with AutoMato!"}
          </MTypography>
          <MTypography variant={"subtitle1"} customClass={`${classes.CreatingOrAdding}`}>
            Optimizing or adding new products couldn’t be easier
          </MTypography>
          <MButton color={"primary"} rootClass={"w-100 mt-4"} onClick={onClick}>
            Upgrade
          </MButton>
        </div>
      </CSSTransition>

      {!showDrawer && (
        <CSSTransition
          in={!showTrans}
          nodeRef={nodeRef}
          timeout={{
            appear: 0,
            enter: 20,
            exit: 100,
          }}
          classNames="alert"
          unmountOnExit
        >
          <div ref={nodeRef} className={classes.ContainerCollapsed}>
            <MTypography variant="subtitle1" customClass={classes.SmallHeading}>
              {"Start with AutoMato!"}
            </MTypography>
            <MButton color={"primary"} rootClass={`w-100 ${classes.SmallButton}`} size="small" onClick={onClick}>
              Upgrade
            </MButton>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

export default UpgradeCard;
