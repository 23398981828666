import React, { ReactNode, useState } from "react";
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import MuiPopover from "../Popover";
import { useDeviceType } from "../../../hooks/useDeviceType";
import classes from "./index.module..css";
import MTypography from "../MTypography";

interface MuiTooltipProps {
  arrow: boolean;
  children: ReactNode;
  content: ReactNode;
  placement?: TooltipProps["placement"];
}
interface MuiTooltipState {
  loading: boolean;
  openPopper: boolean;
  anchorEl: HTMLDivElement | null;
}
const MuiTooltip = ({ arrow, children, content, placement }: MuiTooltipProps) => {
  const { isDesktop } = useDeviceType();
  const [state, setState] = useState<MuiTooltipState>({
    loading: false,
    openPopper: false,
    anchorEl: null,
  });

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "white",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      margin: "0 !important",
      backgroundColor: "white",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      padding: "0.75rem",
      color: " #000000",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    },
  }));

  return (
    <div>
      {isDesktop ? (
        <BootstrapTooltip title={content} arrow={arrow} placement={placement || "bottom"}>
          <div>{children}</div>
        </BootstrapTooltip>
      ) : (
        <>
          <MuiPopover
            sx={{ "& .MuiPaper-root": { padding: "4px" } }}
            open={state.openPopper}
            anchorEl={state.anchorEl}
            onClose={() => setState({ ...state, openPopper: false })}
          >
            <div className={`${classes.TooltipContainer} p-3`}>
              <MTypography variant={"body2"} customClass={classes.TooltipContent}>
                {content}
              </MTypography>
            </div>
          </MuiPopover>
          <div
            onClick={(e) => {
              setState({
                ...state,
                anchorEl: e.currentTarget,
                openPopper: true,
              });
            }}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default MuiTooltip;
