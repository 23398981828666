import React, { useState } from "react";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import { SettingsEnum } from "../../../utils/constants/enums";
import MuiTooltip from "../../../components/Atoms/MuiTooltip";
import { InfoOutlined } from "@mui/icons-material";
import { useDeviceType } from "../../../hooks/useDeviceType";
import classes from "./index.module.css";
import MTypography from "../../../components/Atoms/MTypography";
import Space from "../../../components/Atoms/Space";
import MButton from "../../../components/Atoms/MButton";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import { GogoSettingKeywordsArrangeSliders, GogoSettingSliders } from "./config";
import MSlider from "../../../components/Atoms/MSlider";
import MTextField from "../../../components/Atoms/MTextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import MBottomNavigation from "../../../components/Atoms/MBottomNavigation";

const GoGoAutomatoSettings = () => {
  const { isDesktop } = useDeviceType();

  const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");

  const [gogoConfigs, setGogoConfigs] = useState({
    competitorsRelevancy: 0,
    keywordsRelevancy: 0,
    keywordsUsabilityScore: 0,
    brandFilterScore: 0,
    competitivenessScore: 300,
    currentRank: 300,
    searchVolume: addCommas(0),
    relevancyScore: 300,
  });
  const handleSliderChange = (evt: React.ChangeEvent<any>) =>
    setGogoConfigs({
      ...gogoConfigs,
      [evt.target.name]: addCommas(removeNonNumeric(evt.target.value)),
    });
  const handleBack = () => window.history.go(-1);
  const handleSaveSetting = () => {
    console.log("Setiggs => ", gogoConfigs);
    toast.success("Settings Updated");
  };
  return (
    <div>
      {isDesktop ? (
        <TabsToolbar
          title={SettingsEnum.GoGoAutomato}
          infoIcon={
            <div>
              <MuiTooltip
                arrow
                content={
                  <div>
                    <MTypography variant={"subtitle2"} customClass={classes.TooltipHeading}>
                      {"Status of content"}
                    </MTypography>
                    <Space top={"0.5rem"} />
                    <MTypography variant={"subtitle2"} customClass={classes.TooltipBody}>
                      {
                        "Settings allows for advanced users to tweak the weights and thresholds of Go Go AutoMato Fully Automatic Listing Generation."
                      }
                    </MTypography>
                  </div>
                }
              >
                <InfoOutlined fontSize={"large"} className={`cursor-pointer ${classes.InfoIcon}`} />
              </MuiTooltip>
            </div>
          }
        >
          <div>
            {isDesktop && (
              <div className={"d-flex align-items-center gap-4"}>
                <div onClick={handleBack} className={"cursor-pointer"}>
                  {"< Back"}
                </div>
                <MButton onClick={handleSaveSetting}>{"Save"}</MButton>
              </div>
            )}
          </div>
        </TabsToolbar>
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2"}>
            <ArrowBackIcon onClick={handleBack} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>
              <div className={"d-flex align-items-center gap-3"}>
                {capitalizeEachLetter(SettingsEnum.GoGoAutomato.split("-").join(" "))}
                <MuiTooltip
                  arrow
                  content={
                    <div>
                      <MTypography variant={"subtitle2"} customClass={classes.TooltipHeading}>
                        {"Status of content"}
                      </MTypography>
                      <Space top={"0.5rem"} />
                      <MTypography variant={"subtitle2"} customClass={classes.TooltipBody}>
                        {
                          "Settings allows for advanced users to tweak the weights and thresholds of Go Go AutoMato Fully Automatic Listing Generation."
                        }
                      </MTypography>
                    </div>
                  }
                >
                  <InfoOutlined fontSize={"small"} className={`cursor-pointer ${classes.InfoIcon}`} />
                </MuiTooltip>
              </div>
            </MTypography>
          </div>
        </div>
      )}

      <div className={"p-4 mt-2"}>
        <div className={`${classes.Container}`}>
          {GogoSettingSliders.map((item, index) => (
            <div key={item.key}>
              <div
                className={`d-flex align-items-center  ${classes.Section} ${isDesktop ? "flex-row" : "flex-column"}`}
              >
                <div className={isDesktop ? "w-25" : "w-100"}>
                  <MTypography variant={"subtitle2"}>{item.text}</MTypography>
                </div>

                <div className={isDesktop ? "w-25" : "w-100"}>
                  <MSlider
                    min={item?.minValue}
                    max={item?.maxValue}
                    value={gogoConfigs[item.key]}
                    marks={item?.marks}
                    onChange={(e: any) => handleSliderChange(e)}
                    name={item.key}
                  />
                </div>
              </div>
              {index < GogoSettingSliders.length - 1 && <Divider />}
            </div>
          ))}
        </div>

        <MTypography variant={"subtitle2"} customClass={`m-2 mt-4 ${classes.KeywordsWeight}`}>
          {"Keyword Arrangement Weights"}
        </MTypography>

        <div className={`${classes.Container} mt-4`}>
          <div
            className={`d-flex align-items-center w-100 ${classes.Section} ${isDesktop ? "flex-row" : "flex-column"}`}
          >
            <MTextField
              name={"searchVolume"}
              value={gogoConfigs.searchVolume}
              onChange={(e) => handleSliderChange(e)}
              placeholder={"Search Volume"}
              label={"Search Volume"}
              rootClass={isDesktop ? "w-50" : "w-100"}
            />
          </div>
          {GogoSettingKeywordsArrangeSliders.map((item, index) => (
            <div key={item.key}>
              <div
                className={`d-flex align-items-center  ${classes.Section} ${isDesktop ? "flex-row" : "flex-column"}`}
              >
                <div className={isDesktop ? "w-25" : "w-100"}>
                  <MTypography variant={"subtitle2"}>{item.text}</MTypography>
                </div>

                <div className={isDesktop ? "w-25" : "w-100"}>
                  <MSlider
                    min={item?.minValue}
                    max={item?.maxValue}
                    value={gogoConfigs[item.key]}
                    marks={item.marks}
                    onChange={handleSliderChange}
                    step={item.steps}
                    name={item.key}
                    showLines
                  />
                </div>
              </div>
              {index < GogoSettingKeywordsArrangeSliders.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </div>

      {!isDesktop && (
        <MBottomNavigation>
          <MButton onClick={handleSaveSetting} fullWidth>
            {"Save"}
          </MButton>
        </MBottomNavigation>
      )}
    </div>
  );
};

export default GoGoAutomatoSettings;
