import React from "react";

import TabsToolbar from "../../components/Molecules/TabsToolbar";
import { TabsEnum } from "../../utils/constants/enums";
import { useDeviceType } from "../../hooks/useDeviceType";
import MTypography from "../../components/Atoms/MTypography";
import { capitalizeEachLetter } from "../../utils/helpers/strings";
import Exact from "../../components/Organisms/KeywordBlocklist/Exact/Exact";
import BroadKeywords from "../../components/Organisms/KeywordBlocklist/BroadKeywords/BroadKeywords";

const BlacklistWords = () => {
  const { isDesktop } = useDeviceType();

  return (
    <>
      <div style={{ maxWidth: "100%", marginBottom: "150px" }}>
        {isDesktop ? (
          <TabsToolbar title={TabsEnum.BLACKLIST} />
        ) : (
          <div className={"ms-2 p-2 mt-3"}>
            <MTypography variant={"h5"}>{capitalizeEachLetter(TabsEnum.BLACKLIST.replace("-", " "))}</MTypography>
          </div>
        )}

        <Exact />
        <div className={"my-5"} />
        <BroadKeywords />
      </div>
    </>
  );
};

export default BlacklistWords;
