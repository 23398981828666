import React from "react";

import MTypography from "../../../Atoms/MTypography";
import CardOverlay from "../../../Atoms/CardOverlay";

import { useDeviceType } from "../../../../hooks/useDeviceType";
import { toCamelCase } from "../../../../utils/helpers/strings";
import FormLanguageModelDropdown from "../../../Molecules/FormLanguageModelDropdown";

import { languageModelDropdownList, languageModelTitleNames } from "./config";
import classes from "./index.module.css";

const LanguageModelConfigForm = () => {
  const { isDesktop } = useDeviceType();

  return (
    <>
      <div className={`pt-4`}>
        <MTypography variant={"subtitle1"} customClass={classes.Title}>
          Language Model Configuration
        </MTypography>
        <MTypography variant={"subtitle1"} customClass={`${classes.Description}`}>
          Select the primary and backup AI models for each OpenAI call type to ensure optimal performance and
          reliability.
        </MTypography>
      </div>
      <div className={`${classes.ListContainer}`}>
        <CardOverlay zeroPadding={true}>
          {languageModelTitleNames.map((item: any) => (
            <div className={`${isDesktop ? classes.ListItemContainer : classes.ListItemContainerM}`} key={item.title}>
              <div
                className={`d-flex ${
                  isDesktop ? "justify-content-between align-items-center w-100 h-100" : "flex-column pt-3 pb-3"
                } `}
              >
                <MTypography variant="body2" customClass={`${classes.TitleName} ${!isDesktop && classes.TitleNameM}`}>
                  {item.title}
                </MTypography>
                <div style={{ width: "35%" }} className={`${!isDesktop && "w-100 pt-2"}`}>
                  <div className="w-100">
                    <FormLanguageModelDropdown
                      name={toCamelCase(item.title)}
                      optionsList={languageModelDropdownList}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CardOverlay>
      </div>
    </>
  );
};

export default LanguageModelConfigForm;
