import { getChangeValueInX } from "../../../../pages/Product/ProductDetails/config";
import { ProductLiveStatusEnum } from "../../../../utils/constants/enums";
import { getDifferenceFromCurrentDate } from "../../../../utils/helpers/date";
import { formatNumberWithTwoValuesAfterDecimal } from "../../../../utils/helpers/numbers";

export const manageSearchIncrease = (searchIncrease: number, volumeChange: number) => {
  if (searchIncrease < 1 && searchIncrease > 0) {
    return `${Math.round(searchIncrease)}%`;
  } else if (searchIncrease === 0 && volumeChange > 0) {
    return "∞";
  } else if (searchIncrease === 0 && volumeChange === 0) {
    return "0%";
  } else if (searchIncrease < 0) {
    return `${searchIncrease.toFixed(2)}%`;
  } else if (searchIncrease) {
    return `${getChangeValueInX(searchIncrease.toString())}`;
  } else {
    return "0%";
  }
};

export const manageVolumeChange = (volChange: number | string) => {
  if (volChange) {
    return formatNumberWithTwoValuesAfterDecimal(volChange);
  } else {
    return 0;
  }
};

export const manageKeywordCoverageRatio = (keywordCoverage: number) => {
  if (keywordCoverage) {
    return `${keywordCoverage.toFixed(2)}%`;
  } else {
    return "0%";
  }
};

export const manageLastContentLive = (date: string) => {
  if (date) {
    const noOfDays = getDifferenceFromCurrentDate(date);
    return `${noOfDays} Day${noOfDays > 1 ? "s" : ""} ago.`;
  } else {
    return ProductLiveStatusEnum.NEVER;
  }
};

export const filterMarks = [
  {
    value: 10,
    label: "10%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 30,
    label: "30%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 50,
    label: "50%",
  },
];
