import { Box, Skeleton } from "@mui/material";
const KeywordsCardSkeletonM = (props: any) => {
    const { view = "keywords" } = props
    const getSkeleton = () => {
        const skeletons = [];
        for (let index = 0; index < 10; index++) {
            skeletons.push(
                <div className={"card flex-row mt-4 cursor-pointer p-3 mw-100"} key={index}>
                    <div className="d-flex flex-row w-100 gap-2">
                        <div className="w-100">
                            <Box component={'div'} className="w-100" sx={{ mb: '16px' }}>
                                <div className={`d-flex gap-2 align-items-center ${view === "keywords" ? "" : 'justify-content-between flex-row-reverse'}`}>
                                    <Skeleton
                                        variant="circular"
                                        width={20}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={150}
                                        height={30}
                                    />
                                </div>

                            </Box>
                            <div className={"d-flex justify-content-between align-items-center mt-3 mb-2 gap-5"}>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                            </div>
                            <div className={"d-flex justify-content-between align-items gap-5"}>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                                <Box component={'div'} sx={{ width: '50%' }}>
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        height={20}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'100%'}
                                        height={30}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return skeletons;
    }

    return (
        <>
            {getSkeleton()}
        </>
    )
}

export default KeywordsCardSkeletonM;