import React, { memo } from "react";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import { RolesEnum } from "../../../../utils/constants/enums";
import { useSelector } from "react-redux";
import { showUserEmailInProductsPage } from "../../../../utils/helpers/priviligesChecks";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import classes from "./GridSkeleton.module.css";
import ProductTable from "../ProductDataGrid/ProductTable";

import { usePagination } from "../../../../hooks/usePagination";
import { AppState } from "../../../../store/reducers";
import { generateProductGridSkeleton } from "../../../../utils/helpers/skeleton";
import { userRole as userRolesCheck } from "../../../../utils/helpers/priviligesChecks";

const componentTypeObject = {
  products: "products",
  opportunityReport: "opportunityReport",
  brandsAlias: "brandsAlias",
  teamManagement: "teamManagement",
  companies: "Companies",
  individuals: "Individuals",
};
interface GridSkeletonProps {
  componentType?: "products" | "opportunityReport" | "brandsAlias" | "teamManagement" | "Companies" | "Individuals";
  isCompanyUser?: boolean;
}

const GridSkeleton: React.FC<GridSkeletonProps> = ({
  componentType = componentTypeObject.products,
  isCompanyUser = false,
}) => {
  const dbUser = useSelector(DbUserSelector);
  const userRole = useSelector(UserRoleSelector);
  const products: any = generateProductGridSkeleton(50);
  const totalProducts = useSelector((state: AppState) => state.product.totalProducts);

  const { pageSize, setPageNumber, pageNumber, setPageSize, setSortModel } = usePagination(50);

  const productsColumns: GridColDef[] = [];
  if (componentType === componentTypeObject.products) {
    productsColumns.push({
      field: "actions",
      headerName: "",
      width: 110,
      sortingOrder: ["desc", "asc"],
      sortable: false,
      renderCell: () => (
        <div className={"d-flex gap-2 w-100"}>
          <Skeleton variant={"rectangular"} sx={{ width: "28px", height: "28px" }} />
          <Skeleton variant={"rectangular"} sx={{ width: "28px", height: "28px" }} />
        </div>
      ),
    });
    productsColumns.push({
      field: "productASIN",
      headerName: "ASIN",
      width: 160,
      sortingOrder: ["desc", "asc"],
      sortable: false,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "title",
      headerName: "Title",
      width: 250,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"d-flex align-items-center gap-2 w-100"}>
          <Skeleton variant={"rectangular"} className={classes.ImageSkeleton} />
          <div className={"w-100"}>
            <Skeleton variant={"text"} className={classes.TextSkeleton} />
            <Skeleton variant={"text"} className={classes.TextSkeleton} />
          </div>
        </div>
      ),
    });
    productsColumns.push({
      field: "domain",
      headerName: "Marketplace",
      width: 120,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div>
          <div className={`d-flex align-items-center`}>
            <Skeleton variant={"text"} className={classes.MarketSkeleton} />
          </div>
        </div>
      ),
    });
    productsColumns.push({
      field: "productBrand",
      headerName: "Brand",
      width: 110,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    if (showUserEmailInProductsPage(userRole as RolesEnum)) {
      productsColumns.push({
        field: "userEmail",
        headerName: "User",
        hideable: true,
        width: 210,
        sortable: false,
        renderCell: () => (
          <div className={"w-100"}>
            <Skeleton variant={"text"} />
            <Skeleton variant={"text"} />
          </div>
        ),
      });
    }
    productsColumns.push({
      field: "status",
      headerName: "Status",
      width: 170,
      sortable: false,
      renderCell: () => <Skeleton variant={"rectangular"} className={classes.StatusSkeleton} />,
    });
    productsColumns.push({
      field: "lastProductGeneratedAt",
      headerName: "Last version generated",
      width: 210,
      sortingOrder: ["desc", "asc"],
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    // productsColumns.push({
    //   field: "delete",
    //   headerName: "",
    //   width: 130,
    //   renderCell: () => (
    //     <div className={"w-100 d-flex gap-3"}>
    //       <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
    //       <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
    //     </div>
    //   ),
    // });
  } else if (componentType === componentTypeObject.opportunityReport) {
    productsColumns.push({
      field: "productASIN",
      headerName: "ASIN",
      width: 135,
      sortingOrder: ["desc", "asc"],
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "title",
      headerName: "Title",
      width: 250,
      sortingOrder: ["desc", "asc"],
      renderCell: () => (
        <div className={"d-flex align-items-center gap-2 w-100"}>
          <Skeleton variant={"rectangular"} className={classes.ImageSkeleton} />
          <div className={"w-100"}>
            <Skeleton variant={"text"} className={classes.TextSkeleton} />
            <Skeleton variant={"text"} className={classes.TextSkeleton} />
          </div>
        </div>
      ),
    });

    productsColumns.push({
      field: "searchIncrease",
      headerName: "Search Increase",
      width: 180,
      sortingOrder: ["desc", "asc"],
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "volumeChange",
      headerName: "Volume Change",
      width: 180,
      sortingOrder: ["desc", "asc"],
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "keywordCoverageRatio",
      headerName: "Keyword Coverage Ratio",
      width: 230,
      sortingOrder: ["desc", "asc"],
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "lastLiveDate",
      headerName: "Last Content Live",
      width: 165,
      sortable: false,
      renderCell: () => <Skeleton variant={"rectangular"} className={classes.StatusSkeleton} />,
    });
  } else if (componentType === componentTypeObject.brandsAlias) {
    productsColumns.push({
      field: "name",
      headerName: "Brand name",
      width: 250,
      sortable: false,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "alias",
      headerName: "Brand Alias",
      width: 250,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} className={classes.TextSkeleton} />
        </div>
      ),
    });
    productsColumns.push({
      field: "voice",
      headerName: "Brand Voice",
      width: 250,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} className={classes.TextSkeleton} />
        </div>
      ),
    });
    if (userRolesCheck.isAdminOrInternal(userRole) || isCompanyUser) {
      productsColumns.push({
        field: "userEmail",
        headerName: "User Email",
        width: 250,
        sortable: false,
        renderCell: (_params: GridCellParams) => (
          <div className={"w-100"}>
            <Skeleton variant={"text"} className={classes.TextSkeleton} />
          </div>
        ),
      });
    }
    productsColumns.push({
      field: "createdAt",
      headerName: "Last updated",
      width: 250,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });

    productsColumns.push({
      field: "status",
      headerName: "Hide brand name",
      width: 200,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
        </div>
      ),
    });
    productsColumns.push({
      field: "edit",
      headerName: "",
      width: 200,
      renderCell: () => (
        <div className={"w-100 d-flex gap-3"}>
          <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
        </div>
      ),
    });
  } else if (
    componentType === componentTypeObject.teamManagement ||
    componentType === componentTypeObject.companies ||
    componentType === componentTypeObject.individuals
  ) {
    productsColumns.push({
      field: "name",
      headerName: "Name",
      width: 160,
      sortable: false,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "email",
      headerName: "Email",
      width:
        componentType === componentTypeObject.companies || componentType === componentTypeObject.individuals
          ? 200
          : 150,
      sortable: false,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "role",
      headerName: "Role",
      width:
        componentType === componentTypeObject.companies || componentType === componentTypeObject.individuals
          ? 130
          : 150,
      sortable: false,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    componentType === componentTypeObject.companies &&
      productsColumns.push({
        field: "totalMembers",
        headerName: "Members",
        width: 150,
        sortable: false,
        renderCell: () => (
          <div className={"w-100"}>
            <Skeleton variant={"text"} />
          </div>
        ),
      });
    productsColumns.push({
      field: "countContentGeneration",
      headerName: "ASIN limit",
      width: 100,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} className={classes.TextSkeleton} />
        </div>
      ),
    });
    productsColumns.push({
      field: "countKeywordRefreshes",
      headerName: "Keywords refreshes",
      width: 160,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={`w-100`}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    productsColumns.push({
      field: "countContentRegeneration",
      headerName: "Content regeneration",
      width: 180,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });

    productsColumns.push({
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (_params: GridCellParams) => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    });
    componentType === componentTypeObject.teamManagement &&
      productsColumns.push({
        field: "updatedAt",
        headerName: "Last Updated",
        width: 150,
        renderCell: () => (
          <div className={"w-100"}>
            <Skeleton variant={"text"} />
          </div>
        ),
      });
  }

  return (
    <div>
      <ProductTable
        total={totalProducts}
        pageSize={pageSize}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        columns={productsColumns}
        rows={products ?? []}
        initialState={{
          columns: {
            columnVisibilityModel: {
              userEmail: showUserEmailInProductsPage(dbUser?.role),
            },
          },
        }}
        setSortModel={setSortModel}
        checkboxSelection={componentType === componentTypeObject.products}
        isRowSelectable={() => false}
        withoutPagination={true}
      />
    </div>
  );
};

export default memo(GridSkeleton);
