import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import DragItem from "../DragItem";

import classes from "./index.module.css";
import { isObject } from "../../../../utils/helpers/common";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { AsinKeywordHistorySelector } from "../../../../store/product/product.selector";
import { useParams } from "react-router-dom";
import { ParamsEnum } from "../../../../utils/constants/enums";

import DropTextImg from "../../../../assets/png/drop-text.png";
import DropTitleImg from "../../../../assets/png/title--drop.png";

interface Props {
  col: {
    id: string;
    list: any;
  };
  isSelected?: boolean;
  handleSelected: any;
  selectedItem: any;
}

const Column: React.FC<Props> = (props) => {
  const {
    col: { list, id },
    isSelected = false,
  } = props;

  const { isDesktop } = useDeviceType();

  const keywordHistory = useSelector(AsinKeywordHistorySelector);
  const params = useParams();
  const topSpace = 34;
  return (
    <>
      {isDesktop ? (
        <div className={isDesktop ? `${classes.Container}` : classes.ContainerMobile}>
          {isDesktop && (props.col?.id === "restKeywords" || props.col?.id === "selectedKeywords") && (
            <>
              <div className={classes.Number} style={{ top: `${topSpace}px`, left: "-4px" }}>
                1
              </div>
              <div className={classes.Number} style={{ top: `${topSpace}px`, right: "-4px" }}>
                2
              </div>
              <div className={classes.Number} style={{ top: `133px`, left: "-4px" }}>
                3
              </div>
              {props.col?.id === "restKeywords" && (
                <>
                  <div className={classes.Number} style={{ top: `133px`, right: "-4px" }}>
                    4
                  </div>
                  <div className={classes.Number} style={{ top: `228px`, left: "-4px" }}>
                    5
                  </div>
                  <div className={classes.Number} style={{ top: `228px`, right: "-4px" }}>
                    6
                  </div>
                  <div className={classes.Number} style={{ top: `323px`, left: "-4px" }}>
                    7
                  </div>
                  <div className={classes.Number} style={{ top: `323px`, right: "-4px" }}>
                    8
                  </div>
                  <div className={classes.Number} style={{ top: `419px`, left: "-4px" }}>
                    9
                  </div>
                  <div className={classes.Number} style={{ top: `419px`, right: "-4px" }}>
                    10
                  </div>
                  <div className={classes.Number} style={{ top: `516px`, left: "-8px" }}>
                    11
                  </div>
                  <div className={classes.Number} style={{ top: `516px`, right: "-8px" }}>
                    12
                  </div>
                  <div className={classes.Number} style={{ top: `610px`, left: "-8px" }}>
                    13
                  </div>
                  <div className={classes.Number} style={{ top: `610px`, right: "-8px" }}>
                    14
                  </div>
                </>
              )}
            </>
          )}
          {isSelected ? (
            <Droppable droppableId={id}>
              {(provided: any) => (
                <div
                  className={props.col?.id === "restKeywords" ? classes.RestStyledColumn : classes.SelectedStyledColumn}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {list.length > 0 ? (
                    <div className={"row gx-0"}>
                      {list.map((item: any, index: number) => (
                        <DragItem
                          isSelected={isSelected}
                          key={JSON.stringify(item)}
                          text={isObject(item) ? item.phrase : item}
                          searchVolume={isObject(item) ? item.searchVolume : null}
                          competitiveness={isObject(item) ? item.competitiveness : null}
                          titleRarirty={isObject(item) ? item?.titleRarity : null}
                          index={index}
                          keywordHistory={
                            keywordHistory.hasOwnProperty(item.phrase) ? keywordHistory[item.phrase] : null
                          }
                          isSeoKeyword={isObject(item)}
                          isObject={isObject(item)}
                          rank={isObject(item) ? item[params[ParamsEnum.ASIN] as string] : null}
                          keywordType={id}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  ) : (
                    <div className={props.col?.id === "restKeywords" ? classes.RestCol : classes.SelectedCol}>
                      <img src={props.col?.id === "restKeywords" ? DropTextImg : DropTitleImg} alt="" />
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          ) : (
            <Droppable droppableId={id}>
              {(provided: any) => (
                <div className={classes.StyledColumn} {...provided.droppableProps} ref={provided.innerRef}>
                  <div className={`row gx-0`}>
                    {list.map((item: any, index: number) => (
                      <DragItem
                        titleRarirty={isObject(item) ? item?.titleRarity : null}
                        isSelected={isSelected}
                        key={JSON.stringify(item)}
                        text={isObject(item) ? item.phrase : item}
                        index={index}
                        searchVolume={isObject(item) ? item.searchVolume : null}
                        competitiveness={isObject(item) ? item.competitiveness : null}
                        keywordHistory={keywordHistory.hasOwnProperty(item.phrase) ? keywordHistory[item.phrase] : null}
                        isSeoKeyword={isObject(item)}
                        isObject={isObject(item)}
                        rank={isObject(item) ? item[params[ParamsEnum.ASIN] as string] : null}
                        keywordType={id}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          )}
        </div>
      ) : (
        <div className={isDesktop ? `${classes.Container}` : classes.ContainerMobile}>
          {isSelected ? (
            <div className={id === "restKeywords" ? classes.SelectedStyledColumnRest : classes.SelectedStyledColumn}>
              <div className={"row gx-1"}>
                {list.map((item: any, index: number) => (
                  <DragItem
                    titleRarirty={isObject(item) ? item?.titleRarity : null}
                    isSelected={isSelected}
                    key={JSON.stringify(item)}
                    text={isObject(item) ? item.phrase : item}
                    searchVolume={isObject(item) ? item.searchVolume : null}
                    competitiveness={isObject(item) ? item.competitiveness : null}
                    index={index}
                    id={id || ""}
                    item={item}
                    handleSelected={props?.handleSelected}
                    selectedItem={props?.selectedItem}
                    keywordHistory={keywordHistory.hasOwnProperty(item.phrase) ? keywordHistory[item.phrase] : null}
                    isSeoKeyword={isObject(item)}
                    isObject={isObject(item)}
                    rank={isObject(item) ? item[params[ParamsEnum.ASIN] as string] : null}
                    keywordType={id}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={classes.StyledColumn}>
              <div className={`row gx-0`}>
                {list.map((item: any, index: number) => (
                  <DragItem
                    titleRarirty={isObject(item) ? item?.titleRarity : null}
                    isSelected={isSelected}
                    key={JSON.stringify(item)}
                    text={isObject(item) ? item.phrase : item}
                    index={index}
                    id={id || ""}
                    item={item}
                    handleSelected={props?.handleSelected}
                    selectedItem={props?.selectedItem}
                    searchVolume={isObject(item) ? item.searchVolume : null}
                    competitiveness={isObject(item) ? item.competitiveness : null}
                    keywordHistory={keywordHistory.hasOwnProperty(item.phrase) ? keywordHistory[item.phrase] : null}
                    isSeoKeyword={isObject(item)}
                    isObject={isObject(item)}
                    rank={isObject(item) ? item[params[ParamsEnum.ASIN] as string] : null}
                    keywordType={id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Column;
