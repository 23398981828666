import { Grid, Skeleton } from "@mui/material";
import React from "react";
import TeamStatCard from "../../TeamManagement/TeamStatsContainer/TeamStatCard/TeamStatCard";
import MTypography from "../../../Atoms/MTypography";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import classes from "./index.module.css";

interface TeamUserStatsProps {
  isLoading: boolean;
  userData: any;
  stats: any;
}
const TeamUserStats = (props: TeamUserStatsProps) => {
  const { isLoading, userData, stats } = props;

  const { isDesktop } = useDeviceType();

  const getLiveStat = (): number => {
    let live = 0;
    if (stats?.productCountsWithStatuses.length > 0) {
      stats?.productCountsWithStatuses.forEach((item: any) => {
        if (item?._id === "live") {
          live = item?.count;
        }
      });
    }
    return live;
  };
  const liveStat = getLiveStat();

  return (
    <div>
      <Grid
        container
        flexDirection={"row"}
        px={{ xs: 2, lg: 4 }}
        mt={{ lg: 2 }}
        spacing={{ xs: 1.5, md: 2 }}
        textAlign={"center"}
      >
        <Grid item xs={6} lg={2.4}>
          <TeamStatCard title="Products Handling" volume={stats?.totalRecords || 0} isLoading={isLoading} />
        </Grid>
        <Grid item xs={6} lg={2.4}>
          <TeamStatCard title="Total Approved" volume={stats?.totalApproved || 0} isLoading={isLoading} />
        </Grid>
        <Grid item xs={6} lg={2.4}>
          <TeamStatCard title="Total Submitted" volume={stats?.totalPublishedProducts} isLoading={isLoading} />
        </Grid>
        <Grid item xs={6} lg={2.4}>
          <TeamStatCard title="Total Live" volume={liveStat} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} lg={2.4}>
          <div className={isDesktop ? classes.CardContainer : classes.CardContainerM}>
            <MTypography
              variant={"subtitle1"}
              customClass={`${isDesktop ? classes.CardTitle : classes.CardTitleM}`}
              color={"#4d4d4d"}
            >
              Usage Counter
            </MTypography>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Skeleton variant="text" width={"50%"} height={20} />
              </div>
            ) : (
              <div className={"row"}>
                <div className={"col-4"}>
                  <MTypography variant={"h1"} customClass={isDesktop ? classes.Count : classes.CountM}>
                    {userData?.countContentGeneration}
                  </MTypography>
                  <p className={isDesktop ? classes.Subtitle : classes.SubtitleM}>ASINs</p>
                </div>
                <div className={"col-4"}>
                  <MTypography variant={"h1"} customClass={isDesktop ? classes.Count : classes.CountM}>
                    {userData?.countKeywordRefreshes}
                  </MTypography>
                  <p className={isDesktop ? classes.Subtitle : classes.SubtitleM}>Keyword refreshes</p>
                </div>
                <div className={"col-4"}>
                  <MTypography variant={"h1"} customClass={isDesktop ? classes.Count : classes.CountM}>
                    {userData?.countContentRegeneration}
                  </MTypography>
                  <p className={isDesktop ? classes.Subtitle : classes.SubtitleM}>Content regeneration</p>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TeamUserStats;
