import React, { ReactNode } from "react";
import AppBar from "../AppBar";
import CenterElement from "../AppBar/centerElement";
import { PagesEnum } from "../../../utils/constants/enums";
import CrossElement from "../AppBar/crossElement";
import { useDeviceType } from "../../../hooks/useDeviceType";
import FetchProductHeader from "../FetchProductHeader";
import Donut from "../../../assets/svgs/ProductFetchSteps/Mobile/Donut.svg";

const ProductFetchScreen = ({ page, children }: ProductFetchScreenProps) => {
  const { isDesktop, isMobile, isIpad } = useDeviceType();

  return (
    <>
      {isDesktop && <AppBar centerElement={<CenterElement page={page} />} rightElement={<CrossElement />}></AppBar>}
      {(isMobile || isIpad) && (
        <FetchProductHeader donut={Donut} next={"Competitors"} title={"Enter ASIN"} tabNumber={1} />
      )}
      <div
        className={`${isDesktop ? "d-flex justify-content-center align-items-center" : "d-flex justify-content-center"
          } `}
        style={{ marginBottom: isMobile || isIpad ? "125px" : "" }}
      >
        <div className={"w-100"}>{children}</div>
      </div>
    </>
  );
};

interface ProductFetchScreenProps {
  children: ReactNode;
  page: PagesEnum;
}

export default ProductFetchScreen;
