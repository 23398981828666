import React from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import ProductDetailsTab from "./ProductDetailsTab";
import MTypography from "../../../Atoms/MTypography";
import classes from "./index.module.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { enableEditFunctionalityOnDetailPage } from "../../../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../../store/user/user.selector";
import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import { toast } from "react-toastify";
const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  sx?: object;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div className={classes.TabsContainer}>{children}</div>}
    </div>
  );
};

const ProductDetailsSwipeableTabs = () => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const currentUser = useSelector(DbUserSelector);
  const completeProduct = useSelector(CompleteProductSelector);
  const [value, setValue] = React.useState(1);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const disableChangeTab =
      completeProduct?.bulletPointsByChatEditMode ||
      completeProduct?.generatedDescriptionEditMode ||
      completeProduct?.generatedTitleEditMode;
    if (disableChangeTab && newValue === 0) {
      toast.warn("You are in edit mode. Cannot switch tabs");
    } else setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  return (
    <div>
      <AppBar position="static" className={classes.Appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          aria-label="full width tabs example"
          className="sw-tabs"
          sx={{ backgroundColor: "transparent" }}
        >
          <Tab
            icon={
              <div>
                <MTypography
                  variant={"subtitle1"}
                  customClass={`${classes.TabHeader} ${value === 0 && classes.TabHeaderActive}`}
                >{`Before Content`}</MTypography>
              </div>
            }
            className={`${classes.TabHeader}`}
            {...a11yProps(0)}
          />
          <Tab
            icon={
              <div>
                <MTypography
                  variant={"subtitle1"}
                  customClass={`${classes.TabHeader} ${value === 1 && classes.TabHeaderActive}`}
                >
                  <div className={"d-flex align-items-center gap-1"}>
                    {`After Content`}
                    {enableEditFunctionalityOnDetailPage(currentUser?.role, completeProduct?.status) && (
                      <EditOutlinedIcon fontSize={isDesktop ? "medium" : "small"} />
                    )}
                  </div>
                </MTypography>
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ProductDetailsTab />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <ProductDetailsTab aiGenerated />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default ProductDetailsSwipeableTabs;
