import React from 'react'
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import classes
  from "../../Organisms/ProductDetails/ProductDetailsSwipeableTabs/ProductDetailsTab/TabCard/index.module.css";
import MTypography from "../MTypography";

interface CopyButtonProps{
  onClick: any
}

const CopyButton:React.FC<CopyButtonProps> = (props) => {

  const {onClick} = props


  return <>
    <div
      className={"d-flex align-items-center cursor-pointer HoverEffectForButton"}
      onClick={onClick}
    >
      <ContentCopyOutlinedIcon className={classes.CopyIcon} />
      <div>
        <MTypography variant={"subtitle2"} customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}>
          {"Copy"}
        </MTypography>
      </div>
    </div>
    </>
    }
    export default CopyButton