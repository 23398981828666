
import React, { memo } from 'react';
import { keywordsDensityTitle } from '../../../../utils/constants/common';
import MTypography from '../../../Atoms/MTypography';
import classes from '../index.module.css';
import { DensitiesList } from '../KeywordDensity';
import { acceptablePercentage, acceptableTitleCount } from '../../../../utils/helpers/keywordDensityHelper/keywordDensityChecker';
import { SwipeableTabTitlesEnums } from '../../../../utils/constants/enums';

interface KeywordsDensityCardsMProps {
    keywordsDensityList: DensitiesList[];
    onKeywordClick: (phrase: string, highlightClass: string) => void;
    title: string;
}

const KeywordsDensityCardsM: React.FC<KeywordsDensityCardsMProps> = (props) => {
    const { keywordsDensityList, onKeywordClick, title } = props;

    return (
        <div className={classes.ContainerM}>
            <MTypography variant="subtitle1" customClass={classes.DensityDetail}>
                {keywordsDensityTitle}
            </MTypography>
            {keywordsDensityList?.length ? keywordsDensityList?.map((item) => {
                const isExceed = title === SwipeableTabTitlesEnums.TITLE ? (item?.occurrences <= acceptableTitleCount) : (item?.density <= acceptablePercentage);
                return (
                    <div key={item?.density + item?.word} >
                        <div className={classes.KeywordsCardM}>
                            <div className={"d-flex flex-column p-3"}>
                                <div
                                    className={`cursor-pointer ${isExceed ? 'primary-fc' : classes.LimitExceedColor}`}
                                    onClick={() =>
                                        onKeywordClick(item?.word, isExceed ? 'KeywordDensityHighlighted' : 'KeywordDensityHighlightedRed')
                                    }
                                >
                                    <MTypography
                                        variant={"subtitle1"}
                                        customClass={`${classes.KeywordCardTitleM}`}
                                        color='inherit'
                                    >
                                        <span className={classes.BottomBorder}>{item?.word}</span>
                                    </MTypography>
                                </div>
                                <div className={"d-flex mt-3 gap-5"}>
                                    <div className={"d-flex flex-column"}>
                                        <MTypography variant={"subtitle1"} customClass={`${classes.KeywordsRecordTitleM} ${isExceed ? classes.LimitNotExceedColor : classes.LimitExceedColor}`}>
                                            {item?.occurrences}
                                        </MTypography>
                                        <MTypography variant={"subtitle1"} customClass={classes.KeywordsRecordTextM}>
                                            Count
                                        </MTypography>
                                    </div>
                                    <div className={"d-flex flex-column"}>
                                        <MTypography variant={"subtitle1"} customClass={`${classes.KeywordsRecordTitleM} ${isExceed ? classes.LimitNotExceedColor : classes.LimitExceedColor}`}>
                                            {item?.density}
                                        </MTypography>
                                        <MTypography variant={"subtitle1"} customClass={classes.KeywordsRecordTextM}>
                                            Percent
                                        </MTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }) :
                <div className={"d-flex justify-content-center align-items-center w-100 h-100 p-2"}>
                    <MTypography variant={"subtitle2"}>{"No keywords to display"}</MTypography>
                </div>
            }

        </div>
    )
}

export default memo(KeywordsDensityCardsM);