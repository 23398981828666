import { AppState } from "../reducers";
import { RolesEnum } from "../../utils/constants/enums";
export const UserSelector = (state: AppState) => state.user;
export const DbUserSelector = (state: AppState) => state.user.dbUser;
export const UserIdSelector = (state: AppState) => state.user.dbUser?._id;
export const UserRoleSelector = (state: AppState): RolesEnum => state.user.role;
export const UserListSelector = (state: AppState) => state.user.userList;
export const UserEmailSelector = (state: AppState) => state.user?.dbUser?.email;
export const UserCredentialSelector = (state: AppState) => state.user?.userCredentials;
export const TeamMembersSelector = (state: AppState) => state.user?.allTeamMembers;
export const TeamStatsSelector = (state: AppState) => state.user?.teamMembersStats;
