import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";

import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import ProfileForm from "../../../components/Organisms/Profile/ProfileForm";
import MTypography from "../../../components/Atoms/MTypography";
import classes from "./index.module.css";
import backIcon from "../../../assets/svgs/back-icon.svg";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { URL_SETTINGS } from "../../../routes/routes-path";
import MainLoader from "../../../components/Atoms/MainLoader";

import {
  ProfileObject,
  profileType,
  initialValue,
  profileTabs,
  profileTabsValue,
  inputName,
  validationSchemaBasicDetails,
  validationSchemaPassword,
  validationSchemaBasicDetailsForUser,
} from "./config";
import MTabs from "../../../components/Atoms/MTabs";
import MButton from "../../../components/Atoms/MButton";
import HeadingUnderline from "../../../components/Molecules/HeadingUnderline";
import FormTextField from "../../../components/Molecules/FormTextField";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../../store/user/user.selector";
import { UserApis } from "../../../apis/user";
import { CompanyApis } from "../../../apis/companies";
import { setUserAction } from "../../../store/user/user.actions";
import { toast } from "react-toastify";
import { SUCC_COMPANY_PROFILE_UPDATED, SUCC_USER_PROFILE_UPDATED } from "../../../utils/constants/messages/success";
import { capitalizeFirstLetter } from "../../../utils/helpers/strings";
import { errorHandler } from "../../../utils/helpers/apis";

interface ProfileProps {
  type: "userProfile" | "companyProfile";
}

const Profile: React.FC<ProfileProps> = (props) => {
  const { type } = props;

  const dispatch = useDispatch();

  const currentUser = useSelector(DbUserSelector);
  const companyId = currentUser?.hasOwnProperty("companyID") ? currentUser?.companyID : "";
  const userId = currentUser?._id;
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();

  const [companyProfile, setCompanyProfile] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>(profileTabsValue.basicDetails);
  const [formInitialValue, setFormInitialValues] = useState<any>(initialValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (type === "companyProfile") {
      setInitialLoading(true);
      UserApis.getCompanyProfile(companyId).then((res: any) => {
        setCompanyProfile(res);
        setFormInitialValues({
          ...initialValue,
          asinLimit: res?.contentGenerationLimit,
          keywordRefreshes: res?.helium10CallsLimit,
          contentRegeneration: res?.contentRegenerationLimit,
          name: capitalizeFirstLetter(res?.name || ""),
          email: currentUser?.email,
          userName: capitalizeFirstLetter(currentUser?.name || ""),
          phoneNo: currentUser?.phoneNumber,
        });
        setInitialLoading(false);
      });
    } else {
      setFormInitialValues({
        name: capitalizeFirstLetter(currentUser?.name || ""),
        email: currentUser?.email,
        phoneNo: currentUser?.phoneNumber,
      });
    }
  }, []);

  const handleBack = () => {
    navigate(`${URL_SETTINGS}`);
  };
  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const submitForm = async (values: ProfileObject) => {
    try {
      setIsLoading(true);
      if (type === "userProfile") {
        const res = await UserApis.updateUserSettings({
          userID: userId,
          name: values.name,
          phoneNumber: values.phoneNo,
        });
        // @ts-ignore
        dispatch(setUserAction());
        if (res) {
          setIsLoading(false);
          toast.success(SUCC_USER_PROFILE_UPDATED);
        }
      } else {
        const res = await CompanyApis.updateCompanyProfile({
          name: values.name,
          companyID: companyId,
          userName: values.userName,
          phoneNumber: values.phoneNo,
        });
        // @ts-ignore
        dispatch(setUserAction());
        if (res) {
          setIsLoading(false);
          toast.success(SUCC_COMPANY_PROFILE_UPDATED);
        }
      }
    } catch (e) {
      console.log("error Submit Form", e);
      setIsLoading(false);
      toast.error(errorHandler(e))
    }
  };
  let validationSchema: any;
  if (type === "userProfile") {
    validationSchema =
      activeTab === profileTabsValue.basicDetails ? validationSchemaBasicDetailsForUser : validationSchemaPassword;
  } else {
    validationSchema =
      activeTab === profileTabsValue.basicDetails ? validationSchemaBasicDetails : validationSchemaPassword;
  }
  return (
    <>
      {initialLoading ? (
        <MainLoader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValue}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, dirty }) => (
            <Form autoComplete="off">
              <div>
                {isDesktop ? (
                  <div className={`d-flex align-items-center justify-content-between ${classes.HeadingContainer}`}>
                    <TabsToolbar title={type === profileType.companyProfile ? "Company Profile" : "User Profile"} />
                    <div className={"d-flex gap-4 align-items-center"}>
                      <Box onClick={handleBack} className={"cursor-pointer"}>
                        <MTypography variant={"body2"} customClass={classes.BackButton}>
                          {"<Back"}
                        </MTypography>
                      </Box>
                      <MButton
                        isLoading={isLoading}
                        size={"small"}
                        variant={"contained"}
                        onClick={handleSubmit}
                        disabled={!dirty}
                      >
                        Update
                      </MButton>
                    </div>
                  </div>
                ) : (
                  <div className={"p-3 pb-1 w-100"}>
                    <div className="d-flex align-items-center gap-2 justify-content-between w-100 px-2 pb-2">
                      <Box onClick={handleBack} className={"cursor-pointer"}>
                        <MTypography variant={"body2"} customClass={classes.BackButton}>
                          <img src={backIcon} alt={"back-icon"} className={classes.BackIcon} />
                        </MTypography>
                      </Box>
                      <MButton
                        isLoading={isLoading}
                        size={"small"}
                        variant={"contained"}
                        onClick={handleSubmit}
                        disabled={!dirty}
                      >
                        Update
                      </MButton>
                    </div>
                    <MTypography variant={"h4"} customClass={'px-2'}>
                      {type === profileType.companyProfile ? "Company Profile" : "User Profile"}
                    </MTypography>
                  </div>
                )}

                <div className={"px-4"}>
                  <div className={"d-flex"}>
                    <MTabs handleChange={handleActiveTab} value={activeTab} tabs={profileTabs} isCamelCase={false} />
                  </div>

                  <div>
                    {activeTab === profileTabsValue.basicDetails && (
                      <ProfileForm type={type} companyProfile={companyProfile} />
                    )}
                  </div>
                  <div>
                    {activeTab === profileTabsValue.changePassword && (
                      <>
                        <HeadingUnderline content={"Change Password"} />
                        <div className={"row"}>
                          <div className={"col-md-6 col-sm-12"}>
                            <FormTextField fullWidth name={inputName.password} label={"Current Password"} />
                          </div>
                          <div className={"col-md-6 col-sm-12"}>
                            <FormTextField fullWidth name={inputName.newPassword} label={"New Password"} />
                          </div>

                          <div className={"col-md-6 col-sm-12"}>
                            <FormTextField
                              fullWidth
                              name={inputName.reEnterNewPassword}
                              label={"Re-Enter New Password"}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Profile;
