export enum UserActionTypes {
  setToken = "setToken",
  setRole = "setRole",
  setUser = "setUser",
  setUserList = "setUserLists",
  setBulletPointsFormat = "setBulletPointsFormat",
  setFirstLogin = "setFirstLogin",
  setUserCredentials = "setUserCredentials",
  setTeamMembers = "setTeamMembers",
  setTeamMembersStats = "setTeamMembersStats",
  setSelectedTeamMember = "setSelectedTeamMember",
}
