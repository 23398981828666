import React from "react";
import { useDeviceType } from "../../hooks/useDeviceType";
import { TabsEnum } from "../../utils/constants/enums";
import TabsToolbar from "../../components/Molecules/TabsToolbar";
import { filterSettingsByRole } from "./config";
import SettingMenuCard from "../../components/Organisms/SettingsMenuCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MTypography from "../../components/Atoms/MTypography";
import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { navigatePage } from "../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../routes/routes-path";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DbUserSelector } from "../../store/user/user.selector";
import { Grid } from "@mui/material";

const SettingsTab = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const dbUser = useSelector(DbUserSelector);
  let menuToVisible =  filterSettingsByRole(dbUser?.role);

  const navigateMoreTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.MORE}`, navigate);
  };
  const navigateSettingMenu = (menu: string) => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${menu}`, navigate);
  };
  return (
    <div>
      {isDesktop ? (
        <div>
          <TabsToolbar title={TabsEnum.SETTINGS} />
        </div>
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2"}>
            <ArrowBackIcon onClick={navigateMoreTab} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>{capitalizeEachLetter(TabsEnum.SETTINGS.replace("-", " "))}</MTypography>
          </div>
        </div>
      )}

      <div className={`d-flex align-items-center ${isDesktop ? "flex-row" : "flex-column"}`}>
        <Grid container>
          {menuToVisible.map((menu) => (
            <Grid
              item
              xs={12}
              lg={6}
              xl={3}
              key={menu.title}
              onClick={() => navigateSettingMenu(menu.name)}
              className={`${isDesktop && "m-5"}`}
            >
              <SettingMenuCard title={menu.title} description={menu.description} icon={menu.icon} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default SettingsTab;
