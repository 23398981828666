import React from "react";

import classes from "./RangeFilters.module.css";

const filtersValues: any = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "4 week",
    value: "4 week",
  },
  {
    name: "8 week",
    value: "8 weeks",
  },
  {
    name: "12 week",
    value: "12 week",
  },
];

const calculateDateRange = (filter: string): { startDate: Date; endDate: Date } => {
  const endDate = new Date(); // Current date
  let startDate = new Date();

  switch (filter) {
    case "all":
      startDate = new Date("2024-01-01");
      break;
    case "4 week":
      startDate.setDate(endDate.getDate() - 28); // 4 weeks = 28 days
      break;
    case "8 weeks":
      startDate.setDate(endDate.getDate() - 56); // 8 weeks = 56 days
      break;
    case "12 week":
      startDate.setDate(endDate.getDate() - 84); // 12 weeks = 84 days
      break;
    default:
      startDate = new Date("2024-01-01");
  }

  return { startDate, endDate };
};

interface RangeFiltersProps {
  onDateChange: (item: any) => any;
  selectedPill: string;
  setSelectedPill: any;
}

const RangeFilters: React.FC<RangeFiltersProps> = (props) => {
  const { onDateChange, selectedPill, setSelectedPill } = props;

  const handlePill = (value: string) => {
    setSelectedPill(value);

    const { startDate, endDate } = calculateDateRange(value);
    console.log("Selected Filter:", value);
    console.log("Start Date:", startDate.toLocaleDateString());
    console.log("End Date:", endDate.toLocaleDateString());

    onDateChange({
      startDate,
      endDate,
    });
  };
  return (
    <div className={classes.Container}>
      <div className={classes.RangeText}>Range</div>
      {filtersValues.map((item: any) => (
        <div
          onClick={() => handlePill(item.value)}
          className={selectedPill === item.value ? classes.PillActive : classes.PillInactive}
          key={item.name}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default RangeFilters;
