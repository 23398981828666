import React, { useEffect, useRef, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import BrandsTable from "../BrandsTable";
import MTextField from "../../../Atoms/MTextField";
import { brandsFilters, brandSortOption, brandTabs, brandTabsValue, brandViewTabs } from "./config";
import classes from "./index.module.css";
import BrandCard from "../BrandCard";

import { GetAllBrandsArrayApi, GetAllBrandsPayload, initialGetAllProductPayload } from "../../../../apis/types/product";
import { navigatePage } from "../../../../utils/helpers/common";
import { useNavigate } from "react-router-dom";
import { defaultPageSizeOptions, usePagination } from "../../../../hooks/usePagination";
import { errorHandler } from "../../../../utils/helpers/apis";
import { generateProductApis } from "../../../../apis/generate-product";
import MSelect from "../../../Atoms/MSelect";
import { SelectChangeEvent } from "@mui/material";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import {
  resetProductsDispatch,
  setActiveBrandTabDispatch,
  SetOpportunityStatsDispatch,
  setTotalProductsStatusDispatch,
} from "../../../../store/product/product.actions";
import SavedProductHeader from "../SavedProductHeader";
import { URL_PRODUCTS, URL_FETCH_ASIN, URL_OPPORTUNITY_REPORT } from "../../../../routes/routes-path";
import { toast } from "react-toastify";
import { DbUserSelector } from "../../../../store/user/user.selector";
import MTabs from "../../../Atoms/MTabs";
import SavedProductsTab from "../../../../pages/SavedProducts";
import MTypography from "../../../Atoms/MTypography";
import MButton from "../../../Atoms/MButton";
import { ActiveBrandTabSelector } from "../../../../store/product/product.selector";
import BrandsSkeleton from "../BrandsSkeleton";
import BrandsSkeletonM from "../../../Molecules/AnimatedSkeletonCard/BrandsSkeletonM";
import NoResultsFound from "../../../Atoms/NoResultsFound/NoResultsFound";
import { getOpportunityStats } from "../../../../utils/helpers/opportunity-report";
import ProductStatusCounter from "../SavedProductHeader/ProductStatusCounter";

interface BrandsProps {
  // setting true when called from OpportunityReport
  opportunityBrands?: boolean;
}

const Brands: React.FC<BrandsProps> = (props) => {
  const { opportunityBrands = false } = props;
  const { isDesktop } = useDeviceType();
  const currentUser = useSelector(DbUserSelector);
  const activeBrandTab = useSelector(ActiveBrandTabSelector);

  const [search, setSearch] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(brandViewTabs.list);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(brandsFilters.newest);
  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy } = usePagination();

  const navigate = useNavigate();

  const [brandsData, setBrandsData] = useState<GetAllBrandsArrayApi>({
    totalRecords: 0,
    brands: [],
  });

  const dispatch = useDispatch();

  const debouncedSearch = useRef(
    debounce((_value: string) => {
      setSearchQuery(_value);
      setPageNumber(0);
    }, 1500)
  ).current;

  const handleTabChange = (tab: string) => {
    dispatch(setActiveBrandTabDispatch(tab));
  };

  useEffect(() => {
    if (!isDesktop) setActiveTab(brandViewTabs.grid);
  }, [isDesktop]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const getBrands = (filters: GetAllBrandsPayload) => {
    setFetchLoading(true);
    generateProductApis
      .getAllBrands(filters)
      .then((res: any) => {
        setBrandsData(res);
        if(res?.productCountsWithStatuses){ 
          dispatch(setTotalProductsStatusDispatch(res?.productCountsWithStatuses));
        }
        setFetchLoading(false);
        if (opportunityBrands) {
          dispatch(SetOpportunityStatsDispatch(getOpportunityStats(res)));
        }
      })
      .catch((e) => {
        errorHandler(e);
        setFetchLoading(false);
      });
  };

  const getFilters = (): any => {
    try {
      let filters: any = {
        offset,
        limit: pageSize,
        searchQuery: searchQuery,
        searchKey: "productBrand",
        sortBy: filter.sortBy,
        sortOrder: filter.sortOrder,
        opportunityReport: opportunityBrands,
      };

      return filters;
    } catch (_e) {
      return initialGetAllProductPayload;
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentUser?.role) getBrands(getFilters());
  }, [pageNumber, pageSize, offset, sortBy, sortOrder, searchQuery, filter]);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const onBrandClick = (brandName: string) => {
    if (opportunityBrands) {
      navigatePage(`${URL_OPPORTUNITY_REPORT}/${brandName}`, navigate, { state: { productBrand: brandName } });
    } else {
      // for saved-product view
      dispatch(resetProductsDispatch());
      navigatePage(`${URL_PRODUCTS}/${brandName}`, navigate, { state: { productBrand: brandName } });
    }
  };

  const handleFilterChange = (e: SelectChangeEvent) => {
    try {
      const filter: string = e.target.value;
      setFilter(filter);
    } catch (e: any) {
      toast.error(e);
    }
  };
  const navigateFetchAsinPage = () => {
    navigatePage(URL_FETCH_ASIN, navigate);
  };

  return (
    <div>
      {!opportunityBrands && <SavedProductHeader />}

      {!isDesktop && (
        <div className="p-3 pb-0">
          <div className={"d-flex w-100 justify-content-between"}>
            <MTypography variant={"h4"}>Products</MTypography>
            <MButton
              size={"small"}
              variant="contained"
              rootClass={classes.savedProductsAddButtonM}
              onClick={navigateFetchAsinPage}
            >
              {"Add ASIN"}
            </MButton>
          </div>
          {!opportunityBrands && <ProductStatusCounter />}
        </div>
      )}

      <div className={isDesktop ? classes.Container : ""}>
        {/* restrictring below code for opportunity view */}
        {!opportunityBrands && (
          <>
            <div className={isDesktop ? "d-flex" : ""}>
              <MTabs handleChange={handleTabChange} value={activeBrandTab} tabs={brandTabs} isCamelCase={false} />
            </div>
            <div className={isDesktop ? "mb-4" : ""}></div>
          </>
        )}
        <div className={isDesktop ? "" : "px-2"}>
          {/* restrictring below code for opportunity view */}
          {!opportunityBrands && <>{activeBrandTab === brandTabsValue.asin && <SavedProductsTab isChildComponent />}</>}

          {(activeBrandTab === brandTabsValue.brand || opportunityBrands) && (
            <div>
              <div
                className={`d-flex  ${
                  isDesktop ? "justify-content-between align-items-center" : "flex-column align-items-start w-100"
                }`}
              >
                <div className={`${isDesktop ? "w-25" : "w-100 px-2"}`}>
                  <MTextField
                    icon={"search"}
                    position={"start"}
                    onChange={handleChange}
                    name={"customKeyword"}
                    placeholder={"Search a brand by name"}
                    value={search}
                    margin={"dense"}
                    rootClass={isDesktop ? classes.Input : "w-100"}
                  />
                </div>
                {brandsData.brands.length > 0 && (
                  <div
                    className={`${
                      isDesktop ? "w-50" : "w-100 px-3"
                    } d-flex justify-content-end align-items-center`}
                  >
                    <div>
                      <MSelect
                        margin={"dense"}
                        handleChange={handleFilterChange}
                        value={filter}
                        options={brandSortOption}
                        variant="icon"
                      />
                    </div>
                  </div>
                )}
              </div>
              {fetchLoading ? (
                <div>
                  {activeTab === brandViewTabs.list ? (
                    <BrandsSkeleton opportunityBrands={opportunityBrands} />
                  ) : (
                    <BrandsSkeletonM opportunityBrands={opportunityBrands} />
                  )}
                </div>
              ) : (
                <div>
                  {activeTab === brandViewTabs.list ? (
                    <BrandsTable
                      onBrandClick={onBrandClick}
                      brandsData={brandsData}
                      opportunityBrands={opportunityBrands}
                    />
                  ) : (
                    <div className={" px-3"}>
                      <div className={"row"}>
                        {brandsData.brands.length > 0 ? (
                          brandsData.brands.map((brand) => (
                            <div key={Object.keys(brand)[0]} className={"col-sm-12 col-md-6 col-lg-3 g-2"}>
                              <BrandCard
                                brand={brand}
                                onBrandClick={onBrandClick}
                                opportunityBrands={opportunityBrands}
                              />
                            </div>
                          ))
                        ) : (
                          <NoResultsFound pt={"20px"} />
                        )}
                      </div>
                    </div>
                  )}
                  <div className={`${!isDesktop && classes.MarginPagination}`}>
                    {brandsData.brands.length > 0 && (
                      <TablePagination
                        sx={{
                          "& .MuiInputBase-root": {
                            width: "auto",
                          },
                          "& .MuiTablePagination-selectLabel ": {
                            marginTop: "14px !important",
                          },
                          "& .MuiTablePagination-displayedRows ": {
                            marginTop: "14px !important",
                          },
                          "& .MuiTablePagination-actions": {
                            marginLeft: { xs: "0px !important", md: "20px !important" },
                          },
                          "& .MuiTablePagination-toolbar": {
                            paddingLeft: { xs: "0px !important", md: "16px !important" },
                            paddingRight: { xs: "0px !important", md: "2px !important" },
                            maxWidth: { xs: "100% !important" },
                          },
                        }}
                        component="div"
                        count={brandsData.totalRecords}
                        page={pageNumber}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={defaultPageSizeOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Brands;
