import React, { useState } from "react";
import classes from "./index.module.css";
import CloseIcon from "@mui/icons-material/Close";
import BasicDetails from "../BasicDetails";
import { Divider } from "@mui/material";
import MTypography from "../../../Atoms/MTypography";
import { useSelector } from "react-redux";
import RatingBreakDown from "./RatingBreakdown";
import MRating from "../../../Atoms/MRating";
import { getDateWithTime } from "../../../../utils/helpers/date";
import dummyUser from "../../../../assets/svgs/dummyAmazonUser.svg";
import ProductVariant from "./Variants";
import MButton from "../../../Atoms/MButton";
import { VariantsIncreaseLimits } from "../config";
import { ScrapeProductSelector } from "../../../../store/product/productScrape/productScrape.selectors";
interface ProductDetailsModalProps {
  showDetailButton: boolean;
  openDetailBox: () => void;
  hideDetailBox: () => void;
}
const CompleteFetchProductDetails: React.FC<ProductDetailsModalProps> = ({
  showDetailButton,
  openDetailBox,
  hideDetailBox,
}) => {
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const [showAllReviews, setShowAllReviews] = useState<boolean>(false);
  const actualLength = scrapeProduct?.product?.variants?.length || 0;
  const [variantsLimits, setVariantsLimits] = useState(
    actualLength <= VariantsIncreaseLimits ? actualLength : VariantsIncreaseLimits
  );
  const increaseVariantsLimits = () => {
    setVariantsLimits(
      variantsLimits + VariantsIncreaseLimits < actualLength ? variantsLimits + VariantsIncreaseLimits : actualLength
    );
  };
  const resetLimit = () =>
    setVariantsLimits(actualLength <= VariantsIncreaseLimits ? actualLength : VariantsIncreaseLimits);
  const handleReviewsVisibility = () => setShowAllReviews(!showAllReviews);

  return (
    <div className={`d-flex align-items-center justify-content-center  ${classes.MainContainer}`}>
      <div className={classes.DetailBoxContainer}>
        <div className={"d-flex justify-content-end"}>
          <CloseIcon className={classes.CloseIcon} onClick={hideDetailBox} />
        </div>
        <BasicDetails openDetailBox={openDetailBox} showDetailButton={showDetailButton} />
        <Divider className={classes.Divider} />
        {scrapeProduct?.product?.description && (
          <div>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Product Description"}
            </MTypography>
            <MTypography variant={"subtitle2"} customClass={classes.Body}>
              {scrapeProduct?.product?.description}
            </MTypography>
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.feature_bullets?.length && (
          <div>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Product Details"}
            </MTypography>

            <ul>
              {scrapeProduct?.product?.feature_bullets?.map((bullet, index) => (
                <li key={index} className={classes.Bullet}>
                  <MTypography variant={"subtitle2"} customClass={classes.Body}>
                    {bullet}
                  </MTypography>
                </li>
              ))}
            </ul>
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.variants?.length && (
          <div>
            <MTypography variant={"subtitle2"} customClass={classes.Title}>
              {"Variants"}
            </MTypography>
            <div className={"d-flex align-items-center gap-3 flex-wrap"}>
              {scrapeProduct?.product?.variants?.map((variant, index) => (
                <div key={variant?.asin} className={index < variantsLimits ? "" : "d-none"}>
                  <ProductVariant variant={variant} />
                </div>
              ))}
            </div>
            {scrapeProduct?.product?.variants?.length >= VariantsIncreaseLimits && (
              <div className={"d-flex align-items-center justify-content-center mt-4"}>
                {variantsLimits === actualLength ? (
                  <MButton variant={"outlined"} onClick={resetLimit}>
                    {"View Less"}
                  </MButton>
                ) : (
                  <MButton variant={"outlined"} onClick={increaseVariantsLimits}>
                    {"View More"}
                  </MButton>
                )}
              </div>
            )}
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.important_information?.sections?.length > 1 && (
          <div>
            {scrapeProduct?.product?.important_information?.sections?.map((section, index) => (
              <div key={index} className={`${index !== 0 && "mt-3"}`}>
                {section?.title && (
                  <MTypography variant={"subtitle2"} customClass={classes.Title}>
                    {section.title}
                  </MTypography>
                )}
                {section?.title && (
                  <MTypography variant={"subtitle2"} customClass={classes.Body}>
                    {section.body}
                  </MTypography>
                )}
              </div>
            ))}
            <Divider className={classes.Divider} />
          </div>
        )}
        {scrapeProduct?.product?.rating_breakdown && (
          <div>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Reviews"}
            </MTypography>

            <div className={"d-flex align-items-start justify-content-between h-100"}>
              <div className={`w-75 ${classes.RatingBreakDown}`}>
                {scrapeProduct?.product?.rating_breakdown?.five_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.five_star?.percentage}
                    value={5}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.four_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.four_star?.percentage}
                    value={4}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.three_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.three_star?.percentage}
                    value={3}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.two_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.two_star?.percentage}
                    value={2}
                  />
                )}
                {scrapeProduct?.product?.rating_breakdown?.one_star && (
                  <RatingBreakDown
                    percentage={scrapeProduct?.product?.rating_breakdown?.one_star?.percentage}
                    value={1}
                  />
                )}
              </div>
              <div className={"w-25 h-100"}>
                <div
                  className={` d-flex align-items-center flex-column justify-content-center ${classes.OverallRating} `}
                >
                  <MTypography variant={"body2"} customClass={classes.OverallText}>
                    {"Overall review ratings"}
                  </MTypography>
                  <MTypography variant={"body2"} customClass={classes.OverallRatingText}>
                    {scrapeProduct?.product?.rating}
                  </MTypography>
                  <MRating value={scrapeProduct?.product?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
                </div>
              </div>
            </div>
          </div>
        )}

        {scrapeProduct?.product?.top_reviews?.length && (
          <div className={"mt-5"}>
            <MTypography variant={"body2"} customClass={classes.Title}>
              {"Top Reviews"}
            </MTypography>
            {scrapeProduct?.product?.top_reviews?.map((review, index) => (
              <div key={index}>
                {index < 2 && (
                  <div className={classes.ReviewContainer}>
                    <div className={"d-flex justify-content-between align-items-start"}>
                      <div className={"d-flex align-items-start"}>
                        <div>
                          <img
                            src={review?.profile?.image || dummyUser}
                            alt={"reviewer"}
                            className={classes.ReviewerImage}
                          />
                        </div>
                        <div>
                          {review?.profile?.name && (
                            <MTypography variant={"subtitle2"} customClass={classes.ReviewerName}>
                              {review?.profile?.name}
                            </MTypography>
                          )}
                          {review?.rating && (
                            <MRating value={review?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
                          )}
                        </div>
                      </div>
                      <div>
                        {review?.date?.utc && (
                          <MTypography variant={"subtitle2"} customClass={classes.ReviewDate}>{`${getDateWithTime(
                            review?.date?.utc?.toString(),
                            true
                          )}`}</MTypography>
                        )}
                      </div>
                    </div>
                    {review?.body && (
                      <div>
                        <MTypography variant={"body2"} customClass={classes.ReviewBody}>
                          {review?.body}
                        </MTypography>
                      </div>
                    )}
                  </div>
                )}
                {index > 1 && showAllReviews && (
                  <div className={classes.ReviewContainer}>
                    <div className={"d-flex justify-content-between align-items-start"}>
                      <div className={"d-flex align-items-start"}>
                        <div>
                          <img
                            src={review?.profile?.image || dummyUser}
                            alt={"reviewer"}
                            className={classes.ReviewerImage}
                          />
                        </div>
                        <div>
                          {review?.profile?.name && (
                            <MTypography variant={"subtitle2"} customClass={classes.ReviewerName}>
                              {review?.profile?.name}
                            </MTypography>
                          )}
                          {review?.rating && (
                            <MRating value={review?.rating} isScrapeProduct={!!scrapeProduct.product} noGap />
                          )}
                        </div>
                      </div>
                      <div>
                        {review?.date?.utc && (
                          <MTypography variant={"subtitle2"} customClass={classes.ReviewDate}>{`${getDateWithTime(
                            review?.date?.utc?.toString(),
                            true
                          )}`}</MTypography>
                        )}
                      </div>
                    </div>
                    {review?.body && (
                      <div>
                        <MTypography variant={"body2"} customClass={classes.ReviewBody}>
                          {review?.body}
                        </MTypography>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div className={"d-flex justify-content-end"}>
              <MTypography variant={"body2"} customClass={classes.ShowMoreReview}>
                <span onClick={handleReviewsVisibility}>{showAllReviews ? "View Less" : "View All"}</span>
              </MTypography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompleteFetchProductDetails;
