import { TitleVariationsEnum } from "../../../../utils/constants/enums";
import { capitalizeFirstLetter } from "../../../../utils/helpers/strings";

export const getTitleVariationHeader = (title: string) => {
    switch (title) {
        case TitleVariationsEnum.AUTOMATO_RECOMMENDED:
            return "Sales";
        case TitleVariationsEnum.ORIGINAL:
            return "SEO";
        default:
            return capitalizeFirstLetter(title || "");
    }
}