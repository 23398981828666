import { useEffect, useState } from "react";

// Define the type for the custom hook return value
type UseAdjustHeightToScreen = () => number;

// Custom hook for adjusting div height to screen size
export const useScreenSize: UseAdjustHeightToScreen = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenHeight;
};
