import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import MuiPopover from "../../Atoms/Popover";
import MenuOption from "../../Molecules/MenuOption";
import MTypography from "../../Atoms/MTypography";

import classes from "./index.module.css";

interface ProductDetailsTabCardMenuMProps {
  menuState: any;
  setMenuState: React.Dispatch<React.SetStateAction<any>>;
  handleCopyClick?: () => void;
  handleEditClick: () => void;
  handleShowEditsClick: () => void;
  isShowEditOptionVisible: boolean | undefined;
  isEditOptionVisible: boolean | undefined;
  isCopyOptionVisible: boolean | undefined;
  showChanges: boolean;
  isBulletPointsWrapper: boolean;
}

const ProductDetailsTabCardMenuM: React.FC<ProductDetailsTabCardMenuMProps> = (props) => {
  const {
    menuState,
    setMenuState,
    handleCopyClick,
    handleEditClick,
    handleShowEditsClick,
    isShowEditOptionVisible = false,
    isEditOptionVisible = false,
    isCopyOptionVisible = false,
    showChanges = false,
    isBulletPointsWrapper = false,
  } = props;

  const closeMenu = () => {
    setMenuState({
      anchorEl: null,
      openPopper: false,
    });
  };

  return (
    <div>
      <div className={`${classes.IconContainer}`}>
        <MoreVertIcon
          className={`${classes.IconColor}`}
          sx={{}}
          fontSize="small"
          onClick={(e) => {
            setMenuState({
              anchorEl: e.currentTarget,
              openPopper: true,
            });
          }}
        />
      </div>
      <MuiPopover
        open={menuState.openPopper}
        anchorEl={menuState.anchorEl}
        onClose={() => setMenuState({ ...menuState, openPopper: false })}
      >
        <div className={"py-2"}>
          {isCopyOptionVisible && !isBulletPointsWrapper && handleCopyClick && (
            <MenuOption>
              <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                <div
                  onClick={() => {
                    handleCopyClick();
                    closeMenu();
                  }}
                >
                  Copy
                </div>
              </MTypography>
            </MenuOption>
          )}
          {isEditOptionVisible && (
            <MenuOption>
              <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                <div
                  onClick={() => {
                    handleEditClick();
                    closeMenu();
                  }}
                >
                  Edit
                </div>
              </MTypography>
            </MenuOption>
          )}
          {isShowEditOptionVisible && (
            <MenuOption>
              <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                <div
                  onClick={() => {
                    handleShowEditsClick();
                    closeMenu();
                  }}
                >
                  {showChanges ? "Hide Edits" : "Show Edits"}
                </div>
              </MTypography>
            </MenuOption>
          )}
        </div>
      </MuiPopover>
    </div>
  );
};

export default ProductDetailsTabCardMenuM;
