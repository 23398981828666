import React, { ReactNode } from "react";

interface CardOverlayProps {
  zeroPadding?: boolean;
  children: ReactNode;
}
const CardOverlay: React.FC<CardOverlayProps> = (props) => {
  const { zeroPadding = false, children } = props;

  return (
    <div
      style={{
        paddingTop: zeroPadding ? "0px" : "20px",
        paddingBottom: zeroPadding ? "0px" : "20px",
        boxShadow: "0px 3px 10px 0px #F0F2F5",
        backgroundColor: "#FFF",
        borderRadius: "10px",
      }}
    >
      {children}
    </div>
  );
};

export default CardOverlay;
