import React, { useEffect, useState } from "react";
import MTypography from "../../../Atoms/MTypography";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { SettingsEnum, TabsEnum } from "../../../../utils/constants/enums";
import UserDetailCard from "../../UserDetailCard";
import { DatabaseUser } from "../../../../apis/types/user";
import { AccountManagementState } from "../../../../pages/AccountManagement";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { navigatePage } from "../../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import KeywordsCardSkeletonM from "../../../Molecules/AnimatedSkeletonCard/KeywordsCardSkeletonM";
import MButton from "../../../Atoms/MButton";
import MTextField from "../../../Atoms/MTextField";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
interface AccountManagementMProps {
  accountManagementState: AccountManagementState;
  setAccountManagementState: React.Dispatch<React.SetStateAction<AccountManagementState>>;
  loading: boolean;
  userList: any[];
  handleAddUser: () => void;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  search: string;
  total: number;
  setPageNumber: (page: number) => void;
  pageNumber: number;
}
const AccountManagementM = ({
  accountManagementState,
  setAccountManagementState,
  loading,
  userList,
  handleAddUser,
  handleChange,
  search,
  total,
  setPageNumber,
  pageNumber,
}: AccountManagementMProps) => {
  const navigate = useNavigate();

  const navigateMoreTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.MORE}`, navigate);
  };

  const [hasMore, setHasMore] = useState<boolean>(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!loading) {
      setHasMore(total > userList?.length);
    }
  }, [userList]);

  const fetchMore = () => {
    if (total > userList?.length) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className={"d-flex flex-column h-auto p-2 m-2"} style={{ marginBottom: "75px" }}>
      <div className={"my-2 d-flex justify-content-between"}>
        <ArrowBackIcon onClick={navigateMoreTab} />
        <MButton variant="contained" onClick={handleAddUser}>
          Add Internal User
        </MButton>
      </div>
      <div className={"my-2 mx-1"}>
        <MTypography variant={"h5"}>{capitalizeEachLetter(SettingsEnum.USERS.replace("-", " "))}</MTypography>
      </div>
      <div>
        <MTextField
          icon={"search"}
          position={"start"}
          onChange={handleChange}
          name={"customKeyword"}
          placeholder={"Search by name or email"}
          value={search}
          margin={"dense"}
          rootClass={"w-100"}
        />

        <InfiniteScroll
          dataLength={userList?.length}
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <div className={"d-flex justify-content-center my-3"}>
              {loading && <CircularProgress color={"primary"} />}
            </div>
          }
        >
          {Array.isArray(userList) && userList.length > 0 ? (
            userList.map((user: DatabaseUser) => (
              <UserDetailCard
                user={user}
                key={user?._id}
                accountManagementState={accountManagementState}
                setAccountManagementState={setAccountManagementState}
              />
            ))
          ) : (
            <>
              {loading ? (
                <KeywordsCardSkeletonM view="accounts" />
              ) : (
                <div className={"mt-5 d-flex justify-content-center "}>
                  <MTypography variant={"body1"}>{"No users found..."}</MTypography>
                </div>
              )}
            </>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default AccountManagementM;
