import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import MButton from "../../../Atoms/MButton";
import FormTextField from "../../../Molecules/FormTextField";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { CreateProductObject, initialValue, inputName, validationSchema } from "./config";
import MTypography from "../../../Atoms/MTypography";
import classes from "../FetchAsinForm/index.module.css";
import { getExceptionError, navigatePage } from "../../../../utils/helpers/common";
import { URL_LANDING_PAGE, URL_PRODUCT_FETCHED, URL_PRODUCT_FETCHED_COMPETITORS } from "../../../../routes/routes-path";
import { useDispatch, useSelector } from "react-redux";
import { ParamsEnum, ProductFetchType, RolesEnum, TabsEnum } from "../../../../utils/constants/enums";
import { toast } from "react-toastify";
import ToastError from "../../../Atoms/ToastError";
import { ScrapeProductSelector } from "../../../../store/product/productScrape/productScrape.selectors";

import { unAttachedProductAction } from "../../../../store/product/productScrape/productScrape.actions";
import WhatIsGoGoToolTip from "../../../Molecules/WhatIsGoGoToolTip";
import { ProductApis } from "../../../../apis/product";
import KeywordsPageDialoge from "../../../mui/dialogebox/keywordsPageDialoge";
import { generatingProcessTimeTenToFifteen } from "../../../../utils/constants/common";
import { DbUserSelector } from "../../../../store/user/user.selector";
import PricingContext from "../../../../context/PricingContext";
import { UnlockGreaterPossibilities } from "../../../../utils/constants/messages/errors";
import {
  checkIsCompanyReachedHelium10Limit,
  checkIsUserReachedHisHelium10Limit,
} from "../../../../pages/SavedProducts/config";

interface CreateProductFormProps {
  setProductFetch: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateProductForm: React.FC<CreateProductFormProps> = (props) => {
  const { setProductFetch } = props;
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [runType, setRunType] = useState<string>("");

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loadingGoGO, setLoadingGoGO] = useState<boolean>(false);

  const currentUser = useSelector(DbUserSelector);
  const { onOpen } = useContext(PricingContext);

  const showUpgradeMessage = () => {
    if (currentUser?.companyID) {
      if (currentUser?.role === RolesEnum.COMPANY_ADMIN) {
        onOpen();
      } else {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
      }
    } else {
      onOpen();
    }
  };

  const submitForm = async (values: CreateProductObject) => {
    const isLimitReached = currentUser?.companyID
      ? await checkIsCompanyReachedHelium10Limit(currentUser?.companyID)
      : await checkIsUserReachedHisHelium10Limit(currentUser?._id);

    if (isLimitReached) {
      toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
      showUpgradeMessage();
    } else {
      try {
        setLoading(true);
        setProductFetch(true);
        if (runType === ProductFetchType.GOGO) {
          setLoadingGoGO(true);
        }
        dispatch(
          unAttachedProductAction({
            product: {
              title: values.title,
              feature_bullets: [values.productContent],
            },
            runType,
          })
        )
          .then((res: any) => {
            if (runType === ProductFetchType.MANUAL) {
              navigatePage(
                `${URL_PRODUCT_FETCHED}/${params[ParamsEnum.DOMAIN] ?? "com"}/${
                  scrapeProduct?.productASIN
                }${URL_PRODUCT_FETCHED_COMPETITORS}`,
                navigate
              );
            } else {
              ProductApis.gogoAutomatoProductGeneration({
                domain: res?.domain as string,
                asin: res.productASIN,
                userID: res?.userID,
                isRerun: false,
                updateCompetitors: true,
              })
                .then(() => {
                  setIsOpen(true);
                })
                .catch((err: any) => {
                  setLoadingGoGO(false);
                  throw new Error(err);
                });
            }
          })
          .catch((e: any) => {
            toast.error(<ToastError error={e} />);
            setLoading(false);
          });
      } catch (e) {
        setLoading(false);
        getExceptionError(e);
      }
    }
  };

  const closeDialogBox = () => {
    setIsOpen(false);
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, { replace: true });
  };

  return (
    <div>
      <KeywordsPageDialoge open={isOpen} onClose={closeDialogBox} title={generatingProcessTimeTenToFifteen} />
      <div className={"my-2 text-center"}>
        <MTypography variant={"h4"}>{"Don’t have live ASIN?"}</MTypography>
      </div>

      <div className={"my-2 text-center"}>
        <MTypography variant={"subtitle1"} customClass={classes.AsinFormDescription} color={" rgba(126, 126, 126, 1)"}>
          Input Title and Product Content for Your Listing
        </MTypography>
      </div>

      <Formik enableReinitialize initialValues={initialValue} onSubmit={submitForm} validationSchema={validationSchema}>
        {({ handleSubmit }) => (
          <Form autoComplete="off">
            <div className={isDesktop ? "d-flex flex-column" : "d-flex flex-column"}>
              <FormTextField name={inputName.title} label={"Enter Title"} />
              <FormTextField name={inputName.productContent} label={"Product Content"} multiline rows={7} />
              <div className={"d-flex flex-row mt-2 justify-content-end"}>
                {isDesktop ? (
                  <div className={"w-100"}>
                    <div>
                      <div className={"d-flex justify-content-end gap-2"}>
                        <MButton
                          size={"medium"}
                          disabled={loading || loadingGoGO}
                          variant={"outlined"}
                          onClick={() => {
                            handleSubmit();
                            setRunType(ProductFetchType.MANUAL);
                          }}
                          rootClass={classes.RunManual}
                        >
                          {loading && !loadingGoGO ? "Loading" : "Run Manual"}
                        </MButton>
                        <MButton
                          size={"medium"}
                          disabled={loading || loadingGoGO}
                          onClick={() => {
                            handleSubmit();
                            setRunType(ProductFetchType.GOGO);
                          }}
                          rootClass={classes.GoGoButton}
                        >
                          <div className={"d-flex align-items-center gap-1"}>
                            {loadingGoGO ? "Loading" : "Go Go AutoMato"}
                          </div>
                        </MButton>
                      </div>
                      <WhatIsGoGoToolTip />
                    </div>

                    <div className={"d-flex justify-content-center "}>
                      <p className={classes.ButtonCaption}>
                        {scrapeProduct?.productFetch && scrapeProduct?.isUnAttached
                          ? "This may take 30-45 seconds"
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={"d-flex flex-column w-100 "}>
                    <div className={"d-flex flex-column gap-3 w-100"}>
                      <div className={"d-flex flex-column w-100"}>
                        <MButton
                          size={"medium"}
                          variant={"contained"}
                          disabled={loading || loadingGoGO}
                          onClick={() => {
                            handleSubmit();
                            setRunType(ProductFetchType.GOGO);
                          }}
                        >
                          <div className={"d-flex align-items-center gap-1"}>
                            {loadingGoGO ? "Loading" : "Go Go AutoMato"}
                          </div>
                        </MButton>
                        <WhatIsGoGoToolTip mobile />
                      </div>
                      <MButton
                        width={"large"}
                        size={"medium"}
                        variant={"outlined"}
                        rootClass={"comp-button-mb"}
                        disabled={loading || loadingGoGO}
                        onClick={() => {
                          handleSubmit();
                          setRunType(ProductFetchType.MANUAL);
                        }}
                      >
                        {loading && !loadingGoGO ? "Loading" : "Run Manual"}
                      </MButton>
                    </div>

                    <div className={"d-flex w-100 justify-content-center"}>
                      <p className={classes.ButtonCaptionMobile}>
                        {scrapeProduct?.productFetch && scrapeProduct?.isUnAttached
                          ? "This may take 30-45 seconds"
                          : ""}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateProductForm;
