import axios from "../axios";
import { GetAllClientsPayload, GetAllClientsResponse, IndividualClientDetailsInterface, getAllClientsDefaultPayload } from "./types/clients";

// import { errorHandler } from "../utils/helpers/apis";

export const ClientsApis = {
  controller: "/clients",
  async getAllClients(payload = getAllClientsDefaultPayload) {
    return axios
      .post<GetAllClientsPayload, GetAllClientsResponse>(`/user/get-all-clients`, { ...payload })
      .then((r: any) => r.data.data);
  },
  async getIndividualCompleteDetails(userID: string) {
    return axios.get<any, IndividualClientDetailsInterface>(`/user/complete-detail/${userID}`).then((r: any) => r.data.data);
  },
};
 