import React, { useEffect, useState } from "react";
import { DialogProps } from "@mui/material/Dialog";
import ConfirmBox from "../../mui/dialogebox/confirmBox";
import { useDeviceType } from "../../../hooks/useDeviceType";
import classes from "./index.module.css";
import MTypography from "../../Atoms/MTypography";
import MTextField from "../../Atoms/MTextField";
import { TagColorsEnum } from "../../Organisms/SavedProducts/TagPopper/config";
import { UserProductTag } from "../../../apis/types/tag";
import { useDispatch, useSelector } from "react-redux";
import { UserProductTagsSelector } from "../../../store/product/product.selector";
import { handleUserTagsAction } from "../../../store/product/product.actions";
import { errorHandler } from "../../../utils/helpers/apis";
import { toast } from "react-toastify";

const CustomizedProductTagBox: React.FC<DialogProps & { tag: UserProductTag; tagIndex: number }> = ({
  open,
  onClose,
  tag,
  tagIndex,
}) => {
  const dispatch = useDispatch();
  const allTags = useSelector(UserProductTagsSelector);
  const { isDesktop } = useDeviceType();
  const [tagName, setTagName] = useState<string>("");
  const [tagColor, setTagColor] = useState<TagColorsEnum>();

  useEffect(() => {
    setTagName(tag.name);
    setTagColor(tag?.color || TagColorsEnum.WHITE);
  }, [tag]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(e.target.value);
  };

  const handleColorSelect = (color: TagColorsEnum) => {
    setTagColor(color);
  };

  const handleConfirmTag = () => {
    if (!tagName.length) {
      toast.warn("Tag cannot be empty");
    } else {
      dispatch(handleUserTagsAction(allTags, { ...tag, color: tagColor as TagColorsEnum, name: tagName }, tagIndex))
        .then(() => {
          toast.success("Tags has been updated");
          if (onClose) onClose({}, "escapeKeyDown");
        })
        .catch((e: any) => {
          toast.error(errorHandler(e));
        });
    }
  };
  return (
    <ConfirmBox
      open={open}
      confirm={handleConfirmTag}
      cancel={onClose as any}
      confirmButtonText={tag?.name ? "Update" : "Create"}
    >
      <div className={`${isDesktop && classes.Container}`}>
        <MTypography variant={"body2"} customClass={classes.Heading}>
          {"Create a Tag"}
        </MTypography>
        <MTextField
          onChange={handleChange as any}
          name={"tagName"}
          value={tagName}
          rootClass={"w-100"}
          placeholder={"Text"}
        />
        <MTypography variant={"body2"} customClass={classes.SubHeading}>
          {"Choose Color"}
        </MTypography>
        <div className={"d-flex align-items-center gap-3 flex-wrap"}>
          {Object.values(TagColorsEnum).map((color) => (
            <div
              key={color}
              style={{ backgroundColor: color }}
              className={`${classes.ColorBox} ${color === tagColor && classes.Selected}`}
              onClick={() => handleColorSelect(color)}
            />
          ))}
        </div>
      </div>
    </ConfirmBox>
  );
};

export default CustomizedProductTagBox;
