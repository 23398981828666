import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { TabsEnum } from "../../utils/constants/enums";
import { errorHandler } from "../../utils/helpers/apis";

import { GetAllUserTagsAction } from "../../store/product/product.actions";

import { useDeviceType } from "../../hooks/useDeviceType";

import ProductDataGrid from "../../components/Organisms/SavedProducts/ProductDataGrid";
import SavedProductsM from "../../components/Organisms/SavedProducts/SavedProductsM";
import SavedProductHeader from "../../components/Organisms/SavedProducts/SavedProductHeader";
import { CompleteProduct } from "../../apis/types/generate-product";
import { completeProductDispatch } from "../../store/product/productDetails/productDetails.actions";

interface Props {
  isChildComponent?: boolean;
  isTeamManagementUser?: boolean;
  teamUserId?: string;
  isCompany?: boolean;
}

const SavedProductsTab: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isChildComponent = false, isTeamManagementUser = false, teamUserId = "", isCompany = false } = props;
  const { isDesktop } = useDeviceType();


  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // TODO: Set this to false will enable when we active tags module
    const product = {} as CompleteProduct
    dispatch(completeProductDispatch(product))
    if (false) {
      dispatch(GetAllUserTagsAction()).catch((e: any) => {
        toast.error(errorHandler(e));
      });
    }
  }, []);

  if (isDesktop) {
    return (
      <div>
        {!isChildComponent && !isTeamManagementUser && <SavedProductHeader />}
        <ProductDataGrid
          isCompany={isCompany}
          isChildComponent={isChildComponent}
          isRerun
          tab={TabsEnum.SAVED}
          isTeamManagementUser={isTeamManagementUser}
          teamUserId={teamUserId}
        />
      </div>
    );
  } else {
    return (
      <SavedProductsM
        isCompany={isCompany}
        isChildComponent={isChildComponent}
        isTeamManagementUser={isTeamManagementUser}
        teamUserId={teamUserId}
      />
    );
  }
};

export default SavedProductsTab;
