import { RolesEnum } from "../../../utils/constants/enums";

export const teamManagementUserTabsValue = {
  basicDetails: "Basic Details",
  products: "Products",
};
export const teamManagementUserTabs = [teamManagementUserTabsValue.products, teamManagementUserTabsValue.basicDetails];

export const getTextBasedOnCompanyRole = (userRole: RolesEnum, companyRole: RolesEnum) => {
  if (userRole === RolesEnum.USER && companyRole === RolesEnum.TRIAL) {
    return "The user's access to company resources is restricted during the trial period.";
  } else if (userRole === RolesEnum.COMPANY_ADMIN && companyRole === RolesEnum.TRIAL) {
    return "The company admin has limited access to the platform during the trial period.";
  } else if (userRole === RolesEnum.COMPANY_ADMIN && companyRole === RolesEnum.PAID) {
    return "The company admin has complete access to the platform within the company.";
  } else if (userRole === RolesEnum.USER && companyRole === RolesEnum.PAID) {
    return "The user has permission to add, edit, approve, and publish company products.";
  } else {
    return ""
  }
};
