export const matchEmailwithDomainName = (email: string) => {
  if (/@automatoai.com\s*$/.test(email)) return true;
  return false;
};

export const removeMultipleSpace = (text: string) => text.replace(/\s\s+/g, " ");

export const fetchAmazonASIN = (input: string): string | false => {
  const asinPattern = /\/(dp|product)\/([A-Z0-9]{10})/;
  const asinOnlyPattern = /^[A-Z0-9]{10}$/;

  if (asinPattern.test(input)) {
    const match = input.match(asinPattern);
    if (match && match[2]) {
      return match[2];
    }
  } else if (asinOnlyPattern.test(input)) {
    return input;
  }

  return false;
};

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
