import React, { useEffect } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { URL_LANDING_PAGE } from "../../routes/routes-path";
import { TabsEnum } from "../../utils/constants/enums";
import { navigatePage } from "../../utils/helpers/common";
const ErrorPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "404 - Page Not Found";
  }, []);
  return (
    <>
      <div className="text">
        <p>404</p>
      </div>
      <div className="container">
        <div className="caveman">
          <div className="leg">
            <div className="foot">
              <div className="fingers"></div>
            </div>
          </div>
          <div className="leg">
            <div className="foot">
              <div className="fingers"></div>
            </div>
          </div>
          <div className="shape">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <div className="head">
            <div className="eye">
              <div className="nose"></div>
            </div>
            <div className="mouth"></div>
          </div>
          <div className="arm-right">
            <div className="club"></div>
          </div>
        </div>

        <div className="caveman">
          <div className="leg">
            <div className="foot">
              <div className="fingers"></div>
            </div>
          </div>
          <div className="leg">
            <div className="foot">
              <div className="fingers"></div>
            </div>
          </div>
          <div className="shape">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <div className="head">
            <div className="eye">
              <div className="nose"></div>
            </div>
            <div className="mouth"></div>
          </div>
          <div className="arm-right">
            <div className="club"></div>
          </div>
        </div>
      </div>
      <span
        onClick={() => {
          navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate);
        }}
        className="back"
      >
        <div id="link">
          <i className="fab fa-codepen"></i>
          <p>Goto Home Page</p>
        </div>
      </span>
    </>
  );
};
export default ErrorPage;
