import * as yup from "yup";

export interface CreateBrandObject {
  name: string;
  alias: string;
  status: boolean;
}

export const inputName = {
  name: "name",
  alias: "alias",
  status: "status",
};

export const initialValue: CreateBrandObject = {
  name: "",
  alias: "",
  status: false,
};

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
});
