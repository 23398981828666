import { SettingsEnum } from "../../../utils/constants/enums";
// import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import autoMatoAccessKeyIcon from "../../../assets/svgs/accessKeysIcons/automato-accesskey-icon.svg";

export const apiAccessKeysMenu = [
    // only adding one for now 
    {
        // title: capitalizeEachLetter(SettingsEnum.AUTOMATO_API_ACCESS.split("-").join(" ")),
        title: "AutoMato API Access",
        description: "Manage and generates API access keys",
        icon: autoMatoAccessKeyIcon,
        name: SettingsEnum.AUTOMATO_API_ACCESS,
    },
]