import React from "react";
import { useFormikContext } from "formik";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import classes from "./index.module.css";

interface FormLanguageModelDropdownProps {
  name: string;
  label?: string;
  optionsList: any[];
  disabled?: boolean;
}
const FormLanguageModelDropdown: React.FC<FormLanguageModelDropdownProps> = ({
  name,
  label,
  optionsList,
  disabled = false,
}) => {
  const { values, setFieldValue, errors }: any = useFormikContext();

  const inputChangeHandler = (e: any) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <FormControl sx={{ marginTop: "16px", width: { xs: "100%" } }}>
      {label && <InputLabel id="language-model-select">{label}</InputLabel>}
      <Select
        labelId="language-model-label"
        id="language-model-select"
        label={label}
        value={values[name]}
        onChange={inputChangeHandler}
        name={name}
        defaultValue={values[name] || ""}
        disabled={disabled}
      >
        {optionsList?.map((option: any) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={`${classes.MenuItem}`}
            disabled={option.status !== "available"}
          >
            {`${option.value}`}
            <span
              className={`ps-1 ${option.status === "available" ? classes.AvailableColor : classes.NotAvailableColor}`}
            >{`(${option.status})`}</span>
          </MenuItem>
        ))}
      </Select>
      <span className={`${classes.ErrorText}`}>{errors[name] && errors[name]}</span>
    </FormControl>
  );
};

export default FormLanguageModelDropdown;
